<span *ngFor="let letter of keys">
  <h2>{{ letter }}</h2>
  <nz-collapse [nzBordered]="false">
    <nz-collapse-panel
      id="{{ panel.name }}"
      *ngFor="let panel of datamodel.glossarMap.get(letter)"
      [nzHeader]="panel.name"
      [nzActive]="panel.active"
      [ngStyle]="panel.customStyle"
      class="margin"
    >
      <p>{{ panel.content }}</p>
      <ng-template #expandedIcon let-active>
        {{ active }}
        <i
          nz-icon
          nzType="caret-right"
          [nzRotate]="panel.active ? 90 : -90"
        ></i>
      </ng-template>
    </nz-collapse-panel>
  </nz-collapse>
</span>
