/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./headerbar.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./logo/logo.component.ngfactory";
import * as i3 from "./logo/logo.component";
import * as i4 from "../../utils/routing.service";
import * as i5 from "./burgermenu/burgermenu.component.ngfactory";
import * as i6 from "./burgermenu/burgermenu.component";
import * as i7 from "../../utils/language.service";
import * as i8 from "../../utils/datamodel.service";
import * as i9 from "./firmabutton/firmabutton.component.ngfactory";
import * as i10 from "./firmabutton/firmabutton.component";
import * as i11 from "../../utils/role.service";
import * as i12 from "../../utils/segment-implementation.service";
import * as i13 from "./notifybutton/notifybutton.component.ngfactory";
import * as i14 from "./notifybutton/notifybutton.component";
import * as i15 from "./dropdown/dropdown.component.ngfactory";
import * as i16 from "./dropdown/dropdown.component";
import * as i17 from "../../guards/auth/auth.service";
import * as i18 from "../../utils/cookies.service";
import * as i19 from "./headerbar.component";
var styles_HeaderbarComponent = [i0.styles];
var RenderType_HeaderbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderbarComponent, data: {} });
export { RenderType_HeaderbarComponent as RenderType_HeaderbarComponent };
export function View_HeaderbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "content headerbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-logo", [["class", "margins"]], null, null, null, i2.View_LogoComponent_0, i2.RenderType_LogoComponent)), i1.ɵdid(2, 49152, null, 0, i3.LogoComponent, [i4.RoutingService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-burgermenu", [["class", "margins burgercenter"]], null, null, null, i5.View_BurgermenuComponent_0, i5.RenderType_BurgermenuComponent)), i1.ɵdid(4, 49152, null, 0, i6.BurgermenuComponent, [i7.LanguageService, i8.DataModelService], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [["class", "fill"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "fill"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-firmabutton", [["class", "select"]], null, null, null, i9.View_FirmabuttonComponent_0, i9.RenderType_FirmabuttonComponent)), i1.ɵdid(8, 180224, null, 0, i10.FirmabuttonComponent, [i7.LanguageService, i8.DataModelService, i11.RoleService, i12.SegmentImplementationService], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-notifybutton", [["class", "margins"]], null, null, null, i13.View_NotifybuttonComponent_0, i13.RenderType_NotifybuttonComponent)), i1.ɵdid(10, 180224, null, 0, i14.NotifybuttonComponent, [i7.LanguageService, i8.DataModelService], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-dropdown", [["class", "margins"]], null, null, null, i15.View_DropdownComponent_0, i15.RenderType_DropdownComponent)), i1.ɵdid(12, 49152, null, 0, i16.DropdownComponent, [i7.LanguageService, i8.DataModelService, i4.RoutingService, i17.AuthService, i18.Cookies], null, null)], null, null); }
export function View_HeaderbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-headerbar", [], null, null, null, View_HeaderbarComponent_0, RenderType_HeaderbarComponent)), i1.ɵdid(1, 49152, null, 0, i19.HeaderbarComponent, [i7.LanguageService], null, null)], null, null); }
var HeaderbarComponentNgFactory = i1.ɵccf("app-headerbar", i19.HeaderbarComponent, View_HeaderbarComponent_Host_0, {}, {}, []);
export { HeaderbarComponentNgFactory as HeaderbarComponentNgFactory };
