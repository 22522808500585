import {
  Component,
  Input,
  QueryList,
  ViewChildren,
  Output,
  EventEmitter,
  TemplateRef
} from '@angular/core';
import { Question } from '../../../../models/question';
import { Segment } from '../../../../models/segment';
import { SegmentEntry } from '../../../../models/segmentEntry';
import { TextResolverService } from '../../../../utils/text-resolver.service';
import { RadioComponent } from '../../questions/radio/radio.component';
import { TextInputComponent } from '../../text-input/text-input.component';
import { QuestionType } from './../../../../models/questionType';
import { SegmentEntryType } from './../../../../models/segmentEntryType';
import { QuestionService } from './../../../../utils/question.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { AddMasterDataEntryComponent } from '../../../../views/application/consulting/dashboard/manage-master-data/components/add-master-data-entry/add-master-data-entry.component';
import { MasterData } from '../../../../models/masterData';
import { SelectComponent } from '../../questions/select/select.component';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css']
})
export class GroupComponent {
  @ViewChildren(RadioComponent) radios: QueryList<RadioComponent>;
  @ViewChildren(SelectComponent) selects: QueryList<SelectComponent>;
  @ViewChildren(TextInputComponent) inputs: QueryList<TextInputComponent>;

  @Input()
  segment: Segment;
  @Input()
  shouldSave: boolean;
  @Input()
  consultMode = false;
  @Input()
  addEntry: TemplateRef<AddMasterDataEntryComponent>;

  _editMode: boolean;

  radioAnswers = new Map<string, string>();

  @Output()
  titleRenamed = new EventEmitter<string>();
  @Output()
  addSegmentEntryClicked = new EventEmitter<void>();
  @Output()
  segmentEntryDoubleClicked = new EventEmitter<SegmentEntry>();
  @Output()
  segmentEntryDropped = new EventEmitter<CdkDragDrop<string[]>>();

  constructor(
    private questionService: QuestionService,
    public textResolverService: TextResolverService
  ) {}

  @Input()
  set editMode(editMode: boolean) {
    if (editMode && !this.consultMode) {
      this.radios.forEach(radio =>
        this.radioAnswers.set(radio.segmentEntry.entry._id, radio.answer)
      );
    }
    if (this._editMode && this.shouldSave) {
      if (this.radios) {
        this.radios.forEach(radio =>
          this.questionService.insertOrUpdateCacheAnswer(
            radio.segmentEntry.entry,
            radio.answer
          )
        );
      }
      if (this.inputs) {
        this.inputs.forEach(input =>
          this.questionService.insertOrUpdateCacheAnswer(
            input.question,
            input.text
          )
        );
      }
      if (this.selects) {
        this.selects.forEach(select =>
          this.questionService.insertOrUpdateCacheAnswer(
            select.question,
            select.answer
          )
        );
      }
      this.questionService.saveCacheAnswer();
    }
    this._editMode = editMode;
  }

  get editMode(): boolean {
    return this._editMode;
  }

  getAnswer(
    question: Question,
    fallback: boolean,
    dropdownAnswerResolved?: boolean
  ): { answer: string; fallback: boolean } {
    if (this.consultMode) {
      return { answer: null, fallback: false };
    } else if (this._editMode && this.isDisabled(question)) {
      return {
        answer:
          question.parentDefinedAnswers[
            question.parentConditions.indexOf(
              this.radioAnswers.get(question.parentQuestionUUID)
            )
          ],
        fallback: false
      };
    } else {
      const answer = this.questionService.getQuestionAnswer(question._id);
      if (answer) {
        return {
          answer:
            this.isDropdown(question) && dropdownAnswerResolved
              ? this.textResolverService.getLanguageText(answer)
              : answer,
          fallback: false
        };
      }
      return {
        answer: fallback
          ? this.isDropdown(question) && !dropdownAnswerResolved
            ? null
            : this.textResolverService.getLanguageText(question.textParts[0])
          : '',
        fallback: true
      };
    }
  }

  isQuestionNotComplete(segmentEntry: SegmentEntry): boolean {
    return (
      !this.hasTextParts(segmentEntry) ||
      !this.hasPredefinedAnswers(segmentEntry)
    );
  }

  hasTextParts(segmentEntry: SegmentEntry): boolean {
    return (
      segmentEntry.entry.textParts && segmentEntry.entry.textParts.length > 0
    );
  }

  hasPredefinedAnswers(segmentEntry: SegmentEntry): boolean {
    return (
      segmentEntry.entry.predefinedAnswers &&
      segmentEntry.entry.predefinedAnswers.length > 0
    );
  }

  hasQuestions(masterData: MasterData): boolean {
    return (
      masterData.questions && masterData.questions.some(qA => qA.length > 0)
    );
  }

  hasSegmentAnyEntries(): boolean {
    return (
      this.segment.segmentEntries && this.segment.segmentEntries.length > 0
    );
  }

  hasSegmentTitle(): boolean {
    return this.segment.title && this.segment.title.length > 0;
  }

  getInputSize(question: Question): string {
    return question.inputSize + 'em';
  }

  // getRadioAnswer(entry: SegmentEntry, question: Question): string {
  //   if (this._editMode && this.isDisabled(question)) {
  //     return question.parentDefinedAnswer;
  //   } else {
  //     return (
  //       this.radioAnswers.get(entry.entry.uuid) ||
  //       this.questionService.getQuestionAnswer(question._id) ||
  //       ''
  //     );
  //   }
  // }

  getStammdatenQuestionTooltip(question: Question): string {
    return this.textResolverService.getLanguageText(question.textParts[0]);
  }

  isRadio(entry: SegmentEntry): boolean {
    return (
      entry.type === SegmentEntryType.QUESTION &&
      entry.entry.type === QuestionType.RADIO
    );
  }

  isTextField(entry: SegmentEntry): boolean {
    return (
      entry.type === SegmentEntryType.QUESTION &&
      entry.entry.type === QuestionType.TEXTFIELD
    );
  }

  isDropdown(question: Question): boolean {
    return question.type === QuestionType.SELECT;
  }

  isInfo(entry: SegmentEntry): boolean {
    return entry.type === SegmentEntryType.INFO;
  }

  isMasterData(entry: SegmentEntry): boolean {
    return entry.type === SegmentEntryType.MASTER_DATA;
  }

  isDisabled(question: Question) {
    return (
      question.parentQuestionUUID &&
      question.parentQuestionUUID.length > 0 &&
      this.radioAnswers.has(question.parentQuestionUUID) &&
      question.parentConditions.some(
        c => c === this.radioAnswers.get(question.parentQuestionUUID)
      )
    );
  }

  handleRadioAnswer(question: Question, answer: string) {
    this.radioAnswers.set(question._id, answer);
  }
}
