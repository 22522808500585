import { LanguageService } from './../../../utils/language.service';
import { DBService } from './../../../utils/dbverbindung.service';
import { Component, OnInit } from '@angular/core';
import { RoutingService } from '../../../utils/routing.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit {
  success = true;
  failed = false;
  constructor(public lang: LanguageService, private dbservice: DBService, public router: RoutingService) {}

  ngOnInit(): void {
    this.dbservice.verifyAccount('uuid').subscribe(success => {
      if (success === true) {
        this.success = true;
      } else {
        this.failed = true;
      }
    });
  }

  onNavigate() {
    const url = 'https://www.eprivacy.eu/kontaktformular/';
    window.open(url, '_blank');
  }
}
