<nz-spin [nzSpinning]="this.authService.loginInitiated">
  <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
    <nz-form-item>
      <nz-form-control
        [nzErrorTip]="this.languageService.translate().accountNotEmpty"
      >
        <nz-input-group nzPrefixIcon="user">
          <input
            type="text"
            nz-input
            formControlName="account"
            [placeholder]="this.languageService.translate().username"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control [nzErrorTip]="pwErrors">
        <nz-input-group nzPrefixIcon="lock">
          <input
            type="password"
            nz-input
            formControlName="pw"
            [placeholder]="this.languageService.translate().password"
          />
        </nz-input-group>
        <ng-template #pwErrors let-control>
          <ng-container *ngIf="control.hasError('required')">
            {{ this.languageService.translate().pwNotEmpty }}
          </ng-container>
          <ng-container *ngIf="control.hasError('noMatch')">
            {{ this.languageService.translate().loginFailed }}
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <a (click)="this.openTempMessage()">{{
      this.languageService.translate().forgotPW
    }}</a>
    <nz-form-item class="login-button-form">
      <nz-form-control>
        <label nz-checkbox formControlName="cookie">
          <span>{{ this.languageService.translate().rememberMe }}</span>
        </label>
        <button
          nz-button
          class="login-form-button"
          [nzType]="'primary'"
          [nzLoading]="this.dbService.userAndCompany"
        >
          {{ this.languageService.translate().login }}
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
