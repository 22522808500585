import { BehaviorSubject } from 'rxjs';
import { LanguageEnglisch } from './../models/language.englisch';
import { Cookies } from './cookies.service';
import { LanguageDeutsch } from '../models/language.deutsch';
import * as i0 from "@angular/core";
import * as i1 from "./cookies.service";
export class LanguageService {
    constructor(cookieService) {
        this.cookieService = cookieService;
        this.language = new BehaviorSubject(null);
        this.engLanguage = new LanguageEnglisch();
        this.deuLanguage = new LanguageDeutsch();
        this.setInitialLanguage();
    }
    translate() {
        return this.language.value;
    }
    setInitialLanguage(lang) {
        let userLang = navigator.language;
        if (lang) {
            userLang = lang;
        }
        else if (this.cookieService.getApplicationCookie().lang) {
            userLang = this.cookieService.getApplicationCookie().lang;
        }
        if (userLang.includes('de')) {
            this.language.next(this.deuLanguage);
        }
        else if (userLang.includes('en')) {
            this.language.next(this.engLanguage);
        }
        else {
            this.language.next(this.engLanguage);
        }
    }
    getCurrentLanguageString() {
        switch (true) {
            case this.language.value instanceof LanguageDeutsch:
                return 'de';
            case this.language.value instanceof LanguageEnglisch:
                return 'en';
            default:
                return 'en';
        }
    }
    getAvailableLanguages() {
        return ['de', 'en'];
    }
}
LanguageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LanguageService_Factory() { return new LanguageService(i0.ɵɵinject(i1.Cookies)); }, token: LanguageService, providedIn: "root" });
