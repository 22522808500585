<div *ngIf="visible" class="height" [ngClass]="{ bannerfixed: fixed }">
  <div class="bannernormal">
    <img class="logo" src="assets/epclogo.png" alt="image" />
    <div nz-row class="row">
      <div nz-col nzSpan="6">
        <a (click)="terms()">{{ lang.translate().agb }}</a>
      </div>
      <div nz-col nzSpan="6">
        <a (click)="impressum()">{{ lang.translate().imprint_header }}</a>
      </div>
      <div nz-col nzSpan="6">
        <a (click)="privacy()">{{ lang.translate().privacypolice }}</a>
      </div>
      <div nz-col nzSpan="6"><a href="https://www.eprivacy.eu/newsletter/">Newsletter</a></div>
    </div>
  </div>
</div>
