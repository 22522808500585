import { Observable } from 'rxjs';
import * as io from 'socket.io-client';
import { Globals } from '../models/globals';
import { DataModelService } from './datamodel.service';
import { User } from '../models/user';
import { isDevMode } from '@angular/core';

export class SocketService {
  private url = new Globals().socketIOURL;
  private socket: any;

  constructor() {
    this.socket = io(this.url, { secure: true });
  }

  public emitUpdate(user: User): void {
    this.socket.emit('update', {
      userUUID: user._id,
      companyUUID: user.companyUUID
    });
  }

  public getSocketID(): string {
    return this.socket.id;
  }

  public getMessages() {
    return new Observable<string>((observer: any) => {
      this.socket.on('connect', () => {
        observer.next(this.socket);
      });
    });
  }
}
