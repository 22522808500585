<p focus="true">
  {{ this.text }}
</p>
<form nz-form [formGroup]="validateForm" (ngSubmit)="this.enterSubmit()">
  <nz-form-item>
    <nz-form-control nzErrorTip="Text darf nicht leer sein!">
      <p-editor
        formControlName="textarea"
        [style]="{ height: '200px' }"
        (onTextChange)="this.listenToSubmit($event)"
      >
        <p-header>
          <span
            class="ql-format-group"
            [ngClass]="{ hidden: disableFormatting }"
          >
            <span class="ql-formats">
              <button class="ql-bold" aria-label="Bold"></button>
              <button class="ql-italic" aria-label="Italic"></button>
              <button class="ql-underline" aria-label="Underline"></button>
            </span>
            <span class="ql-formats">
              <select class="ql-color" style="height: 36px;"></select>
            </span>
            <span class="ql-formats">
              <button class="ql-link" aria-label="Link"></button>
            </span>
          </span>
          <span class="ql-formats">
            <button class="ql-clean" aria-label="Remove"></button>
          </span>
        </p-header>
      </p-editor>
    </nz-form-control>
  </nz-form-item>
</form>
<!--
<form nz-form [formGroup]="validateForm" (ngSubmit)="this.enterSubmit()">
  <nz-form-item>
    <nz-form-control nzErrorTip="Text darf nicht leer sein!">
      <textarea
        nz-input
        formControlName="textarea"
        [nzAutosize]="this.getTextAreaSize()"
        (keydown.enter)="this.enterSubmit($event)"
      ></textarea>
    </nz-form-control>
  </nz-form-item>
</form>
<p class="infotext">
  Um ein Wort <b>Fett</b> zu schreiben, umschließe dies mit Sternen -> *wort*
</p>
<p class="infotext">
  Um ein Wort <i>Kursiv</i> zu schreiben, umschließe dies mit Bindestrichen ->
  -wort-
</p>
<p class="infotext">
  Um ein Wort zu <ins>unterstreichen</ins>, umschließe dies mit Unterstrichen ->
  _wort_
</p>
-->
