<div class="mainContainer">
  <nz-upload
    nzType="drag"
    [(nzFileList)]="this.fileList"
    [nzMultiple]="true"
    [nzDisabled]="this.disabled"
    [nzRemove]="this.handleFileRemoval"
    [nzBeforeUpload]="this.beforeUpload"
    [nzAction]="this.fileService.dbUrl + '/upload'"
  >
    <div class="circle-container-outer">
      <div class="circle-container">
        <div class="img-wrapper">
          <img src="https://img.icons8.com/officexs/80/000000/circled-up.png" />
        </div>
      </div>
    </div>

    <div class="dropInfo text-grey">
      <div><span>DRAG & DROP</span></div>
    </div>
  </nz-upload>
  <div class="progress-bar">
    <nz-progress
      [nzPercent]="this.progress"
      [nzStatus]="this.getActive()"
    ></nz-progress>
  </div>
  <div class="lower-segment">
    <div class="padding-text">
      <div class="text-grey borderSelectedFiles">
        {{ this.getAmountFilesSelectedText() }}
      </div>
    </div>
    <div class="dropDownContainer">
      <nz-select
        class="dropDown"
        [(ngModel)]="chapter"
        [nzPlaceHolder]="this.languageService.translate().chooseChapter"
      >
        <nz-option
          *ngFor="let p of options"
          [nzValue]="p"
          [nzLabel]="this.textResolverService.getLanguageText(p.title)"
          [nzDisabled]="false"
        ></nz-option>
      </nz-select>
    </div>
    <div class="buttonContainer">
      <button
        nz-button
        [nzType]="'primary'"
        [nzLoading]="this.uploading"
        class="default-button wide"
        (click)="uploadFile(this.chapter._id)"
        [disabled]="isDisabled()"
      >
        {{ this.languageService.translate().upload.toUpperCase() }}
      </button>
    </div>
    <nz-collapse class="collapse-container" *ngIf="this.doesFileExist()">
      <nz-collapse-panel [nzHeader]="collapseHeader" [nzActive]="this.isActive">
        <div class="errorEntry" *ngIf="this.fileExistsInQueue">
          <span>
            {{ this.languageService.translate().filesAlreadyInQueue }}
          </span>
        </div>

        <div class="errorList" *ngIf="this.fileExistsInQueue">
          <div *ngFor="let file of this.filesAlreadyInQueue">
            <b>{{ file }}</b>
          </div>
        </div>

        <nz-divider
          *ngIf="this.fileExistsInQueue && this.fileExistsInAudit"
        ></nz-divider>

        <div class="errorEntry" *ngIf="this.fileExistsInAudit">
          <span>
            {{ this.languageService.translate().filesAlreadyUploaded }}
          </span>
        </div>

        <div class="errorList" *ngIf="this.fileExistsInAudit">
          <div *ngFor="let fileName of this.fileNamesAlreadyInAudit">
            <b>{{ fileName }}</b>
          </div>
        </div>

        <div class="errorEntry" *ngIf="this.fileExistsInAudit">
          <span>
            {{ this.languageService.translate().filesAlreadyInChapters }}
          </span>
        </div>

        <div class="errorList" *ngIf="this.fileExistsInAudit">
          <div *ngFor="let chapterName of this.chapterNamesOfExistingFile">
            <b>{{ chapterName }}</b>
          </div>
        </div>
      </nz-collapse-panel>
    </nz-collapse>

    <ng-template #collapseHeader>
      <span class="errorText">{{
        this.languageService.translate().filesCouldNotBeAdded
      }}</span>
    </ng-template>
  </div>
</div>
