import { LanguageInterface } from './language.interface';

export class LanguageEnglisch implements LanguageInterface {
  login = 'Login';
  informations = 'Informations';
  contact = 'Contact';
  imprint_header = 'Imprint';
  imprint_content = [
    'ePrivacy GmbH',
    'represented by',
    'Prof. Dr. Christoph Bauer',
    'Große Bleichen 21, 20354 Hamburg',
    'Telefon: +49 40 609451 810',
    'Fax: +49 40 609451 820 ',
    'AG Hamburg, HRB 118163',
    'UStID DE277922094',
    '',
    'Below you will find a list of the Adobe Stock images used:',
    '© StockPhotoPro',
    '© Tiko',
    '© ipopba',
    '© rogerphoto',
    '© joyfotoliakid'
  ];
  privacypolice = 'Privacy Policy';
  agb = 'Terms and Conditions';
  contactform = 'Contact Form';
  name = 'English';
  account = 'Account';
  logout = 'Logout';
  settings = 'Settings';
  language = 'Language';
  username = 'Username';
  password = 'Password';
  home = 'HOME';
  introduction = 'INTRODUCTION';
  audit = 'AUDIT';
  uploads = 'UPLOADS';
  files = 'Files';
  messages = 'Messages';
  glossar = 'GLOSSAR';
  downloads = 'DOWNLOADS';
  history = 'HISTORY';
  manageManual = 'Manage manual';
  reportDesigner = 'Report Designer';
  manual = 'MANUAL';
  manualIntroTitle = 'Welcome to the ePrivacyaudit';
  blogLink = 'NEWS';
  blogLang = 'en';
  noManualAvailable = 'Sorry, there is currently no manual available';
  support = 'SUPPORT';
  faqs = 'FAQs';
  implementation = 'IMPLEMENTATION';
  praxistips = 'TIPS';
  more = 'MORE';
  less = 'LESS';
  notes = 'Notes';
  ok = 'Ok';
  cancel = 'Cancel';
  documents = 'DOCUMENTS';
  stammdaten = 'MASTER DATA';
  edit = 'EDIT';
  save = 'SAVE';
  consulting = 'Consulting';
  manageAudit = 'Manage Audit';
  manageMasterData = 'Manage Master Data';
  accessDeniedTitle = 'Access denied';
  accessDeniedText = 'You are not authorized to access this page!';
  templates = 'TEMPLATES';
  enterUserName = 'Please enter your username';
  requestNewPassword = 'Recovery request received';
  requestNewPasswordSubtext =
    'If your account is registered, you will immediately receive a recovery link';
  documentManagement = 'DOCUMENTS';
  next = 'NEXT';
  prev = 'PREV';
  rememberMe = 'Remember me';
  accountNotEmpty = 'Please enter your account name!';
  pwNotEmpty = 'Please enter your password!';
  loginFailed = 'Account name and / or password is wrong!';
  notAvailableDemoTitle = 'Under way';
  notAvailableDemoText =
    'This chapter is currently under construction and will be available soon. In the section “templates,” you can already find templates to cover this topic. If you have current documents concerning this chapter, you can upload them for review.';
  landing_tiles = [
    [
      'Welcome to ePrivacyaudit - the smart data protection management software',
      'ePrivacyaudit is our innovative software for the easy management of data protection documentation. This handy tool includes numerous sample templates to help you create the documents you need. As a central platform for managing all relevant documents, ePrivacyaudit can be used by several users in the company. The easy-to-handle, user-friendly interface gives you a good overview.'
    ],
    [
      'Years of experience in a software format',
      'ePrivacyaudit has been developed by lawyers, data protection officers and IT specialists and offers an efficient tool for those in charge of data protection. We have incorporated our years of expertise and knowledge from consulting companies with digital products and services into the software. ePrivacyaudit can be used by companies of different sizes as well as by external and internal data protection officers.'
    ],
    [
      'The data protection documentation always up to date and accessible at any time.',
      'ePrivacyaudit enables very simple updates of already created documents, so that your data protection documentation is always up-to-date. The software guides you through all areas of a company which are affected by the GDPR. The current status of your GDPR compliance is recorded and you always have an overview as well as the necessary assistance with the implementation of open topics. Access to ePrivacyaudit is possible from anywhere at any time and enables you to work flexibly.'
    ],
    [
      'The sample templates simplify the creation of documentation and save time.',
      'Using our sample templates for documentation creation and updating makes your work easier and also saves you a lot of time. The accuracy of the provided information is checked by experienced data protection lawyers. The software and all templates are available in German and English.'
    ],
    [
      'The following modules are included in the ePrivacyaudit:',
      '• Data processing in compliance with data protection regulations\n' +
        '• Information duties\n' +
        '• Processing overview\n' +
        '• TOMs\n' +
        '• Process in case of a data breach (notification within 72 hours)\n' +
        '• Data processing agreements\n' +
        '• Data protection management system\n' +
        '• And further...'
    ]
  ];

  privacypolice_header = 'EPRIVACY GMBH PRIVACY POLICY';
  privacypolice_content: JSON = <JSON>(<unknown>{
    '1': {
      '': {
        '1':
          'With this privacy polocy we would like to inform you about how we process personal data. The protection of your privacy is of the utmost importance to us, which is why compliance with the legal provisions on data protection is a matter of course for us.'
      }
    },
    '2': {
      'Name and contact details of the person responsible': {
        '1': 'ePrivacy GmbH',
        '2': 'represented by Prof. Dr. Christoph Bauer',
        '3': 'Große Bleichen 21, 20354 Hamburg',
        '4': '',
        '5': 'info@eprivacy.eu'
      }
    },
    '3': {
      'Personal data': {
        '1':
          'Personal data is any information about an identified or identifiable person. This includes the following categories of personal data that we process:   ',
        '2': [
          'Contact information (such as e-mail address, name and job position)',
          'Correspondence with us',
          'Online identifiers (e.B. Your IP address, browser type and browser version, the related operating system, the referrer URL, the file name, the access status, the amount of data transferred, the date and time of the server request)'
        ]
      }
    },
    '4': {
      'Purpose of Processing': {
        '1': 'We process your data for the following purposes:',
        '2': [
          'For the contact you have requested,',
          'to process contracts with you (project management, external data protection officer),',
          'to provide our service',
          'for quality assurance and',
          'for our statistics.'
        ]
      }
    },

    '5': {
      'Legal basis of data processing': {
        '1':
          'The processing of your data takes place on the following legal bases:',
        '2': [
          'for the execution of a contract with you in accordance with Art. 6 para. 1 lit.b) GDPR',
          'to fulfil legal obligations pursuant to Art. 6 para. 1 lit.c) GDPR or',
          'for a legitimate interest pursuant to Art. 6 para. 1 lit. f) GDPR.'
        ]
      }
    },
    '6': {
      'Legitimate interests': {
        '1':
          'If we base the processing of your personal data on legitimate interests within the meaning of the German Federal Republic of Germany. Art. 6 para. 1 lit. f) GDPR, such are',
        '2': [
          'the improvement of our offer,',
          'the protection of our systems against misuse and',
          'the compilation of statistics.'
        ]
      }
    },
    '7': {
      'Recipients or categories of recipients of personal data': {
        '1':
          'When processing your data, we work with the following service providers who have access to your data:',
        '2': ['Web-Hosting', 'Partner law firm']
      }
    },
    '8': {
      'Storage Period': {
        '1':
          'We store your personal data only for as long as it is necessary to achieve the processing purpose or the storage is subject to a statutory retention period.',
        '2': 'We store your data,',
        '3': [
          'if we need the data for the execution of a contract, at most as long as the contractual relationship with you exists or legal retention periods run,',
          'if we use the data on the basis of a legitimate interest, at most as faras your interest in deletion or anonymisation does not prevail or until you object to the processing of your data.'
        ]
      }
    },

    '9': {
      'Your rights': {
        '1': 'You have the right – sometimes under certain conditions – to:',
        '2': [
          'to request information about the processing of your data free of charge, as well as the receipt of a copy of your personal data. You can request information, among other things, about the purposes of the processing, the categories of personal data that are processed, the recipients of the data (if a transfer takes place), the duration of the storage or the criteria for determining the duration;',
          'Correct your data. If your personal data is incomplete, you have the right to complete the data, taking into account the purposes of the processing;',
          'Delete or block your data. Reasons for the existence of a right to erasure/blocking may include, among other things, the revocation of consent on which the processing is based, the data subject objects to the processing, the personal data have been unlawfully processed;',
          'restrict processing;',
          'object to the processing of your data;',
          'revoke your consent to the processing of your data for the future and',
          'to complain to the competent supervisory authority about inadmissible data processing.'
        ]
      }
    },
    '10': {
      'Requirement or obligation to provide data': {
        '1':
          'Unless this is expressly stated in the collection, the provision of data is not necessary or mandatory. Such an obligation may arise from legal requirements or contractual regulations.'
      }
    },

    '11': { 'Further information on data protection': { '1': '' } },

    '12': {
      Logfiles: {
        '1':
          'Each time you access our Internet pages, usage data is transmitted by the respective Internet browser and stored in log files, the so-called server log files. The stored data records contain the following data: Date and time of retrieval IP address of the accessing computer. These log file records are evaluated in anonymous form in order to find and correct errors or for fraud prevention.'
      }
    },

    '13': {
      Cookies: {
        '1':
          'A cookie is a text file with an identification number that is transmitted to the users computer when using the website together with the other data actually requested and stored there. The file is kept there for later access and serves to authenticate the user. Since cookies are only simple files and not executable programs, they do not pose a threat to the computer.',
        '2':
          'Depending on the selected setting of the Internet browser by the user, this automatically accepts cookies. However, this setting can be changed and the storage of cookies can be deactivated or set in such a way that the user is notified as soon as a cookie is set. If the use of cookies is deactivated,  some functions of the website may not be available or may only be available to a limited extent. You can prevent the setting of cookies by our website at any time by means of a corresponding setting of the Internet browser used and thus permanently object to the setting of cookies.',
        '3':
          'Cookies that are already active can be deleted at any time via an Internet browser or other software programs. '
      }
    },

    '14': {
      'Persistent Cookies': {
        '1':
          'You have the option of setting a "Remember-me" cookie to simplify the registration process for your account.  This persistent cookie remains on your device after you close your browser. The term of operation corresponds to one year. After that, they are automatically deleted.'
      }
    },

    '15': {
      'Status of this data protection declaration:': {
        '1': 'When our processes change, we adjust the information. ',
        '2': 'Status of the privacy policy: June 2022'
      }
    }
  });
  terms_header = 'GENERAL LICENSE TERMS FOR THE USE OF THE EPRIVACYAUDIT';
  terms_content: JSON = <JSON>(<unknown>{
    '1': {
      '1. General, scope of application of the license': [
        'ePrivacy GmbH (hereinafter referred to as “ePrivacy”) offers an auditing software called “ePrivacyaudit” (hereinafter also referred to uniformly as the “IT Product”) to prepare for implementation under the requirements of the General Data Protection Regulation (GDPR).',
        'The following provisions govern the use of ePrivacyaudit as well as the transfer of rights of use (license) to the corresponding software.'
      ]
    },
    '2': {
      '2. License': [
        '2.1. Scope of the rights of use - According to the following provisions, ePrivacy transfers to the licensee the non-exclusive and non-transferable right to use the software forming the basis of the ePrivacyaudit.',
        '2.2. The licensee is obligated not to change, edit or decompile this software. He is also obligated neither to circumvent the security measures nor to upload any racist, anti-constitutional, pornographic or unethical content.',
        '2.3. The rights of use shall be granted to the licensee for a limited period of time, as governed precisely in the contract.'
      ]
    },
    '3': {
      '3. License fee, remuneration': [
        '3.1. There are two options how to use the software. The so-called quickcheck and the data privacy statement generator are free of charge. The license model is against payment. In the latter case, the licensee is obligated to pay the individually agreed user fee to ePrivacy for use of the ePrivacyaudit.',
        '3.2. Services going beyond this shall be remunerated in addition, unless agreed otherwise by the parties.'
      ]
    },
    '4': {
      '4. Self-promotion / Publication of the seal': [
        'ePrivacy is entitled to refer to the cooperation with the licensee within the scope of its self-promotion, provided that the parties have not agreed otherwise.'
      ]
    },
    '5': {
      '5. Liability': [
        '5.1. ePrivacy excludes any liability for simply negligent breaches of duty, provided that they do not concern material contract duties, life, health or body are not affected or there are no claims in accordance with the “Produkthaftungsgesetz” [German law on product liability] or from a warranty. The same applies for breaches of duty by representatives and vicarious agents.',
        '5.2. Liability for other damages which arise as a result of default, through impossibility which ePrivacy is responsible for or the breach of a material contract duty, is limited to damages which are foreseeable and typical to the contract. Liability for indirect damages, in particular pecuniary damages, is excluded.',
        '5.3. Liability is limited to a maximum of 10,000.00 euros per year depending on the amount. ePrivacy is not liable for changes in the case law and in the legal framework conditions.',
        'ePrivacy points out that the ePrivacyaudit is oriented towards the data protection provisions of the GDPR. Due to the ill-defined outlines of the GDPR, ePrivacy expressly points out, however, that even when the auditing criteria of the ePrivacyaudit are met in full, this does not mean that any violation of the data protection law provisions of the GDPR is excluded. The ePrivacyaudit offers support when implementing the criteria to be met through the GDPR. ePrivacy cannot exclude, however, that a court or data protection authority or a comparable institute which addresses this issue represents other legal opinions and makes more demands than those according to the ePrivacyaudit. ePrivacy therefore excludes any liability in this case.',
        '5.4 The liability of ePrivacy GmbH for the accuracy and completeness of the content of the texts created with the data protection declaration generator is excluded. Before using the texts created by you, please check whether the proposed text modules comply with the legal requirements for data processing in your company and whether further or other information may be required.'
      ]
    },
    '6': {
      '6. No legal advice': [
        'ePrivacy does not provide any legal advice within the meaning of the “Rechtsdienstleistungsgesetz” [German law on legal services]. ePrivacy cannot and may not perform an individual legal audit. A positive audit result therefore does not include in particular the statement that the licensee’s IT Product corresponds with all relevant legal provisions, in particular the requirements of the “Bundesdatenschutzgesetz” [German federal law on data protection] or “Telemediengesetz” [German law on telecommunications] or the European data protection regulations (GDPR etc.).'
      ]
    },
    '7': {
      '7. Confidentiality': [
        '7.1. Each party is obligated to treat all information of a commercial or technical nature from the other party in verbal, written or other form, to which they receive access in connection with this agreement, in particular information about company and industry secrets (e.g. technical processes, formulae, source codes, product designs, costs and other undisclosed financial information and other marketing data) as well as the conclusion of this agreement and its contents, with confidentiality and are obligated not to pass it on to third parties either in whole or in part. Each party shall only use the aforementioned information for the purposes of performing the contractual services and not for other purposes of their own or third parties.',
        '7.2. Excluded from the obligations mentioned in Section 7.1 is information which evidently',
        'a) is generally accessible at the time knowledge was obtained or later, or is the state of the art or',
        'b) was already known to the receiving party at the time knowledge was obtained or',
        'c) is or was made accessible to the receiving party by third parties which obtained the information legally and which are authorized to pass on the information or',
        'd) was excluded from these obligations beforehand in writing by the conceding party.',
        '7.3. The obligations governed in this Section 7 shall also continue to apply after the termination of this agreement for the duration of 2 years.'
      ]
    },
    '8': {
      '8. Final provisions': [
        '8.1. Should a provision of this agreement be invalid, the remaining provisions shall remain unaffected by this. The invalid provision is to be replaced by a provision which comes closest to the economic purpose of the invalid provision in an effective way. This also applies should this agreement contain any loopholes.',
        '8.2. All changes and additions to this contract require the written form in order to be effective. This also applies to any changes to or revocation of this clause on the written form.',
        '8.3. This agreement is subject to German law. The exclusive court of jurisdiction for all disputes arising from this contractual relationship is Hamburg, provided it is legally permitted.'
      ]
    }
  });
  manageTemplates = 'Manage Templates';
  deleteDocumentTitle = 'Delete Document';
  deleteDocumentText1 =
    'Are you sure you want to delete the following document?';
  deleteDocumentText2 = "This action can't be undone!";
  delete = 'DELETE';
  download = 'DOWNLOAD';
  upload = 'Upload';
  uploading = 'Uploading...';
  uploadedBy = 'Uploaded by';
  uploadComponentHint1 = 'Click or drag file to this area to upload';
  uploadComponentHint2 = 'Support for a single or bulk upload.';
  manageUsersCompanies = 'Users and Companies';
  MR = 'Mr';
  MRS = 'Mrs';
  test = 'Test version';
  full = 'Full version';
  fullWithConsulting = 'Full version with consulting';
  fullWithDSB = 'Full version with DSB';
  withoutAudit = 'Client without Audit';
  active = 'Active';
  terminated = 'Terminated';
  onHold = 'On-Hold';
  de = 'German';
  en = 'English';
  back = 'Back';
  companyname = 'Company name';
  legalForm = 'Legal form';
  street = 'Street';
  plz = 'Zip code';
  city = 'City';
  country = 'Country';
  userInformations = 'User informations';
  firstname = 'Firstname';
  lastname = 'Lastname';
  prefLanguage = 'Language';
  emailAddress = 'Email address';
  phone = 'Phone number';
  message = 'Message';
  companyinformations = 'Company informations';
  formOfAddress = 'Form of address';
  register = 'Register';
  send = 'Send';
  documentManagement_intro1 =
    ' Here you can manage your documents and records and thus keep track of your implementation status. The files can be deleted or downloaded. Samples and templates for download can be found in the section Templates';
  documentManagement_intro2 =
    'You can upload a new document here, or in the respective chapter';
  documentManagement_heading = 'DOCUMENTS';
  downloadComp_recent = 'Recent Uploads';
  downloadComp_history = 'Additional Uploads';
  downloadComp_noactivity = 'No Activities';
  documentManagement_button = 'Upload';
  noTemplate = 'No Templates';
  noDocument = 'No Documents';
  uploadedDocumentExistsInQueue = 'The selected file is already in queue!';
  uploadedDocumentExistsInAudit =
    'The selected file has already been uploaded in chapter:';
  notificationSuccessTitle = 'Saved';
  notificationSuccessMessage = 'Action has been saved successfully';
  notificationErrorTitle = 'Not saved!';
  notificationErrorMessage =
    "Action couldn't be saved successfully! Please refresh this site and repeat your last action!";
  establishVerifyConnection =
    'We are currently connecting to the database and activating your account.';
  processVerify = 'Your account is being unlocked';
  processVerifySuccessed = 'Your account is now unlocked.';
  establishVerifyConnectionSuccessed =
    'We have activated your account. You can now log in.';
  processVerifyFailed = 'Your account was not unlocked';
  establishVerifyConnectionFailed =
    'We could not activate your account. Please contact us.';
  processRequestAccount = 'Your account has been requested';
  processRequestAccountMessage = 'We will get back to you via e-mail. ';
  processContactRequest = 'Your request has been submitted';
  processContactRequestMessage = 'We will get back to you via e-mail shortly.';
  captchaError = 'Captcha could not be resolved';
  disclaimer =
    'I agree that ePrivacy GmbH may contact me via the data, I have provided in order to process my request. I can revoke this consent at any time under info@eprivacy.eu. Further information can be found in the privacy policy.';
  welcomeContactTitle = 'Welcome to the ePrivacyaudit';
  welcomeContact =
    "We are pleased that you want to try our simple and user-friendly solution for your company's data protection. To contact you regarding an account, we need some information:";
  deleteNoticeTitle = 'Delete Note';
  deleteNoticeMessage =
    'Are you sure you want to delete this note? This action cannot be undone!';
  chapterStatusNew = 'New';
  chapterStatusInProgress = 'Work in progress at the company';
  chapterStatusInReview = 'Being reviewed by ePrivacy';
  chapterStatusDone = 'Completed';
  chapterStatusNewExplanation =
    'So far no details have been given in the implementation and no documents have been uploaded';
  chapterStatusInProgressExplanation =
    'You have already given details in the implementation or uploaded documents';
  chapterStatusInReviewExplanation =
    'You have submitted this chapter for review.It is not possible to upload new files in this chapter during the check or to use the implementation function. Please address your contact person or service@eprivacy.eu if you would like to unlock the chapter or add documents.';
  chapterStatusDoneExplanation = '';
  shortVersion = 'Short version';
  longVersion = 'Long version';
  manageTags = 'Tag Management';
  chapterToInReviewTitle = 'To review';
  chapterToInReview =
    'Please note that it is not possible to upload new files in this chapter during the check or to use the section “implementation” of the chapter.\n\nYou will receive a mail notification as soon as the checked documents are available for you in the ePrivacyaudit.\n\nPlease confirm that you would like to put the chapter in review.';
  chapterToDoneTitle = 'Complete chapter';
  chapterToDone =
    'Please note that it is not possible to upload new files in the chapter after finishing the chapter or to use the “implementation” section. The chapter is displayed as completed on the “Home” page.\n\nHowever, you can release the chapter for editing at any time.\n\nPlease confirm that you would like to close the chapter.';
  chapterBackToInProgressTitle = 'Unlock';
  chapterBackToInProgress =
    'You have completed this chapter. When you open the chapter again, the status will change into "In Progress".';
  chapterBackToInProgressWithConsulting =
    'You have completed this chapter. When you open the chapter again, the status will change into "In Progress".\n\nYou will have to let the chapter be checked again after editing. \n\nPlease confirm that you would like to unlock the chapter again.';
  chapterStatusToInReviewButton = 'REVIEW';
  chapterStatusToDoneButton = 'DONE';
  chapterStatusToInProgressButton = 'UNLOCK';
  progress = 'Progress';
  statistic = 'Statistic';
  uploadedDocuments = 'Uploaded documents';
  lastLoggedIn = 'Last login';
  urlNotValid =
    'Sorry - the requested URL is not valid anymore!\nPlease contact us if you need help regarding this matter.';
  pwChangedSuccesfully =
    'Your password has been changed successfully!\nYou can now use your new password to log in.';
  waitForVerification = 'Waiting for verification...';
  landingPage = 'Landing Page';
  forgotPW = 'Forgot your password?';
  forgotPWTitle = 'Forgot Password';
  forgotPWMessage =
    'If you forgot your password or username please contact\n\n<b>service@eprivacy.eu</b>\n\nProvide your username and we will reach out to you!';
  filesSelectedSingular = 'FILE SELECTED';
  filesSelectedPlural = 'FILES SELECTED';
  chooseChapter = 'Select chapter...';
  filesAlreadyInQueue = 'The following files are already in the queue:';
  filesAlreadyUploaded = 'The following files have already been uploaded:';
  filesAlreadyInChapters = 'Those files are in these chapters:';
  filesCouldNotBeAdded = 'Some files could not be added!';
  close = 'Close';
  templateIntroductionText =
    'Below you find our templates concerning data protection. When using the templates, make sure to adapt them to your data processes.';
  gbWarning =
    'In case you want to set up your documentation in order to comply to the british UK GDPR please pick the template with the abbreviation "UK" at the beginning.';
  generateReportButton = 'GENERATE REPORT';
  dropdownSelect = 'Select...';
  userLocked = 'Validity';
}
