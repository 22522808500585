import { Component } from '@angular/core';
import { AuthService } from '../../../guards/auth/auth.service';
import { Roles } from '../../../models/roles';
import { RoutingService } from '../../../utils/routing.service';
import { Cookies } from './../../../utils/cookies.service';
import { DataModelService } from './../../../utils/datamodel.service';
import { LanguageService } from './../../../utils/language.service';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent {
  consult = Roles.CONSULT;

  constructor(
    public lang: LanguageService,
    public dataModel: DataModelService,
    private routerService: RoutingService,
    private authService: AuthService,
    private cookieService: Cookies
  ) {}

  navigateToAccount() {
    this.routerService.navigateToAccount();
  }

  navigateConsulting() {
    this.routerService.navigateToConsulting();
  }

  logout() {
    this.cookieService.removeCookies();
    this.routerService.navigateToLanding();
    this.authService.isLoggedIn = false;
  }

  switchLang(lang: string): void {
    this.lang.setInitialLanguage(lang);
    this.dataModel.setUserLang(lang);
  }

  getName(): string {
    return this.dataModel.user
      ? this.dataModel.user.firstName + ' ' + this.dataModel.user.lastName
      : 'not logged in';
  }
}
