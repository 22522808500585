<div class="topWidth">
  <app-landinglogo></app-landinglogo>
  <app-landingtoprightrow></app-landingtoprightrow>
</div>

<nz-result [nzTitle]="lang.translate().processContactRequest" [nzSubTitle]="lang.translate().processContactRequestMessage"> </nz-result>

<div id="footernavi">
  <app-landingfooter></app-landingfooter>
</div>
