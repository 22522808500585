/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rename.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng-zorro-antd/icon";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "./rename.component";
import * as i5 from "ng-zorro-antd/modal";
var styles_RenameComponent = [i0.styles];
var RenderType_RenameComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RenameComponent, data: {} });
export { RenderType_RenameComponent as RenderType_RenameComponent };
export function View_RenameComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [["class", "editIcon"], ["nz-icon", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.renameDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 2834432, null, 0, i2.NzIconDirective, [i2.NzIconService, i1.ElementRef, i1.Renderer2, i3.Platform], { nzType: [0, "nzType"] }, null)], function (_ck, _v) { var currVal_0 = "edit"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_RenameComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-rename", [], null, null, null, View_RenameComponent_0, RenderType_RenameComponent)), i1.ɵdid(1, 180224, null, 0, i4.RenameComponent, [i5.NzModalService], null, null)], null, null); }
var RenameComponentNgFactory = i1.ɵccf("app-rename", i4.RenameComponent, View_RenameComponent_Host_0, { title: "title", text: "text", name: "name", minRows: "minRows", maxRows: "maxRows" }, { renamed: "renamed" }, []);
export { RenameComponentNgFactory as RenameComponentNgFactory };
