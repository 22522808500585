import { CookieService } from 'ngx-cookie-service';
import { CookieApplication } from '../models/cookieApplication';
import { CookieUser } from '../models/cookieUser';
import * as i0 from "@angular/core";
import * as i1 from "ngx-cookie-service";
export class Cookies {
    constructor(cookieService) {
        this.cookieService = cookieService;
        this.cookieNameUser = 'eprivacyaudit.eu';
        this.cookieNameApplication = 'eprivacyaudit.eu_application';
    }
    hasAnyCookie() {
        return (this.cookieService.check(this.cookieNameUser) ||
            this.cookieService.check(this.cookieNameApplication));
    }
    hasUserCookie() {
        return this.cookieService.check(this.cookieNameUser);
    }
    setUserCookie(hash) {
        const cookie = this.getUserCookie();
        cookie.hash = hash;
        this.cookieService.set(this.cookieNameUser, JSON.stringify(cookie), 0, '/');
    }
    setLangInApplicationCookie(lang) {
        const cookie = this.getApplicationCookie();
        cookie.lang = lang;
        this.cookieService.set(this.cookieNameApplication, JSON.stringify(cookie), 0, '/');
    }
    getUserCookie() {
        const cookie = this.cookieService.get(this.cookieNameUser);
        const cookieUser = cookie ? JSON.parse(cookie) : new CookieUser();
        return cookieUser;
    }
    getApplicationCookie() {
        const cookie = this.cookieService.get(this.cookieNameApplication);
        const cookieApplication = cookie
            ? JSON.parse(cookie)
            : new CookieApplication();
        return cookieApplication;
    }
    removeCookies() {
        this.cookieService.delete(this.cookieNameUser);
    }
}
Cookies.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Cookies_Factory() { return new Cookies(i0.ɵɵinject(i1.CookieService)); }, token: Cookies, providedIn: "root" });
