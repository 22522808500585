import { Injectable } from '@angular/core';

@Injectable({
  // damit der service ein singleton ist
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn = false;
  desiredRoute = 'home';
  // if cookie is set and login is being processed automatically
  loginInitiated: boolean;

  constructor() {
    this.loginInitiated = true;
  }
}
