import { NzCarouselComponent } from 'ng-zorro-antd';
import { EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Component, Output } from '@angular/core';
import { LanguageService } from '../../../utils/language.service';
import { DataModelService } from '../../../utils/datamodel.service';

@Component({
  selector: 'app-landingcarousel',
  templateUrl: './landingcarousel.component.html',
  styleUrls: ['./landingcarousel.component.css']
})
export class LandingCarouselComponent {
  isPaused = false;
  canWheelScroll = true;

  @ViewChild('carousel', { static: false })
  carousel: NzCarouselComponent;

  @Output()
  index: EventEmitter<number> = new EventEmitter<number>();

  constructor(public lang: LanguageService, public datamodel: DataModelService) {}

  change(number: number): void {
    this.index.emit(number);
  }
  onMouseWheel(event) {
    if (this.canWheelScroll) {
      this.canWheelScroll = false;

      setTimeout(x => {
        this.canWheelScroll = true;
      }, 2000);
      if (event.deltaY < 0) {
        this.carousel.pre();
      } else if (event.deltaY > 0) {
        this.carousel.next();
      }
    }
  }
}
