<div>
  <div class="rightDiv">
    <div class="centerFlaggen">
      <img
        class="flaggeDeu button"
        (click)="this.switchLang('de')"
        [ngClass]="{
          notClickable: lang.translate().name === 'Deutsch',
          clickable: lang.translate().name === 'English'
        }"
        src="../../../../assets/lang_de.png"
      />
      <img
        class="flaggeDeu button"
        (click)="this.switchLang('en')"
        [ngClass]="{
          notClickable: lang.translate().name === 'English',
          clickable: lang.translate().name === 'Deutsch'
        }"
        src="../../../../assets/lang_en.png"
      />
    </div>
    <app-landinglogin
      class="centerFlaggen"
      *ngIf="this.login"
    ></app-landinglogin>
    <button
      class="button centerFlaggen noshadow requestButton"
      nz-button
      (click)="contact()"
    >
      {{ lang.translate().contact }}
    </button>
  </div>
</div>
