import { Roles } from '../models/roles';
import { DataModelService } from './datamodel.service';
import * as i0 from "@angular/core";
import * as i1 from "./datamodel.service";
export class RoleService {
    constructor(dataModel) {
        this.dataModel = dataModel;
    }
    isConsultOrHigher() {
        return (this.dataModel.user.role === Roles.CONSULT ||
            this.dataModel.user.role === Roles.ADMIN);
    }
}
RoleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RoleService_Factory() { return new RoleService(i0.ɵɵinject(i1.DataModelService)); }, token: RoleService, providedIn: "root" });
