import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  lastRequestResult: boolean = null;
  requestResultDuration = 3000;

  constructor(
    private nzNotifyService: NzNotificationService,
    private langService: LanguageService
  ) {}

  requestResultNotification(
    res: boolean,
    shouldSuppressNotification?: boolean
  ): void {
    if (!shouldSuppressNotification) {
      if (!res && (this.lastRequestResult === null || this.lastRequestResult)) {
        this.lastRequestResult = res;
        this.nzNotifyService.error(
          this.langService.translate().notificationErrorTitle,
          this.langService.translate().notificationErrorMessage,
          { nzDuration: this.requestResultDuration }
        );
        setTimeout(
          () => (this.lastRequestResult = null),
          this.requestResultDuration
        );
      } else if (res && this.lastRequestResult === null) {
        this.lastRequestResult = res;
        this.nzNotifyService.success(
          this.langService.translate().notificationSuccessTitle,
          this.langService.translate().notificationSuccessMessage,
          { nzDuration: this.requestResultDuration }
        );
        setTimeout(
          () => (this.lastRequestResult = null),
          this.requestResultDuration
        );
      }
    }
  }
}
