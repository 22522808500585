import { Component } from '@angular/core';

@Component({
  selector: 'app-placeholder-card',
  templateUrl: './placeholder-card.component.html',
  styleUrls: ['./placeholder-card.component.css']
})
export class PlaceholderCardComponent {
  constructor() {}
}
