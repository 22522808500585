import { Component, Input } from '@angular/core';
import { LanguageService } from '../../../utils/language.service';

@Component({
  selector: 'app-landinglogin',
  templateUrl: './landinglogin.component.html',
  styleUrls: ['./landinglogin.component.css']
})
export class LandingLoginComponent {
  constructor(public languageService: LanguageService) {}
}
