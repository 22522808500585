import { v4 } from 'uuid';
import { ChapterCard } from './chapterCard';

const INTRODUCTION_IMAGE_PATH =
  'assets/chapter-icons/icons8-lesezeichen-480.png';
const IMPLEMENTATION_IMAGE_PATH =
  'assets/chapter-icons/icons8-oeffnen-im-browser-480.png';
const DOKUMENTS_IMAGE_PATH = 'assets/chapter-icons/icons8-pruefen-480.png';

export class Chapter {
  constructor(
    public _id: string,
    public title: string,
    public shortDescription: string,
    public longDescription: string,
    public order: number,
    public blocked: boolean,
    public hidden: boolean,
    public chapterCards: ChapterCard[],
    public reportUUID: string
  ) {}

  static CLEAN_INSTANCE(title: string, order: number): Chapter {
    return new Chapter(
      v4(),
      title,
      '',
      '',
      order,
      true,
      true,
      [
        ChapterCard.CLEAN_INSTANCE('introduction', INTRODUCTION_IMAGE_PATH),
        ChapterCard.CLEAN_INSTANCE('implementation', IMPLEMENTATION_IMAGE_PATH),
        ChapterCard.CLEAN_INSTANCE('files', DOKUMENTS_IMAGE_PATH)
      ],
      null
    );
  }

  toJSON() {
    return {
      _id: this._id,
      title: this.title,
      shortDescription: this.shortDescription,
      longDescription: this.longDescription,
      order: this.order,
      blocked: this.blocked,
      hidden: this.hidden,
      chapterCards: this.chapterCards.map(card => card._id),
      reportUUID: this.reportUUID
    };
  }
}
