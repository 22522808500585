<div *ngIf="!this.authService.isLoggedIn">
  <div class="topWidth">
    <app-landinglogo></app-landinglogo>
    <app-landingtoprightrow></app-landingtoprightrow>
  </div>

  <div
    *ngFor="let item of images; let i = index"
    class="outer imagevisible"
    [ngClass]="{
      imagevisible: this.currentImageIndex === i,
      imageinvisible: this.currentImageIndex !== i
    }"
    [ngStyle]="{ 'background-image': item }"
  ></div>

  <div class="carouselDiv">
    <app-landingcarousel (index)="indexChange($event)"></app-landingcarousel>
  </div>
</div>

<div *ngIf="this.authService.isLoggedIn">
  <app-placeholder-card></app-placeholder-card>
</div>
