<div class="cardwidth">
  <div class="header">
    <h1 class="headerh1">Set Password</h1>
  </div>
  <nz-card>
    Hello {{ username }}, please enter your new password:
    <form
      nz-form
      [formGroup]="validateForm"
      class="loginform"
      (ngSubmit)="submitForm()"
    >
      <nz-form-item class="marginform">
        <nz-form-control nzErrorTip="Please input your password!">
          <nz-input-group nzPrefixIcon="lock">
            <input
              type="password"
              (focus)="loginfailed = false"
              nz-input
              formControlName="password1"
              placeholder="Password"
              required
              (keyup)="validateConditions(validateForm)"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="marginform">
        <nz-form-control nzErrorTip="Please retype your password!">
          <nz-input-group nzPrefixIcon="lock">
            <input
              type="password"
              (focus)="loginfailed = false"
              nz-input
              formControlName="password2"
              placeholder="Retype password"
              required
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-alert
        class="buttondiv"
        *ngIf="loginfailed"
        nzType="error"
        [nzMessage]="errorMessage"
      >
      </nz-alert>
      <div class="requireBox">
        <div class="requireText">
          <div>Password must meet the following requirements:</div>
          <div class="optionTrue" *ngIf="this.pwLength">
            At least 6 characters
          </div>
          <div class="optionFalse" *ngIf="!this.pwLength">
            At least 6 characters
          </div>
          <div class="optionTrue" *ngIf="this.pwUppercase">
            At least one capital letter
          </div>
          <div class="optionFalse" *ngIf="!this.pwUppercase">
            At least one capital letter
          </div>
          <div class="optionTrue" *ngIf="this.pwNumber">
            At least one number
          </div>
          <div class="optionFalse" *ngIf="!this.pwNumber">
            At least one number
          </div>
          <div class="optionTrue" *ngIf="this.pwSpecial">
            At least one special character
          </div>
          <div class="optionFalse" *ngIf="!this.pwSpecial">
            At least one special character
          </div>
        </div>
      </div>

      <nz-form-control class="buttondiv">
        <button
          [disabled]="!isValidPassword(validateForm)"
          nz-button
          class="button buttons"
        >
          Save Password
        </button>
      </nz-form-control>
    </form>
  </nz-card>
</div>
