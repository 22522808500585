import { Component, Input, OnInit } from '@angular/core';
import { v4 } from 'uuid';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';
import { CustomerReportEntry } from '../../../../../../../../models/customerReportEntry';
import { AuditService } from '../../../../../../../../utils/audit.service';
import { Question } from '../../../../../../../../models/question';
import { QuestionService } from '../../../../../../../../utils/question.service';
import { DataModelService } from '../../../../../../../../utils/datamodel.service';
import { QuestionType } from '../../../../../../../../models/questionType';
import { TextResolverService } from '../../../../../../../../utils/text-resolver.service';

@Component({
  selector: 'app-dialog-customerreportcondition',
  templateUrl: './dialog-customerreportcondition.component.html',
  styleUrls: ['./dialog-customerreportcondition.component.css']
})
export class DialogCustomerReportConditionComponent implements OnInit {
  resultObject: { tag: string; condition: string } = {
    tag: null,
    condition: null
  };

  allQuestions: Question[] = [];
  allConditionTags: { tag: string; question: string }[];
  selectedQuestionAnswers: { _id: string; answer: string }[];

  @Input()
  currentValues: { tag: string; condition: string };

  constructor(
    private datamodelService: DataModelService,
    private modalRef: NzModalRef,
    private textResolverService: TextResolverService
  ) {}
  ngOnInit(): void {
    this.modalRef.getInstance().onClickOkCancel = (type: string) => {
      if (type === 'ok') {
        this.modalRef.destroy(this.resultObject);
      } else {
        this.modalRef.destroy();
      }
    };
    const allQuestionUUIDs = this.datamodelService.questions
      .filter(
        q =>
          q.type === QuestionType.CHECKBOX ||
          q.type === QuestionType.RADIO ||
          q.type === QuestionType.MULTIPLE_CHOICE ||
          q.type === QuestionType.SELECT
      )
      .map(q2 => q2._id);
    const tagArray = this.datamodelService.tags.filter(t => {
      return allQuestionUUIDs.includes(t.usedQuestionUUID);
    });

    this.allConditionTags = tagArray.map(q2 => {
      return {
        tag: q2.identifier,
        question: q2.usedQuestionUUID
      };
    });
    this.allQuestions = this.datamodelService.questions.filter(
      q =>
        (q.type === QuestionType.CHECKBOX ||
          q.type === QuestionType.RADIO ||
          q.type === QuestionType.MULTIPLE_CHOICE ||
          q.type === QuestionType.SELECT) &&
        q.predefinedAnswers
    );

    if (
      this.currentValues &&
      this.currentValues.condition &&
      this.currentValues.tag
    ) {
      this.resultObject.condition = this.currentValues.condition;
      this.resultObject.tag = this.currentValues.tag;
      const questionUUID = this.allConditionTags.find(
        x => x.tag === this.resultObject.tag
      ).question;

      const tmpQuestion = this.allQuestions.find(x => x._id === questionUUID);
      this.selectedQuestionAnswers = tmpQuestion.predefinedAnswers.map(
        answer => {
          return {
            _id: answer,
            answer: this.textResolverService.getLanguageText(answer)
          };
        }
      );
    }
  }

  changeSelectedTag(tag: string): void {
    this.resultObject.tag = tag;

    const questionUUID = this.allConditionTags.find(x => x.tag === tag)
      .question;
    const tmpQuestion = this.allQuestions.find(x => x._id === questionUUID);
    this.selectedQuestionAnswers = tmpQuestion.predefinedAnswers.map(answer => {
      return {
        _id: answer,
        answer: this.textResolverService.getLanguageText(answer)
      };
    });
  }
}
