<!-- old code
  <mat-sidenav-container id="outerdiv" class="containerHeight">
  <mat-sidenav #sidenav [mode]="this.getSidenavMode()" [opened]="datamodel.openSider" class="sidenav">
    <app-sidebar></app-sidebar>
  </mat-sidenav>
  <mat-sidenav-content id="appContent" class="background">
    <div id="innerdiv">
      <router-outlet> </router-outlet>
      <app-loggedinfooter></app-loggedinfooter>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container> -->

<mat-sidenav-container class="margintop">
  <mat-sidenav
    class="sidenav margintop"
    #sidenav
    [fixedInViewport]="true"
    [mode]="this.getSidenavMode()"
    [opened]="datamodel.openSider"
  >
    <app-sidebar></app-sidebar>
  </mat-sidenav>
  <mat-sidenav-content id="appContent" class="background">
    <div
      *ngIf="datamodel.selectedCompany2.getValue()?._id !== '0000-0000'; else selectCompany"
    >
      <router-outlet> </router-outlet>
      <app-loggedinfooter></app-loggedinfooter>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #selectCompany>
  <h1 class="header">
    Bitte eine Firma auswählen
  </h1>
</ng-template>
