import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SegmentEntry } from '../../../../models/segmentEntry';
import { LanguageService } from '../../../../utils/language.service';
import { QuestionService } from '../../../../utils/question.service';
import { TextResolverService } from './../../../../utils/text-resolver.service';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css']
})
export class CheckboxComponent {
  @Input()
  segmentEntry: SegmentEntry;
  @Input()
  answer = '';

  @Output()
  answered = new EventEmitter<string>();

  constructor(
    public languageService: LanguageService,
    public textResolverService: TextResolverService
  ) {}
}
