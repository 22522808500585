import { v4 } from 'uuid';
import { SegmentEntryType } from './segmentEntryType';

export class SegmentEntry {
  constructor(
    public _id: string,
    public type: SegmentEntryType,
    public entry: any,
    public result: boolean,
    public helpMap: Map<string, string>
  ) {}

  static CLEAN_INSTANCE(segmentEntryType: SegmentEntryType): SegmentEntry {
    return new SegmentEntry(v4(), segmentEntryType, null, false, new Map());
  }

  /*
  If an object being stringified has a property named toJSON whose value is a function,
  then the toJSON() method customizes JSON stringification behavior: instead of the object
  being serialized, the value returned by the toJSON() method when called will be serialized.
  JSON.stringify() calls toJSON with one parameter:
  */
  toJSON() {
    return {
      _id: this._id,
      type: this.type,
      entry: this.entry._id,
      result: this.result,
      helpMap: this.helpMapToJSON()
    };
  }

  helpMapToJSON() {
    return {
      dataType: 'Map',
      value: Array.from(this.helpMap.entries())
    };
  }
}
