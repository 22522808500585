/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./plaintext.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../popoverMarked/popoverMarked.component.ngfactory";
import * as i4 from "../../popoverMarked/popoverMarked.component";
import * as i5 from "../../../../utils/text-resolver.service";
import * as i6 from "./plaintext.component";
import * as i7 from "../../../../utils/language.service";
import * as i8 from "../../../../utils/question.service";
var styles_PlaintextComponent = [i0.styles];
var RenderType_PlaintextComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PlaintextComponent, data: {} });
export { RenderType_PlaintextComponent as RenderType_PlaintextComponent };
function View_PlaintextComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "margin-top": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-popover-marked", [], null, null, null, i3.View_PopoverMarkedComponent_0, i3.RenderType_PopoverMarkedComponent)), i1.ɵdid(5, 49152, null, 0, i4.PopoverMarkedComponent, [i5.TextResolverService], { text: [0, "text"], helpMap: [1, "helpMap"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, ((_v.context.index > 0) ? "1em" : "0")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.textResolverService.getLanguageText(_v.context.$implicit); var currVal_2 = _co.entryHelpMaps[_v.context.index]; _ck(_v, 5, 0, currVal_1, currVal_2); }, null); }
export function View_PlaintextComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "info"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { result: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlaintextComponent_1)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "info"; var currVal_1 = _ck(_v, 3, 0, _co.segmentEntry.result); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.segmentEntry.entry.textParts; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_PlaintextComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-plaintext", [], null, null, null, View_PlaintextComponent_0, RenderType_PlaintextComponent)), i1.ɵdid(1, 49152, null, 0, i6.PlaintextComponent, [i7.LanguageService, i8.QuestionService, i5.TextResolverService], null, null)], null, null); }
var PlaintextComponentNgFactory = i1.ɵccf("app-plaintext", i6.PlaintextComponent, View_PlaintextComponent_Host_0, { segmentEntry: "segmentEntry", entryHelpMaps: "entryHelpMaps" }, {}, []);
export { PlaintextComponentNgFactory as PlaintextComponentNgFactory };
