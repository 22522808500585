
    <div id="notfound">
      <div class="notfound">
        <div class="notfound-404">
          <div></div>
          <h1>401</h1>
        </div>
        <h2>Unautorisiert</h2>
        <p>
          Die aufgerufene Seite kann nicht geöffnet werden. <br />Hintergrund
          ist ein unautorisierter Zugriff.
        </p>
        <a href="/">Zum Login</a>
      </div>
    </div>
  