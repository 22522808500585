export class GlossarEntry {
  active = false;
  disabled = false;
  name: string;
  content: string;
  customStyle: {
    background: "#f7f7f7";
    "border-radius": "4px";
    "margin-bottom": "24px";
    border: "0px";
  };

  constructor(name: string, content: string) {
    this.name = name;
    this.content = content;
  }

  toString(): string {
    return this.name;
  }
}
