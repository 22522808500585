/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./waitforcontact.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../components/landingpage/landinglogo/landinglogo.component.ngfactory";
import * as i3 from "../../../components/landingpage/landinglogo/landinglogo.component";
import * as i4 from "../../../utils/routing.service";
import * as i5 from "../../../components/landingpage/landingtoprightrow/landingtoprightrow.component.ngfactory";
import * as i6 from "../../../components/landingpage/landingtoprightrow/landingtoprightrow.component";
import * as i7 from "../../../utils/language.service";
import * as i8 from "../../../utils/datamodel.service";
import * as i9 from "../../../../../node_modules/ng-zorro-antd/result/ng-zorro-antd-result.ngfactory";
import * as i10 from "ng-zorro-antd/core";
import * as i11 from "ng-zorro-antd/result";
import * as i12 from "../../../components/landingpage/landingfooter/landingfooter.component.ngfactory";
import * as i13 from "../../../components/landingpage/landingfooter/landingfooter.component";
import * as i14 from "./waitforcontact.component";
import * as i15 from "../../../utils/dbverbindung.service";
var styles_WaitForContactComponent = [i0.styles];
var RenderType_WaitForContactComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WaitForContactComponent, data: {} });
export { RenderType_WaitForContactComponent as RenderType_WaitForContactComponent };
export function View_WaitForContactComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "topWidth"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-landinglogo", [], null, null, null, i2.View_LandingLogoComponent_0, i2.RenderType_LandingLogoComponent)), i1.ɵdid(2, 49152, null, 0, i3.LandingLogoComponent, [i4.RoutingService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-landingtoprightrow", [], null, null, null, i5.View_LandingTopRightRowComponent_0, i5.RenderType_LandingTopRightRowComponent)), i1.ɵdid(4, 49152, null, 0, i6.LandingTopRightRowComponent, [i7.LanguageService, i8.DataModelService, i4.RoutingService], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "nz-result", [], null, null, null, i9.View_NzResultComponent_0, i9.RenderType_NzResultComponent)), i1.ɵprd(512, null, i10.NzUpdateHostClassService, i10.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(7, 573440, null, 0, i11.NzResultComponent, [i10.NzUpdateHostClassService, i1.ElementRef], { nzTitle: [0, "nzTitle"], nzSubTitle: [1, "nzSubTitle"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["id", "footernavi"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-landingfooter", [], null, null, null, i12.View_LandingFooterComponent_0, i12.RenderType_LandingFooterComponent)), i1.ɵdid(10, 49152, null, 0, i13.LandingFooterComponent, [i7.LanguageService, i8.DataModelService, i4.RoutingService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.lang.translate().processContactRequest; var currVal_1 = _co.lang.translate().processContactRequestMessage; _ck(_v, 7, 0, currVal_0, currVal_1); }, null); }
export function View_WaitForContactComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-waitforcontact", [], null, null, null, View_WaitForContactComponent_0, RenderType_WaitForContactComponent)), i1.ɵdid(1, 49152, null, 0, i14.WaitForContactComponent, [i7.LanguageService, i15.DBService, i4.RoutingService], null, null)], null, null); }
var WaitForContactComponentNgFactory = i1.ɵccf("app-waitforcontact", i14.WaitForContactComponent, View_WaitForContactComponent_Host_0, {}, {}, []);
export { WaitForContactComponentNgFactory as WaitForContactComponentNgFactory };
