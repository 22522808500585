<div class="info" [ngClass]="{ result: this.segmentEntry.result }">
  <div
    *ngFor="let text of this.segmentEntry.entry.textParts; index as i"
    [ngStyle]="{ 'margin-top': i > 0 ? '1em' : '0' }"
  >
    <app-popover-marked
      [text]="this.textResolverService.getLanguageText(text)"
      [helpMap]="this.entryHelpMaps[i]"
    ></app-popover-marked>
  </div>
</div>
