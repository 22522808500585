import { Injectable } from '@angular/core';
import { Segment } from '../models/segment';
import { QuestionService } from './question.service';
import { SegmentCondition } from '../models/segmentCondition';
import { AuditService } from './audit.service';
import { SegmentEntryType } from '../models/segmentEntryType';

@Injectable({
  providedIn: 'root'
})
export class SegmentImplementationService {
  currentSegment = 0;
  mapQuestionsAnswered: Map<number, boolean> = new Map();
  previousSegments: number[] = [];

  segments: Segment[];

  constructor(private questionService: QuestionService) {}

  reset(): void {
    this.currentSegment = 0;
    this.mapQuestionsAnswered.clear();
    this.previousSegments = [];
  }

  checkSegmentState(segment?: Segment, index?: number): void {
    if (this.segments && this.segments.length > 0) {
      let allAnswered = true;
      const currSegment = segment
        ? segment
        : this.segments[this.currentSegment];
      currSegment.segmentEntries.forEach(sE => {
        if (
          sE.type === SegmentEntryType.QUESTION &&
          this.questionService.getQuestionAnswer(sE.entry._id).length === 0
        ) {
          allAnswered = false;
        }
      });
      this.mapQuestionsAnswered.set(
        index ? index : this.currentSegment,
        allAnswered
      );
    }
  }

  initMapQuestionAnswered(): void {
    this.segments.forEach((s, index) => this.checkSegmentState(s, index));
  }

  handlePrevState(): void {
    this.currentSegment = this.previousSegments.pop();
    this.checkSegmentState();
  }

  setNextSegmentState(): void {
    // next segment without conditions as fallback
    let nextSegment = this.segments.indexOf(
      this.segments.find(
        (s, index) =>
          index > this.currentSegment &&
          (!s.segmentConditions || s.segmentConditions.length === 0)
      )
    );


    console.log(nextSegment);

    // next segment which conditions match users answers
    this.segments.forEach((segment, index) => {

      if (segment.segmentConditions) {

        segment.segmentConditions
          .filter(
            sC =>
              sC.referencedSegmentUUID ===
              this.segments[this.currentSegment]._id
          )
          .forEach(sC => {

            let match = true;
            sC.questionStates.forEach((answer, questionUUID) => {
              if (
               this.questionService.getQuestionAnswer(questionUUID) !== answer
              ) {
                console.log('no match found');

                match = false;
              }
            });
            if (match) {
              console.log(index);

              nextSegment = index;
            }
          });
      }
    });
    this.previousSegments.push(this.currentSegment);
    console.log(nextSegment);



    this.currentSegment = nextSegment < 0 ? this.segments.length - 1 : nextSegment;
  }
}
