import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Question } from '../../../models/question';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.css']
})
export class TextInputComponent implements OnInit {
  @Input()
  tooltip = '';
  @Input()
  text: string;
  @Input()
  question: Question;
  @Input()
  disabled: boolean;
  @Input()
  suffixIconName: string;

  @Output()
  textChanged = new EventEmitter<string>();
  @Output()
  iconClicked = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}

  getSize(): string {
    return this.question.inputSize + 'em';
    // return (this.suffixIconName ? 2.55 : 0) + this.question.inputSize + 'em';
    /*
    let sizeEM = '';
    switch (this.size) {
      case InputSize.EXTRA_SMALL:
        sizeEM = '4em';
        break;
      case InputSize.SMALL:
        sizeEM = '6em';
        break;
      case InputSize.MEDIUM:
        sizeEM = '10em';
        break;
      case InputSize.LARGE:
        sizeEM = '16.5em';
        break;
      case InputSize.EXTRA_LARGE:
        sizeEM = '24em';
        break;
      default:
        sizeEM = '100%';
        break;
    }
    return sizeEM;
    */
  }
}
