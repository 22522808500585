/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./einfuehrung.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./einfuehrung.component";
import * as i3 from "../../../utils/language.service";
import * as i4 from "../../../utils/datamodel.service";
var styles_EinfuehrungComponent = [i0.styles];
var RenderType_EinfuehrungComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EinfuehrungComponent, data: {} });
export { RenderType_EinfuehrungComponent as RenderType_EinfuehrungComponent };
export function View_EinfuehrungComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Einf\u00FChrung\n"]))], null, null); }
export function View_EinfuehrungComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-einfuehrung", [], null, null, null, View_EinfuehrungComponent_0, RenderType_EinfuehrungComponent)), i1.ɵdid(1, 49152, null, 0, i2.EinfuehrungComponent, [i3.LanguageService, i4.DataModelService], null, null)], null, null); }
var EinfuehrungComponentNgFactory = i1.ɵccf("app-einfuehrung", i2.EinfuehrungComponent, View_EinfuehrungComponent_Host_0, {}, {}, []);
export { EinfuehrungComponentNgFactory as EinfuehrungComponentNgFactory };
