/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./landingcarousel.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng-zorro-antd/carousel";
import * as i3 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i4 from "@angular/material/card";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../node_modules/ng-zorro-antd/carousel/ng-zorro-antd-carousel.ngfactory";
import * as i8 from "ng-zorro-antd/core";
import * as i9 from "@angular/cdk/platform";
import * as i10 from "ng-zorro-antd/icon";
import * as i11 from "./landingcarousel.component";
import * as i12 from "../../../utils/language.service";
import * as i13 from "../../../utils/datamodel.service";
var styles_LandingCarouselComponent = [i0.styles];
var RenderType_LandingCarouselComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LandingCarouselComponent, data: {} });
export { RenderType_LandingCarouselComponent as RenderType_LandingCarouselComponent };
function View_LandingCarouselComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "textAlignLeft contendPadding inner"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit[1]; _ck(_v, 1, 0, currVal_0); }); }
function View_LandingCarouselComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "textAlignLeft wordwrap contendPadding inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "coloumnEins"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "coloumnZwei"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "coloumnDrei"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit[1]; _ck(_v, 5, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit[2]; _ck(_v, 7, 0, currVal_1); }); }
function View_LandingCarouselComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "heigth"], ["nz-carousel-content", ""]], null, null, null, null, null)), i1.ɵdid(1, 16384, [[2, 4]], 0, i2.NzCarouselContentDirective, [i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 12, "mat-card", [["class", "cardStyle mat-card"]], [[2, "_mat-animation-noopable", null]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = ((_co.isPaused = true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = ((_co.isPaused = false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MatCard_0, i3.RenderType_MatCard)), i1.ɵdid(3, 49152, null, 0, i4.MatCard, [[2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 4, "mat-card-header", [["class", "mat-card-header"]], null, null, null, i3.View_MatCardHeader_0, i3.RenderType_MatCardHeader)), i1.ɵdid(5, 49152, null, 0, i4.MatCardHeader, [], null, null), (_l()(), i1.ɵeld(6, 0, null, 1, 2, "mat-card-title", [["class", "headertext wordwrap mat-card-title"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i4.MatCardTitle, [], null, null), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵeld(9, 0, null, 0, 5, "mat-card-content", [["class", "noselect contentHeight mat-card-content"]], null, null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i4.MatCardContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LandingCarouselComponent_2)), i1.ɵdid(12, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LandingCarouselComponent_3)), i1.ɵdid(14, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_v.context.$implicit !== _co.lang.translate().landing_tiles[(_co.lang.translate().landing_tiles.length - 1)]); _ck(_v, 12, 0, currVal_2); var currVal_3 = (_v.context.$implicit === _co.lang.translate().landing_tiles[(_co.lang.translate().landing_tiles.length - 1)]); _ck(_v, 14, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit[0]; _ck(_v, 8, 0, currVal_1); }); }
export function View_LandingCarouselComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { carousel: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "carouselDiv"]], null, [[null, "wheel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("wheel" === en)) {
        var pd_0 = (_co.onMouseWheel($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "nz-carousel", [["class", "carousel"]], [[2, "ant-carousel-vertical", null]], [[null, "nzAfterChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("nzAfterChange" === en)) {
        var pd_0 = (_co.change($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_NzCarouselComponent_0, i7.RenderType_NzCarouselComponent)), i1.ɵdid(3, 5947392, [[1, 4], ["carousel", 4]], 1, i2.NzCarouselComponent, [i1.ElementRef, i8.NzConfigService, i1.Renderer2, i1.ChangeDetectorRef, i9.Platform, i8.NzDomEventService, i8.NzDragService, [2, i2.NZ_CAROUSEL_CUSTOM_STRATEGIES]], { nzEffect: [0, "nzEffect"], nzAutoPlay: [1, "nzAutoPlay"], nzAutoPlaySpeed: [2, "nzAutoPlaySpeed"] }, { nzAfterChange: "nzAfterChange" }), i1.ɵqud(603979776, 2, { carouselContents: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_LandingCarouselComponent_1)), i1.ɵdid(6, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 10, "div", [["class", "buttongroup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "i", [["class", "clickable"], ["nz-icon", ""], ["nzTheme", "twotone"], ["nzType", "pause-circle"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.isPaused = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i6.ɵNgClassImpl, i6.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(10, 278528, null, 0, i6.NgClass, [i6.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(11, { notClickable: 0, clickable: 1 }), i1.ɵdid(12, 2834432, null, 0, i10.NzIconDirective, [i10.NzIconService, i1.ElementRef, i1.Renderer2, i9.Platform], { nzType: [0, "nzType"], nzTheme: [1, "nzTheme"], nzTwotoneColor: [2, "nzTwotoneColor"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 4, "i", [["class", "buttonmargin"], ["nz-icon", ""], ["nzTheme", "twotone"], ["nzType", "play-circle"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.isPaused = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i6.ɵNgClassImpl, i6.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(15, 278528, null, 0, i6.NgClass, [i6.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(16, { notClickable: 0, clickable: 1 }), i1.ɵdid(17, 2834432, null, 0, i10.NzIconDirective, [i10.NzIconService, i1.ElementRef, i1.Renderer2, i9.Platform], { nzType: [0, "nzType"], nzTheme: [1, "nzTheme"], nzTwotoneColor: [2, "nzTwotoneColor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "scrollx"; var currVal_2 = !_co.isPaused; var currVal_3 = 8000; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.lang.translate().landing_tiles; _ck(_v, 6, 0, currVal_4); var currVal_5 = "clickable"; var currVal_6 = _ck(_v, 11, 0, _co.isPaused, !_co.isPaused); _ck(_v, 10, 0, currVal_5, currVal_6); var currVal_7 = "pause-circle"; var currVal_8 = "twotone"; var currVal_9 = "#0f94c3"; _ck(_v, 12, 0, currVal_7, currVal_8, currVal_9); var currVal_10 = "buttonmargin"; var currVal_11 = _ck(_v, 16, 0, !_co.isPaused, _co.isPaused); _ck(_v, 15, 0, currVal_10, currVal_11); var currVal_12 = "play-circle"; var currVal_13 = "twotone"; var currVal_14 = "#0f94c3"; _ck(_v, 17, 0, currVal_12, currVal_13, currVal_14); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).vertical; _ck(_v, 2, 0, currVal_0); }); }
export function View_LandingCarouselComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-landingcarousel", [], null, null, null, View_LandingCarouselComponent_0, RenderType_LandingCarouselComponent)), i1.ɵdid(1, 49152, null, 0, i11.LandingCarouselComponent, [i12.LanguageService, i13.DataModelService], null, null)], null, null); }
var LandingCarouselComponentNgFactory = i1.ɵccf("app-landingcarousel", i11.LandingCarouselComponent, View_LandingCarouselComponent_Host_0, {}, { index: "index" }, []);
export { LandingCarouselComponentNgFactory as LandingCarouselComponentNgFactory };
