<div class="top">
  <app-landinglogo></app-landinglogo>
  <app-landingtoprightrow [login]="false"></app-landingtoprightrow>
</div>
<div class="login-container" *ngIf="!this.authService.isLoggedIn">
  <app-login-mask></app-login-mask>
</div>
<div *ngIf="this.authService.isLoggedIn">
  <app-placeholder-card></app-placeholder-card>
</div>
