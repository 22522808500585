/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popoverMarkedManual.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng-zorro-antd/icon";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "../../format-text/format-text.component.ngfactory";
import * as i5 from "../../format-text/format-text.component";
import * as i6 from "ng-zorro-antd/tooltip";
import * as i7 from "./popoverMarkedManual.component";
var styles_PopoverMarkedManualComponent = [i0.styles];
var RenderType_PopoverMarkedManualComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopoverMarkedManualComponent, data: {} });
export { RenderType_PopoverMarkedManualComponent as RenderType_PopoverMarkedManualComponent };
function View_PopoverMarkedManualComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [["class", "innerIcon"], ["nz-icon", ""]], null, null, null, null, null)), i1.ɵdid(1, 2834432, null, 0, i2.NzIconDirective, [i2.NzIconService, i1.ElementRef, i1.Renderer2, i3.Platform], { nzType: [0, "nzType"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-format-text", [["class", "help-text"]], null, null, null, i4.View_FormatTextComponent_0, i4.RenderType_FormatTextComponent)), i1.ɵdid(3, 49152, null, 0, i5.FormatTextComponent, [], { text: [0, "text"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconPopover; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.text; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_PopoverMarkedManualComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "outterIcon"], ["nz-icon", ""]], null, null, null, null, null)), i1.ɵdid(2, 2834432, null, 0, i2.NzIconDirective, [i2.NzIconService, i1.ElementRef, i1.Renderer2, i3.Platform], { nzType: [0, "nzType"] }, null), (_l()(), i1.ɵeld(3, 16777216, null, null, 3, "a", [["class", "markedText"], ["nz-tooltip", ""], ["nzTrigger", "click"]], [[2, "ant-tooltip-open", null]], null, null, null, null)), i1.ɵdid(4, 4931584, null, 0, i6.NzTooltipDirective, [i1.ElementRef, i1.ViewContainerRef, i1.ComponentFactoryResolver, i1.Renderer2, [2, i6.NzTooltipBaseComponentLegacy], [8, null]], { nzTitle: [0, "nzTitle"], nzTrigger: [1, "nzTrigger"], directiveNameTitle: [2, "directiveNameTitle"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-format-text", [], null, null, null, i4.View_FormatTextComponent_0, i4.RenderType_FormatTextComponent)), i1.ɵdid(6, 49152, null, 0, i5.FormatTextComponent, [], { text: [0, "text"] }, null), (_l()(), i1.ɵand(0, [["titleTemplate", 2]], null, 0, null, View_PopoverMarkedManualComponent_1))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconTitle; _ck(_v, 2, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 7); var currVal_3 = "click"; var currVal_4 = ""; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.title; _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 4).isTooltipComponentVisible; _ck(_v, 3, 0, currVal_1); }); }
export function View_PopoverMarkedManualComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popover-marked-manual", [], null, null, null, View_PopoverMarkedManualComponent_0, RenderType_PopoverMarkedManualComponent)), i1.ɵdid(1, 49152, null, 0, i7.PopoverMarkedManualComponent, [], null, null)], null, null); }
var PopoverMarkedManualComponentNgFactory = i1.ɵccf("app-popover-marked-manual", i7.PopoverMarkedManualComponent, View_PopoverMarkedManualComponent_Host_0, { iconTitle: "iconTitle", iconPopover: "iconPopover", title: "title", text: "text" }, {}, []);
export { PopoverMarkedManualComponentNgFactory as PopoverMarkedManualComponentNgFactory };
