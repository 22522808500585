<div>
  <div class="indicators" *ngIf="this.showIndicators">
    <div
      *ngFor="let index of this.getMaxSegmentArray(); index as i"
      [ngClass]="this.isCurrentSegment(i) ? 'indicator-big' : 'indicator-small'"
      (click)="this.setToSegment(i + 1)"
    ></div>
  </div>
  <div class="controlGroup">
    <div class="currentSegment">
      <ng-container *ngIf="this.enableJumper">
        <input
          class="inputSegment"
          #segmentInput
          nz-input
          nz-tooltip
          [nzTooltipTitle]="'Press Enter to submit'"
          nzTooltipTrigger="focus"
          [nzSize]="'small'"
          [(ngModel)]="this.currentInputSegment"
          (blur)="this.resetCurrentInput()"
          (keydown)="this.jumpToSegment($event)"
        />
        <span>/</span>
        <span>{{ this.amountSegments }}</span>
      </ng-container>
    </div>
    <div class="buttonGroup">
      <div class="button">
        <button
          nz-button
          nzType="primary"
          class="default-button"
          [disabled]="this.initialSegment === 1"
          (click)="this.prevSegment()"
        >
          {{ this.languageService.translate().prev }}
        </button>
      </div>
      <div class="button">
        <button
          nz-button
          nzType="primary"
          class="default-button"
          [disabled]="this.isNextDisabled()"
          (click)="this.nextSegment()"
        >
          {{ this.languageService.translate().next }}
        </button>
      </div>
    </div>
    <div class="currentSegment"></div>
  </div>
</div>
