import { InfoType } from './infoType';

export class Info {
  constructor(
    public _id: string,
    public textParts: string[],
    public iconPath: string,
    public type: InfoType
  ) {}
}
