import { Component } from '@angular/core';
import { AuthService } from '../../guards/auth/auth.service';
import { Router } from '@angular/router';
import { DBService } from '../../utils/dbverbindung.service';
import { LanguageService } from '../../utils/language.service';
import { RoutingService } from '../../utils/routing.service';

@Component({
  selector: 'app-idlink',
  templateUrl: './idlink.component.html',
  styleUrls: ['./idlink.component.css']
})
export class IdLinkComponent {
  username: string;
  linkstatus = 0;
  idlinkUUID: string;
  userUUID: string;
  constructor(
    private router: Router,
    public authService: AuthService,
    public dbService: DBService,
    public languageService: LanguageService,
    public routingService: RoutingService
  ) {
    const split = this.router.url.split('/');
    if (split.length === 3) {
      this.idlinkUUID = split[2];
      dbService.isIdLinkValid(this.idlinkUUID).subscribe(x => {
        if (x.isValid === false) {
          this.linkstatus = 2;
        } else {
          this.username = x.username;
          this.userUUID = x.userUUID;
          this.linkstatus = 1;
        }
      });
    } else {
      this.linkstatus = 2;
    }
  }

  setPassword(pw: string): void {
    this.dbService
      .setPassword(this.userUUID, pw, this.idlinkUUID)
      .subscribe(x => {
        if (x.result === true) {
          this.linkstatus = 3;
        }
      });
  }
  navigateToLanding(): void {
    this.routingService.navigateToLanding();
  }
}
