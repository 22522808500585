<div
  class="part"
  *ngFor="let questionPart of this.segmentEntry.entry.textParts"
>
  <app-popover-marked
    [text]="this.textResolverService.getLanguageText(questionPart)"
    [helpMap]="this.segmentEntry.helpMap"
  ></app-popover-marked>
</div>
<div class="radioContainer">
  <nz-radio-group
    [nzDisabled]="this.disabled"
    [(ngModel)]="this.answer"
    (ngModelChange)="this.setAnswer($event)"
  >
    <label
      *ngFor="let singleAnswer of this.segmentEntry.entry.predefinedAnswers"
      nz-radio
      [nzValue]="singleAnswer"
      >{{ this.textResolverService.getLanguageText(singleAnswer) }}</label
    >
  </nz-radio-group>
</div>
