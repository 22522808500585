import { moveItemInArray } from '@angular/cdk/drag-drop';
import { v4 } from 'uuid';
import { Chapter } from '../models/chapter';
import { DBCollections } from '../models/dbCollections';
import { Download } from '../models/download';
import { FileType } from '../models/fileType';
import { InfoType } from '../models/infoType';
import { Segment } from '../models/segment';
import { SegmentEntry } from '../models/segmentEntry';
import { SegmentFile } from '../models/segmentFile';
import { Info } from './../models/info';
import { Question } from './../models/question';
import { QuestionType } from './../models/questionType';
import { SegmentEntryType } from './../models/segmentEntryType';
import { DataModelService } from './datamodel.service';
import { LanguageService } from './language.service';
import { TextResolverService } from './text-resolver.service';
import { Company } from '../models/company';
import { BookedPackage } from '../models/bookedPackage';
import { Country } from '../models/country';
import { QuestionService } from './question.service';
import * as i0 from "@angular/core";
import * as i1 from "./datamodel.service";
import * as i2 from "./text-resolver.service";
import * as i3 from "./language.service";
import * as i4 from "./question.service";
export class AuditService {
    constructor(dataModel, textResolverService, languageService, questionService) {
        this.dataModel = dataModel;
        this.textResolverService = textResolverService;
        this.languageService = languageService;
        this.questionService = questionService;
    }
    addChapter(title) {
        const chapter = Chapter.CLEAN_INSTANCE(this.textResolverService.setLanguageText(title), this.dataModel.chapters.length);
        this.dataModel.chapters = [...this.dataModel.chapters, chapter];
        this.dataModel.insertChapter(chapter);
    }
    addSegment(chapterCard, segmentKey) {
        const segment = Segment.CLEAN_INSTANCE();
        chapterCard.segments.get(segmentKey).push(segment);
        this.dataModel.insertIntoCollection([segment], DBCollections.SEGMENTS);
        this.dataModel.updateCollection(chapterCard._id, {
            segments: this.dataModel.segmentMapToJSON(chapterCard)
        }, DBCollections.CHAPTER_CARDS);
    }
    addSegmentEntry(chapterCard, segmentKey, segmentIndex, segmentEntry) {
        const segment = chapterCard.segments.get(segmentKey)[segmentIndex];
        const entries = segment.segmentEntries;
        segment.segmentEntries = entries
            ? [...entries, segmentEntry]
            : [segmentEntry];
        this.dataModel.insertIntoCollection([segmentEntry], DBCollections.SEGMENT_ENTRIES);
        switch (segmentEntry.type) {
            case SegmentEntryType.QUESTION:
                this.dataModel.insertIntoCollection([segmentEntry.entry], DBCollections.QUESTIONS);
                break;
            case SegmentEntryType.INFO:
                this.dataModel.insertIntoCollection([segmentEntry.entry], DBCollections.INFOS);
                break;
            case SegmentEntryType.FILE:
                this.dataModel.insertIntoCollection([segmentEntry.entry], DBCollections.SEGMENT_FILES);
                break;
        }
        this.dataModel.updateCollection(segment._id, {
            segmentEntries: segment.segmentEntries.map(sE => sE._id)
        }, DBCollections.SEGMENTS);
    }
    addEntryTextPart(segmentEntry, text) {
        switch (segmentEntry.type) {
            case SegmentEntryType.QUESTION:
                segmentEntry.entry.textParts = [
                    ...segmentEntry.entry.textParts,
                    this.textResolverService.setLanguageText(text)
                ];
                this.dataModel.updateCollection(segmentEntry.entry._id, { textParts: segmentEntry.entry.textParts }, DBCollections.QUESTIONS);
                break;
            case SegmentEntryType.INFO:
                segmentEntry.entry.textParts = [
                    ...segmentEntry.entry.textParts,
                    this.textResolverService.setLanguageText(text)
                ];
                this.dataModel.updateCollection(segmentEntry.entry._id, { textParts: segmentEntry.entry.textParts }, DBCollections.INFOS);
                break;
            // TODO: implement other options
        }
    }
    removeChapter(chapter) {
        this.dataModel.chapters.splice(this.dataModel.chapters.indexOf(chapter), 1);
        this.dataModel.deleteFromCollection([chapter._id], DBCollections.CHAPTERS);
    }
    removeSegment(chapterCard, segmentKey, segmentIndex) {
        const segment = chapterCard.segments.get(segmentKey)[segmentIndex];
        chapterCard.segments.get(segmentKey).length <= 1
            ? chapterCard.segments
                .get(segmentKey)
                .splice(segmentIndex, 1, Segment.CLEAN_INSTANCE())
            : chapterCard.segments.get(segmentKey).splice(segmentIndex, 1);
        this.dataModel.deleteFromCollection([segment._id], DBCollections.SEGMENTS);
        this.dataModel.updateCollection(chapterCard._id, { segments: this.dataModel.segmentMapToJSON(chapterCard) }, DBCollections.CHAPTER_CARDS);
    }
    removeSegmentEntry(chapterCard, segmentKey, segmentIndex, segmentEntry) {
        const segment = chapterCard.segments.get(segmentKey)[segmentIndex];
        this.dataModel.deleteFromCollection([segmentEntry._id], DBCollections.SEGMENT_ENTRIES);
        this.removeInnerSegmentEntry(segmentEntry);
        this.dataModel.updateCollection(segment._id, { segmentEntries: segment.segmentEntries.map(sE => sE._id) }, DBCollections.SEGMENTS);
        segment.segmentEntries.splice(segment.segmentEntries.indexOf(segmentEntry), 1);
    }
    removeInnerSegmentEntry(segmentEntry) {
        switch (segmentEntry.type) {
            case SegmentEntryType.QUESTION:
                this.dataModel.deleteFromCollection([segmentEntry.entry._id], DBCollections.QUESTIONS);
                break;
            case SegmentEntryType.INFO:
                this.dataModel.deleteFromCollection([segmentEntry.entry._id], DBCollections.INFOS);
                break;
            case SegmentEntryType.FILE:
                this.dataModel.deleteFromCollection([segmentEntry.entry._id], DBCollections.SEGMENT_FILES);
                break;
        }
    }
    removeTextPart(segmentEntry, textPartIndex) {
        const textPartUUID = segmentEntry.entry.textParts[textPartIndex];
        this.dataModel.deleteFromCollection([textPartUUID], DBCollections.LANGUAGE_TEXTS);
        switch (segmentEntry.type) {
            case SegmentEntryType.QUESTION:
                segmentEntry.entry.textParts.splice(textPartIndex, 1);
                this.dataModel.updateCollection(segmentEntry.entry._id, { textParts: segmentEntry.entry.textParts }, DBCollections.QUESTIONS);
                break;
            case SegmentEntryType.INFO:
                segmentEntry.entry.textParts.splice(textPartIndex, 1);
                this.dataModel.updateCollection(segmentEntry.entry._id, { textParts: segmentEntry.entry.textParts }, DBCollections.INFOS);
                break;
        }
    }
    reorderChapters(prevIndex, currIndex) {
        moveItemInArray(this.dataModel.chapters, prevIndex, currIndex);
        this.dataModel.chapters.forEach((chapter, index) => (chapter.order = index));
        this.dataModel.chapters.forEach(chapter => this.dataModel.updateCollection(chapter._id, {
            order: chapter.order
        }, DBCollections.CHAPTERS));
    }
    reorderSegments(chapterCard, segmentKey, prevIndex, currIndex) {
        moveItemInArray(chapterCard.segments.get(segmentKey), prevIndex, currIndex);
        this.dataModel.updateCollection(chapterCard._id, {
            segments: this.dataModel.segmentMapToJSON(chapterCard)
        }, DBCollections.CHAPTER_CARDS);
    }
    reorderTextParts(segmentEntry, prevIndex, currIndex) {
        switch (segmentEntry.type) {
            case SegmentEntryType.QUESTION:
                moveItemInArray(segmentEntry.entry.textParts, prevIndex, currIndex);
                this.dataModel.updateCollection(segmentEntry.entry._id, { textParts: segmentEntry.entry.textParts }, DBCollections.QUESTIONS);
                break;
            case SegmentEntryType.INFO:
                moveItemInArray(segmentEntry.entry.textParts, prevIndex, currIndex);
                this.dataModel.updateCollection(segmentEntry.entry._id, { textParts: segmentEntry.entry.textParts }, DBCollections.INFOS);
                break;
        }
    }
    reorderSegmentEntries(chapterCard, segmentKey, segmentIndex, prevIndex, currIndex) {
        const segment = chapterCard.segments.get(segmentKey)[segmentIndex];
        moveItemInArray(segment.segmentEntries, prevIndex, currIndex);
        this.dataModel.updateCollection(segment._id, {
            segmentEntries: segment.segmentEntries.map(sE => sE._id)
        }, DBCollections.SEGMENTS);
        // this.dataModel.updateChapters();
    }
    changeHelpMap(segmentEntry, helpMap) {
        segmentEntry.helpMap = helpMap;
        this.dataModel.updateCollection(segmentEntry._id, { helpMap: this.dataModel.helpMapToJSON(segmentEntry) }, DBCollections.SEGMENT_ENTRIES);
    }
    changeChapterBlocked(chapter, blocked) {
        chapter.blocked = blocked;
        this.dataModel.updateCollection(chapter._id, { blocked: blocked }, DBCollections.CHAPTERS);
    }
    changeChapterHidden(chapter, hidden) {
        chapter.hidden = hidden;
        this.dataModel.updateCollection(chapter._id, { hidden: hidden }, DBCollections.CHAPTERS);
    }
    changeChapterName(chapter, name) {
        this.textResolverService.setLanguageText(name, chapter.title);
    }
    changeEntryType(segmentEntry, entryType) {
        segmentEntry.entry.type = entryType;
        switch (segmentEntry.type) {
            case SegmentEntryType.QUESTION:
                this.dataModel.updateCollection(segmentEntry.entry._id, { type: entryType }, DBCollections.QUESTIONS);
                break;
            case SegmentEntryType.INFO:
                this.dataModel.updateCollection(segmentEntry.entry._id, { type: entryType }, DBCollections.INFOS);
                break;
            case SegmentEntryType.FILE:
                segmentEntry.entry.templateFilesUUID =
                    segmentEntry.entry.type !== FileType.TEMPLATE
                        ? null
                        : segmentEntry.entry.templateFilesUUID;
                this.dataModel.updateCollection(segmentEntry.entry._id, {
                    type: entryType,
                    auditFileTemplateUUID_DE: segmentEntry.entry.auditFileTemplateUUID_DE,
                    auditFileTemplateUUID_EN: segmentEntry.entry.auditFileTemplateUUID_EN
                }, DBCollections.SEGMENT_FILES);
                break;
        }
    }
    changeSegmentEntryResult(segmentEntry) {
        segmentEntry.result = !segmentEntry.result;
        this.dataModel.updateCollection(segmentEntry._id, { result: segmentEntry.result }, DBCollections.SEGMENT_ENTRIES);
    }
    changeSegmentTitle(chapterCard, segmentKey, segmentIndex, title) {
        const segment = chapterCard.segments.get(segmentKey)[segmentIndex];
        segment.title = this.textResolverService.setLanguageText(title, segment.title);
        this.dataModel.updateCollection(segment._id, { title: segment.title }, DBCollections.SEGMENTS);
    }
    changeTextPart(segmentEntry, textPartIndex, text) {
        let dbCollection;
        switch (segmentEntry.type) {
            case SegmentEntryType.QUESTION:
                segmentEntry.entry.textParts[textPartIndex] = this.textResolverService.setLanguageText(text, segmentEntry.entry.textParts[textPartIndex]);
                dbCollection = DBCollections.QUESTIONS;
                break;
            case SegmentEntryType.INFO:
                segmentEntry.entry.textParts[textPartIndex] = this.textResolverService.setLanguageText(text, segmentEntry.entry.textParts[textPartIndex]);
                dbCollection = DBCollections.INFOS;
                break;
        }
        this.dataModel.updateCollection(segmentEntry.entry._id, { textParts: segmentEntry.entry.textParts }, dbCollection);
    }
    changeIntroductionText(chapterCard, text) {
        chapterCard.introductionText = this.textResolverService.setLanguageText(text, chapterCard.introductionText);
        this.dataModel.updateCollection(chapterCard._id, { introductionText: chapterCard.introductionText }, DBCollections.CHAPTER_CARDS);
    }
    checkChapterReportValidity(segments) {
        let valid = true;
        const segmentChainUUIDs = [];
        let runSegmentIndex = 0;
        while (runSegmentIndex < segments.length - 1 && valid) {
            let nextSegmentIndex;
            segments[runSegmentIndex].segmentEntries.forEach(sE => {
                if (sE.type === SegmentEntryType.QUESTION &&
                    this.questionService.getQuestionAnswer(sE.entry._id).length === 0) {
                    valid = false;
                }
            });
            segmentChainUUIDs.push(segments[runSegmentIndex]._id);
            if (valid) {
                nextSegmentIndex = segments.indexOf(segments.find((s, index) => index > runSegmentIndex &&
                    (!s.segmentConditions || s.segmentConditions.length === 0)));
                let match = true;
                segments.forEach((segment, index) => {
                    if (segment.segmentConditions) {
                        segment.segmentConditions
                            .filter(sC => sC.referencedSegmentUUID === segments[runSegmentIndex]._id)
                            .some(sC => {
                            match = true;
                            sC.questionStates.forEach((answer, questionUUID) => {
                                if (this.questionService.getQuestionAnswer(questionUUID) !==
                                    answer) {
                                    match = false;
                                }
                            });
                            if (match) {
                                nextSegmentIndex = index;
                                return match;
                            }
                        });
                    }
                });
            }
            runSegmentIndex = nextSegmentIndex;
        }
        return { valid: valid, segmentChainUUIDs: segmentChainUUIDs };
    }
    hasQuestionTag(questionUUID) {
        return (this.dataModel.tags.filter(t => t.usedQuestionUUID === questionUUID)
            .length > 0);
    }
    hasSegmentFileTemplates(file) {
        return ((file.auditFileTemplateUUID_DE &&
            file.auditFileTemplateUUID_DE.length > 0) ||
            (file.auditFileTemplateUUID_EN &&
                file.auditFileTemplateUUID_EN.length > 0));
    }
    hasTemplateFileTemplates(templateFile) {
        return ((templateFile.auditFileTemplateUUID_DE &&
            templateFile.auditFileTemplateUUID_DE.length > 0) ||
            (templateFile.auditFileTemplateUUID_EN &&
                templateFile.auditFileTemplateUUID_EN.length > 0));
    }
    hasSegmentEntries(chapterCard, segmentKey, segmentIndex) {
        const temp = chapterCard.segments.get(segmentKey)[segmentIndex];
        return temp.segmentEntries && temp.segmentEntries.length > 0;
    }
    isInfo(segmentEntry) {
        return segmentEntry.type === SegmentEntryType.INFO;
    }
    isQuestion(segmentEntry) {
        return segmentEntry.type === SegmentEntryType.QUESTION;
    }
    isFile(segmentEntry) {
        return segmentEntry.type === SegmentEntryType.FILE;
    }
    isQuestionWithPredefinedAnswers(segmentEntry) {
        return (segmentEntry.type === SegmentEntryType.QUESTION &&
            (segmentEntry.entry.type === QuestionType.MULTIPLE_CHOICE ||
                segmentEntry.entry.type === QuestionType.RADIO ||
                segmentEntry.entry.type === QuestionType.SELECT));
    }
    getTagFromQuestionUUID(questionUUID) {
        return this.dataModel.tags.find(t => t.usedQuestionUUID === questionUUID);
    }
    getSegmentUUIDsForChapter(chapterCard, segmentKey) {
        return chapterCard.segments.get(segmentKey).map(segment => segment._id);
    }
    getQuestionsSuitableForConditions(chapterCard, segmentKey, segmentUUID) {
        return chapterCard.segments
            .get(segmentKey)
            .find(segment => segment._id === segmentUUID)
            .segmentEntries.reduce((acc, sE) => {
            if (this.isQuestionWithPredefinedAnswers(sE)) {
                acc.push(sE);
            }
            return acc;
        }, []);
    }
    getTextParts(segmentEntry) {
        switch (segmentEntry.type) {
            case SegmentEntryType.QUESTION:
                return segmentEntry.entry.textParts;
            case SegmentEntryType.INFO:
                return segmentEntry.entry.textParts;
            default:
                return ['SegmentEntryType not implemented yet'];
        }
    }
    getTemplateAuditFile(uuid) {
        return this.dataModel.auditFiles.find(auditFile => auditFile._id === uuid);
    }
    getNewSegmentEntryForSegmentEntryType(segmentEntryType, entryType) {
        const segmentEntry = SegmentEntry.CLEAN_INSTANCE(segmentEntryType);
        switch (segmentEntryType) {
            case SegmentEntryType.QUESTION:
                segmentEntry.entry = new Question(v4(), entryType, [], null, null, null, null, null, null, null);
                break;
            case SegmentEntryType.INFO:
                segmentEntry.entry = new Info(v4(), [], null, entryType);
                break;
            case SegmentEntryType.FILE:
                segmentEntry.entry = new SegmentFile(v4(), entryType, null, null);
        }
        return segmentEntry;
    }
    getAuditFileTemplateUUIDs(file) {
        if (this.languageService.getCurrentLanguageString() === 'de') {
            return file.auditFileTemplateUUID_DE || [];
        }
        else if (this.languageService.getCurrentLanguageString() === 'en') {
            return file.auditFileTemplateUUID_EN || [];
        }
        return [];
    }
    getTemplateFileForEntity(entityUUID) {
        return this.dataModel.templateFiles.find(tF => tF.entityUUID === entityUUID);
    }
    getUploadedFileDownloads(chapterUUID) {
        // return this.dataModel.auditFiles
        //   ? this.dataModel.auditFiles.reduce<Download[]>((acc, auditFile) => {
        //       if (
        //         auditFile.providedByCompanyUUID &&
        //         auditFile.providedForCompanyUUID &&
        //         auditFile.entityUUID === chapterUUID
        //       ) {
        //         acc.push(
        //           new Download(
        //             auditFile._id,
        //             auditFile.fileName,
        //             auditFile.dateCreated,
        //             auditFile.fileSize + '',
        //             [
        //               this.dataModel.selectedCompany,
        //               this.dataModel.epcCompany
        //             ].find(c => c._id === auditFile.providedByCompanyUUID).name
        //           )
        //         );
        //       }
        //       return acc;
        //     }, [])
        //   : [];
        return this.dataModel.auditFiles
            ? this.dataModel.auditFiles
                .filter(auditFile => auditFile.providedByCompanyUUID &&
                auditFile.providedForCompanyUUID &&
                auditFile.entityUUID === chapterUUID)
                .map(auditFile => new Download(auditFile._id, auditFile.fileName, auditFile.dateCreated, auditFile.fileSize + '', [
                this.dataModel.selectedCompany2.getValue()
                    ? this.dataModel.selectedCompany2.getValue()
                    : new Company('0000-0000', 'Bitte eine Firma auswählen', '1', new Country('', '', ''), '2', BookedPackage.test, '0000', '0001', [], [], null),
                this.dataModel.epcCompany
            ].find(c => c._id === auditFile.providedByCompanyUUID).name))
            : [];
    }
    getTemplateLinksInChapters(auditFileUUID) {
        const res = new Map();
        this.dataModel.chapters.forEach(c => c.chapterCards.forEach(cC => Array.from(cC.segments.values()).forEach(sA => sA.forEach((s, indexSegment) => s.segmentEntries
            .filter(sE => sE.type === SegmentEntryType.FILE)
            .forEach(sEE => {
            if (sEE.entry.type === FileType.TEMPLATE &&
                ((sEE.entry.auditFileTemplateUUID_DE &&
                    sEE.entry.auditFileTemplateUUID_DE.includes(auditFileUUID)) ||
                    (sEE.entry.auditFileTemplateUUID_EN &&
                        sEE.entry.auditFileTemplateUUID_EN.includes(auditFileUUID)))) {
                const cardTitle = this.languageService
                    .translate()[cC.title].toLowerCase();
                if (!res.has(this.textResolverService.getLanguageText(c.title))) {
                    res.set(this.textResolverService.getLanguageText(c.title), []);
                }
                res.set(this.textResolverService.getLanguageText(c.title), [
                    ...Array.from(res.get(this.textResolverService.getLanguageText(c.title))),
                    cardTitle.charAt(0).toUpperCase() +
                        cardTitle.slice(1) +
                        ' - ' +
                        (indexSegment + 1) +
                        '. Segment'
                ]);
            }
        })))));
        return res;
    }
    getTemplateFileDownloads(file) {
        return file
            ? this.getAuditFileTemplateUUIDs(file).reduce((acc, auditFileTemplateUUID) => {
                const auditFile = this.dataModel.auditFiles.find(aF => aF._id === auditFileTemplateUUID &&
                    aF.lang === this.languageService.getCurrentLanguageString());
                if (auditFile) {
                    acc.push(new Download(auditFile._id, auditFile.fileName, auditFile.dateCreated, auditFile.fileSize + '', 'ePrivacy GmbH'));
                }
                return acc;
            }, [])
            : [];
    }
    getAmountSegments(chapterCard, segmentKey) {
        return chapterCard.segments.get(segmentKey).length;
    }
    getSegmentTitle(chapterCard, segmentKey, segmentIndex) {
        const temp = chapterCard.segments.get(segmentKey);
        return temp.length > 0
            ? this.textResolverService.getLanguageText(temp[segmentIndex].title)
            : null;
    }
    getSegmentEntries(chapterCard, segmentKey, segmentIndex) {
        return chapterCard.segments.get(segmentKey)[segmentIndex].segmentEntries;
    }
    getSegmentEntryTypeName(segmentEntryType) {
        switch (segmentEntryType) {
            case SegmentEntryType.QUESTION:
                return 'Frage';
            case SegmentEntryType.INFO:
                return 'Info';
            case SegmentEntryType.MASTER_DATA:
                return 'Stammdaten';
            case SegmentEntryType.FILE:
                return 'Dokument';
        }
    }
    getQuestionTypeName(questionType) {
        switch (questionType) {
            case QuestionType.TEXTFIELD:
                return 'Textfeld';
            case QuestionType.RADIO:
                return 'Radio';
            case QuestionType.MULTIPLE_CHOICE:
                return 'Multiple Choice';
            case QuestionType.CHECKBOX:
                return 'Checkbox';
            case QuestionType.SELECT:
                return 'Select';
            default:
                return 'Neu';
        }
    }
    getInfoTypeName(infoType) {
        switch (infoType) {
            case InfoType.PLAINTEXT:
                return 'Text';
            case InfoType.LIST_NUMBERED:
                return 'Nummeriert';
            case InfoType.LIST_NOT_NUMBERED:
                return 'Bulletpoints';
            case InfoType.LIST_NUMBERED_GROUPED:
                return 'Nummeriert und gruppiert';
            case InfoType.LIST_NOT_NUMBERED_GROUPED:
                return 'Bulletpoints und gruppiert';
            default:
                return 'Neu';
        }
    }
    getFileTypeName(fileType) {
        switch (fileType) {
            case FileType.TEMPLATE:
                return 'Vorlage';
            case FileType.UPLOAD:
                return 'Upload';
            default:
                return 'Neu';
        }
    }
    getAvailableSegmentEntryTypes() {
        return [
            SegmentEntryType.QUESTION,
            SegmentEntryType.INFO,
            SegmentEntryType.FILE
        ];
        /* Later on if all available segment entry types are supported for consulting
        return Object.values(SegmentEntryType).filter(type => !isNaN(type));
        */
    }
    getAvailableQuestionTypes() {
        return Object.values(QuestionType)
            .filter(type => !isNaN(type))
            .filter(n => n !== 0);
    }
    getAvailableInfoTypes() {
        return Object.values(InfoType).filter(type => !isNaN(type));
    }
    getAvailableFileTypes() {
        return Object.values(FileType).filter(type => !isNaN(type));
    }
}
AuditService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuditService_Factory() { return new AuditService(i0.ɵɵinject(i1.DataModelService), i0.ɵɵinject(i2.TextResolverService), i0.ɵɵinject(i3.LanguageService), i0.ɵɵinject(i4.QuestionService)); }, token: AuditService, providedIn: "root" });
