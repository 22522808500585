/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./format-text.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../utils/formatting.pipe";
import * as i3 from "@angular/common";
import * as i4 from "./format-text.component";
var styles_FormatTextComponent = [i0.styles];
var RenderType_FormatTextComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_FormatTextComponent, data: {} });
export { RenderType_FormatTextComponent as RenderType_FormatTextComponent };
export function View_FormatTextComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.FormattingPipe, [i1.Sanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 4, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(4, { missing: 0 }), i1.ɵppd(5, 1)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 4, 0, _co.missing); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), _co.text)); _ck(_v, 1, 0, currVal_0); }); }
export function View_FormatTextComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-format-text", [], null, null, null, View_FormatTextComponent_0, RenderType_FormatTextComponent)), i1.ɵdid(1, 49152, null, 0, i4.FormatTextComponent, [], null, null)], null, null); }
var FormatTextComponentNgFactory = i1.ɵccf("app-format-text", i4.FormatTextComponent, View_FormatTextComponent_Host_0, { text: "text", missing: "missing" }, {}, []);
export { FormatTextComponentNgFactory as FormatTextComponentNgFactory };
