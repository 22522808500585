import { Component } from '@angular/core';
import { CONSTANTS } from '../../models/globals';
import { LanguageService } from '../../utils/language.service';
import { DataModelService } from './../../utils/datamodel.service';
import { RoutingService } from './../../utils/routing.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent {
  sidebarWidth = CONSTANTS.sidebarWidth;
  constructor(public lang: LanguageService, public datamodel: DataModelService, public router: RoutingService) {}
}
