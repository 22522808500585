import { Component } from "@angular/core";
import { DataModelService } from "../../../utils/datamodel.service";
import { LanguageService } from "../../../utils/language.service";

@Component({
  selector: "app-einfuehrung",
  templateUrl: "./einfuehrung.component.html",
  styleUrls: ["./einfuehrung.component.css"]
})
export class EinfuehrungComponent {
  constructor(
    public lang: LanguageService,
    private datamodel: DataModelService
  ) {}
}
