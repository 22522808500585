/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./landingtoprightrow.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../landinglogin/landinglogin.component.ngfactory";
import * as i3 from "../landinglogin/landinglogin.component";
import * as i4 from "../../../utils/language.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../node_modules/ng-zorro-antd/button/ng-zorro-antd-button.ngfactory";
import * as i7 from "ng-zorro-antd/core";
import * as i8 from "ng-zorro-antd/button";
import * as i9 from "@angular/cdk/observers";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "./landingtoprightrow.component";
import * as i12 from "../../../utils/datamodel.service";
import * as i13 from "../../../utils/routing.service";
var styles_LandingTopRightRowComponent = [i0.styles];
var RenderType_LandingTopRightRowComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LandingTopRightRowComponent, data: {} });
export { RenderType_LandingTopRightRowComponent as RenderType_LandingTopRightRowComponent };
function View_LandingTopRightRowComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-landinglogin", [["class", "centerFlaggen"]], null, null, null, i2.View_LandingLoginComponent_0, i2.RenderType_LandingLoginComponent)), i1.ɵdid(1, 49152, null, 0, i3.LandingLoginComponent, [i4.LanguageService], null, null)], null, null); }
export function View_LandingTopRightRowComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "rightDiv"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "centerFlaggen"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "img", [["class", "flaggeDeu button"], ["src", "../../../../assets/lang_de.png"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchLang("de") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { notClickable: 0, clickable: 1 }), (_l()(), i1.ɵeld(7, 0, null, null, 3, "img", [["class", "flaggeDeu button"], ["src", "../../../../assets/lang_en.png"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchLang("en") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(9, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(10, { notClickable: 0, clickable: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LandingTopRightRowComponent_1)), i1.ɵdid(12, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 4, "button", [["class", "button centerFlaggen noshadow requestButton"], ["nz-button", ""]], [[1, "nz-wave", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.contact() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_NzButtonComponent_0, i6.RenderType_NzButtonComponent)), i1.ɵprd(512, null, i7.NzUpdateHostClassService, i7.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(15, 1818624, null, 1, i8.NzButtonComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2, i9.ContentObserver, i7.NzUpdateHostClassService, i1.NgZone, i7.NzConfigService, [2, i7.NZ_WAVE_GLOBAL_CONFIG], [2, i10.ANIMATION_MODULE_TYPE]], null, null), i1.ɵqud(603979776, 1, { listOfIconElement: 1 }), (_l()(), i1.ɵted(17, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "flaggeDeu button"; var currVal_1 = _ck(_v, 6, 0, (_co.lang.translate().name === "Deutsch"), (_co.lang.translate().name === "English")); _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_2 = "flaggeDeu button"; var currVal_3 = _ck(_v, 10, 0, (_co.lang.translate().name === "English"), (_co.lang.translate().name === "Deutsch")); _ck(_v, 9, 0, currVal_2, currVal_3); var currVal_4 = _co.login; _ck(_v, 12, 0, currVal_4); _ck(_v, 15, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = i1.ɵnov(_v, 15).nzWave; _ck(_v, 13, 0, currVal_5); var currVal_6 = _co.lang.translate().contact; _ck(_v, 17, 0, currVal_6); }); }
export function View_LandingTopRightRowComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-landingtoprightrow", [], null, null, null, View_LandingTopRightRowComponent_0, RenderType_LandingTopRightRowComponent)), i1.ɵdid(1, 49152, null, 0, i11.LandingTopRightRowComponent, [i4.LanguageService, i12.DataModelService, i13.RoutingService], null, null)], null, null); }
var LandingTopRightRowComponentNgFactory = i1.ɵccf("app-landingtoprightrow", i11.LandingTopRightRowComponent, View_LandingTopRightRowComponent_Host_0, { login: "login" }, {}, []);
export { LandingTopRightRowComponentNgFactory as LandingTopRightRowComponentNgFactory };
