<div class="topWidth" *ngIf="!authService.isLoggedIn">
  <app-landinglogo></app-landinglogo>
  <app-landingtoprightrow></app-landingtoprightrow>
</div>
<div class="centderDiv">
  <button nz-button class="botmargin" (click)="zurueck()">{{ lang.translate().back }}</button>

  <nz-card [nzTitle]="lang.translate().imprint_header">
    <p *ngFor="let line of lang.translate().imprint_content">{{ line }}</p>
  </nz-card>
</div>
