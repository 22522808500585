<button class="button" nz-button nz-dropdown [nzDropdownMenu]="menu">
  {{ this.getName() }}
  <i nz-icon nzType="down"></i>
</button>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu>
    <li *appRole="this.consult" nz-menu-item (click)="this.navigateConsulting()">
      <span><i nz-icon nzType="setting"></i> Consulting</span>
    </li>
    <!-- <li nz-menu-item>
      <span
        ><i nz-icon nzType="setting"></i> {{ lang.translate().settings }}</span
      >
    </li> -->
    <li nz-menu-item (click)="this.navigateToAccount()">
      <span><i nz-icon nzType="user" ></i> {{ lang.translate().account }}</span>
    </li>
    <li nz-submenu>
      <span title>
        <i nz-icon>
          <div *ngIf="lang.translate().name === 'Deutsch'">
            <ng-container *ngTemplateOutlet="deu"></ng-container>
          </div>
          <div *ngIf="lang.translate().name === 'English'">
            <ng-container *ngTemplateOutlet="eng"></ng-container>
          </div>
        </i>
        {{ lang.translate().language }}
      </span>
      <ul nz-menu>
        <li nz-menu-item (click)="this.switchLang('de')">
          <span>
            <ng-container *ngTemplateOutlet="deu"></ng-container>
            Deutsch
          </span>
        </li>
        <li nz-menu-item (click)="this.switchLang('en')">
          <span>
            <ng-container *ngTemplateOutlet="eng"></ng-container>
            English
          </span>
        </li>
      </ul>
    </li>

    <li nz-menu-item (click)="this.logout()">
      <span><i nz-icon nzType="logout"></i> {{ lang.translate().logout }}</span>
    </li>
  </ul>
</nz-dropdown-menu>

<ng-template #eng>
  <i nz-icon>
    <svg viewBox="0 0 60 30">
      <clipPath>
        <path d="M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z" />
      </clipPath>
      <path d="M0,0 v30 h60 v-30 z" fill="#00247d" />
      <path d="M0,0 L60,30 M60,0 L0,30" stroke="#fff" stroke-width="6" />
      <path d="M0,0 L60,30 M60,0 L0,30" clip-path="url(#t)" stroke="#cf142b" stroke-width="4" />
      <path d="M30,0 v30 M0,15 h60" stroke="#fff" stroke-width="10" />
      <path d="M30,0 v30 M0,15 h60" stroke="#cf142b" stroke-width="6" />
    </svg>
  </i>
</ng-template>

<ng-template #deu>
  <i nz-icon>
    <svg viewBox="0 0 5 3">
      <rect width="5" height="3" y="0" x="0" fill="#000" />
      <rect width="5" height="2" y="1" x="0" fill="#D00" />
      <rect width="5" height="1" y="2" x="0" fill="#FFCE00" />
    </svg>
  </i>
</ng-template>
