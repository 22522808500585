import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormGroupDirective,
  NgForm
} from '@angular/forms';
import { LanguageService } from '../../utils/language.service';
import { ErrorStateMatcher } from '@angular/material';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.dirty
    );

    return invalidCtrl || invalidParent;
  }
}
@Component({
  selector: 'app-setpassword',
  templateUrl: './setpassword.component.html',
  styleUrls: ['./setpassword.component.css']
})
export class SetPasswordComponent implements OnInit {
  validateForm: FormGroup;
  loginfailed: boolean;
  errorMessage = 'Passwörter stimmen nicht über ein!';
  matcher: MyErrorStateMatcher;
  @Input()
  username: string;
  @Output()
  setPassword: EventEmitter<string> = new EventEmitter<string>();
  pwLength = false;
  pwUppercase = false;
  pwNumber = false;
  pwSpecial = false;

  constructor(
    private fb: FormBuilder,
    public languageService: LanguageService
  ) {
    this.matcher = new MyErrorStateMatcher();
  }
  ngOnInit(): void {
    this.validateForm = this.fb.group(
      {
        password1: ['', [Validators.required]],
        password2: ['']
      },
      { validator: this.isValidPassword }
    );
  }

  isValidPassword(group: FormGroup) {
    const password1 = group.get('password1').value;
    const password2 = group.get('password2').value;

    return password1 !== '' &&
      password1 === password2 &&
      this.pwLength &&
      this.pwNumber &&
      this.pwUppercase &&
      this.pwSpecial
      ? true
      : false;
  }
  validateConditions(group: FormGroup) {
    const password1 = group.get('password1').value;
    this.pwLength = password1.length > 5;
    this.pwNumber = password1.match(/[0-9]/);
    this.pwUppercase = password1.match(/[A-Z]/);
    this.pwSpecial = password1.match(/\W+/);
  }

  submitForm(): void {
    this.setPassword.emit(this.validateForm.get('password1').value);
  }
}
