<div class="topWidth" *ngIf="!authService.isLoggedIn">
  <app-landinglogo></app-landinglogo>
  <app-landingtoprightrow></app-landingtoprightrow>
</div>
<div class="centderDiv">
  <button nz-button class="botmargin" (click)="zurueck()">{{ langService.translate().back }}</button>

  <div class="login">
    <nz-card [nzTitle]="langService.translate().welcomeContactTitle">
      <p>{{ langService.translate().welcomeContact }}</p>
      <p></p>

      <h3>{{ langService.translate().companyinformations }}</h3>

      <div nz-row class="inp">
        <div nz-col nzSpan="17">
          <label>{{ langService.translate().companyname }}:*</label>
          <input nz-input [(ngModel)]="companyname" />
        </div>
        <div nz-col nzSpan="6" nzOffset="1">
          <label>{{ langService.translate().legalForm }}:*</label>
          <input nz-input [(ngModel)]="legalForm" />
        </div>
      </div>

      <h3 class="inp">{{ langService.translate().userInformations }}</h3>
      <div nz-row class="inp">
        <div nz-col nzSpan="4">
          <label>{{ langService.translate().formOfAddress }}:*</label>
          <nz-select class="select" [(ngModel)]="formOfAddress">
            <nz-option [nzLabel]="langService.translate().MR" [nzValue]="langService.translate().MR"></nz-option>
            <nz-option [nzLabel]="langService.translate().MRS" [nzValue]="langService.translate().MRS"></nz-option>
          </nz-select>
        </div>
        <div nz-col nzSpan="9" nzOffset="1">
          <label>{{ langService.translate().firstname }}:</label>
          <input nz-input [(ngModel)]="firstName" />
        </div>
        <div nz-col nzSpan="9" nzOffset="1">
          <label>{{ langService.translate().lastname }}:*</label>
          <input nz-input [(ngModel)]="lastName" />
        </div>
      </div>
      <div nz-row class="inp">
        <div nz-col nzSpan="4">
          <label>{{ langService.translate().prefLanguage }}:*</label>
          <nz-select class="select" [(ngModel)]="lang">
            <nz-option [nzLabel]="langService.translate().de" [nzValue]="langService.translate().de"></nz-option>
            <nz-option [nzLabel]="langService.translate().en" [nzValue]="langService.translate().en"></nz-option>
          </nz-select>
        </div>
        <div nz-col nzSpan="9" nzOffset="1">
          <label>{{ langService.translate().emailAddress }}:*</label>
          <input nz-input [(ngModel)]="email" />
        </div>
        <div nz-col nzSpan="9" nzOffset="1">
          <label>{{ langService.translate().phone }}:</label>
          <input nz-input [(ngModel)]="phone" />
        </div>
      </div>

      <div class="inp">
        <label>{{ langService.translate().message }}:</label>
        <textarea rows="4" nz-input [(ngModel)]="message"></textarea>
      </div>
      <div class="inp">
        <p>
          <label>{{ langService.translate().disclaimer }}</label>
        </p>
      </div>

      <app-captcha (emitUUID)="setCaptchaUUID($event)" (emitInput)="setCaptchaText($event)"></app-captcha>
      <p *ngIf="captchaFalse" class="red inp">{{ langService.translate().captchaError }}</p>
      <div class="inp">
        <button nz-button [disabled]="isEverythingEmpty()" (click)="testCaptchaAndSendRequest()" type="submit">
          {{ langService.translate().send }}
        </button>
      </div>
    </nz-card>
  </div>
</div>
