import { FileType } from './fileType';

export class SegmentFile {
  constructor(
    public _id: string,
    public type: FileType,
    public auditFileTemplateUUID_DE: string[],
    public auditFileTemplateUUID_EN: string[]
  ) {}
}
