import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd';
import { DBCollections } from '../../../../models/dbCollections';
import { Download } from '../../../../models/download';
import { DataModelService } from '../../../../utils/datamodel.service';
import { LanguageService } from '../../../../utils/language.service';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent {
  @Input() _downloads: Download[];
  @Input() isTemplate = false;
  @Input() dateRange = 5;
  @Input() maxPinnedFiles = 3;
  @Input() deleteDisabled = false;

  @Output()
  deletedFile = new EventEmitter<void>();

  pinnedDownloads: Download[] = [];
  historyDownloads: Download[] = [];
  openMenu = false;

  constructor(
    private modalService: NzModalService,
    public lang: LanguageService,
    private dataModel: DataModelService
  ) {}

  @Input()
  set downloads(downloads: Download[]) {
    this._downloads = downloads;
    if (!this.isTemplate) {
      this._downloads.sort((a, b) => b.date - a.date);
    }
    this.separateDownloads();
  }

  separateDownloads() {
    if (this._downloads) {
      if (this.isTemplate) {
        this.pinnedDownloads = this._downloads;
      } else {
        this.pinnedDownloads = [];
        this.historyDownloads = [];
        let firstElement = true;
        this._downloads.forEach(element => {
          if (firstElement === true) {
            this.pinnedDownloads.push(element);
            firstElement = false;
          } else {
            if (
              this.pinnedDownloads[0].date - element.date <=
                86400000 * this.dateRange &&
              this.pinnedDownloads.length < this.maxPinnedFiles
            ) {
              this.pinnedDownloads.push(element);
            } else {
              this.historyDownloads.push(element);
            }
          }
        });
      }
    }
  }

  confirmDocumentDeletion(auditFileUUID: string): void {
    this.modalService.confirm({
      nzTitle: this.lang.translate().deleteDocumentTitle,
      nzContent:
        this.lang.translate().deleteDocumentText1 +
        '<br /><br />' +
        '<b>' +
        this._downloads.find(d => d.auditFileUUID === auditFileUUID).name +
        '</b>' +
        '<br /><br />' +
        this.lang.translate().deleteDocumentText2,
      nzOnOk: () => {
        this.dataModel.auditFiles.splice(
          this.dataModel.auditFiles.findIndex(aF => aF._id === auditFileUUID),
          1
        );
        this.deletedFile.emit();
        this.dataModel.deleteFromCollection(
          [auditFileUUID],
          DBCollections.AUDIT_FILES
        );
      }
    });
  }

  shouldDeleteBeDisabled(download: Download) {
    return (
      (download.user === this.dataModel.epcCompany.name &&
        this.dataModel.company._id !== this.dataModel.epcCompany._id) ||
      this.deleteDisabled
    );
  }
}
