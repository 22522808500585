/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./stammdaten.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/ng-zorro-antd/button/ng-zorro-antd-button.ngfactory";
import * as i3 from "ng-zorro-antd/core";
import * as i4 from "ng-zorro-antd/button";
import * as i5 from "@angular/cdk/observers";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../slider/slider.component.ngfactory";
import * as i8 from "../slider/slider.component";
import * as i9 from "../../../utils/language.service";
import * as i10 from "./group/group.component.ngfactory";
import * as i11 from "./group/group.component";
import * as i12 from "../../../utils/question.service";
import * as i13 from "../../../utils/text-resolver.service";
import * as i14 from "../../../../../node_modules/ng-zorro-antd/divider/ng-zorro-antd-divider.ngfactory";
import * as i15 from "ng-zorro-antd/divider";
import * as i16 from "@angular/common";
import * as i17 from "../chapter-card/chapter-card.component.ngfactory";
import * as i18 from "../chapter-card/chapter-card.component";
import * as i19 from "./stammdaten.component";
import * as i20 from "../../../utils/datamodel.service";
var styles_StammdatenComponent = [i0.styles];
var RenderType_StammdatenComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StammdatenComponent, data: {} });
export { RenderType_StammdatenComponent as RenderType_StammdatenComponent };
function View_StammdatenComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "default-button hollow-button"], ["nz-button", ""], ["nzType", "primary"]], [[1, "nz-wave", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleEditModeWithoutSave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NzButtonComponent_0, i2.RenderType_NzButtonComponent)), i1.ɵprd(512, null, i3.NzUpdateHostClassService, i3.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(2, 1818624, null, 1, i4.NzButtonComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2, i5.ContentObserver, i3.NzUpdateHostClassService, i1.NgZone, i3.NzConfigService, [2, i3.NZ_WAVE_GLOBAL_CONFIG], [2, i6.ANIMATION_MODULE_TYPE]], { nzType: [0, "nzType"] }, null), i1.ɵqud(603979776, 1, { listOfIconElement: 1 }), (_l()(), i1.ɵted(4, 0, [" ", " "]))], function (_ck, _v) { var currVal_1 = "primary"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).nzWave; _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.getCancelButtonText(); _ck(_v, 4, 0, currVal_2); }); }
function View_StammdatenComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "default-button hollow-button margin-button-save-edit"], ["nz-button", ""], ["nzType", "primary"]], [[1, "nz-wave", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleEditModeWithSave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NzButtonComponent_0, i2.RenderType_NzButtonComponent)), i1.ɵprd(512, null, i3.NzUpdateHostClassService, i3.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(2, 1818624, null, 1, i4.NzButtonComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2, i5.ContentObserver, i3.NzUpdateHostClassService, i1.NgZone, i3.NzConfigService, [2, i3.NZ_WAVE_GLOBAL_CONFIG], [2, i6.ANIMATION_MODULE_TYPE]], { nzType: [0, "nzType"] }, null), i1.ɵqud(603979776, 2, { listOfIconElement: 1 }), (_l()(), i1.ɵted(4, 0, [" ", " "]))], function (_ck, _v) { var currVal_1 = "primary"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).nzWave; _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.getSaveEditButtonText(); _ck(_v, 4, 0, currVal_2); }); }
function View_StammdatenComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "app-slider", [], null, null, null, i7.View_SliderComponent_0, i7.RenderType_SliderComponent)), i1.ɵdid(2, 49152, [["slider", 4]], 0, i8.SliderComponent, [i9.LanguageService], { amountSegments: [0, "amountSegments"], showIndicators: [1, "showIndicators"], idToScroll: [2, "idToScroll"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "app-group", [], null, null, null, i10.View_GroupComponent_0, i10.RenderType_GroupComponent)), i1.ɵdid(4, 49152, null, 0, i11.GroupComponent, [i12.QuestionService, i13.TextResolverService], { segment: [0, "segment"], editMode: [1, "editMode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getAmountSegments(); var currVal_1 = true; var currVal_2 = _co.getID(); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.getCurrentSegment(i1.ɵnov(_v, 2)._currentSegment); var currVal_4 = true; _ck(_v, 4, 0, currVal_3, currVal_4); }, null); }
function View_StammdatenComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "nz-divider", [], null, null, null, i14.View_NzDividerComponent_0, i14.RenderType_NzDividerComponent)), i1.ɵprd(512, null, i3.NzUpdateHostClassService, i3.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(2, 638976, null, 0, i15.NzDividerComponent, [i1.ElementRef, i3.NzUpdateHostClassService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_StammdatenComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-group", [], null, null, null, i10.View_GroupComponent_0, i10.RenderType_GroupComponent)), i1.ɵdid(2, 49152, null, 0, i11.GroupComponent, [i12.QuestionService, i13.TextResolverService], { segment: [0, "segment"], shouldSave: [1, "shouldSave"], editMode: [2, "editMode"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammdatenComponent_6)), i1.ɵdid(4, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.shouldSave; var currVal_2 = _co.editMode; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = !_co.isLastSegment(_v.context.index); _ck(_v, 4, 0, currVal_3); }, null); }
function View_StammdatenComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammdatenComponent_5)), i1.ɵdid(2, 278528, null, 0, i16.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getSegmentArray(); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_StammdatenComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "app-chapter-card", [], null, null, null, i17.View_ChapterCardComponent_0, i17.RenderType_ChapterCardComponent)), i1.ɵdid(1, 49152, null, 0, i18.ChapterCardComponent, [i9.LanguageService, i13.TextResolverService], { chapterCard: [0, "chapterCard"], isOpen: [1, "isOpen"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 4, "div", [["class", "button"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammdatenComponent_1)), i1.ɵdid(4, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StammdatenComponent_2)), i1.ɵdid(6, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_StammdatenComponent_3)), i1.ɵdid(8, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_StammdatenComponent_4)), i1.ɵdid(10, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, 0, 0, "div", [["class", "placeholder"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataModel.stammdaten; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (!_co.shouldBeSlides && _co.editMode); _ck(_v, 4, 0, currVal_2); var currVal_3 = !_co.shouldBeSlides; _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.shouldBeSlides; _ck(_v, 8, 0, currVal_4); var currVal_5 = !_co.shouldBeSlides; _ck(_v, 10, 0, currVal_5); }, null); }
export function View_StammdatenComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-stammdaten", [], null, null, null, View_StammdatenComponent_0, RenderType_StammdatenComponent)), i1.ɵdid(1, 114688, null, 0, i19.StammdatenComponent, [i20.DataModelService, i9.LanguageService, i13.TextResolverService, i12.QuestionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StammdatenComponentNgFactory = i1.ɵccf("app-stammdaten", i19.StammdatenComponent, View_StammdatenComponent_Host_0, { shouldBeSlides: "shouldBeSlides" }, {}, []);
export { StammdatenComponentNgFactory as StammdatenComponentNgFactory };
