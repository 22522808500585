<ng-container *ngIf="this.question; else defaultSelectQuestion">
  <span [ngClass]="{ 'select-container': !this.noMargin }">
    <nz-select
      class="select"
      nzShowSearch
      nzAllowClear
      [nzPlaceHolder]="
        this.textResolverService.getLanguageText(this.question.textParts[0])
      "
      [(ngModel)]="this.answer"
      (ngModelChange)="this.setAnswer($event)"
    >
      <nz-option
        [nzLabel]="this.textResolverService.getLanguageText(singleAnswer)"
        [nzValue]="singleAnswer"
        *ngFor="let singleAnswer of this.question.predefinedAnswers"
      ></nz-option>
    </nz-select>
  </span>
</ng-container>
<ng-template #defaultSelectQuestion>
  <div
    class="part"
    *ngFor="let questionPart of this.segmentEntry.entry.textParts"
  >
    <app-popover-marked
      [text]="this.textResolverService.getLanguageText(questionPart)"
      [helpMap]="this.segmentEntry.helpMap"
    ></app-popover-marked>
  </div>
  <span class="select-container">
    <nz-select
      class="select"
      nzShowSearch
      nzAllowClear
      [nzPlaceHolder]="this.languageService.translate().dropdownSelect"
      [(ngModel)]="this.answer"
      (ngModelChange)="this.setAnswer($event)"
    >
      <nz-option
        [nzLabel]="this.textResolverService.getLanguageText(singleAnswer)"
        [nzValue]="singleAnswer"
        *ngFor="let singleAnswer of this.segmentEntry.entry.predefinedAnswers"
      ></nz-option> </nz-select></span
></ng-template>
<button
  *ngIf="this.suffixIconName"
  class="suffix-button"
  nz-button
  nzType="default"
  (click)="this.iconClicked.emit()"
>
  <i nz-icon [nzType]="this.suffixIconName"></i>
</button>
