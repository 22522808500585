<div class="topWidth" *ngIf="!authService.isLoggedIn">
  <app-landinglogo></app-landinglogo>
  <app-landingtoprightrow></app-landingtoprightrow>
</div>
<div class="centderDiv">
  <button nz-button class="botmargin" (click)="zurueck()">{{ lang.translate().back }}</button>

  <nz-card [nzTitle]="lang.translate().terms_header">
    <span *ngFor="let int of getSectionCount(lang.translate().terms_content)">
      <span *ngFor="let key of getKeysFromJSON(lang.translate().terms_content[int])">
        <h3>{{ key }}</h3>
        <span *ngFor="let value of getKeysFromJSON(lang.translate().terms_content[int][key])">
          <p>{{ lang.translate().terms_content[int][key][value] }}</p>
        </span>
      </span>
    </span>
  </nz-card>
</div>
