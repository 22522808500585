/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/shared/login-mask/login-mask.component.ngfactory";
import * as i3 from "../../components/shared/login-mask/login-mask.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../utils/datamodel.service";
import * as i6 from "../../utils/dbverbindung.service";
import * as i7 from "../../guards/auth/auth.service";
import * as i8 from "@angular/router";
import * as i9 from "../../utils/socket.service";
import * as i10 from "../../utils/cookies.service";
import * as i11 from "../../utils/language.service";
import * as i12 from "../../utils/role.service";
import * as i13 from "ng-zorro-antd/modal";
import * as i14 from "../../components/shared/placeholder-card/placeholder-card.component.ngfactory";
import * as i15 from "../../components/shared/placeholder-card/placeholder-card.component";
import * as i16 from "../../components/landingpage/landinglogo/landinglogo.component.ngfactory";
import * as i17 from "../../components/landingpage/landinglogo/landinglogo.component";
import * as i18 from "../../utils/routing.service";
import * as i19 from "../../components/landingpage/landingtoprightrow/landingtoprightrow.component.ngfactory";
import * as i20 from "../../components/landingpage/landingtoprightrow/landingtoprightrow.component";
import * as i21 from "@angular/common";
import * as i22 from "./login.component";
var styles_LoginComponent = [i0.styles];
var RenderType_LoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
function View_LoginComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "login-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-login-mask", [], null, null, null, i2.View_LoginMaskComponent_0, i2.RenderType_LoginMaskComponent)), i1.ɵdid(2, 245760, null, 0, i3.LoginMaskComponent, [i4.FormBuilder, i5.DataModelService, i6.DBService, i7.AuthService, i8.Router, i9.SocketService, i10.Cookies, i11.LanguageService, i12.RoleService, i13.NzModalService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_LoginComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-placeholder-card", [], null, null, null, i14.View_PlaceholderCardComponent_0, i14.RenderType_PlaceholderCardComponent)), i1.ɵdid(2, 49152, null, 0, i15.PlaceholderCardComponent, [], null, null)], null, null); }
export function View_LoginComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-landinglogo", [], null, null, null, i16.View_LandingLogoComponent_0, i16.RenderType_LandingLogoComponent)), i1.ɵdid(2, 49152, null, 0, i17.LandingLogoComponent, [i18.RoutingService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-landingtoprightrow", [], null, null, null, i19.View_LandingTopRightRowComponent_0, i19.RenderType_LandingTopRightRowComponent)), i1.ɵdid(4, 49152, null, 0, i20.LandingTopRightRowComponent, [i11.LanguageService, i5.DataModelService, i18.RoutingService], { login: [0, "login"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginComponent_1)), i1.ɵdid(6, 16384, null, 0, i21.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginComponent_2)), i1.ɵdid(8, 16384, null, 0, i21.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; _ck(_v, 4, 0, currVal_0); var currVal_1 = !_co.authService.isLoggedIn; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.authService.isLoggedIn; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_LoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i1.ɵdid(1, 49152, null, 0, i22.LoginComponent, [i7.AuthService], null, null)], null, null); }
var LoginComponentNgFactory = i1.ɵccf("app-login", i22.LoginComponent, View_LoginComponent_Host_0, {}, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
