<div class="content headerbar">
  <app-logo class="margins"></app-logo>
  <app-burgermenu class="margins burgercenter"></app-burgermenu>
  <span class="fill"></span>
  <!-- <app-suchleiste class="margins addwidth"></app-suchleiste> -->
  <span class="fill"></span>
  <app-firmabutton class="select"></app-firmabutton>
  <app-notifybutton class="margins"></app-notifybutton>
  <app-dropdown class="margins"></app-dropdown>
</div>
