<div class="title-sticky">
  <div class="font-header">{{ lang.translate().manual }}</div>
</div>

<div class="topdiv">
  <h2 [innerHTML]="intro1 | formatting"></h2>
  <h3 [innerHTML]="intro2 | formatting"></h3>
</div>

<div class="margin">
  <nz-tabset>
    <nz-tab *ngFor="let tab of tabs" [nzTitle]="tab.tabTitle">
      <ng-container *ngTemplateOutlet="tabTemplate; context: { innerTab: tab }">
      </ng-container>
    </nz-tab>
  </nz-tabset>
</div>

<ng-template #tabTemplate let-innerTab="innerTab">
  <div *ngIf="innerTab?.isTabAndNotContent === true; else item">
    <nz-tabset>
      <nz-tab *ngFor="let tab of innerTab?.tabs" [nzTitle]="tab.tabTitle">
        <ng-container
          *ngTemplateOutlet="tabTemplate; context: { innerTab: tab }"
        >
        </ng-container>
      </nz-tab>
    </nz-tabset>
  </div>
  <ng-template #item>
    <div *ngFor="let card of innerTab.cards">
      <nz-card class="titleCard">
        <label class="">{{ card.title.toUpperCase() }} </label>
      </nz-card>
      <nz-card class="lowerCard">
        <div *ngFor="let element of card.content; index as i">
          <div
            *ngIf="element.type == 'image'"
            class="spacing-element"
            [style.text-align]="element.options.align"
          >
            <img
              [style.border]="
                element.options.border ? '0.5px solid lightgrey' : ''
              "
              [style.width.%]="element.options.size"
              [style.height.%]="element.options.size"
              [src]="element.contentString"
              alt="Ungültiger Bildlink"
            />
          </div>
          <div class="spacing-element" *ngIf="element.type == 'text'">
            <span [innerHTML]="element.contentString | formatting"></span>
          </div>
        </div>
      </nz-card>
    </div>
  </ng-template>
</ng-template>
