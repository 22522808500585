import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpRequest,
  HttpHeaders
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { UploadFile } from 'ng-zorro-antd';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError, switchMap, mergeMap, tap } from 'rxjs/operators';
import { v4 } from 'uuid';
import { AuditFile } from '../models/auditFile';
import { DBCollections } from '../models/dbCollections';
import { Globals } from '../models/globals';
import { AuditService } from './audit.service';
import { DataModelService } from './datamodel.service';
import { LanguageService } from './language.service';
import { RoleService } from './role.service';
import { DBService } from './dbverbindung.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  dbUrl = new Globals().dbUrl;

  constructor(
    private http: HttpClient,
    private dataModel: DataModelService,
    private dbService: DBService,
    private roleService: RoleService,
    public languageService: LanguageService,
    public auditService: AuditService
  ) {}

  updateDB(
    entityUUID: string,
    fileList: UploadFile[],
    isTemplate: boolean
  ): AuditFile[] {
    const uploadedFiles: AuditFile[] = [];
    fileList.forEach(file => {
      const uploadedFile = new AuditFile(
        v4(),
        entityUUID,
        !isTemplate
          ? this.roleService.isConsultOrHigher()
            ? this.dataModel.selectedCompany2.getValue()._id
            : this.dataModel.epcCompany._id
          : null,
        !isTemplate ? this.dataModel.company._id : null,
        this.dataModel.user._id,
        file.name,
        moment.now(),
        file.size,
        this.languageService.getCurrentLanguageString()
      );
      uploadedFiles.push(uploadedFile);
      this.dataModel.insertIntoCollection(
        [uploadedFile],
        DBCollections.AUDIT_FILES
      );
    });
    this.dataModel.auditFiles.push(...uploadedFiles);
    return uploadedFiles;
  }

  uploadFile(fileList: UploadFile[], isTemplate: boolean): Observable<number> {
    const formData = new FormData();

    let index = 0;
    fileList.forEach(file => {
      formData.append('audit_files', file as any, file.filename);
      index++;
    });

    let uploadURL = this.dbUrl + '/upload';
    let headers = this.dbService.headers.delete('Content-Type');
    if (!isTemplate) {
      uploadURL += '/' + this.dataModel.selectedCompany2.getValue()._id + '';
    } else {
      headers = headers.append('istemplate', 'true');
    }

    return this.http
      .post<number>(uploadURL, formData, {
        reportProgress: true,
        observe: 'events',
        headers: headers
      })
      .pipe(
        map((event: HttpEvent<number>) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              if (event.total > 0) {
                // tslint:disable-next-line:no-any
                (event as any).percent = (event.loaded / event.total) * 100;
              }
              break;
            case HttpEventType.Response:
              console.log('Response');

              break;
            default:
              break;
          }
          return (event as any).percent
            ? +(event as any).percent.toFixed(0)
            : 0;
        }),
        catchError((err, caught) => {
          console.log('httperr: ', err);
          return throwError(err);
        })
      );
  }

  // downloadFile(fileName: string, isTemplate = false): Observable<any> {
  //   const form = new FormData();
  //   let headers = this.dbService.headers;
  //   headers = headers.append('Content-Type', 'application/json');
  //   headers = headers.append('isTemplate', isTemplate.toString());
  //   headers = headers.append(
  //     'companyUUID',
  //     this.dataModel.selectedCompany2.getValue()._id
  //   );
  //   headers = headers.append('fileName', fileName);

  //   return this.http
  //     .post(this.dbUrl + '/download', {
  //       headers: headers,
  //       responseType: 'blob',
  //       observe: 'response',
  //       reportProgress: true
  //     })
  //     .pipe(tap(res => res));
  // }
}
