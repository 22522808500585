import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LanguageService } from '../../../../utils/language.service';
import { TextResolverService } from '../../../../utils/text-resolver.service';
import { SegmentEntry } from '../../../../models/segmentEntry';
import { Question } from '../../../../models/question';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
export class SelectComponent {
  @Input()
  segmentEntry: SegmentEntry;
  @Input()
  question: Question;
  @Input()
  answer: string;
  @Input()
  disabled = false;
  @Input()
  noMargin = false;
  @Input()
  suffixIconName: string;

  @Output()
  answered = new EventEmitter<string>();
  @Output()
  iconClicked = new EventEmitter<void>();

  constructor(
    public languageService: LanguageService,
    public textResolverService: TextResolverService
  ) {}

  setAnswer(answer: string) {
    this.answer = answer;
    this.answered.emit(this.answer);
  }
}
