import { Component, Input } from '@angular/core';
import { SegmentEntry } from '../../../../models/segmentEntry';
import { LanguageService } from '../../../../utils/language.service';
import { QuestionService } from '../../../../utils/question.service';
import { TextResolverService } from '../../../../utils/text-resolver.service';

@Component({
  selector: 'app-plaintext',
  templateUrl: './plaintext.component.html',
  styleUrls: ['./plaintext.component.css']
})
export class PlaintextComponent {
  @Input()
  segmentEntry: SegmentEntry;
  @Input()
  entryHelpMaps: Map<string, string>[];

  constructor(
    public languageService: LanguageService,
    public questionService: QuestionService,
    public textResolverService: TextResolverService
  ) {}
}
