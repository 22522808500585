import { Component } from '@angular/core';
import { DataModelService } from '../../utils/datamodel.service';
import { ScreenSizeService } from '../../utils/screenSize.service';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.css']
})
export class ApplicationComponent {
  constructor(private screenSize: ScreenSizeService, public datamodel: DataModelService) {}

  isScreenSmall(): boolean {
    return this.screenSize.isScreenSmall();
  }

  toggleSidenav(): void {
    this.screenSize.show = !this.screenSize.show;
  }

  getSidenavMode() {
    return this.isScreenSmall() ? 'over' : 'side';
  }
}
