/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./list-grouped.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../popoverMarked/popoverMarked.component.ngfactory";
import * as i4 from "../../popoverMarked/popoverMarked.component";
import * as i5 from "../../../../utils/text-resolver.service";
import * as i6 from "./list-grouped.component";
import * as i7 from "../../../../utils/language.service";
import * as i8 from "../../../../utils/question.service";
var styles_ListGroupedComponent = [i0.styles];
var RenderType_ListGroupedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ListGroupedComponent, data: {} });
export { RenderType_ListGroupedComponent as RenderType_ListGroupedComponent };
function View_ListGroupedComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "avatar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.segmentEntry.entry.iconPath; _ck(_v, 1, 0, currVal_0); }); }
function View_ListGroupedComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "part"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "span", [["class", "base-point"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { point: 0 }), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 5, "span", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(8, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(9, { "text-bullet": 0 }), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-popover-marked", [], null, null, null, i3.View_PopoverMarkedComponent_0, i3.RenderType_PopoverMarkedComponent)), i1.ɵdid(11, 49152, null, 0, i4.PopoverMarkedComponent, [i5.TextResolverService], { text: [0, "text"], helpMap: [1, "helpMap"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "base-point"; var currVal_1 = _ck(_v, 4, 0, !_co.isNumbered()); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = _ck(_v, 9, 0, !_co.isNumbered()); _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.textResolverService.getLanguageText(_v.context.$implicit); var currVal_5 = _co.entryHelpMaps[_v.context.index]; _ck(_v, 11, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getPoint(_v.context.index); _ck(_v, 5, 0, currVal_2); }); }
export function View_ListGroupedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { result: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListGroupedComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListGroupedComponent_2)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.segmentEntry.result); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.hasIcon(); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.segmentEntry.entry.textParts; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_ListGroupedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-list-grouped", [], null, null, null, View_ListGroupedComponent_0, RenderType_ListGroupedComponent)), i1.ɵdid(1, 49152, null, 0, i6.ListGroupedComponent, [i7.LanguageService, i8.QuestionService, i5.TextResolverService], null, null)], null, null); }
var ListGroupedComponentNgFactory = i1.ɵccf("app-list-grouped", i6.ListGroupedComponent, View_ListGroupedComponent_Host_0, { segmentEntry: "segmentEntry", entryHelpMaps: "entryHelpMaps" }, {}, []);
export { ListGroupedComponentNgFactory as ListGroupedComponentNgFactory };
