import { Pipe, PipeTransform, Sanitizer, SecurityContext } from '@angular/core';

@Pipe({
  name: 'formatting'
})
export class FormattingPipe implements PipeTransform {
  constructor(private sanitizer: Sanitizer) {}

  transform(value: string): any {
    return this.sanitize(this.replace(value));
  }

  replace(str: string): string {
    if (str) {
      str = str.replace('<p>', '');
      str = str.replace('</p>', '');
    }
    return str;
  }

  sanitize(str: string): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, str);
  }

  // transform(
  //   value: string | null,
  //   context: SecurityContext = SecurityContext.HTML
  // ): SafeValue | null {
  //   return this.bypassSecurityTrust(
  //     context,
  //     DOMPurify.sanitize(this.replace(value))
  //   );
  // }

  // replace(str: string | null): string {
  //   if (str) {
  //     str = str.replace('<p>', '');
  //     str = str.replace('</p>', '');
  //   }
  //   return str;
  // }

  // private bypassSecurityTrust(
  //   context: SecurityContext,
  //   purifiedValue: string
  // ): SafeValue | null {
  //   switch (context) {
  //     case SecurityContext.HTML:
  //       return this.sanitizer.bypassSecurityTrustHtml(purifiedValue);
  //     case SecurityContext.STYLE:
  //       return this.sanitizer.bypassSecurityTrustStyle(purifiedValue);
  //     case SecurityContext.SCRIPT:
  //       return this.sanitizer.bypassSecurityTrustScript(purifiedValue);
  //     case SecurityContext.URL:
  //       return this.sanitizer.bypassSecurityTrustUrl(purifiedValue);
  //     case SecurityContext.RESOURCE_URL:
  //       return this.sanitizer.bypassSecurityTrustResourceUrl(purifiedValue);
  //     default:
  //       return null;
  //   }
  // }
}
