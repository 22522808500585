import { LanguageService } from "./../../utils/language.service";
import { Component } from "@angular/core";
import { DataModelService } from "./../../utils/datamodel.service";

@Component({
  selector: "app-headerbar",
  templateUrl: "./headerbar.component.html",
  styleUrls: ["./headerbar.component.css"]
})
export class HeaderbarComponent {
  constructor(public lang: LanguageService) {}
}
