import { DataModelService } from './datamodel.service';
import { SegmentEntryType } from '../models/segmentEntryType';
import { LanguageService } from './language.service';
import { AuditService } from './audit.service';
import * as i0 from "@angular/core";
import * as i1 from "./datamodel.service";
import * as i2 from "./language.service";
import * as i3 from "./audit.service";
export class ChapterStatusService {
    constructor(dataModel, languageService, auditService) {
        this.dataModel = dataModel;
        this.languageService = languageService;
        this.auditService = auditService;
        this.colorCodes = {
            new: 'darkgrey',
            inProgress: 'darkorange',
            inReview: '#47ADD6',
            done: 'limegreen'
        };
    }
    getStatus(chapterUUID, short) {
        if (this.isChapterDone(chapterUUID)) {
            return {
                text: this.languageService.translate().chapterStatusDone,
                explanation: this.languageService.translate()
                    .chapterStatusDoneExplanation,
                color: this.colorCodes.done
            };
        }
        else if (this.isChapterInReview(chapterUUID)) {
            return {
                text: this.languageService.translate().chapterStatusInReview,
                explanation: this.languageService.translate()
                    .chapterStatusInReviewExplanation,
                color: this.colorCodes.inReview
            };
        }
        else if (this.isChapterInProgress(chapterUUID)) {
            return {
                text: this.languageService.translate().chapterStatusInProgress,
                explanation: this.languageService.translate()
                    .chapterStatusInProgressExplanation,
                color: this.colorCodes.inProgress
            };
        }
        else {
            return {
                text: this.languageService.translate().chapterStatusNew,
                explanation: this.languageService.translate()
                    .chapterStatusNewExplanation,
                color: this.colorCodes.new
            };
        }
    }
    isChapterInReview(chapterUUID) {
        return (this.dataModel.selectedCompany2.getValue() &&
            this.dataModel.selectedCompany2.getValue().inReviewChapterUUIDs &&
            this.dataModel.selectedCompany2
                .getValue()
                .inReviewChapterUUIDs.includes(chapterUUID));
    }
    isChapterDone(chapterUUID) {
        return (this.dataModel.selectedCompany2.getValue() &&
            this.dataModel.selectedCompany2.getValue().doneChapterUUIDs &&
            this.dataModel.selectedCompany2
                .getValue()
                .doneChapterUUIDs.includes(chapterUUID));
    }
    isChapterInProgress(chapterUUID) {
        const chapter = this.dataModel.chapters.find(c => c._id === chapterUUID);
        return (chapter.chapterCards.some(cC => Array.from(cC.segments.values()).some(sA => sA.some(s => s.segmentEntries
            .filter(sE => sE.type === SegmentEntryType.QUESTION)
            .some(sEE => {
            if (this.dataModel.auditAnswers.find(a => a.questionUUID === sEE.entry._id)) {
                return true;
            }
        })))) ||
            this.auditService.getUploadedFileDownloads(chapterUUID).length > 0 ||
            this.dataModel.chapterHasNotices(chapterUUID));
    }
}
ChapterStatusService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChapterStatusService_Factory() { return new ChapterStatusService(i0.ɵɵinject(i1.DataModelService), i0.ɵɵinject(i2.LanguageService), i0.ɵɵinject(i3.AuditService)); }, token: ChapterStatusService, providedIn: "root" });
