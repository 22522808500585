<div class="margin" [id]="this.chapterCard.title">
  <nz-card [nzTitle]="extraTemplate">
    <div class="content-body">
      <div class="content">
        <p class="intro-text">
          <app-format-text
            [text]="
              this.textResolverService.getLanguageText(
                this.chapterCard.introductionText
              )
            "
          ></app-format-text>
        </p>
        <div [@contentExpand]="this.contentExpandStatus">
          <ng-content *ngIf="this.open"></ng-content>
        </div>
      </div>
      <div class="button">
        <button
          nz-button
          nzType="primary"
          class="default-button"
          (click)="this.toggleCardSize()"
        >
          {{ getButtonText() }}
        </button>
      </div>
    </div>
  </nz-card>
</div>
<ng-template #extraTemplate>
  <div class="card-header">
    <img class="image" [src]="this.chapterCard.imagePath" />
    <span class="title-font">{{
      this.languageService.translate()[this.chapterCard.title].toUpperCase()
    }}</span>
  </div>
</ng-template>
