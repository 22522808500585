import { ChapterStatusService } from './../../../../utils/chapter-status.service';
import { Component, Input, OnDestroy } from '@angular/core';
import { UploadFile } from 'ng-zorro-antd';
import { Subscription } from 'rxjs';
import { Download } from '../../../../models/download';
import { AuditService } from '../../../../utils/audit.service';
import { DataModelService } from '../../../../utils/datamodel.service';
import { FileService } from '../../../../utils/file.service';
import { LanguageService } from '../../../../utils/language.service';
import { Chapter } from './../../../../models/chapter';
import { TextResolverService } from './../../../../utils/text-resolver.service';

@Component({
  selector: 'app-dialog-upload',
  templateUrl: './dialog.upload.component.html',
  styleUrls: ['./dialog.upload.component.css']
})
export class DialogUploadComponent implements OnDestroy {
  @Input()
  disabled: boolean;

  _showUploads: boolean;
  downloads: Download[];

  isActive = false;

  fileList: UploadFile[] = [];
  currentBatchSelectedFile: UploadFile[];

  fileExistsInQueue = false;
  fileExistsInAudit = false;
  filesAlreadyInQueue: string[] = [];
  fileNamesAlreadyInAudit: string[] = [];
  chapterNamesOfExistingFile: string[] = [];

  uploadError = false;
  uploading = false;
  progress = 0;

  options: Chapter[] = this.dataModel.chapters.concat(
    this.dataModel.documentChapters
  );
  chapter: Chapter;

  subscriptions: Subscription[] = [];

  constructor(
    private dataModel: DataModelService,
    private auditService: AuditService,
    public languageService: LanguageService,
    public textResolverService: TextResolverService,
    public fileService: FileService,
    private chapterStatusService: ChapterStatusService
  ) {}

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  @Input()
  set showUploads(param: boolean) {
    this._showUploads = param;
    if (param) {
      this.downloads = this.auditService.getUploadedFileDownloads(
        this.dataModel.selectedChapter._id
      );
    }
  }

  doesFileExist(): boolean {
    return this.fileExistsInQueue || this.fileExistsInAudit;
  }

  handleFileRemoval = (file: UploadFile): boolean => {
    this.resetErrorMessage();
    return true;
  };

  uploadFile(entityUUID: string) {
    if (this.chapter && this.fileList.length > 0) {
      this.resetErrorMessage();
      this.uploading = true;
      this.uploadError = false;
      this.subscriptions.push(
        this.fileService.uploadFile(this.fileList, false).subscribe(
          (progress: number) => {
            this.progress = this.progress !== 100 ? progress : this.progress;
            if (this.progress === 100) {
              this.uploading = false;
              this.fileService.updateDB(entityUUID, this.fileList, false);
              this.fileList = [];
            }
          },
          err => {
            console.log(err);
            this.uploading = false;
            this.uploadError = true;
          }
        )
      );
    } else {
    }
  }

  isDisabled(): boolean {
    if (this.chapter && this.fileList.length > 0 && !this.uploading) {
      return false;
    } else {
    }
    return true;
  }

  renewDownloads(): void {
    this.downloads = this.auditService.getUploadedFileDownloads(
      this.dataModel.selectedChapter._id
    );
  }

  beforeUpload = (_: UploadFile, fileList: UploadFile[]): boolean => {
    if (this.currentBatchSelectedFile !== fileList) {
      this.currentBatchSelectedFile = fileList;
      this.resetErrorMessage();
      this.currentBatchSelectedFile.forEach(file => {
        let canBeAdded = true;
        if (this.fileList.find(f => f.name === file.name)) {
          canBeAdded = false;
          this.fileExistsInQueue = true;
          if (!this.filesAlreadyInQueue.find(f => f === file.name)) {
            this.filesAlreadyInQueue.push(file.name);
          }
        }
        if (
          this.dataModel.auditFiles.find(
            aF =>
              aF.providedByCompanyUUID &&
              aF.providedForCompanyUUID &&
              aF.fileName === file.name
          )
        ) {
          canBeAdded = false;
          this.fileExistsInAudit = true;
          if (
            this.isChapterAlreadyListed(
              this.findChapterOfExistingFile(file.name)
            )
          ) {
            this.chapterNamesOfExistingFile.push(
              this.findChapterOfExistingFile(file.name)
            );
          }
          if (this.isFilenameAlreadyListed(file.name)) {
            this.fileNamesAlreadyInAudit.push(file.name);
          }
        }
        if (canBeAdded) {
          this.fileList = [...this.fileList, file];
        }
      });
    }
    return false;
  };

  resetErrorMessage() {
    this.uploadError = false;
    this.fileExistsInAudit = false;
    this.fileExistsInQueue = false;
    this.chapterNamesOfExistingFile = [];
    this.fileNamesAlreadyInAudit = [];
    this.filesAlreadyInQueue = [];
    this.progress = 0;
  }

  findChapterOfExistingFile(filename: string): string {
    return this.textResolverService.getLanguageText(
      this.dataModel.chapters.find(
        c =>
          c._id ===
          this.dataModel.auditFiles.find(
            aF =>
              aF.providedByCompanyUUID &&
              aF.providedForCompanyUUID &&
              aF.fileName === filename
          ).entityUUID
      ).title
    );
  }
  isChapterAlreadyListed(chapterName: string): boolean {
    if (this.chapterNamesOfExistingFile.find(c => c === chapterName)) {
      return false;
    } else {
      return true;
    }
  }
  isFilenameAlreadyListed(fileName: string): boolean {
    if (this.fileNamesAlreadyInAudit.find(f => f === fileName)) {
      return false;
    } else {
      return true;
    }
  }

  getActive(): string {
    return this.uploadError ? 'exception' : this.uploading ? 'active' : null;
  }

  getAmountFilesSelectedText(): string {
    return (
      this.fileList.length +
      ' ' +
      this.languageService.translate()[
        'filesSelected' + (this.fileList.length === 1 ? 'Singular' : 'Plural')
      ]
    );
  }
  chapterDoneOrInReview(chapterUUID): boolean {
    return (
      this.chapterStatusService.isChapterDone(chapterUUID) ||
      this.chapterStatusService.isChapterInReview(chapterUUID)
    );
  }
}
