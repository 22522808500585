import { LanguageService } from './../../../utils/language.service';
import { DBService } from './../../../utils/dbverbindung.service';
import { Component, OnInit } from '@angular/core';
import { RoutingService } from '../../../utils/routing.service';

@Component({
  selector: 'app-waitforcontact',
  templateUrl: './waitforcontact.component.html',
  styleUrls: ['./waitforcontact.component.css']
})
export class WaitForContactComponent {
  constructor(public lang: LanguageService, private dbservice: DBService, public router: RoutingService) {}
}
