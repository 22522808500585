import { AfterViewInit, Component } from "@angular/core";
import { DataModelService } from "../../../utils/datamodel.service";

@Component({
  selector: "app-glossar",
  templateUrl: "./glossar.component.html",
  styleUrls: ["./glossar.component.css"]
})
export class GlossarComponent implements AfterViewInit {
  constructor(public datamodel: DataModelService) {}

  ngAfterViewInit(): void {
    this.datamodel.scrollElement.subscribe(element => {
      if (element) {
        document.getElementById(element).scrollIntoView();
      }
    });
  }
}
