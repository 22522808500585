/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loggedinfooter.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ng-zorro-antd/core";
import * as i4 from "ng-zorro-antd/grid";
import * as i5 from "@angular/cdk/layout";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "./loggedinfooter.component";
import * as i8 from "../../utils/screenSize.service";
import * as i9 from "../../utils/language.service";
import * as i10 from "../../utils/datamodel.service";
import * as i11 from "../../utils/routing.service";
var styles_LoggedinFooterComponent = [i0.styles];
var RenderType_LoggedinFooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoggedinFooterComponent, data: {} });
export { RenderType_LoggedinFooterComponent as RenderType_LoggedinFooterComponent };
function View_LoggedinFooterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 28, "div", [["class", "height"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { bannerfixed: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 24, "div", [["class", "bannernormal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", "image"], ["class", "logo"], ["src", "assets/epclogo.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 22, "div", [["class", "row"], ["nz-row", ""]], null, null, null, null, null)), i1.ɵprd(512, null, i3.NzUpdateHostClassService, i3.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(8, 4931584, null, 0, i4.NzRowDirective, [i1.ElementRef, i1.Renderer2, i3.NzUpdateHostClassService, i5.MediaMatcher, i1.NgZone, i6.Platform, i3.NzDomEventService], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["nz-col", ""], ["nzSpan", "6"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.NzUpdateHostClassService, i3.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(11, 4931584, null, 0, i4.NzColDirective, [i3.NzUpdateHostClassService, i1.ElementRef, [2, i4.NzRowDirective], i1.Renderer2], { nzSpan: [0, "nzSpan"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.terms() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [["nz-col", ""], ["nzSpan", "6"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.NzUpdateHostClassService, i3.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(16, 4931584, null, 0, i4.NzColDirective, [i3.NzUpdateHostClassService, i1.ElementRef, [2, i4.NzRowDirective], i1.Renderer2], { nzSpan: [0, "nzSpan"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.impressum() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(18, null, ["", ""])), (_l()(), i1.ɵeld(19, 0, null, null, 4, "div", [["nz-col", ""], ["nzSpan", "6"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.NzUpdateHostClassService, i3.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(21, 4931584, null, 0, i4.NzColDirective, [i3.NzUpdateHostClassService, i1.ElementRef, [2, i4.NzRowDirective], i1.Renderer2], { nzSpan: [0, "nzSpan"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.privacy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(23, null, ["", ""])), (_l()(), i1.ɵeld(24, 0, null, null, 4, "div", [["nz-col", ""], ["nzSpan", "6"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.NzUpdateHostClassService, i3.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(26, 4931584, null, 0, i4.NzColDirective, [i3.NzUpdateHostClassService, i1.ElementRef, [2, i4.NzRowDirective], i1.Renderer2], { nzSpan: [0, "nzSpan"] }, null), (_l()(), i1.ɵeld(27, 0, null, null, 1, "a", [["href", "https://www.eprivacy.eu/newsletter/"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Newsletter"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "height"; var currVal_1 = _ck(_v, 3, 0, _co.fixed); _ck(_v, 2, 0, currVal_0, currVal_1); _ck(_v, 8, 0); var currVal_2 = "6"; _ck(_v, 11, 0, currVal_2); var currVal_4 = "6"; _ck(_v, 16, 0, currVal_4); var currVal_6 = "6"; _ck(_v, 21, 0, currVal_6); var currVal_8 = "6"; _ck(_v, 26, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.lang.translate().agb; _ck(_v, 13, 0, currVal_3); var currVal_5 = _co.lang.translate().imprint_header; _ck(_v, 18, 0, currVal_5); var currVal_7 = _co.lang.translate().privacypolice; _ck(_v, 23, 0, currVal_7); }); }
export function View_LoggedinFooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoggedinFooterComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LoggedinFooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loggedinfooter", [], null, null, null, View_LoggedinFooterComponent_0, RenderType_LoggedinFooterComponent)), i1.ɵdid(1, 49152, null, 0, i7.LoggedinFooterComponent, [i8.ScreenSizeService, i9.LanguageService, i10.DataModelService, i11.RoutingService], null, null)], null, null); }
var LoggedinFooterComponentNgFactory = i1.ɵccf("app-loggedinfooter", i7.LoggedinFooterComponent, View_LoggedinFooterComponent_Host_0, {}, {}, []);
export { LoggedinFooterComponentNgFactory as LoggedinFooterComponentNgFactory };
