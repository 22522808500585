import { Notice } from '../../../../models/notice';
import { LanguageService } from '../../../../utils/language.service';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
  group
} from '@angular/animations';
import { NzModalService } from 'ng-zorro-antd';
import { formatDate } from '@angular/common';
import { DataModelService } from '../../../../utils/datamodel.service';
import { User } from '../../../../models/user';
import * as moment from 'moment';
import { RoleService } from '../../../../utils/role.service';

@Component({
  selector: 'app-notice-entry',
  templateUrl: './notice-entry.component.html',
  styleUrls: ['./notice-entry.component.css'],
  animations: [
    trigger('deleteNotice', [
      state(
        'display',
        style({
          opacity: 1,
          height: '*'
        })
      ),
      state(
        'delete',
        style({
          margin: 0,
          opacity: 0,
          height: 0
        })
      ),
      transition('void => display', [
        style({ opacity: 0, height: 0, margin: 0 }),
        group([
          animate('400ms ease-in-out', style({ height: '*', margin: '*' })),
          animate('400ms cubic-bezier(.75,.33,.9,.63)', style({ opacity: 1 }))
        ])
      ]),
      transition('display => delete', animate('400ms ease-in-out'))
    ])
  ]
})
export class NoticeEntryComponent implements OnInit {
  @Input()
  notice: Notice;
  @Output() delete = new EventEmitter<void>();

  iconHovering = false;
  deleteNoticeStatus = 'display';

  user: User;

  constructor(
    public modalService: NzModalService,
    public languageService: LanguageService,
    private dataModel: DataModelService,
    private roleService: RoleService
  ) {}

  ngOnInit() {
    if (this.roleService.isConsultOrHigher()) {
      this.user = this.dataModel.availableUsers
        .concat(this.dataModel.epcUsers)
        .find(u => u._id === this.notice.userUUID);
    } else {
      this.user = this.dataModel.epcUsers
        .concat(this.dataModel.user)
        .concat(this.dataModel.otherUsersOfCompany)
        .find(u => u._id === this.notice.userUUID);
    }
  }

  iconShouldShow(): boolean {
    return this.iconHovering && this.hasPermissionToDelete();
  }

  hasPermissionToDelete(): boolean {
    return (
      this.roleService.isConsultOrHigher() ||
      this.notice.userUUID === this.dataModel.user._id
    );
  }

  isConsultNotice(): boolean {
    return (
      this.dataModel.epcUsers.find(u => u._id === this.notice.userUUID) !==
      undefined
    );
  }

  getUserName(): string {
    return (
      this.user.firstName +
      ' ' +
      this.user.lastName +
      (this.dataModel.epcUsers.find(u => u._id === this.notice.userUUID)
        ? ' (ePrivacy)'
        : '')
    );
  }

  getDate(): string {
    return moment(this.notice.timestamp).format('DD.MM.YYYY');
  }

  getTime(): string {
    return moment(this.notice.timestamp).format('HH:mm');
  }

  getNoticeEntry(): string {
    return this.notice.text;
  }

  triggerDelete() {
    if (this.hasPermissionToDelete()) {
      this.modalService.confirm({
        nzTitle: this.languageService.translate().deleteNoticeTitle,
        nzContent: this.languageService.translate().deleteNoticeMessage,
        nzOkText: this.languageService.translate().ok,
        nzCancelText: this.languageService.translate().cancel,
        nzOnOk: () => {
          this.deleteNoticeStatus = 'delete';
          setTimeout(() => {
            this.delete.emit();
          }, 400);
        }
      });
    }
  }
}
