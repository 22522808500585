/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./landinglogin.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/login-mask/login-mask.component.ngfactory";
import * as i3 from "../../shared/login-mask/login-mask.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../utils/datamodel.service";
import * as i6 from "../../../utils/dbverbindung.service";
import * as i7 from "../../../guards/auth/auth.service";
import * as i8 from "@angular/router";
import * as i9 from "../../../utils/socket.service";
import * as i10 from "../../../utils/cookies.service";
import * as i11 from "../../../utils/language.service";
import * as i12 from "../../../utils/role.service";
import * as i13 from "ng-zorro-antd/modal";
import * as i14 from "../../../../../node_modules/ng-zorro-antd/button/ng-zorro-antd-button.ngfactory";
import * as i15 from "ng-zorro-antd/core";
import * as i16 from "ng-zorro-antd/button";
import * as i17 from "@angular/cdk/observers";
import * as i18 from "@angular/platform-browser/animations";
import * as i19 from "ng-zorro-antd/popover";
import * as i20 from "./landinglogin.component";
var styles_LandingLoginComponent = [i0.styles];
var RenderType_LandingLoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LandingLoginComponent, data: {} });
export { RenderType_LandingLoginComponent as RenderType_LandingLoginComponent };
function View_LandingLoginComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "login"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-login-mask", [], null, null, null, i2.View_LoginMaskComponent_0, i2.RenderType_LoginMaskComponent)), i1.ɵdid(2, 245760, null, 0, i3.LoginMaskComponent, [i4.FormBuilder, i5.DataModelService, i6.DBService, i7.AuthService, i8.Router, i9.SocketService, i10.Cookies, i11.LanguageService, i12.RoleService, i13.NzModalService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_LandingLoginComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, [["contentTemplate", 2]], null, 0, null, View_LandingLoginComponent_1)), (_l()(), i1.ɵeld(1, 16777216, null, null, 5, "button", [["class", "button noshadow"], ["nz-button", ""], ["nz-popover", ""], ["nzPopoverPlacement", "bottom"], ["nzPopoverTrigger", "click"], ["nzType", "primary"]], [[1, "nz-wave", 0], [2, "ant-popover-open", null]], null, null, i14.View_NzButtonComponent_0, i14.RenderType_NzButtonComponent)), i1.ɵprd(512, null, i15.NzUpdateHostClassService, i15.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(3, 1818624, null, 1, i16.NzButtonComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2, i17.ContentObserver, i15.NzUpdateHostClassService, i1.NgZone, i15.NzConfigService, [2, i15.NZ_WAVE_GLOBAL_CONFIG], [2, i18.ANIMATION_MODULE_TYPE]], { nzType: [0, "nzType"] }, null), i1.ɵqud(603979776, 1, { listOfIconElement: 1 }), i1.ɵdid(5, 4931584, null, 0, i19.NzPopoverDirective, [i1.ElementRef, i1.ViewContainerRef, i1.ComponentFactoryResolver, i1.Renderer2, [2, i19.NzPopoverComponent], [8, null]], { specificContent: [0, "specificContent"], directiveNameTitle: [1, "directiveNameTitle"], specificTrigger: [2, "specificTrigger"], specificPlacement: [3, "specificPlacement"] }, null), (_l()(), i1.ɵted(6, 0, [" ", "\n"]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 3, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 0); var currVal_4 = ""; var currVal_5 = "click"; var currVal_6 = "bottom"; _ck(_v, 5, 0, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).nzWave; var currVal_1 = i1.ɵnov(_v, 5).isTooltipComponentVisible; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_7 = _co.languageService.translate().login; _ck(_v, 6, 0, currVal_7); }); }
export function View_LandingLoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-landinglogin", [], null, null, null, View_LandingLoginComponent_0, RenderType_LandingLoginComponent)), i1.ɵdid(1, 49152, null, 0, i20.LandingLoginComponent, [i11.LanguageService], null, null)], null, null); }
var LandingLoginComponentNgFactory = i1.ɵccf("app-landinglogin", i20.LandingLoginComponent, View_LandingLoginComponent_Host_0, {}, {}, []);
export { LandingLoginComponentNgFactory as LandingLoginComponentNgFactory };
