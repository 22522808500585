<div class="top">
  <app-landinglogo></app-landinglogo>
  <app-landingtoprightrow [login]="true"></app-landingtoprightrow>
</div>
<div>
  <div class="login-container" *ngIf="linkstatus === 0; else active">
    <i
      style="font-size: 80px;position:relative;text-align: center;"
      nz-icon
      nzType="loading"
    ></i>
    <p>{{ this.languageService.translate().waitForVerification }}</p>
  </div>
</div>

<ng-template #active>
  <app-setpassword
    *ngIf="linkstatus === 1; else fail"
    [username]="username"
    (setPassword)="setPassword($event)"
  >
  </app-setpassword>
</ng-template>
<ng-template #fail>
  <nz-result
    *ngIf="linkstatus === 2; else success"
    nzStatus="error"
    nzTitle="Failed"
    [nzSubTitle]="this.languageService.translate().urlNotValid"
  >
    <div nz-result-extra>
      <button nz-button nzType="primary" (click)="navigateToLanding()">
        {{ this.languageService.translate().landingPage }}
      </button>
    </div>
  </nz-result>
</ng-template>
<ng-template #success>
  <nz-result
    nzStatus="success"
    nzTitle="Erfolg"
    [nzSubTitle]="this.languageService.translate().pwChangedSuccesfully"
  >
    <div nz-result-extra>
      <button nz-button nzType="primary" (click)="navigateToLanding()">
        {{ this.languageService.translate().landingPage }}
      </button>
    </div>
  </nz-result>
</ng-template>
