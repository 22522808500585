<div class="info" [ngClass]="{ result: this.segmentEntry.result }">
  <div class="avatar" *ngIf="this.hasIcon()">
    <img [src]="this.segmentEntry.entry.iconPath" />
  </div>
  <div class="content">
    <div
      class="part"
      *ngFor="let text of this.segmentEntry.entry.textParts; index as i"
    >
      <span [ngClass]="{ point: !this.isNumbered() }" class="base-point">
        {{ this.getPoint(i) }}
      </span>
      <span class="text">
        <app-popover-marked
          [text]="this.textResolverService.getLanguageText(text)"
          [helpMap]="this.entryHelpMaps[i]"
        >
        </app-popover-marked>
      </span>
    </div>
  </div>
</div>
