import { Component } from '@angular/core';
import { RoutingService } from '../../../utils/routing.service';
@Component({
  selector: 'app-landinglogo',
  templateUrl: './landinglogo.component.html',
  styleUrls: ['./landinglogo.component.css']
})
export class LandingLogoComponent {
  constructor(private routingService: RoutingService) {}

  toLanding(): void {
    this.routingService.navigateToLanding();
  }
}
