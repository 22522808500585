<div class="container">
  <div class="row">
    <div class="col-xs-12 col-sm-4 col-lg-2 col-lg-offset-2">
      <div class="footercontent">
        <h4>{{ lang.translate().informations }}</h4>
        <ul>
          <li>
            <a (click)="impressum()">{{ lang.translate().imprint_header }}</a>
          </li>
          <li>
            <a (click)="privacy()">{{ lang.translate().privacypolice }}</a>
          </li>
          <li>
            <a (click)="terms()">{{ lang.translate().agb }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-xs-12 col-sm-4 col-lg-2 col-lg-offset-1">
      <div class="footercontent">
        <h4>{{ lang.translate().informations }}</h4>
        <ul>
          <li><a href="https://www.eprivacy.eu/sitemap/">Sitemap</a></li>
          <li><a href="https://www.eprivacy.eu/glossar/">Glossar</a></li>
          <li><a href="https://www.eprivacy.eu/newsletter/">Newsletter</a></li>
        </ul>
      </div>
    </div>
    <div class="col-xs-12 col-sm-4 col-lg-2 col-lg-offset-1">
      <div class="footercontent">
        <h4>{{ lang.translate().contact }}</h4>
        <ul>
          <li><a href="mailto:info@eprivacy.eu">info@eprivacy.eu</a></li>
          <li>
            <a href="https://www.eprivacy.eu/kontaktformular/">{{ lang.translate().contactform }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="clear"></div>
  </div>
</div>
