import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgZorroAntdModule, NzCollapseModule } from 'ng-zorro-antd';
import { EditorModule } from 'primeng/editor';
import { InfosComponent } from '../../components/shared/infos/infos.component';
import { ListGroupedComponent } from '../../components/shared/infos/list-grouped/list-grouped.component';
import { ListComponent } from '../../components/shared/infos/list/list.component';
import { PlaintextComponent } from '../../components/shared/infos/plaintext/plaintext.component';
import { FormattingPipe } from '../../utils/formatting.pipe';
import { ChapterCardComponent } from './chapter-card/chapter-card.component';
import { CustomEmptyComponent } from './custom-empty/custom-empty.component';
import { InputDialogComponent } from './dialogs/input-dialog/input-dialog.component';
import { DialogUploadComponent } from './dialogs/upload/dialog.upload.component';
import { DownloadTableComponent } from './files/download/download-table/download-table.component';
import { DownloadComponent } from './files/download/download.component';
import { DownloadEntryComponent } from './files/download/downloadEntry/downloadEntry.component';
import { FilesComponent } from './files/files.component';
import { UploadComponent } from './files/upload/upload.component';
import { FormatTextComponent } from './format-text/format-text.component';
import { LoginMaskComponent } from './login-mask/login-mask.component';
import { PlaceholderCardComponent } from './placeholder-card/placeholder-card.component';
import { PopoverMarkedComponent } from './popoverMarked/popoverMarked.component';
import { PopoverMarkedManualComponent } from './popoverMarked/popoverMarkedManual/popoverMarkedManual.component';
import { CheckboxComponent } from './questions/checkbox/checkbox.component';
import { MultipleChoiceComponent } from './questions/multipleChoice/multipleChoice.component';
import { QuestionsComponent } from './questions/questions.component';
import { RadioComponent } from './questions/radio/radio.component';
import { TextFieldComponent } from './questions/textField/textField.component';
import { SharedAnchorComponent } from './shared-anchor/shared-anchor.component';
import { NavigationComponent } from './slider/navigation/navigation.component';
import { SliderComponent } from './slider/slider.component';
import { GroupComponent } from './stammdaten/group/group.component';
import { StammdatenComponent } from './stammdaten/stammdaten.component';
import { TextInputComponent } from './text-input/text-input.component';
import { NoticesComponent } from './notices/notices.component';
import { NoticeEntryComponent } from './notices/notice-entry/notice-entry.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RenameComponent } from './rename/rename.component';
import { AddElementComponent } from './add-element/add-element.component';
import { ExplanationHoverComponent } from './explanation-hover/explanation-hover.component';
import { SubmissionComponent } from './submission/submission.component';
import { UploadErrorComponent } from './upload-error/upload-error.component';
import { SelectComponent } from './questions/select/select.component';

const components = [
  SharedAnchorComponent,
  FormattingPipe,
  FormatTextComponent,
  PopoverMarkedComponent,
  PopoverMarkedManualComponent,
  SliderComponent,
  NavigationComponent,
  UploadComponent,
  DownloadComponent,
  DownloadEntryComponent,
  ChapterCardComponent,
  StammdatenComponent,
  GroupComponent,
  TextInputComponent,
  RadioComponent,
  InfosComponent,
  ListGroupedComponent,
  ListComponent,
  PlaintextComponent,
  InputDialogComponent,
  DialogUploadComponent,
  QuestionsComponent,
  CheckboxComponent,
  MultipleChoiceComponent,
  TextFieldComponent,
  PlaceholderCardComponent,
  DownloadTableComponent,
  LoginMaskComponent,
  FilesComponent,
  CustomEmptyComponent,
  NoticesComponent,
  NoticeEntryComponent,
  RenameComponent,
  AddElementComponent,
  ExplanationHoverComponent,
  SubmissionComponent,
  UploadErrorComponent,
  SelectComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    NzCollapseModule,
    EditorModule,
    DragDropModule
  ],
  declarations: components,
  entryComponents: [InputDialogComponent, DialogUploadComponent],
  exports: components
})
export class SharedModule {}
