import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SegmentEntry } from '../../../../models/segmentEntry';
import { LanguageService } from '../../../../utils/language.service';
import { QuestionService } from '../../../../utils/question.service';
import { TextResolverService } from '../../../../utils/text-resolver.service';
import { Question } from '../../../../models/question';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.css']
})
export class RadioComponent {
  @Input()
  segmentEntry: SegmentEntry;
  @Input()
  answer: string;
  @Input()
  disabled = false;

  @Output()
  answered = new EventEmitter<string>();

  constructor(
    public languageService: LanguageService,
    public textResolverService: TextResolverService
  ) {}

  setAnswer(answer: string) {
    this.answer = answer;
    this.answered.emit(this.answer);
  }
}
