import { Component } from "@angular/core";
import { DataModelService } from "../../../utils/datamodel.service";
import { LanguageService } from "../../../utils/language.service";

@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.css"]
})
export class FaqComponent {
  constructor(
    public lang: LanguageService,
    private datamodel: DataModelService
  ) {}
}
