import { Component, Input } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd';
import { Chapter } from '../../../models/chapter';
import { DataModelService } from './../../../utils/datamodel.service';
import { LanguageService } from './../../../utils/language.service';
import { RoutingService } from './../../../utils/routing.service';
import { TextResolverService } from './../../../utils/text-resolver.service';
import { DBService } from '../../../utils/dbverbindung.service';
import { Cookies } from '../../../utils/cookies.service';

@Component({
  selector: 'app-navmenu',
  templateUrl: './navmenu.component.html',
  styleUrls: ['./navmenu.component.css']
})
export class NavmenuComponent {
  constructor(
    public router: RoutingService,
    public lang: LanguageService,
    public dbService: DBService,
    public datamodel: DataModelService,
    public modalService: NzModalService,
    public textResolverService: TextResolverService,
    public cookieService: Cookies
  ) {}

  isSelectedChapter(chapter: Chapter): boolean {
    return (
      this.datamodel.selectedChapter &&
      this.datamodel.selectedChapter === chapter
    );
  }

  openExternalBlog(url) {
    console.log(this.lang.translate().blogLang);

    window.open(
      'http://blog.eprivacy.eu/?lang=' + this.lang.translate().blogLang,
      '_blank'
    );
  }
}
