<ng-container *ngIf="this.textContainsHelp(); else noHelp">
  <ng-container *ngFor="let textPart of this.splittedText; index as i">
    <app-format-text [text]="textPart"></app-format-text>

    <ng-container *ngIf="!this.isLastPart(i)">
      <app-popover-marked-manual
        [iconTitle]="'info-circle'"
        [iconPopover]="'info-circle'"
        [title]="this.textResolverService.getLanguageText(this.getHelpKey(i))"
        [text]="this.getPopoverText(i)"
      ></app-popover-marked-manual>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #noHelp>
  <app-format-text [text]="text"></app-format-text>
</ng-template>
