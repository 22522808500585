<span>
  <i nz-icon [nzType]="this.iconTitle" class="outterIcon"> </i>
  <a nz-tooltip nzTrigger="click" [nzTitle]="titleTemplate" class="markedText">
    <app-format-text [text]="this.title"></app-format-text>
  </a>
</span>
<ng-template #titleTemplate>
  <i nz-icon [nzType]="this.iconPopover" class="innerIcon"> </i>
  <app-format-text class="help-text" [text]="this.text"></app-format-text>
</ng-template>
