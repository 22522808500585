import { DataModelService } from "./../../../utils/datamodel.service";
import { LanguageService } from "./../../../utils/language.service";
import { Component } from "@angular/core";
import {
  trigger,
  state,
  style,
  animate,
  transition
} from "@angular/animations";

@Component({
  selector: "app-burgermenu",
  templateUrl: "./burgermenu.component.html",
  styleUrls: ["./burgermenu.component.css"],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger("rotatedState", [
      state("default", style({ transform: "rotate(-180deg) scale(1.4)" })),
      state("rotated", style({ transform: "rotate(0) scale(1.4)" })),
      transition("rotated => default", animate("500ms ease-out")),
      transition("default => rotated", animate("500ms ease-out"))
    ])
  ]
})
export class BurgermenuComponent {
  state = "default";

  constructor(
    public lang: LanguageService,
    public datamodel: DataModelService
  ) {}

  triggerSider(): void {
    this.datamodel.openSider = !this.datamodel.openSider;
    this.state = this.state === "default" ? "rotated" : "default";
  }
}
