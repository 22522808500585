<div
  class="part"
  *ngFor="let questionPart of this.segmentEntry.entry.textParts"
>
  <app-popover-marked
    [text]="this.textResolverService.getLanguageText(questionPart)"
    [helpMap]="this.segmentEntry.helpMap"
  ></app-popover-marked>
</div>
<div class="textFieldContainer">
  <textarea
    [disabled]="this.disabled"
    rows="4"
    [(ngModel)]="this._answer"
    nz-input
  ></textarea>
  <button
    nz-button
    nzType="primary"
    class="default-button save-button"
    (click)="this.answered.emit(this._answer)"
    [disabled]="this.isDisabled()"
    *ngIf="this.saveButton"
  >
    {{ this.languageService.translate().save }}
  </button>
</div>
