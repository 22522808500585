import { Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Download } from '../../../models/download';
import { FileType } from '../../../models/fileType';
import { SegmentFile } from '../../../models/segmentFile';
import { TemplateFile } from '../../../models/templateFile';
import { AuditService } from '../../../utils/audit.service';
import { LanguageService } from './../../../utils/language.service';
import { AuditFile } from '../../../models/auditFile';
import { DataModelService } from '../../../utils/datamodel.service';
import { ChapterStatusService } from '../../../utils/chapter-status.service';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.css']
})
export class FilesComponent implements OnDestroy {
  @Input()
  uploadDisabled = false;
  @Input()
  deleteDisabled = false;
  @Input()
  deleteHidden = false;
  @Input()
  showUploads = true;

  _file: SegmentFile | TemplateFile;

  downloads: Download[] = [];

  subscription: Subscription;

  constructor(
    public auditService: AuditService,
    public dataModel: DataModelService,
    private languageService: LanguageService,
    private chapterStatusService: ChapterStatusService
  ) {}

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @Input()
  set file(file: SegmentFile | TemplateFile) {
    this._file = file;
    this.subscription = this.languageService.language.subscribe(_ =>
      this.renewDownloads()
    );
  }

  renewDownloads(): void {
    this.downloads = this.auditService.getTemplateFileDownloads(this._file);
  }

  fileIsTemplate(): boolean {
    return (
      ((this._file as SegmentFile).type !== undefined &&
        (this._file as SegmentFile).type === FileType.TEMPLATE) ||
      (this._file as TemplateFile).entityUUID !== undefined
    );
  }

  fileIsUpload(): boolean {
    return (
      (this._file as SegmentFile).type !== undefined &&
      (this._file as SegmentFile).type === FileType.UPLOAD
    );
  }
}
