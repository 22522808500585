import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-format-text',
  templateUrl: './format-text.component.html',
  styleUrls: ['./format-text.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FormatTextComponent {
  @Input()
  text = '';
  @Input()
  missing = false;
}
