/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./idlink.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng-zorro-antd/icon";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "../../components/setpassword/setpassword.component.ngfactory";
import * as i5 from "../../components/setpassword/setpassword.component";
import * as i6 from "@angular/forms";
import * as i7 from "../../utils/language.service";
import * as i8 from "@angular/common";
import * as i9 from "../../../../node_modules/ng-zorro-antd/result/ng-zorro-antd-result.ngfactory";
import * as i10 from "ng-zorro-antd/core";
import * as i11 from "ng-zorro-antd/result";
import * as i12 from "../../../../node_modules/ng-zorro-antd/button/ng-zorro-antd-button.ngfactory";
import * as i13 from "ng-zorro-antd/button";
import * as i14 from "@angular/cdk/observers";
import * as i15 from "@angular/platform-browser/animations";
import * as i16 from "../../components/landingpage/landinglogo/landinglogo.component.ngfactory";
import * as i17 from "../../components/landingpage/landinglogo/landinglogo.component";
import * as i18 from "../../utils/routing.service";
import * as i19 from "../../components/landingpage/landingtoprightrow/landingtoprightrow.component.ngfactory";
import * as i20 from "../../components/landingpage/landingtoprightrow/landingtoprightrow.component";
import * as i21 from "../../utils/datamodel.service";
import * as i22 from "./idlink.component";
import * as i23 from "@angular/router";
import * as i24 from "../../guards/auth/auth.service";
import * as i25 from "../../utils/dbverbindung.service";
var styles_IdLinkComponent = [i0.styles];
var RenderType_IdLinkComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IdLinkComponent, data: {} });
export { RenderType_IdLinkComponent as RenderType_IdLinkComponent };
function View_IdLinkComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "login-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["nz-icon", ""], ["nzType", "loading"], ["style", "font-size: 80px;position:relative;text-align: center;"]], null, null, null, null, null)), i1.ɵdid(2, 2834432, null, 0, i2.NzIconDirective, [i2.NzIconService, i1.ElementRef, i1.Renderer2, i3.Platform], { nzType: [0, "nzType"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var currVal_0 = "loading"; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.languageService.translate().waitForVerification; _ck(_v, 4, 0, currVal_1); }); }
function View_IdLinkComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-setpassword", [], null, [[null, "setPassword"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("setPassword" === en)) {
        var pd_0 = (_co.setPassword($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_SetPasswordComponent_0, i4.RenderType_SetPasswordComponent)), i1.ɵdid(1, 114688, null, 0, i5.SetPasswordComponent, [i6.FormBuilder, i7.LanguageService], { username: [0, "username"] }, { setPassword: "setPassword" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.username; _ck(_v, 1, 0, currVal_0); }, null); }
function View_IdLinkComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_IdLinkComponent_3)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.linkstatus === 1); var currVal_1 = i1.ɵnov(_v.parent, 9); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_IdLinkComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "nz-result", [["nzStatus", "error"], ["nzTitle", "Failed"]], null, null, null, i9.View_NzResultComponent_0, i9.RenderType_NzResultComponent)), i1.ɵprd(512, null, i10.NzUpdateHostClassService, i10.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(2, 573440, null, 0, i11.NzResultComponent, [i10.NzUpdateHostClassService, i1.ElementRef], { nzTitle: [0, "nzTitle"], nzStatus: [1, "nzStatus"], nzSubTitle: [2, "nzSubTitle"] }, null), (_l()(), i1.ɵeld(3, 0, null, 4, 6, "div", [["class", "ant-result-extra"], ["nz-result-extra", ""]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i11.NzResultExtraDirective, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "button", [["nz-button", ""], ["nzType", "primary"]], [[1, "nz-wave", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToLanding() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_NzButtonComponent_0, i12.RenderType_NzButtonComponent)), i1.ɵprd(512, null, i10.NzUpdateHostClassService, i10.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(7, 1818624, null, 1, i13.NzButtonComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2, i14.ContentObserver, i10.NzUpdateHostClassService, i1.NgZone, i10.NzConfigService, [2, i10.NZ_WAVE_GLOBAL_CONFIG], [2, i15.ANIMATION_MODULE_TYPE]], { nzType: [0, "nzType"] }, null), i1.ɵqud(603979776, 1, { listOfIconElement: 1 }), (_l()(), i1.ɵted(9, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Failed"; var currVal_1 = "error"; var currVal_2 = _co.languageService.translate().urlNotValid; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = "primary"; _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵnov(_v, 7).nzWave; _ck(_v, 5, 0, currVal_3); var currVal_5 = _co.languageService.translate().landingPage; _ck(_v, 9, 0, currVal_5); }); }
function View_IdLinkComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_IdLinkComponent_5)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.linkstatus === 2); var currVal_1 = i1.ɵnov(_v.parent, 10); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_IdLinkComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "nz-result", [["nzStatus", "success"], ["nzTitle", "Erfolg"]], null, null, null, i9.View_NzResultComponent_0, i9.RenderType_NzResultComponent)), i1.ɵprd(512, null, i10.NzUpdateHostClassService, i10.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(2, 573440, null, 0, i11.NzResultComponent, [i10.NzUpdateHostClassService, i1.ElementRef], { nzTitle: [0, "nzTitle"], nzStatus: [1, "nzStatus"], nzSubTitle: [2, "nzSubTitle"] }, null), (_l()(), i1.ɵeld(3, 0, null, 4, 6, "div", [["class", "ant-result-extra"], ["nz-result-extra", ""]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i11.NzResultExtraDirective, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "button", [["nz-button", ""], ["nzType", "primary"]], [[1, "nz-wave", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToLanding() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_NzButtonComponent_0, i12.RenderType_NzButtonComponent)), i1.ɵprd(512, null, i10.NzUpdateHostClassService, i10.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(7, 1818624, null, 1, i13.NzButtonComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2, i14.ContentObserver, i10.NzUpdateHostClassService, i1.NgZone, i10.NzConfigService, [2, i10.NZ_WAVE_GLOBAL_CONFIG], [2, i15.ANIMATION_MODULE_TYPE]], { nzType: [0, "nzType"] }, null), i1.ɵqud(603979776, 2, { listOfIconElement: 1 }), (_l()(), i1.ɵted(9, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Erfolg"; var currVal_1 = "success"; var currVal_2 = _co.languageService.translate().pwChangedSuccesfully; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = "primary"; _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵnov(_v, 7).nzWave; _ck(_v, 5, 0, currVal_3); var currVal_5 = _co.languageService.translate().landingPage; _ck(_v, 9, 0, currVal_5); }); }
export function View_IdLinkComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-landinglogo", [], null, null, null, i16.View_LandingLogoComponent_0, i16.RenderType_LandingLogoComponent)), i1.ɵdid(2, 49152, null, 0, i17.LandingLogoComponent, [i18.RoutingService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-landingtoprightrow", [], null, null, null, i19.View_LandingTopRightRowComponent_0, i19.RenderType_LandingTopRightRowComponent)), i1.ɵdid(4, 49152, null, 0, i20.LandingTopRightRowComponent, [i7.LanguageService, i21.DataModelService, i18.RoutingService], { login: [0, "login"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IdLinkComponent_1)), i1.ɵdid(7, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["active", 2]], null, 0, null, View_IdLinkComponent_2)), (_l()(), i1.ɵand(0, [["fail", 2]], null, 0, null, View_IdLinkComponent_4)), (_l()(), i1.ɵand(0, [["success", 2]], null, 0, null, View_IdLinkComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.linkstatus === 0); var currVal_2 = i1.ɵnov(_v, 8); _ck(_v, 7, 0, currVal_1, currVal_2); }, null); }
export function View_IdLinkComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-idlink", [], null, null, null, View_IdLinkComponent_0, RenderType_IdLinkComponent)), i1.ɵdid(1, 49152, null, 0, i22.IdLinkComponent, [i23.Router, i24.AuthService, i25.DBService, i7.LanguageService, i18.RoutingService], null, null)], null, null); }
var IdLinkComponentNgFactory = i1.ɵccf("app-idlink", i22.IdLinkComponent, View_IdLinkComponent_Host_0, {}, {}, []);
export { IdLinkComponentNgFactory as IdLinkComponentNgFactory };
