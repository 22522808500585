/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./landinglogo.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./landinglogo.component";
import * as i3 from "../../../utils/routing.service";
var styles_LandingLogoComponent = [i0.styles];
var RenderType_LandingLogoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LandingLogoComponent, data: {} });
export { RenderType_LandingLogoComponent as RenderType_LandingLogoComponent };
export function View_LandingLogoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "svg"], ["src", "../../../assets/epc-logo.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toLanding() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_LandingLogoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-landinglogo", [], null, null, null, View_LandingLogoComponent_0, RenderType_LandingLogoComponent)), i1.ɵdid(1, 49152, null, 0, i2.LandingLogoComponent, [i3.RoutingService], null, null)], null, null); }
var LandingLogoComponentNgFactory = i1.ɵccf("app-landinglogo", i2.LandingLogoComponent, View_LandingLogoComponent_Host_0, {}, {}, []);
export { LandingLogoComponentNgFactory as LandingLogoComponentNgFactory };
