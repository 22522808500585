/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./glossarentry.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng-zorro-antd/icon";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "../../../../node_modules/ng-zorro-antd/collapse/ng-zorro-antd-collapse.ngfactory";
import * as i5 from "@angular/common";
import * as i6 from "ng-zorro-antd/collapse";
import * as i7 from "ng-zorro-antd/core";
import * as i8 from "./glossarentry.component";
import * as i9 from "../../utils/language.service";
import * as i10 from "../../utils/datamodel.service";
var styles_GlossarEntryComponent = [i0.styles];
var RenderType_GlossarEntryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GlossarEntryComponent, data: {} });
export { RenderType_GlossarEntryComponent as RenderType_GlossarEntryComponent };
function View_GlossarEntryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", " "])), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["nz-icon", ""], ["nzType", "caret-right"]], null, null, null, null, null)), i1.ɵdid(2, 2834432, null, 0, i2.NzIconDirective, [i2.NzIconService, i1.ElementRef, i1.Renderer2, i3.Platform], { nzRotate: [0, "nzRotate"], nzType: [1, "nzType"] }, null)], function (_ck, _v) { var currVal_1 = (_v.parent.context.$implicit.active ? 90 : (0 - 90)); var currVal_2 = "caret-right"; _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 0, 0, currVal_0); }); }
function View_GlossarEntryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "nz-collapse-panel", [["class", "margin"]], [[8, "id", 0], [2, "ant-collapse-no-arrow", null], [2, "ant-collapse-item-active", null], [2, "ant-collapse-item-disabled", null]], null, null, i4.View_NzCollapsePanelComponent_0, i4.RenderType_NzCollapsePanelComponent)), i1.ɵprd(512, null, i5.ɵNgStyleImpl, i5.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i5.NgStyle, [i5.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵdid(3, 245760, null, 0, i6.NzCollapsePanelComponent, [i7.NzConfigService, i1.ChangeDetectorRef, i6.NzCollapseComponent, i1.ElementRef, i1.Renderer2], { nzActive: [0, "nzActive"], nzHeader: [1, "nzHeader"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵand(0, [["expandedIcon", 2]], 0, 0, null, View_GlossarEntryComponent_3))], function (_ck, _v) { var currVal_4 = _v.context.$implicit.customStyle; _ck(_v, 2, 0, currVal_4); var currVal_5 = _v.context.$implicit.active; var currVal_6 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.name, ""); var currVal_1 = !i1.ɵnov(_v, 3).nzShowArrow; var currVal_2 = i1.ɵnov(_v, 3).nzActive; var currVal_3 = i1.ɵnov(_v, 3).nzDisabled; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_7 = _v.context.$implicit.content; _ck(_v, 5, 0, currVal_7); }); }
function View_GlossarEntryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "nz-collapse", [], null, null, null, i4.View_NzCollapseComponent_0, i4.RenderType_NzCollapseComponent)), i1.ɵdid(4, 49152, null, 0, i6.NzCollapseComponent, [i7.NzConfigService], { nzBordered: [0, "nzBordered"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_GlossarEntryComponent_2)), i1.ɵdid(6, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = false; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.datamodel.glossarMap.get(_v.context.$implicit); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }); }
export function View_GlossarEntryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_GlossarEntryComponent_1)), i1.ɵdid(1, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.keys; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_GlossarEntryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-glossarentry", [], null, null, null, View_GlossarEntryComponent_0, RenderType_GlossarEntryComponent)), i1.ɵdid(1, 49152, null, 0, i8.GlossarEntryComponent, [i9.LanguageService, i10.DataModelService], null, null)], null, null); }
var GlossarEntryComponentNgFactory = i1.ɵccf("app-glossarentry", i8.GlossarEntryComponent, View_GlossarEntryComponent_Host_0, {}, {}, []);
export { GlossarEntryComponentNgFactory as GlossarEntryComponentNgFactory };
