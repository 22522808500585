import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import * as moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { v4 } from 'uuid';
import { AuditFile } from '../models/auditFile';
import { DBCollections } from '../models/dbCollections';
import { Globals } from '../models/globals';
import { AuditService } from './audit.service';
import { DataModelService } from './datamodel.service';
import { LanguageService } from './language.service';
import { RoleService } from './role.service';
import { DBService } from './dbverbindung.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./datamodel.service";
import * as i3 from "./dbverbindung.service";
import * as i4 from "./role.service";
import * as i5 from "./language.service";
import * as i6 from "./audit.service";
export class FileService {
    constructor(http, dataModel, dbService, roleService, languageService, auditService) {
        this.http = http;
        this.dataModel = dataModel;
        this.dbService = dbService;
        this.roleService = roleService;
        this.languageService = languageService;
        this.auditService = auditService;
        this.dbUrl = new Globals().dbUrl;
    }
    updateDB(entityUUID, fileList, isTemplate) {
        const uploadedFiles = [];
        fileList.forEach(file => {
            const uploadedFile = new AuditFile(v4(), entityUUID, !isTemplate
                ? this.roleService.isConsultOrHigher()
                    ? this.dataModel.selectedCompany2.getValue()._id
                    : this.dataModel.epcCompany._id
                : null, !isTemplate ? this.dataModel.company._id : null, this.dataModel.user._id, file.name, moment.now(), file.size, this.languageService.getCurrentLanguageString());
            uploadedFiles.push(uploadedFile);
            this.dataModel.insertIntoCollection([uploadedFile], DBCollections.AUDIT_FILES);
        });
        this.dataModel.auditFiles.push(...uploadedFiles);
        return uploadedFiles;
    }
    uploadFile(fileList, isTemplate) {
        const formData = new FormData();
        let index = 0;
        fileList.forEach(file => {
            formData.append('audit_files', file, file.filename);
            index++;
        });
        let uploadURL = this.dbUrl + '/upload';
        let headers = this.dbService.headers.delete('Content-Type');
        if (!isTemplate) {
            uploadURL += '/' + this.dataModel.selectedCompany2.getValue()._id + '';
        }
        else {
            headers = headers.append('istemplate', 'true');
        }
        return this.http
            .post(uploadURL, formData, {
            reportProgress: true,
            observe: 'events',
            headers: headers
        })
            .pipe(map((event) => {
            switch (event.type) {
                case HttpEventType.UploadProgress:
                    if (event.total > 0) {
                        // tslint:disable-next-line:no-any
                        event.percent = (event.loaded / event.total) * 100;
                    }
                    break;
                case HttpEventType.Response:
                    console.log('Response');
                    break;
                default:
                    break;
            }
            return event.percent
                ? +event.percent.toFixed(0)
                : 0;
        }), catchError((err, caught) => {
            console.log('httperr: ', err);
            return throwError(err);
        }));
    }
}
FileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileService_Factory() { return new FileService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.DataModelService), i0.ɵɵinject(i3.DBService), i0.ɵɵinject(i4.RoleService), i0.ɵɵinject(i5.LanguageService), i0.ɵɵinject(i6.AuditService)); }, token: FileService, providedIn: "root" });
