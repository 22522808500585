import { Download } from '../../../../../models/download';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-download-table',
  templateUrl: './download-table.component.html',
  styleUrls: ['./download-table.component.css']
})
export class DownloadTableComponent implements OnInit {
  @Input() historyDownloads: Download[];
  @Input() deleteDisable = false;

  constructor() {}

  ngOnInit() {}
}
