import { Location } from '@angular/common';
import { AfterViewInit, Component } from '@angular/core';
import { AuthService } from '../../../guards/auth/auth.service';
import { LanguageService } from '../../../utils/language.service';
import { RoutingService } from '../../../utils/routing.service';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.css']
})
export class ImpressumComponent implements AfterViewInit {
  constructor(
    public authService: AuthService,
    private _location: Location,
    public lang: LanguageService,
    private router: RoutingService
  ) {}

  ngAfterViewInit(): void {
    const innerdiv = document.getElementById('innerdiv') as HTMLDivElement;
    if (innerdiv) {
      innerdiv.scrollIntoView();
    }
  }

  zurueck(): void {
    this._location.back();
  }
}
