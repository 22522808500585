import { RoutingService } from '../../utils/routing.service';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "../../utils/routing.service";
export class LoginGuard {
    constructor(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    canActivate(next, state) {
        return this.checkLoginState(state.url);
    }
    bypassLogin() {
        if (!this.authService.isLoggedIn) {
            this.authService.isLoggedIn = true;
        }
        return true;
    }
    checkLoginState(url) {
        if (this.authService.isLoggedIn) {
            return true;
        }
        this.authService.desiredRoute = url;
        this.router.navigateToLogin();
        return false;
    }
}
LoginGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginGuard_Factory() { return new LoginGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.RoutingService)); }, token: LoginGuard, providedIn: "root" });
