import { LanguageService } from './../../utils/language.service';
import { Component, Input, ChangeDetectorRef, AfterViewInit, AfterViewChecked, OnInit } from '@angular/core';
import { DataModelService } from '../../utils/datamodel.service';
import { RoutingService } from '../../utils/routing.service';
import { Subscription } from 'rxjs';
import { ScreenSizeService } from '../../utils/screenSize.service';

@Component({
  selector: 'app-loggedinfooter',
  templateUrl: './loggedinfooter.component.html',
  styleUrls: ['./loggedinfooter.component.css']
})
export class LoggedinFooterComponent {
  fixed: boolean;
  visible: boolean;

  constructor(
    public screenSize: ScreenSizeService,
    public lang: LanguageService,
    public datamodel: DataModelService,
    private router: RoutingService
  ) {
    // this.fixed = this.screenSize.checkScrollable();
    this.visible = true;
  }

  impressum(): void {
    this.router.navigateToImpressum();
  }
  privacy(): void {
    this.router.navigateToDatenschutzerklaerung();
  }
  terms(): void {
    this.router.navigateToTermsAndConditions();
  }
}
