import { environment } from './../../environments/environment';

export const CONSTANTS = {
  sidebarWidth: 200
};

export class Globals {
  dbUrl: string;
  socketIOURL: string;
  constructor() {
    this.dbUrl = environment.dburl;
    this.socketIOURL = environment.socketIOURL;
    // this.dbUrl = isDevMode() ? 'http://localhost:4300' : 'https://demo.eprivacyaudit.eu:4300'; // URL to web api
  }
}
