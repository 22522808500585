import { saveAs } from 'file-saver';
import { Cookies } from './../../utils/cookies.service';
import { DBService } from './../../utils/dbverbindung.service';
import { LanguageService } from './../../utils/language.service';
import { Component, ChangeDetectorRef, OnInit, AfterViewChecked } from '@angular/core';
import { AuthService } from '../../guards/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent {
  currentTileIndex = 0;
  currentImageIndex = this.currentTileIndex;
  images = [];

  constructor(
    private lang: LanguageService,
    public authService: AuthService,
    public dbService: DBService,
    private cookieService: Cookies,
    public languageService: LanguageService,
    private router: Router
  ) {
    if (this.cookieService.hasUserCookie()) {
      const cookie = this.cookieService.getUserCookie();
      if (cookie['user']) {
        this.router.navigate(['/login']);
      } else {
        this.dbService.userAndCompany = false;
      }
    } else {
      Promise.resolve(null).then(() => (this.authService.loginInitiated = false));

      for (let index = 0; index < this.lang.translate().landing_tiles.length; index++) {
        this.images.push('url("../../../assets/landing' + index + '-min.jpeg")');
      }
    }
  }

  indexChange(index: number) {
    if (index === -1) {
      index = this.lang.translate().landing_tiles.length - 1;
    } else if (index === this.lang.translate().landing_tiles.length) {
      index = 0;
    }
    if (this.currentImageIndex !== index) {
      this.currentImageIndex = index;
    }
  }
  downloadDocxDemo(): void {
    this.dbService.downloadDocxDemo('b4a74d19-6914-442a-b352-c3447f11aa0d').subscribe(x => {
      saveAs(x, 'demo.docx');
    });
  }
}
