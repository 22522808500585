import { Tag } from './tag';
import { EmailTag } from './emailTag';

export class Email {
  constructor(
    public companyUUID: string,
    public userUUID: string,
    public type: EmailTypeEnum,
    public additionalTags: EmailTag[],
    public language: string
  ) {}
}
export enum EmailTypeEnum {
  Status_Kapitel_bearbeitet_Kunde = 'Status_Kapitel_bearbeitet_Kunde',
  Accounterstellung_Vollversion_Beratung_Kunde = 'Accounterstellung_Vollversion_Beratung_Kunde',
  Accounterstellung_Vollversion_DSB_Kunde = 'Accounterstellung_Vollversion_DSB_Kunde',
  Accounterstellung_Vollversion_Kunde = 'Accounterstellung_Vollversion_Kunde',
  Kontaktanfrage_Landingpage_Sales = 'Kontaktanfrage_Landingpage_Sales',
  Statusaenderung_Kapitel_in_Bearbeitung_Kunde = 'Statusaenderung_Kapitel_in_Bearbeitung_Kunde',
  Kontaktanfrage_Landingpage_Kunde = 'Kontaktanfrage_Landingpage_Kunde',
  Statusaenderung_Kapitel_in_Pruefung_DSBTeam = 'Statusaenderung_Kapitel_in_Pruefung_DSBTeam'
}
