import { Component, Input } from '@angular/core';
import { TextResolverService } from './../../../utils/text-resolver.service';

@Component({
  selector: 'app-popover-marked',
  templateUrl: './popoverMarked.component.html',
  styleUrls: ['./popoverMarked.component.css']
})
export class PopoverMarkedComponent {
  @Input()
  text: string;

  innerHelpMap: Map<string, string>;
  splittedText: string[] = [];

  constructor(public textResolverService: TextResolverService) {}

  @Input()
  set helpMap(helpMap: Map<string, string>) {
    this.innerHelpMap = helpMap;
    if (this.textContainsHelp()) {
      this.setSplittedTextByHelpMap();
    }
  }

  setSplittedTextByHelpMap() {
    const splittedText: string[] = [];
    let lastKey: string;
    let splittedArray = [];
    let runningText = this.text;
    this.innerHelpMap.forEach((value, key) => {
      lastKey = this.textResolverService.getLanguageText(key);
      splittedArray = runningText.split(lastKey);
      splittedText.push(splittedArray.reverse().pop());
      runningText = splittedArray.reverse().join(lastKey);
    });
    splittedText.push(
      this.text.split(splittedText[splittedText.length - 1] + lastKey)[1]
    );
    this.splittedText = splittedText;
  }

  textContainsHelp(): boolean {
    return (
      !!this.innerHelpMap &&
      Array.from(this.innerHelpMap.keys()).find(key =>
        this.text.includes(this.textResolverService.getLanguageText(key))
      ) !== undefined
    );
  }

  getHelpKey(index: number): string {
    return Array.from(this.innerHelpMap.keys())[index];
  }

  getPopoverText(index: number): string {
    return this.textResolverService.getLanguageText(
      this.innerHelpMap.get(this.getHelpKey(index))
    );
  }

  isLastPart(index: number): boolean {
    return index === this.splittedText.length - 1;
  }
}
