import { Injectable, NgZone } from '@angular/core';

const SMALL_WIDTH_BREAKPOINT = 44;
const MEDIUM_WIDTH_BREAKPOINT = 64;

@Injectable()
export class ScreenSizeService {
  private mediaMatcher: MediaQueryList = matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}em)`);
  private mediaMatcher2: MediaQueryList = matchMedia(`(max-width: ${MEDIUM_WIDTH_BREAKPOINT}em)`);
  public show = false;

  constructor(zone: NgZone) {
    this.mediaMatcher.addListener(mql => zone.run(() => (this.mediaMatcher = matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}em)`))));
    this.mediaMatcher2.addListener(mql => zone.run(() => (this.mediaMatcher2 = matchMedia(`(max-width: ${MEDIUM_WIDTH_BREAKPOINT}em)`))));
  }

  public isScreenSmall(): boolean {
    return this.mediaMatcher.matches;
  }
  public isScreenMedium(): boolean {
    return this.mediaMatcher2.matches;
  }
  public checkScrollable(): boolean {
    // Gibt die höhe zwischen dem rechten Content (outer div) und
    // dem inneren div wieder. Wenn inner Scrollable ist, ist das größer als das outer div, sprich > 0
    /*const innerdiv = document.getElementById('innerdiv') as HTMLDivElement;
    const outerdiv = document.getElementById('outerdiv') as HTMLDivElement;
    if (innerdiv && outerdiv) {
      return outerdiv.clientHeight - innerdiv.clientHeight > 0;
    }*/
    return false;
  }
}
