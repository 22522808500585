/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./verify-email.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/ng-zorro-antd/result/ng-zorro-antd-result.ngfactory";
import * as i3 from "ng-zorro-antd/core";
import * as i4 from "ng-zorro-antd/result";
import * as i5 from "../../../../../node_modules/ng-zorro-antd/button/ng-zorro-antd-button.ngfactory";
import * as i6 from "ng-zorro-antd/button";
import * as i7 from "@angular/cdk/observers";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "ng-zorro-antd/icon";
import * as i10 from "@angular/cdk/platform";
import * as i11 from "../../../components/landingpage/landinglogo/landinglogo.component.ngfactory";
import * as i12 from "../../../components/landingpage/landinglogo/landinglogo.component";
import * as i13 from "../../../utils/routing.service";
import * as i14 from "../../../components/landingpage/landingtoprightrow/landingtoprightrow.component.ngfactory";
import * as i15 from "../../../components/landingpage/landingtoprightrow/landingtoprightrow.component";
import * as i16 from "../../../utils/language.service";
import * as i17 from "../../../utils/datamodel.service";
import * as i18 from "@angular/common";
import * as i19 from "../../../components/landingpage/landingfooter/landingfooter.component.ngfactory";
import * as i20 from "../../../components/landingpage/landingfooter/landingfooter.component";
import * as i21 from "./verify-email.component";
import * as i22 from "../../../utils/dbverbindung.service";
var styles_VerifyEmailComponent = [i0.styles];
var RenderType_VerifyEmailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VerifyEmailComponent, data: {} });
export { RenderType_VerifyEmailComponent as RenderType_VerifyEmailComponent };
function View_VerifyEmailComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "nz-result", [["nzStatus", "success"]], null, null, null, i2.View_NzResultComponent_0, i2.RenderType_NzResultComponent)), i1.ɵprd(512, null, i3.NzUpdateHostClassService, i3.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(2, 573440, null, 0, i4.NzResultComponent, [i3.NzUpdateHostClassService, i1.ElementRef], { nzTitle: [0, "nzTitle"], nzStatus: [1, "nzStatus"], nzSubTitle: [2, "nzSubTitle"] }, null), (_l()(), i1.ɵeld(3, 0, null, 4, 11, "div", [["class", "ant-result-extra"], ["nz-result-extra", ""]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i4.NzResultExtraDirective, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "button", [["nz-button", ""]], [[1, "nz-wave", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.router.navigateToLanding() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_NzButtonComponent_0, i5.RenderType_NzButtonComponent)), i1.ɵprd(512, null, i3.NzUpdateHostClassService, i3.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(7, 1818624, null, 1, i6.NzButtonComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2, i7.ContentObserver, i3.NzUpdateHostClassService, i1.NgZone, i3.NzConfigService, [2, i3.NZ_WAVE_GLOBAL_CONFIG], [2, i8.ANIMATION_MODULE_TYPE]], null, null), i1.ɵqud(603979776, 1, { listOfIconElement: 1 }), (_l()(), i1.ɵted(9, 0, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "button", [["nz-button", ""], ["nzType", "primary"]], [[1, "nz-wave", 0]], null, null, i5.View_NzButtonComponent_0, i5.RenderType_NzButtonComponent)), i1.ɵprd(512, null, i3.NzUpdateHostClassService, i3.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(12, 1818624, null, 1, i6.NzButtonComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2, i7.ContentObserver, i3.NzUpdateHostClassService, i1.NgZone, i3.NzConfigService, [2, i3.NZ_WAVE_GLOBAL_CONFIG], [2, i8.ANIMATION_MODULE_TYPE]], { nzType: [0, "nzType"] }, null), i1.ɵqud(603979776, 2, { listOfIconElement: 1 }), (_l()(), i1.ɵted(14, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.lang.translate().processVerifySuccessed; var currVal_1 = "success"; var currVal_2 = _co.lang.translate().establishVerifyConnectionSuccessed; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); _ck(_v, 7, 0); var currVal_6 = "primary"; _ck(_v, 12, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵnov(_v, 7).nzWave; _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.lang.translate().home; _ck(_v, 9, 0, currVal_4); var currVal_5 = i1.ɵnov(_v, 12).nzWave; _ck(_v, 10, 0, currVal_5); var currVal_7 = _co.lang.translate().next; _ck(_v, 14, 0, currVal_7); }); }
function View_VerifyEmailComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "nz-result", [["nzStatus", "error"]], null, null, null, i2.View_NzResultComponent_0, i2.RenderType_NzResultComponent)), i1.ɵprd(512, null, i3.NzUpdateHostClassService, i3.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(2, 573440, null, 0, i4.NzResultComponent, [i3.NzUpdateHostClassService, i1.ElementRef], { nzTitle: [0, "nzTitle"], nzStatus: [1, "nzStatus"], nzSubTitle: [2, "nzSubTitle"] }, null), (_l()(), i1.ɵeld(3, 0, null, 4, 11, "div", [["class", "ant-result-extra"], ["nz-result-extra", ""]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i4.NzResultExtraDirective, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "button", [["nz-button", ""]], [[1, "nz-wave", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.router.navigateToLanding() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_NzButtonComponent_0, i5.RenderType_NzButtonComponent)), i1.ɵprd(512, null, i3.NzUpdateHostClassService, i3.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(7, 1818624, null, 1, i6.NzButtonComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2, i7.ContentObserver, i3.NzUpdateHostClassService, i1.NgZone, i3.NzConfigService, [2, i3.NZ_WAVE_GLOBAL_CONFIG], [2, i8.ANIMATION_MODULE_TYPE]], null, null), i1.ɵqud(603979776, 3, { listOfIconElement: 1 }), (_l()(), i1.ɵted(9, 0, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "button", [["nz-button", ""], ["nzType", "primary"]], [[1, "nz-wave", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNavigate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_NzButtonComponent_0, i5.RenderType_NzButtonComponent)), i1.ɵprd(512, null, i3.NzUpdateHostClassService, i3.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(12, 1818624, null, 1, i6.NzButtonComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2, i7.ContentObserver, i3.NzUpdateHostClassService, i1.NgZone, i3.NzConfigService, [2, i3.NZ_WAVE_GLOBAL_CONFIG], [2, i8.ANIMATION_MODULE_TYPE]], { nzType: [0, "nzType"] }, null), i1.ɵqud(603979776, 4, { listOfIconElement: 1 }), (_l()(), i1.ɵted(14, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.lang.translate().processVerifyFailed; var currVal_1 = "error"; var currVal_2 = _co.lang.translate().establishVerifyConnectionFailed; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); _ck(_v, 7, 0); var currVal_6 = "primary"; _ck(_v, 12, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵnov(_v, 7).nzWave; _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.lang.translate().home; _ck(_v, 9, 0, currVal_4); var currVal_5 = i1.ɵnov(_v, 12).nzWave; _ck(_v, 10, 0, currVal_5); var currVal_7 = _co.lang.translate().contact; _ck(_v, 14, 0, currVal_7); }); }
function View_VerifyEmailComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [["nz-icon", ""]], null, null, null, null, null)), i1.ɵdid(1, 2834432, null, 0, i9.NzIconDirective, [i9.NzIconService, i1.ElementRef, i1.Renderer2, i10.Platform], { nzType: [0, "nzType"] }, null)], function (_ck, _v) { var currVal_0 = "loading"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_VerifyEmailComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "nz-result", [], null, null, null, i2.View_NzResultComponent_0, i2.RenderType_NzResultComponent)), i1.ɵprd(512, null, i3.NzUpdateHostClassService, i3.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(2, 573440, null, 0, i4.NzResultComponent, [i3.NzUpdateHostClassService, i1.ElementRef], { nzIcon: [0, "nzIcon"], nzTitle: [1, "nzTitle"], nzSubTitle: [2, "nzSubTitle"] }, null), (_l()(), i1.ɵand(0, [["loadingIcon", 2]], null, 0, null, View_VerifyEmailComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3); var currVal_1 = _co.lang.translate().processVerify; var currVal_2 = _co.lang.translate().establishVerifyConnection; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_VerifyEmailComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "topWidth"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-landinglogo", [], null, null, null, i11.View_LandingLogoComponent_0, i11.RenderType_LandingLogoComponent)), i1.ɵdid(2, 49152, null, 0, i12.LandingLogoComponent, [i13.RoutingService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-landingtoprightrow", [], null, null, null, i14.View_LandingTopRightRowComponent_0, i14.RenderType_LandingTopRightRowComponent)), i1.ɵdid(4, 49152, null, 0, i15.LandingTopRightRowComponent, [i16.LanguageService, i17.DataModelService, i13.RoutingService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VerifyEmailComponent_1)), i1.ɵdid(6, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VerifyEmailComponent_2)), i1.ɵdid(8, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VerifyEmailComponent_3)), i1.ɵdid(10, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["id", "footernavi"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-landingfooter", [], null, null, null, i19.View_LandingFooterComponent_0, i19.RenderType_LandingFooterComponent)), i1.ɵdid(13, 49152, null, 0, i20.LandingFooterComponent, [i16.LanguageService, i17.DataModelService, i13.RoutingService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.success; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.failed; _ck(_v, 8, 0, currVal_1); var currVal_2 = (!_co.success && !_co.failed); _ck(_v, 10, 0, currVal_2); }, null); }
export function View_VerifyEmailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-verify-email", [], null, null, null, View_VerifyEmailComponent_0, RenderType_VerifyEmailComponent)), i1.ɵdid(1, 114688, null, 0, i21.VerifyEmailComponent, [i16.LanguageService, i22.DBService, i13.RoutingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VerifyEmailComponentNgFactory = i1.ɵccf("app-verify-email", i21.VerifyEmailComponent, View_VerifyEmailComponent_Host_0, {}, {}, []);
export { VerifyEmailComponentNgFactory as VerifyEmailComponentNgFactory };
