<button
  nz-button
  nz-popover
  class="button"
  [nzContent]="contentTemplate"
  [disabled]="notifyEntries?.length === 0"
  nzPlacement="bottomRight"
  nzTrigger="click"
>
  <nz-badge class="badge" [nzCount]="getMyNotifyVisibleCount()"
    ><i nz-icon nzType="bell" class="icon" nzTheme="outline"></i
  ></nz-badge>
</button>

<ng-template #contentTemplate>
  <div class="scroll">
    <app-notifyentry
      *ngFor="let notifyEntry of notifyEntries"
      [entry]="notifyEntry"
    ></app-notifyentry>
  </div>
</ng-template>
