<div class="buttondiv">
  <button
    class="button"
    [@rotatedState]="state"
    (click)="triggerSider()"
    nz-button
    [nzSize]="'large'"
  >
    <i nz-icon nzType="menu-unfold"></i>
  </button>
</div>
