<div
  (click)="changeToSee()"
  nz-row
  [ngClass]="{ maindiv: entry?.viewed === '0', width: true }"
  nzType="flex"
  nzJustify="space-around"
  nzAlign="middle"
>
  <div nz-col nzSpan="20">
    <nz-layout class="layoutcolor">
      <nz-content class="margin">{{
        text.getLanguageText(entry?.messageUUID)
      }}</nz-content>
      <nz-content class="margin">
        {{
          isToday()
            ? (entry?.createdOn | date: 'hh:mm:ss')
            : (entry?.createdOn | date: 'dd.MM.yyyy - hh:mm:ss')
        }}
        <nz-content class="margin">{{
          getUserName(entry?.fromUserUUID)
        }}</nz-content>
      </nz-content>
    </nz-layout>
  </div>
</div>
