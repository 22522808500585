/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./history.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../components/shared/stammdaten/stammdaten.component.ngfactory";
import * as i3 from "../../../components/shared/stammdaten/stammdaten.component";
import * as i4 from "../../../utils/datamodel.service";
import * as i5 from "../../../utils/language.service";
import * as i6 from "../../../utils/text-resolver.service";
import * as i7 from "../../../utils/question.service";
import * as i8 from "./history.component";
var styles_HistoryComponent = [i0.styles];
var RenderType_HistoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HistoryComponent, data: {} });
export { RenderType_HistoryComponent as RenderType_HistoryComponent };
export function View_HistoryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-stammdaten", [], null, null, null, i2.View_StammdatenComponent_0, i2.RenderType_StammdatenComponent)), i1.ɵdid(1, 114688, null, 0, i3.StammdatenComponent, [i4.DataModelService, i5.LanguageService, i6.TextResolverService, i7.QuestionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_HistoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-history", [], null, null, null, View_HistoryComponent_0, RenderType_HistoryComponent)), i1.ɵdid(1, 49152, null, 0, i8.HistoryComponent, [i5.LanguageService], null, null)], null, null); }
var HistoryComponentNgFactory = i1.ɵccf("app-history", i8.HistoryComponent, View_HistoryComponent_Host_0, {}, {}, []);
export { HistoryComponentNgFactory as HistoryComponentNgFactory };
