import { Component } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd';
import { LanguageService } from '../../../../utils/language.service';
import { DBService } from '../../../../utils/dbverbindung.service';

@Component({
  selector: 'app-forgot-passord-dialog',
  templateUrl: './forgotPassordDialog.component.html',
  styleUrls: ['./forgotPassordDialog.component.css']
})
export class ForgotPasswordDialogComponent {
  account: string;
  tabIndex = 0;

  constructor(public lang: LanguageService, private dbService: DBService) {}

  submitForm(): void {
    if (this.account) {
      this.dbService.sendForgotPasswordEmail(
        this.account,
        this.lang.getCurrentLanguageString()
      );
      this.tabIndex = 1;
    }
  }
}
