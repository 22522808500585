import { DataModelService } from "../../utils/datamodel.service";
import { LanguageService } from "../../utils/language.service";
import { Component, AfterViewInit } from "@angular/core";

@Component({
  selector: "app-glossarentry",
  templateUrl: "./glossarentry.component.html",
  styleUrls: ["./glossarentry.component.css"]
})
export class GlossarEntryComponent {
  keys: string[] = [];

  constructor(
    public lang: LanguageService,
    public datamodel: DataModelService
  ) {
    this.keys = Array.from(this.datamodel.glossarMap.keys());
  }
}
