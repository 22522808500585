/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./support.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./support.component";
import * as i3 from "../../../utils/language.service";
import * as i4 from "../../../utils/datamodel.service";
var styles_SupportComponent = [i0.styles];
var RenderType_SupportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SupportComponent, data: {} });
export { RenderType_SupportComponent as RenderType_SupportComponent };
export function View_SupportComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Support\n"]))], null, null); }
export function View_SupportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-support", [], null, null, null, View_SupportComponent_0, RenderType_SupportComponent)), i1.ɵdid(1, 49152, null, 0, i2.SupportComponent, [i3.LanguageService, i4.DataModelService], null, null)], null, null); }
var SupportComponentNgFactory = i1.ɵccf("app-support", i2.SupportComponent, View_SupportComponent_Host_0, {}, {}, []);
export { SupportComponentNgFactory as SupportComponentNgFactory };
