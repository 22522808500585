<div class="content" [@nextSegment]="this.nextSegmentStatus">
  <ng-content></ng-content>
</div>
<app-navigation
  [amountSegments]="this.amountSegments"
  [currentSegment]="this._currentSegment"
  [showIndicators]="this.showIndicators"
  [enableJumper]="this.enableJumper"
  [demo]="this.demo"
  [nextReady]="this.nextReady"
  (nextSegmentEmitter)="this.evalSegment($event)"
  (prevClicked)="this.prevClicked.emit()"
  (nextClicked)="this.nextClicked.emit()"
>
</app-navigation>
