import { DBService } from './../../../../utils/dbverbindung.service';
import { NotifyEntry } from './../../../../models/notifyEntry';
import { Component, Input } from '@angular/core';
import { User } from '../../../../models/user';
import { DataModelService } from '../../../../utils/datamodel.service';
import { LanguageService } from '../../../../utils/language.service';
import { TextResolverService } from '../../../../utils/text-resolver.service';

@Component({
  selector: 'app-notifyentry',
  templateUrl: './notifyentry.component.html',
  styleUrls: ['./notifyentry.component.css']
})
export class NotifyEntryComponent {
  @Input()
  entry: NotifyEntry;

  constructor(
    private dbService: DBService,
    public lang: LanguageService,
    public text:TextResolverService,
    private datamodel: DataModelService
  ) {}

  isToday(): boolean {
    const targetdate = new Date(this.entry.createdOn);
    const today = new Date();
    return (
      today.getDate() === targetdate.getDate() &&
      today.getMonth() === targetdate.getMonth() &&
      today.getFullYear() === targetdate.getFullYear()
    );
  }

  getUserName(userUUID: string): string {
    const usr = this.datamodel.epcUsers.find(u => u._id === userUUID);
    return usr.firstName + ' ' + usr.lastName;
  }

  changeToSee(): void {
    this.dbService
      .changeNotifyEntryToSeen(this.entry._id)
      .subscribe(boolean => {
        if (boolean === true) {
          this.entry.viewed = '1';
        }
      });
  }
}
