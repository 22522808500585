import { NotifyEntry } from './../models/notifyEntry';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { AuditFile } from '../models/auditFile';
import { Chapter } from '../models/chapter';
import { Company } from '../models/company';
import { TemplateFile } from '../models/templateFile';
import { User } from '../models/user';
import { Fetch } from './../models/fetch';
import { Globals } from './../models/globals';
import { LanguageText } from './../models/languageText';
import { Encryption } from './encryption.service';
import { Email } from '../models/email';
import { AuditAnswer } from '../models/auditAnswer';
import { NotificationService } from './notification.service';
import { Notice } from '../models/notice';
import { Tag } from '../models/tag';
import { Telemetrie } from '../models/telemetrie';
import { Country } from '../models/country';
import { CustomerReport } from '../models/customerReport';

@Injectable({
  // damit der service ein singleton ist
  providedIn: 'root'
})
export class DBService {
  private dbUrl: String;

  headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  chaptersFetching = true;
  templateFilesFetching = true;
  languageTextsFetching = true;
  epcCompanyFetching = true;
  epcNotifyFetching = true;
  epcUsersFetching = true;
  tagsFetching = true;

  auditAnswersFetching = false;
  auditFilesFetching = false;
  userAndCompany = false;
  companiesFetching = false;
  usersFetching = false;
  noticesFetching = false;
  reportsFetching = false;

  telemetrieLastLoginFetching = false;

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService
  ) {
    this.dbUrl = new Globals().dbUrl;
  }

  setSessionToken(token: string): void {
    this.headers = this.headers.set('token', token);
  }
  setUserUUID(userUUID: string): void {
    this.headers = this.headers.set('useruuid', userUUID);
  }

  saveReport(report: CustomerReport): Observable<boolean> {
    return this.http.post<boolean>(
      this.dbUrl + '/saveReport',
      { report },
      { headers: this.headers }
    );
  }
  deleteReport(report: CustomerReport): Observable<boolean> {
    return this.http.post<boolean>(
      this.dbUrl + '/deleteReport',
      { report },
      { headers: this.headers }
    );
  }

  getStatistics(): Observable<[]> {
    return this.http.post<[]>(
      this.dbUrl + '/getStatistics',
      {},
      { headers: this.headers }
    );
  }
  getTelemetrie(): Observable<[]> {
    return this.http.post<[]>(
      this.dbUrl + '/getTelemetrie',
      {},
      { headers: this.headers }
    );
  }
  replacer(key, value) {
    const originalObject = this[key];
    if (originalObject instanceof Map) {
      return {
        dataType: 'Map',
        value: Array.from(originalObject.entries()) // or with spread: value: [...originalObject]
      };
    } else {
      return value;
    }
  }

  reviver(key, value) {
    if (typeof value === 'object' && value !== null) {
      if (value.dataType === 'Map') {
        return new Map(value.value);
      }
    }
    return value;
  }

  login(
    account: string,
    pw: string,
    socketID: string,
    hash?: string
  ): Observable<any> {
    this.userAndCompany = true;
    return this.http
      .post<string>(
        this.dbUrl + '/login',
        hash ? { hash: hash } : { account: account, pw: pw, socketID },
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        delay(1000),
        catchError(this.handleError('login', Fetch.USER_COMPANY, true))
      );
  }

  verifyAccount(uuid: string): Observable<boolean> {
    return this.http
      .post<boolean>(
        this.dbUrl + '/verifyAccount',
        { uuid },
        { headers: this.headers }
      )
      .pipe(catchError(this.handleError('verifyAccount', null, true)));
  }

  exportTags(): Observable<any> {
    return this.http.post(
      this.dbUrl + '/download/excel/tags',
      {},
      { headers: this.headers, responseType: 'blob' }
    );
  }
  downloadDocxDemo(companyUUID: string): Observable<any> {
    return this.http.post(
      this.dbUrl + '/download/word/demo',
      { companyUUID },
      { responseType: 'blob' }
    );
  }

  changeNotifyEntryToSeen(notifyEntryUUID: string): Observable<boolean> {
    return this.http
      .post<boolean>(
        this.dbUrl + '/changeNotifyEntryToSeen',
        { notifyEntryUUID },
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map(res => (res.status === 200 ? true : false)),
        catchError(this.handleError('createCompany', Fetch.CHAPTERS))
      );
  }

  createCompany(
    company: Company,
    fromUserUUID: string,
    forUserUUID: string,
    lang: string
  ): Observable<boolean> {
    return this.http
      .post<string>(
        this.dbUrl + '/companies/create',
        {
          company: company,
          fromUserUUID: fromUserUUID,
          forUserUUID: forUserUUID,
          lang: lang
        },
        {
          headers: this.headers,
          observe: 'response'
        }
      )
      .pipe(
        map(res => (res.status === 200 ? true : false)),
        catchError(this.handleError('createCompany', Fetch.CHAPTERS))
      );
  }

  createUser(
    user: User,
    fromUserUUID: string,
    forUserUUID: string
  ): Observable<boolean> {
    return this.http
      .post<string>(
        this.dbUrl + '/users/create',
        {
          user: user,
          fromUserUUID: fromUserUUID,
          forUserUUID: forUserUUID
        },
        {
          headers: this.headers,
          observe: 'response'
        }
      )
      .pipe(
        map(res => (res.status === 200 ? true : false)),
        catchError(this.handleError('createUser', Fetch.CHAPTERS))
      );
  }

  updateUser(
    user: User,
    fromUserUUID: string,
    forUserUUID: string
  ): Observable<boolean> {
    return this.http
      .post<string>(
        this.dbUrl + '/users/update',
        {
          user: user,
          fromUserUUID: fromUserUUID,
          forUserUUID: forUserUUID
        },
        {
          headers: this.headers,
          observe: 'response'
        }
      )
      .pipe(
        map(res => (res.status === 200 ? true : false)),
        catchError(this.handleError('updateUser', Fetch.CHAPTERS))
      );
  }

  updateCompany(
    company: Company,
    fromUserUUID: string,
    forUserUUID: string
  ): Observable<boolean> {
    return this.http
      .post<string>(
        this.dbUrl + '/companies/update',
        {
          company: company,
          fromUserUUID: fromUserUUID,
          forUserUUID: forUserUUID
        },
        {
          headers: this.headers,
          observe: 'response'
        }
      )
      .pipe(
        map(res => (res.status === 200 ? true : false)),
        catchError(this.handleError('updateCompany', Fetch.CHAPTERS))
      );
  }

  insertIntoCollection(
    dataAsJSONStringify: string[],
    collection: string
  ): Observable<boolean> {
    return this.http
      .post<string>(
        this.dbUrl + '/insertIntoCollection',
        {
          collection: collection,
          content: dataAsJSONStringify
        },
        {
          headers: this.headers,
          observe: 'response'
        }
      )
      .pipe(
        map(res => (res.status === 200 ? true : false)),
        catchError(this.handleError('insertIntoCollection', Fetch.CHAPTERS))
      );
  }

  deleteFromCollection(
    _ids: string[],
    collection: string,
    companyUUID: string,
    isTemplateFile: boolean
  ): Observable<boolean> {
    return this.http
      .post<string>(
        this.dbUrl + '/deleteFromCollection',
        {
          _ids: _ids,
          collection: collection,
          companyUUID: companyUUID
        },
        {
          headers: isTemplateFile
            ? this.headers.append('istemplate', 'true')
            : this.headers,
          observe: 'response'
        }
      )
      .pipe(
        map(res => (res.status === 200 ? true : false)),
        catchError(this.handleError('deleteFromCollection', Fetch.CHAPTERS))
      );
  }

  updateCollection(
    _id: string,
    dataToBeUpdatedAsJSONStringify: string,
    collection: string
  ): Observable<boolean> {
    console.log(dataToBeUpdatedAsJSONStringify);
    console.log(collection);

    return this.http
      .post<string>(
        this.dbUrl + '/updateCollection',
        {
          _id: _id,
          attributes: dataToBeUpdatedAsJSONStringify,
          collection: collection
        },
        {
          headers: this.headers,
          observe: 'response'
        }
      )
      .pipe(
        map(res => (res.status === 200 ? true : false)),
        catchError(this.handleError('updateCollection', Fetch.CHAPTERS))
      );
  }

  getAuditData(userUUID: string): Observable<Chapter[]> {
    this.chaptersFetching = true;
    return this.http
      .post<Chapter[]>(
        this.dbUrl + '/getAuditData',
        {
          userUUID: userUUID
        },
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map(res => JSON.parse(JSON.stringify(res.body), this.reviver)),
        catchError(this.handleError('getAuditData', Fetch.CHAPTERS))
      );
  }

  getLanguageTexts(): Observable<LanguageText[]> {
    this.languageTextsFetching = true;
    return this.http
      .post<LanguageText[]>(
        this.dbUrl + '/getLanguageTexts',
        {},
        { headers: this.headers }
      )
      .pipe(
        catchError(this.handleError('getLanguageTexts', Fetch.LANGUAGE_TEXTS))
      );
  }

  getTemplateFiles(): Observable<TemplateFile[]> {
    this.templateFilesFetching = true;
    return this.http
      .post<TemplateFile[]>(
        this.dbUrl + '/getTemplateFiles',
        {},
        { headers: this.headers }
      )
      .pipe(
        catchError(this.handleError('getTemplateFiles', Fetch.TEMPLATE_FILES))
      );
  }

  getTags(): Observable<Tag[]> {
    this.tagsFetching = true;
    return this.http
      .post<Tag[]>(this.dbUrl + '/getTags', {}, { headers: this.headers })
      .pipe(catchError(this.handleError('getTags', Fetch.TAGS)));
  }

  getCompanies(): Observable<Company[]> {
    this.companiesFetching = true;
    return this.http
      .post<Company[]>(this.dbUrl + '/companies', {}, { headers: this.headers })
      .pipe(catchError(this.handleError('getCompanies', Fetch.COMPANIES)));
  }

  getEPCCompany(): Observable<Company[]> {
    this.epcCompanyFetching = true;
    return this.http
      .post<Company[]>(
        this.dbUrl + '/companies/epc',
        {},
        { headers: this.headers }
      )
      .pipe(catchError(this.handleError('getEPCCompany', Fetch.EPCCompany)));
  }

  getEPCUsers(): Observable<User[]> {
    this.epcCompanyFetching = true;
    return this.http
      .post<User[]>(this.dbUrl + '/users/epc', {}, { headers: this.headers })
      .pipe(catchError(this.handleError('getEPCUsers', Fetch.EPCUsers)));
  }

  getAllReports(): Observable<CustomerReport[]> {
    this.reportsFetching = true;
    return this.http
      .post<CustomerReport[]>(
        this.dbUrl + '/getAllReports',
        {},
        { headers: this.headers }
      )
      .pipe(catchError(this.handleError('getAllReports', Fetch.REPORTS)));
  }
  getReportPreview(reportUUID: string, type: number): Observable<any> {
    return this.http
      .post<any>(
        this.dbUrl + '/getReportPreview',
        { reportUUID },
        { responseType: 'blob' as 'json', headers: this.headers }
      )
      .pipe(catchError(this.handleError('getReportPreview', null)));
  }

  getUsers(): Observable<User[]> {
    this.epcCompanyFetching = true;
    return this.http
      .post<User[]>(this.dbUrl + '/users', {}, { headers: this.headers })
      .pipe(catchError(this.handleError('getUsers', Fetch.USERS)));
  }

  getNotifyEntries(userUUID: string): Observable<NotifyEntry[]> {
    this.epcNotifyFetching = true;
    return this.http
      .post<NotifyEntry[]>(
        this.dbUrl + '/getNotifyEntries',
        { forUserUUID: userUUID },
        { headers: this.headers }
      )
      .pipe(
        catchError(this.handleError('getNotifyEntries', Fetch.USERS, true))
      );
  }

  getCountries(): Observable<Country[]> {
    this.epcCompanyFetching = true;
    return this.http
      .post<Country[]>(
        this.dbUrl + '/getCountries',
        {},
        { headers: this.headers }
      )
      .pipe(catchError(this.handleError('getCountries', Fetch.USERS)));
  }

  getAuditAnswers(companyUUID: string): Observable<AuditAnswer[]> {
    this.auditAnswersFetching = true;
    return this.http
      .post<AuditAnswer[]>(
        this.dbUrl + '/getAuditAnswers',
        {
          companyUUID: companyUUID
        },
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map(res => JSON.parse(JSON.stringify(res.body), this.reviver)),
        catchError(this.handleError('getAuditAnswers', Fetch.AUDIT_ANSWERS))
      );
  }

  getAuditFiles(companyUUID: string): Observable<AuditFile[]> {
    this.auditFilesFetching = true;
    return this.http
      .post<AuditFile[]>(
        this.dbUrl + '/getAuditFiles',
        {
          companyUUID: companyUUID
        },
        { headers: this.headers }
      )
      .pipe(catchError(this.handleError('getAuditFiles', Fetch.AUDIT_FILES)));
  }

  getNotices(companyUUID: string): Observable<Notice[]> {
    this.noticesFetching = true;
    return this.http
      .post<Notice[]>(
        this.dbUrl + '/getNotices',
        {
          companyUUID: companyUUID
        },
        { headers: this.headers }
      )
      .pipe(catchError(this.handleError('getNotices', Fetch.NOTICES)));
  }

  getLastLogin(companyUUID: string): Observable<Telemetrie[]> {
    this.telemetrieLastLoginFetching = true;
    return this.http
      .post<Telemetrie[]>(
        this.dbUrl + '/getLastLogin',
        {
          companyUUID: companyUUID
        },
        { headers: this.headers }
      )
      .pipe(
        catchError(this.handleError('getNotices', Fetch.TELEMETRIE_LAST_LOGIN))
      );
  }

  isIdLinkValid(uuid: string): Observable<any> {
    return this.http
      .post<any>(
        this.dbUrl + '/isIdLinkValid',
        { uuid },
        { headers: this.headers }
      )
      .pipe(catchError(this.handleError('isIdLinkValid', null, true)));
  }

  setPassword(
    userUUID: string,
    pw: string,
    idlinkUUID: string
  ): Observable<any> {
    return this.http
      .post<any>(
        this.dbUrl + '/setPassword',
        { userUUID, pw, idlinkUUID },
        { headers: this.headers }
      )
      .pipe(catchError(this.handleError('setPassword', null, true)));
  }
  isCaptchaValid(text: string, uuid: string): Observable<boolean> {
    return this.http
      .post<any>(
        this.dbUrl + '/isCaptchaValid',
        { text, uuid },
        { headers: this.headers }
      )
      .pipe(catchError(this.handleError('isCaptchaValid', null, true)));
  }
  getCaptcha(): Observable<any> {
    return this.http
      .post<any>(this.dbUrl + '/getCaptcha', {}, { headers: this.headers })
      .pipe(catchError(this.handleError('getCaptcha', null, true)));
  }

  getMasterDataTable(
    tagList: { tag: string; member: string }[]
  ): Observable<any> {
    return this.http
      .post<any>(
        this.dbUrl + '/getMasterDataTable',
        { tagList: tagList },
        { headers: this.headers }
      )
      .pipe(catchError(this.handleError('getMasterDataTable', null, true)));
  }

  getDataByEnumStatistic(
    dataEnum: string,
    timespan: number[]
  ): Observable<any> {
    return this.http
      .post<any>(
        this.dbUrl + '/getDataStatistic',
        { dataEnum: dataEnum, timespan: timespan },
        { headers: this.headers }
      )
      .pipe(catchError(this.handleError('/getDataStatistic', null, true)));
  }

  sendForgotPasswordEmail(accountname: string, language: string): Promise<any> {
    return this.http
      .post<any>(
        this.dbUrl + '/sendForgotPasswordEmail',
        { accountname, language },
        { headers: this.headers }
      )
      .toPromise();
  }

  sendEmail(email: Email): Promise<any> {
    return this.http
      .post<any>(
        this.dbUrl + '/sendEmail',
        { email },
        { headers: this.headers }
      )
      .toPromise();
  }

  handleError(operation: string, fetch?: Fetch, hideNotification?: boolean) {
    return (err: any) => {
      if (!hideNotification) {
        this.notificationService.requestResultNotification(false);
      }
      switch (fetch) {
        case Fetch.CHAPTERS:
          this.chaptersFetching = false;
          break;
        case Fetch.LANGUAGE_TEXTS:
          this.languageTextsFetching = false;
          break;
        case Fetch.USER_COMPANY:
          this.userAndCompany = false;
          break;
        case Fetch.TEMPLATE_FILES:
          this.templateFilesFetching = false;
          break;
        case Fetch.TAGS:
          this.tagsFetching = false;
          break;
        case Fetch.AUDIT_FILES:
          this.auditFilesFetching = false;
          break;
        case Fetch.COMPANIES:
          this.companiesFetching = false;
          break;
        case Fetch.EPCCompany:
          this.epcCompanyFetching = false;
          break;
        case Fetch.EPCUsers:
          this.epcUsersFetching = false;
          break;
        case Fetch.USERS:
          this.usersFetching = false;
          break;
        case Fetch.AUDIT_ANSWERS:
          this.auditAnswersFetching = false;
          break;
        case Fetch.NOTICES:
          this.noticesFetching = false;
          break;
        case Fetch.TELEMETRIE_LAST_LOGIN:
          this.telemetrieLastLoginFetching = false;
          break;
      }
      // create toast / notification with information
      return throwError(err);
    };
  }

  // loginversuch(username: string, hashedPassword: string): Promise<string> {
  //   return this.doEncryptedPost(
  //     this.dbUrl + '/73a2b8a3-88c1-4ea4-82e9-05cb6da74e59',
  //     JSON.stringify({ username: username, pass: hashedPassword })
  //   );
  // }

  // private doEncryptedPost(url: string, plaintext?: string): Promise<any> {
  //   let encryptedData: string;
  //   if (plaintext) {
  //     encryptedData = this.crypto.encryptString(plaintext);
  //   }
  //   return this.http
  //     .post(
  //       url,
  //       { uuid: this.crypto.getCurrentUUID(), data: encryptedData },
  //       { headers: this.headers }
  //     )
  //     .toPromise()
  //     .then(res => {
  //       const res2 = this.crypto.decrypt(res);

  //       if (res2.indexOf('statuscode') !== -1 && res2 !== '') {
  //         this.handleStatuscode(JSON.parse(res2)['statuscode']);
  //       } else {
  //         return res2 !== ''
  //           ? JSON.parse(res2)
  //           : JSON.parse(
  //               '{"uuid": "00000000-0000-0000-0000-000000000000","state": false,"userid": 9}'
  //             );
  //       }
  //     })
  //     .catch(this.handleError);
  // }

  // private handleError(error: any): Promise<any> {
  //   console.error('An error occurred', error);
  //   if (error.status === 401) {
  //     this.router.navigate(['/error401'], { skipLocationChange: true });
  //     return new Promise(function(resolve, reject) {
  //       resolve(undefined);
  //     });
  //   } else {
  //     return Promise.reject(error.message || error);
  //   }
  // }

  // private handleStatuscode(statuscode: number): Promise<any> {
  //   if (statuscode === 404) {
  //     this.router.navigate(['/404'], { skipLocationChange: true });
  //   }
  //   return new Promise(function(resolve, reject) {
  //     resolve(undefined);
  //   });
  // }
}
