<app-plaintext
  *ngIf="this.isPlaintext(this.segmentEntry.entry)"
  [segmentEntry]="this.segmentEntry"
  [entryHelpMaps]="
    this.helpMapService.getHelpMapForTextParts(this.segmentEntry)
  "
></app-plaintext>
<app-list
  *ngIf="this.isList(this.segmentEntry.entry)"
  [segmentEntry]="this.segmentEntry"
  [entryHelpMaps]="
    this.helpMapService.getHelpMapForTextParts(this.segmentEntry)
  "
></app-list>
<app-list-grouped
  *ngIf="this.isListGrouped(this.segmentEntry.entry)"
  [segmentEntry]="this.segmentEntry"
  [entryHelpMaps]="
    this.helpMapService.getHelpMapForTextParts(this.segmentEntry)
  "
></app-list-grouped>
