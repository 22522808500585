import { Location } from '@angular/common';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { EmailEditorComponent } from 'angular-email-editor';

@Component({
  selector: 'app-email-designer',
  templateUrl: './email-designer.component.html',
  styleUrls: ['./email-designer.component.css']
})
export class EmailDesignerComponent  {

  @ViewChild(EmailEditorComponent, {static:false})
  private emailEditor: EmailEditorComponent;

  exportHtml() {
    this.emailEditor.exportHtml((data) => console.log('exportHtml', data));
  }

}
