import { Component } from '@angular/core';
import { LanguageService } from '../../../utils/language.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent {
  constructor(public lang: LanguageService) {}
}
