<div class="topWidth" *ngIf="!authService.isLoggedIn">
  <app-landinglogo></app-landinglogo>
  <app-landingtoprightrow></app-landingtoprightrow>
</div>
<div class="centderDiv">
    <button nz-button class="botmargin" (click)="zurueck()">{{lang.translate().back}}</button>

  <nz-card [nzTitle]="lang.translate().privacypolice_header">
    <span *ngFor="let int of getSectionCount(lang.translate().privacypolice_content)">
      <span *ngFor="let key of getKeysFromJSON(lang.translate().privacypolice_content[int])">
        <h3>{{ key }}</h3>
        <span *ngFor="let value of getKeysFromJSON(lang.translate().privacypolice_content[int][key])">
          <span [ngSwitch]="getContent(lang.translate().privacypolice_content[int][key][value])">
            <span *ngSwitchCase="'num'">
              <p>{{ lang.translate().privacypolice_content[int][key][value] }}</p>
            </span>
            <span *ngSwitchCase="'array'">
              <ul>
                <li *ngFor="let line of lang.translate().privacypolice_content[int][key][value]">{{ line }}</li>
              </ul>
            </span>
          </span>
        </span>
      </span>
    </span>
  </nz-card>
</div>
