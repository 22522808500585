import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Download } from '../../../../../models/download';
import { Globals } from '../../../../../models/globals';
import { DataModelService } from '../../../../../utils/datamodel.service';
import { LanguageService } from '../../../../../utils/language.service';
import { DBService } from '../../../../../utils/dbverbindung.service';

@Component({
  selector: 'app-download-entry',
  templateUrl: './downloadEntry.component.html',
  styleUrls: ['./downloadEntry.component.css']
})
export class DownloadEntryComponent implements OnInit, OnDestroy {
  @Input()
  download: Download;
  @Input()
  downloadDisable: boolean;
  @Input()
  deleteDisable: boolean;
  @Input()
  deleteHidden: boolean;

  @Output()
  documentDelete = new EventEmitter<string>();

  icon_path: String = 'default';

  dbUrl = new Globals().dbUrl;

  subscriptions: Subscription[] = [];

  constructor(
    public languageService: LanguageService,
    private dataModel: DataModelService,
    private dbService: DBService
  ) {}
  ngOnInit() {
    this.selectIcon();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  downloadDocument() {
    const form = document.createElement('form');
    form.action = this.dbUrl + '/download';
    form.method = 'POST';

    const label = {
      token: this.dbService.headers.get('token'),
      companyUUID: this.dataModel.selectedCompany2.getValue()
        ? this.dataModel.selectedCompany2.getValue()._id
        : '0000-0000',
      isTemplate: this.deleteHidden,
      fileName: this.encodeBody(this.download.name)
    };

    const content = document.createElement('textarea');
    content.name = 'content';
    content.value = JSON.stringify(label);
    form.appendChild(content);

    // const input = document.createElement('textarea');
    // input.name = 'companyUUID';
    // input.value = this.dataModel.selectedCompany._id;
    // form.appendChild(input);
    // const input2 = document.createElement('textarea');
    // input2.name = 'isTemplate';
    // input2.value = this.deleteHidden.toString();
    // form.appendChild(input2);
    // const input3 = document.createElement('textarea');
    // input3.name = 'fileName';
    // input3.value = this.download.name;
    // form.appendChild(input3);

    form.style.display = 'none';
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);

    // return this.fileService
    //   .downloadFile(this.download.name, this.deleteHidden)
    //   .subscribe();

    // this.subscriptions.push(
    //   this.fileService
    //     .downloadFile(this.download.name, this.deleteHidden)
    //     .subscribe(file => {
    //       saveAs(file, this.download.name);
    //     })
    // );
  }

  deleteDocument() {
    this.documentDelete.emit(this.download.auditFileUUID);
  }

  selectIcon() {
    const extension = this.download.name.split('.').pop();
    switch (extension) {
      case 'pdf':
        this.setIconPath('icons8-pdf-480.png');
        break;
      case 'doc':
        this.setIconPath('icons8-word-480.png');
        break;
      case 'docx':
        this.setIconPath('icons8-word-480.png');
        break;
      case 'png':
        this.setIconPath('icons8-bilddatei-480.png');
        break;
      case 'jpeg':
      case 'jpg':
        this.setIconPath('icons8-bilddatei-480.png');
        break;
      case 'dmg':
      case 'apk':
      case 'exe':
        this.setIconPath('icons8-codedatei-480.png');
        break;
      case 'xls':
      case 'xlsx':
        this.setIconPath('icons8-xls-480.png');
        break;
      case 'crow':
        this.setIconPath('evaluation_crow.jpg');
        break;
      case 'ps':
        this.setIconPath('icons8-ps.png');
        break;
      case 'psd':
        this.setIconPath('icons8-psd.png');
        break;
      case '7z':
      case '7zip':
      case 'zip':
      case 'tar':
      case 'rar':
      case 'gz':
        this.setIconPath('icons8-archive_folder.png');
        break;
      case 'ppt':
        this.setIconPath('icons8-ppt.png');
        break;
      default:
        this.setIconPath('icons8-questions.png');
        break;
    }
  }
  setIconPath(ext: String) {
    this.icon_path = ext;
  }

  getHumanReadableFileSize(): string {
    const i = Math.floor(Math.log(+this.download.size) / Math.log(1024));
    return (
      +(+this.download.size / Math.pow(1024, i)).toFixed(2) * 1 +
      ' ' +
      ['B', 'kB', 'MB', 'GB', 'TB'][i]
    );
  }
  returnDate(mSeconds: number): string {
    return moment(mSeconds).format(
      this.languageService.getCurrentLanguageString() === 'de'
        ? 'DD.MM.YYYY - HH:mm'
        : 'DD/MM/YYYY - hh:mm A'
    );
  }
  encodeBody(value): string {
    return btoa(encodeURIComponent(value));
  }
}
