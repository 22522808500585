import { Injectable } from '@angular/core';
import { Roles } from '../models/roles';
import { DataModelService } from './datamodel.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  constructor(private dataModel: DataModelService) {}

  isConsultOrHigher(): boolean {
    return (
      this.dataModel.user.role === Roles.CONSULT ||
      this.dataModel.user.role === Roles.ADMIN
    );
  }
}
