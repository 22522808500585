import { Router } from '@angular/router';
import { DataModelService } from './datamodel.service';
import { SegmentImplementationService } from './segment-implementation.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./datamodel.service";
import * as i3 from "./segment-implementation.service";
export class RoutingService {
    constructor(router, datamodel, segmentImplService) {
        this.router = router;
        this.datamodel = datamodel;
        this.segmentImplService = segmentImplService;
    }
    navigateToTermsAndConditions() {
        this.router.navigate(['terms']);
    }
    navigateToDatenschutzerklaerung() {
        this.router.navigate(['privacy']);
    }
    navigateToContact() {
        this.router.navigate(['contact']);
    }
    navigateToImpressum() {
        this.router.navigate(['impressum']);
    }
    navigateToAudit() {
        this.router.navigate(['audit']);
    }
    navigateToTemplates() {
        this.router.navigate(['templates']);
    }
    navigateToEinfuehrung() {
        this.router.navigate(['einfuehrung']);
    }
    navigateToFaq() {
        this.router.navigate(['faq']);
    }
    navigateToGlossar() {
        this.router.navigate(['glossar']);
    }
    navigateToGlossarAndScroll(elementId) {
        this.router.navigate(['glossar']);
        this.datamodel.scrollElement.next(elementId);
    }
    navigateToHistory() {
        this.router.navigate(['history']);
    }
    navigateToHome() {
        this.router.navigate(['home']);
    }
    navigateToLanding() {
        this.router.navigate(['landing']);
    }
    navigateToLogin() {
        this.router.navigate(['login']);
    }
    navigateToWaitForContact() {
        this.router.navigate(['waitforcontact']);
    }
    navigateToManual() {
        this.router.navigate(['manual']);
    }
    navigateToSupport() {
        this.router.navigate(['support']);
    }
    navigateToDocumentManagement() {
        this.router.navigate(['document-management']);
    }
    navigateToChapter(chapter) {
        this.datamodel.selectedChapter = chapter;
        this.segmentImplService.reset();
        this.router.navigate(['audit/' + this.datamodel.chapters.indexOf(chapter)]);
    }
    navigateToAccount() {
        this.router.navigate(['account']);
    }
    navigateToConsulting() {
        this.router.navigate(['consulting']);
    }
    navigateToManageAudit() {
        this.router.navigate(['consulting/manage-audit']);
    }
    navigateToEditChapter(index) {
        this.router.navigate(['consulting/manage-audit/' + index]);
    }
    navigateToManageTemplates() {
        this.router.navigate(['consulting/manage-templates']);
    }
    navigateToManageMasterData() {
        this.router.navigate(['consulting/manage-master-data']);
    }
    navigateToManageUsersAndCompanies() {
        this.router.navigate(['consulting/manage-users-companies']);
    }
    navigateToManageTags() {
        this.router.navigate(['consulting/manage-tags']);
    }
    navigateToManageManual() {
        this.router.navigate(['consulting/manage-manual']);
    }
    navigateToStatistic() {
        this.router.navigate(['consulting/statistic']);
    }
    navigateToReportDesigner() {
        this.router.navigate(['consulting/report-designer']);
    }
    getUrl() {
        return this.router.url;
    }
    isSelectedRoute(route) {
        return route === this.router.url || '/' + route === this.router.url;
    }
}
RoutingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RoutingService_Factory() { return new RoutingService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.DataModelService), i0.ɵɵinject(i3.SegmentImplementationService)); }, token: RoutingService, providedIn: "root" });
