import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { of } from 'rxjs';
import { Roles } from '../../models/roles';
import { DataModelService } from '../../utils/datamodel.service';
import { RoutingService } from '../../utils/routing.service';
import { LanguageService } from './../../utils/language.service';
import * as i0 from "@angular/core";
import * as i1 from "../../utils/datamodel.service";
import * as i2 from "ng-zorro-antd/modal";
import * as i3 from "@angular/router";
import * as i4 from "../../utils/routing.service";
import * as i5 from "../../utils/language.service";
export class RoleGuard {
    constructor(dataModel, modalService, router, routingService, languageService) {
        this.dataModel = dataModel;
        this.modalService = modalService;
        this.router = router;
        this.routingService = routingService;
        this.languageService = languageService;
    }
    canActivate(next, state) {
        const roles = next.data.roles;
        if (next.data.chapter && this.dataModel.chapters[+next.url]) {
            if (this.dataModel.chapters[+next.url].blocked) {
                return of(this.checkRoleAndReturn(roles, true));
            }
            else {
                return of(true);
            }
        }
        return of(this.checkRoleAndReturn(roles, false));
    }
    checkRoleAndReturn(roles, isChapter) {
        const result = this.getRoleCheck(roles);
        if (!result) {
            this.navigateAndMessage(isChapter);
        }
        return result;
    }
    getRoleCheck(roles) {
        if (this.dataModel.user.role === undefined ||
            this.dataModel.user.role === null) {
            return false;
        }
        else if (this.dataModel.user.role === Roles.ADMIN) {
            return true;
        }
        else if (roles.includes(this.dataModel.user.role)) {
            return true;
        }
        else {
            return false;
        }
    }
    navigateAndMessage(chapter) {
        if (this.router.url === '/login' || this.router.url === '/landing') {
            this.routingService.navigateToHome();
        }
        this.modalService.info({
            nzTitle: chapter
                ? this.languageService.translate().notAvailableDemoTitle
                : this.languageService.translate().accessDeniedTitle,
            nzContent: chapter
                ? this.languageService.translate().notAvailableDemoText
                : this.languageService.translate().accessDeniedText
        });
    }
}
RoleGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RoleGuard_Factory() { return new RoleGuard(i0.ɵɵinject(i1.DataModelService), i0.ɵɵinject(i2.NzModalService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.RoutingService), i0.ɵɵinject(i5.LanguageService)); }, token: RoleGuard, providedIn: "root" });
