import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { Fetch } from './../models/fetch';
import { Globals } from './../models/globals';
import { NotificationService } from './notification.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./notification.service";
export class DBService {
    constructor(http, notificationService) {
        this.http = http;
        this.notificationService = notificationService;
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        this.chaptersFetching = true;
        this.templateFilesFetching = true;
        this.languageTextsFetching = true;
        this.epcCompanyFetching = true;
        this.epcNotifyFetching = true;
        this.epcUsersFetching = true;
        this.tagsFetching = true;
        this.auditAnswersFetching = false;
        this.auditFilesFetching = false;
        this.userAndCompany = false;
        this.companiesFetching = false;
        this.usersFetching = false;
        this.noticesFetching = false;
        this.reportsFetching = false;
        this.telemetrieLastLoginFetching = false;
        this.dbUrl = new Globals().dbUrl;
    }
    setSessionToken(token) {
        this.headers = this.headers.set('token', token);
    }
    setUserUUID(userUUID) {
        this.headers = this.headers.set('useruuid', userUUID);
    }
    saveReport(report) {
        return this.http.post(this.dbUrl + '/saveReport', { report }, { headers: this.headers });
    }
    deleteReport(report) {
        return this.http.post(this.dbUrl + '/deleteReport', { report }, { headers: this.headers });
    }
    getStatistics() {
        return this.http.post(this.dbUrl + '/getStatistics', {}, { headers: this.headers });
    }
    getTelemetrie() {
        return this.http.post(this.dbUrl + '/getTelemetrie', {}, { headers: this.headers });
    }
    replacer(key, value) {
        const originalObject = this[key];
        if (originalObject instanceof Map) {
            return {
                dataType: 'Map',
                value: Array.from(originalObject.entries()) // or with spread: value: [...originalObject]
            };
        }
        else {
            return value;
        }
    }
    reviver(key, value) {
        if (typeof value === 'object' && value !== null) {
            if (value.dataType === 'Map') {
                return new Map(value.value);
            }
        }
        return value;
    }
    login(account, pw, socketID, hash) {
        this.userAndCompany = true;
        return this.http
            .post(this.dbUrl + '/login', hash ? { hash: hash } : { account: account, pw: pw, socketID }, { headers: this.headers, observe: 'response' })
            .pipe(delay(1000), catchError(this.handleError('login', Fetch.USER_COMPANY, true)));
    }
    verifyAccount(uuid) {
        return this.http
            .post(this.dbUrl + '/verifyAccount', { uuid }, { headers: this.headers })
            .pipe(catchError(this.handleError('verifyAccount', null, true)));
    }
    exportTags() {
        return this.http.post(this.dbUrl + '/download/excel/tags', {}, { headers: this.headers, responseType: 'blob' });
    }
    downloadDocxDemo(companyUUID) {
        return this.http.post(this.dbUrl + '/download/word/demo', { companyUUID }, { responseType: 'blob' });
    }
    changeNotifyEntryToSeen(notifyEntryUUID) {
        return this.http
            .post(this.dbUrl + '/changeNotifyEntryToSeen', { notifyEntryUUID }, { headers: this.headers, observe: 'response' })
            .pipe(map(res => (res.status === 200 ? true : false)), catchError(this.handleError('createCompany', Fetch.CHAPTERS)));
    }
    createCompany(company, fromUserUUID, forUserUUID, lang) {
        return this.http
            .post(this.dbUrl + '/companies/create', {
            company: company,
            fromUserUUID: fromUserUUID,
            forUserUUID: forUserUUID,
            lang: lang
        }, {
            headers: this.headers,
            observe: 'response'
        })
            .pipe(map(res => (res.status === 200 ? true : false)), catchError(this.handleError('createCompany', Fetch.CHAPTERS)));
    }
    createUser(user, fromUserUUID, forUserUUID) {
        return this.http
            .post(this.dbUrl + '/users/create', {
            user: user,
            fromUserUUID: fromUserUUID,
            forUserUUID: forUserUUID
        }, {
            headers: this.headers,
            observe: 'response'
        })
            .pipe(map(res => (res.status === 200 ? true : false)), catchError(this.handleError('createUser', Fetch.CHAPTERS)));
    }
    updateUser(user, fromUserUUID, forUserUUID) {
        return this.http
            .post(this.dbUrl + '/users/update', {
            user: user,
            fromUserUUID: fromUserUUID,
            forUserUUID: forUserUUID
        }, {
            headers: this.headers,
            observe: 'response'
        })
            .pipe(map(res => (res.status === 200 ? true : false)), catchError(this.handleError('updateUser', Fetch.CHAPTERS)));
    }
    updateCompany(company, fromUserUUID, forUserUUID) {
        return this.http
            .post(this.dbUrl + '/companies/update', {
            company: company,
            fromUserUUID: fromUserUUID,
            forUserUUID: forUserUUID
        }, {
            headers: this.headers,
            observe: 'response'
        })
            .pipe(map(res => (res.status === 200 ? true : false)), catchError(this.handleError('updateCompany', Fetch.CHAPTERS)));
    }
    insertIntoCollection(dataAsJSONStringify, collection) {
        return this.http
            .post(this.dbUrl + '/insertIntoCollection', {
            collection: collection,
            content: dataAsJSONStringify
        }, {
            headers: this.headers,
            observe: 'response'
        })
            .pipe(map(res => (res.status === 200 ? true : false)), catchError(this.handleError('insertIntoCollection', Fetch.CHAPTERS)));
    }
    deleteFromCollection(_ids, collection, companyUUID, isTemplateFile) {
        return this.http
            .post(this.dbUrl + '/deleteFromCollection', {
            _ids: _ids,
            collection: collection,
            companyUUID: companyUUID
        }, {
            headers: isTemplateFile
                ? this.headers.append('istemplate', 'true')
                : this.headers,
            observe: 'response'
        })
            .pipe(map(res => (res.status === 200 ? true : false)), catchError(this.handleError('deleteFromCollection', Fetch.CHAPTERS)));
    }
    updateCollection(_id, dataToBeUpdatedAsJSONStringify, collection) {
        console.log(dataToBeUpdatedAsJSONStringify);
        console.log(collection);
        return this.http
            .post(this.dbUrl + '/updateCollection', {
            _id: _id,
            attributes: dataToBeUpdatedAsJSONStringify,
            collection: collection
        }, {
            headers: this.headers,
            observe: 'response'
        })
            .pipe(map(res => (res.status === 200 ? true : false)), catchError(this.handleError('updateCollection', Fetch.CHAPTERS)));
    }
    getAuditData(userUUID) {
        this.chaptersFetching = true;
        return this.http
            .post(this.dbUrl + '/getAuditData', {
            userUUID: userUUID
        }, { headers: this.headers, observe: 'response' })
            .pipe(map(res => JSON.parse(JSON.stringify(res.body), this.reviver)), catchError(this.handleError('getAuditData', Fetch.CHAPTERS)));
    }
    getLanguageTexts() {
        this.languageTextsFetching = true;
        return this.http
            .post(this.dbUrl + '/getLanguageTexts', {}, { headers: this.headers })
            .pipe(catchError(this.handleError('getLanguageTexts', Fetch.LANGUAGE_TEXTS)));
    }
    getTemplateFiles() {
        this.templateFilesFetching = true;
        return this.http
            .post(this.dbUrl + '/getTemplateFiles', {}, { headers: this.headers })
            .pipe(catchError(this.handleError('getTemplateFiles', Fetch.TEMPLATE_FILES)));
    }
    getTags() {
        this.tagsFetching = true;
        return this.http
            .post(this.dbUrl + '/getTags', {}, { headers: this.headers })
            .pipe(catchError(this.handleError('getTags', Fetch.TAGS)));
    }
    getCompanies() {
        this.companiesFetching = true;
        return this.http
            .post(this.dbUrl + '/companies', {}, { headers: this.headers })
            .pipe(catchError(this.handleError('getCompanies', Fetch.COMPANIES)));
    }
    getEPCCompany() {
        this.epcCompanyFetching = true;
        return this.http
            .post(this.dbUrl + '/companies/epc', {}, { headers: this.headers })
            .pipe(catchError(this.handleError('getEPCCompany', Fetch.EPCCompany)));
    }
    getEPCUsers() {
        this.epcCompanyFetching = true;
        return this.http
            .post(this.dbUrl + '/users/epc', {}, { headers: this.headers })
            .pipe(catchError(this.handleError('getEPCUsers', Fetch.EPCUsers)));
    }
    getAllReports() {
        this.reportsFetching = true;
        return this.http
            .post(this.dbUrl + '/getAllReports', {}, { headers: this.headers })
            .pipe(catchError(this.handleError('getAllReports', Fetch.REPORTS)));
    }
    getReportPreview(reportUUID, type) {
        return this.http
            .post(this.dbUrl + '/getReportPreview', { reportUUID }, { responseType: 'blob', headers: this.headers })
            .pipe(catchError(this.handleError('getReportPreview', null)));
    }
    getUsers() {
        this.epcCompanyFetching = true;
        return this.http
            .post(this.dbUrl + '/users', {}, { headers: this.headers })
            .pipe(catchError(this.handleError('getUsers', Fetch.USERS)));
    }
    getNotifyEntries(userUUID) {
        this.epcNotifyFetching = true;
        return this.http
            .post(this.dbUrl + '/getNotifyEntries', { forUserUUID: userUUID }, { headers: this.headers })
            .pipe(catchError(this.handleError('getNotifyEntries', Fetch.USERS, true)));
    }
    getCountries() {
        this.epcCompanyFetching = true;
        return this.http
            .post(this.dbUrl + '/getCountries', {}, { headers: this.headers })
            .pipe(catchError(this.handleError('getCountries', Fetch.USERS)));
    }
    getAuditAnswers(companyUUID) {
        this.auditAnswersFetching = true;
        return this.http
            .post(this.dbUrl + '/getAuditAnswers', {
            companyUUID: companyUUID
        }, { headers: this.headers, observe: 'response' })
            .pipe(map(res => JSON.parse(JSON.stringify(res.body), this.reviver)), catchError(this.handleError('getAuditAnswers', Fetch.AUDIT_ANSWERS)));
    }
    getAuditFiles(companyUUID) {
        this.auditFilesFetching = true;
        return this.http
            .post(this.dbUrl + '/getAuditFiles', {
            companyUUID: companyUUID
        }, { headers: this.headers })
            .pipe(catchError(this.handleError('getAuditFiles', Fetch.AUDIT_FILES)));
    }
    getNotices(companyUUID) {
        this.noticesFetching = true;
        return this.http
            .post(this.dbUrl + '/getNotices', {
            companyUUID: companyUUID
        }, { headers: this.headers })
            .pipe(catchError(this.handleError('getNotices', Fetch.NOTICES)));
    }
    getLastLogin(companyUUID) {
        this.telemetrieLastLoginFetching = true;
        return this.http
            .post(this.dbUrl + '/getLastLogin', {
            companyUUID: companyUUID
        }, { headers: this.headers })
            .pipe(catchError(this.handleError('getNotices', Fetch.TELEMETRIE_LAST_LOGIN)));
    }
    isIdLinkValid(uuid) {
        return this.http
            .post(this.dbUrl + '/isIdLinkValid', { uuid }, { headers: this.headers })
            .pipe(catchError(this.handleError('isIdLinkValid', null, true)));
    }
    setPassword(userUUID, pw, idlinkUUID) {
        return this.http
            .post(this.dbUrl + '/setPassword', { userUUID, pw, idlinkUUID }, { headers: this.headers })
            .pipe(catchError(this.handleError('setPassword', null, true)));
    }
    isCaptchaValid(text, uuid) {
        return this.http
            .post(this.dbUrl + '/isCaptchaValid', { text, uuid }, { headers: this.headers })
            .pipe(catchError(this.handleError('isCaptchaValid', null, true)));
    }
    getCaptcha() {
        return this.http
            .post(this.dbUrl + '/getCaptcha', {}, { headers: this.headers })
            .pipe(catchError(this.handleError('getCaptcha', null, true)));
    }
    getMasterDataTable(tagList) {
        return this.http
            .post(this.dbUrl + '/getMasterDataTable', { tagList: tagList }, { headers: this.headers })
            .pipe(catchError(this.handleError('getMasterDataTable', null, true)));
    }
    getDataByEnumStatistic(dataEnum, timespan) {
        return this.http
            .post(this.dbUrl + '/getDataStatistic', { dataEnum: dataEnum, timespan: timespan }, { headers: this.headers })
            .pipe(catchError(this.handleError('/getDataStatistic', null, true)));
    }
    sendForgotPasswordEmail(accountname, language) {
        return this.http
            .post(this.dbUrl + '/sendForgotPasswordEmail', { accountname, language }, { headers: this.headers })
            .toPromise();
    }
    sendEmail(email) {
        return this.http
            .post(this.dbUrl + '/sendEmail', { email }, { headers: this.headers })
            .toPromise();
    }
    handleError(operation, fetch, hideNotification) {
        return (err) => {
            if (!hideNotification) {
                this.notificationService.requestResultNotification(false);
            }
            switch (fetch) {
                case Fetch.CHAPTERS:
                    this.chaptersFetching = false;
                    break;
                case Fetch.LANGUAGE_TEXTS:
                    this.languageTextsFetching = false;
                    break;
                case Fetch.USER_COMPANY:
                    this.userAndCompany = false;
                    break;
                case Fetch.TEMPLATE_FILES:
                    this.templateFilesFetching = false;
                    break;
                case Fetch.TAGS:
                    this.tagsFetching = false;
                    break;
                case Fetch.AUDIT_FILES:
                    this.auditFilesFetching = false;
                    break;
                case Fetch.COMPANIES:
                    this.companiesFetching = false;
                    break;
                case Fetch.EPCCompany:
                    this.epcCompanyFetching = false;
                    break;
                case Fetch.EPCUsers:
                    this.epcUsersFetching = false;
                    break;
                case Fetch.USERS:
                    this.usersFetching = false;
                    break;
                case Fetch.AUDIT_ANSWERS:
                    this.auditAnswersFetching = false;
                    break;
                case Fetch.NOTICES:
                    this.noticesFetching = false;
                    break;
                case Fetch.TELEMETRIE_LAST_LOGIN:
                    this.telemetrieLastLoginFetching = false;
                    break;
            }
            // create toast / notification with information
            return throwError(err);
        };
    }
}
DBService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DBService_Factory() { return new DBService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.NotificationService)); }, token: DBService, providedIn: "root" });
