import { v4 } from 'uuid';
import { SegmentEntry } from './segmentEntry';
import { SegmentCondition } from './segmentCondition';

export class Segment {
  constructor(
    public _id: string,
    public title: string,
    public order: number,
    public segmentConditions: SegmentCondition[],
    public segmentEntries: SegmentEntry[]
  ) {}

  static CLEAN_INSTANCE(): Segment {
    return new Segment(v4(), null, null, [], []);
  }

  toJSON() {
    return {
      _id: this._id,
      title: this.title,
      order: this.order,
      segmentConditions: this.segmentConditions.map(
        segmentCondition => segmentCondition._id
      ),
      segmentEntries: this.segmentEntries.map(segmentEntry => segmentEntry._id)
    };
  }
}
