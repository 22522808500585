/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-not-found.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/ng-zorro-antd/button/ng-zorro-antd-button.ngfactory";
import * as i3 from "ng-zorro-antd/core";
import * as i4 from "ng-zorro-antd/button";
import * as i5 from "@angular/cdk/observers";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./page-not-found.component";
import * as i8 from "../../utils/routing.service";
var styles_PageNotFoundComponent = [i0.styles];
var RenderType_PageNotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageNotFoundComponent, data: {} });
export { RenderType_PageNotFoundComponent as RenderType_PageNotFoundComponent };
export function View_PageNotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" No page here - please go\n"])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "button", [["nz-button", ""], ["nzType", "primary"]], [[1, "nz-wave", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateLanding() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NzButtonComponent_0, i2.RenderType_NzButtonComponent)), i1.ɵprd(512, null, i3.NzUpdateHostClassService, i3.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(4, 1818624, null, 1, i4.NzButtonComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2, i5.ContentObserver, i3.NzUpdateHostClassService, i1.NgZone, i3.NzConfigService, [2, i3.NZ_WAVE_GLOBAL_CONFIG], [2, i6.ANIMATION_MODULE_TYPE]], { nzType: [0, "nzType"] }, null), i1.ɵqud(603979776, 1, { listOfIconElement: 1 }), (_l()(), i1.ɵted(-1, 0, [" Landing\n"]))], function (_ck, _v) { var currVal_1 = "primary"; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).nzWave; _ck(_v, 2, 0, currVal_0); }); }
export function View_PageNotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-not-found", [], null, null, null, View_PageNotFoundComponent_0, RenderType_PageNotFoundComponent)), i1.ɵdid(1, 49152, null, 0, i7.PageNotFoundComponent, [i8.RoutingService], null, null)], null, null); }
var PageNotFoundComponentNgFactory = i1.ɵccf("app-page-not-found", i7.PageNotFoundComponent, View_PageNotFoundComponent_Host_0, {}, {}, []);
export { PageNotFoundComponentNgFactory as PageNotFoundComponentNgFactory };
