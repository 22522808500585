import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../guards/auth/auth.service';
import { Company } from '../../../models/company';
import { User } from '../../../models/user';
import { Cookies } from '../../../utils/cookies.service';
import { DataModelService } from '../../../utils/datamodel.service';
import { DBService } from '../../../utils/dbverbindung.service';
import { LanguageService } from '../../../utils/language.service';
import { RoleService } from '../../../utils/role.service';
import { NzModalService } from 'ng-zorro-antd';
import { ForgotPasswordDialogComponent } from './forgotPasswordDialog/forgotPassordDialog.component';
import { HttpResponse, HttpHeaders } from '@angular/common/http';
import { SocketService } from '../../../utils/socket.service';

@Component({
  selector: 'app-login-mask',
  templateUrl: './login-mask.component.html',
  styleUrls: ['./login-mask.component.css']
})
export class LoginMaskComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  validateForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dataModel: DataModelService,
    public dbService: DBService,
    public authService: AuthService,
    private router: Router,
    private socket: SocketService,
    private cookieService: Cookies,
    public languageService: LanguageService,
    private roleService: RoleService,
    private nzModalService: NzModalService
  ) {
    if (this.cookieService.hasUserCookie()) {
      const cookie = this.cookieService.getUserCookie();
      if (cookie && cookie.hash) {
        this.login(cookie.hash);
      } else {
        this.dbService.userAndCompany = false;
      }
    } else {
      Promise.resolve(null).then(
        () => (this.authService.loginInitiated = false)
      );
    }
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      account: [null, [Validators.required]],
      pw: [null, [Validators.required]],
      cookie: [false]
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  submitForm(): void {
    // tslint:disable-next-line: forin
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.valid) {
      this.login();
    }
  }

  login(hash?: string): void {
    this.subscriptions.push(
      this.dbService
        .login(
          hash ? '' : this.validateForm.get('account').value,
          hash ? '' : this.validateForm.get('pw').value,
          this.socket.getSocketID(),
          hash
        )
        .subscribe(
          (usersAndCompany: HttpResponse<any>) => {
            if (usersAndCompany.body['user']) {
              if (this.validateForm.get('cookie').value) {
                this.cookieService.setUserCookie(usersAndCompany.body.hash);
              }
              this.setSessionToken(
                usersAndCompany.body['user'],
                usersAndCompany.body.token
              );
              this.finishUpLogin(
                usersAndCompany.body['user'],
                usersAndCompany.body['otherUsersOfCompany'],
                usersAndCompany.body['company']
              );
            } else {
              this.dbService.userAndCompany = false;
              this.validateForm.get('pw').setErrors({ noMatch: true });
            }
          },
          _ => {
            this.dbService.userAndCompany = false;
            this.validateForm.get('pw').setErrors({ noMatch: true });
          }
        )
    );
  }
  setSessionToken(user: User, token: string) {
    this.dbService.setSessionToken(token);
    this.dataModel.updateSession(user);
  }

  finishUpLogin(
    user: User,
    otherUsersOfCompany: User[],
    company: Company
  ): void {
    this.dbService.setUserUUID(user._id);
    this.dataModel.user = user;
    this.dataModel.selectedUser = user;
    this.dataModel.otherUsersOfCompany = otherUsersOfCompany;
    this.dataModel.company = company;
    this.dataModel.selectedCompany2.next(company);
    if (this.roleService.isConsultOrHigher()) {
      this.subscriptions.push(
        this.dataModel.getConsultingData().subscribe(_ => {
          this.dataModel.selectedUser = this.dataModel.availableUsers.filter(
            u =>
              this.dataModel.selectedCompany2.getValue() &&
              u.companyUUID === this.dataModel.selectedCompany2.getValue()._id
          )[0];
          this.subscriptions.push(
            this.dataModel
              .getAllInitialData(
                this.dataModel.selectedCompany2.getValue()
                  ? this.dataModel.selectedCompany2.getValue()._id
                  : '',
                this.dataModel.user._id
              )
              .subscribe(__ => {
                this.setInitialSettingsAfterLogin();
              })
          );
        })
      );
    } else {
      this.subscriptions.push(
        this.dataModel
          .getAllInitialData(
            this.dataModel.selectedCompany2.getValue()
              ? this.dataModel.selectedCompany2.getValue()._id
              : '',
            this.dataModel.user._id
          )
          .subscribe(_ => {
            this.setInitialSettingsAfterLogin();
          })
      );
    }
  }

  setInitialSettingsAfterLogin(): void {
    this.languageService.setInitialLanguage(this.dataModel.user.lang);
    this.dbService.userAndCompany = false;
    this.authService.isLoggedIn = true;
    this.router.navigate([this.authService.desiredRoute]);
  }

  openTempMessage(): void {
    this.nzModalService.create({
      nzFooter: null,
      nzTitle: this.languageService.translate().forgotPWTitle,
      nzContent: ForgotPasswordDialogComponent,
      nzBodyStyle: { 'white-space': 'pre-line' }
    });
  }
}
