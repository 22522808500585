import { QuestionService } from './../../../utils/question.service';
import { TextResolverService } from './../../../utils/text-resolver.service';
import { Component, Input, OnInit } from '@angular/core';
import { Segment } from '../../../models/segment';
import { DataModelService } from '../../../utils/datamodel.service';
import { LanguageService } from '../../../utils/language.service';

@Component({
  selector: 'app-stammdaten',
  templateUrl: './stammdaten.component.html',
  styleUrls: ['./stammdaten.component.css']
})
export class StammdatenComponent implements OnInit {
  all = [
    ['Get to work', 'Pick up groceries', 'Go home', 'Fall asleep'],
    ['Get up', 'Brush teeth', 'Take a shower', 'Check e-mail', 'Walk dog']
  ];

  @Input()
  shouldBeSlides = false;

  shouldSave = true;
  editMode = false;

  constructor(
    public dataModel: DataModelService,
    private languageService: LanguageService,
    private textresolverService: TextResolverService,
    private question: QuestionService
  ) {}

  ngOnInit() {
    const answers = this.dataModel.auditAnswers;
    console.log(answers);

    let segment = this.dataModel.stammdaten.segments.get('');
    console.log(segment);

    segment.forEach(seg2 => {
      seg2.segmentEntries.forEach(entry => {
        console.log(
          this.textresolverService.getLanguageText(
            this.question.getQuestionAnswer(entry.entry._id)
          )
        );
      });
    });
  }

  toggleEditModeWithSave() {
    this.shouldSave = true;
    this.editMode = !this.editMode;
  }

  toggleEditModeWithoutSave() {
    this.shouldSave = false;
    this.editMode = !this.editMode;
  }

  getSaveEditButtonText(): string {
    return this.editMode
      ? this.languageService.translate().save
      : this.languageService.translate().edit;
  }

  getCancelButtonText(): string {
    return this.languageService.translate().cancel.toUpperCase();
  }

  getCurrentSegment(index: number): Segment {
    return Array.from(this.dataModel.stammdaten.segments.values())[0][index];
  }

  getSegmentArray(): Segment[] {
    return Array.from(this.dataModel.stammdaten.segments.values())[0];
  }

  getAmountSegments(): number {
    return Array.from(this.dataModel.stammdaten.segments.values())[0].length;
  }

  getID(): string {
    return this.dataModel.stammdaten.title;
  }

  isLastSegment(index: number): boolean {
    return (
      index ===
      Array.from(this.dataModel.stammdaten.segments.values())[0].length - 1
    );
  }
}
