<ul
  nz-menu
  *ngIf="datamodel.selectedCompany2.getValue()?._id !== '0000-0000'"
  [nzMode]="'inline'"
  class="border"
  [nzSelectable]="false"
>
  <li
    nz-menu-item
    class="first-li"
    [nzSelected]="this.router.isSelectedRoute('home')"
    (click)="router.navigateToHome()"
  >
    <span title>
      <i nz-icon nzType="home"> </i
      ><app-format-text [text]="lang.translate().home"></app-format-text>
    </span>
  </li>
  <li
    nz-menu-item
    class="first-li"
    [nzSelected]="this.router.isSelectedRoute('manual')"
    (click)="router.navigateToManual()"
  >
    <span title>
      <i nz-icon nzType="read"> </i
      ><app-format-text [text]="lang.translate().manual"></app-format-text>
    </span>
  </li>

  <!-- <li
    nz-menu-item
    [nzSelected]="this.router.isSelectedRoute('manual')"
    (click)="router.navigateToManual()"
  >
    <span title>
      <i nz-icon nzType="read"> </i
      ><app-format-text [text]="lang.translate().manual"></app-format-text>
    </span>
  </li> -->

  <li nz-submenu [nzOpen]="this.datamodel.selectedChapter">
    <span title
      ><i nz-icon nzType="audit"></i
      ><app-format-text [text]="lang.translate().audit"></app-format-text
    ></span>
    <ul *ngIf="!this.dbService.chaptersFetching; else loading">
      <li
        nz-menu-item
        class="auditChapters"
        (click)="this.router.navigateToChapter(chapter)"
        [nzSelected]="this.isSelectedChapter(chapter)"
        *ngFor="let chapter of this.datamodel.chapters"
      >
        <app-format-text
          [text]="this.textResolverService.getLanguageText(chapter.title)"
        ></app-format-text>
      </li>
    </ul>
    <ng-template #loading>
      <ul>
        <nz-spin></nz-spin>
      </ul>
    </ng-template>
  </li>
  <li
    nz-menu-item
    [nzSelected]="this.router.isSelectedRoute('document-management')"
    (click)="router.navigateToDocumentManagement()"
  >
    <span title>
      <i nz-icon nzType="file-done"> </i
      ><app-format-text
        [text]="lang.translate().documentManagement"
      ></app-format-text>
    </span>
  </li>
  <li
    nz-menu-item
    [nzSelected]="this.router.isSelectedRoute('templates')"
    (click)="router.navigateToTemplates()"
  >
    <span title>
      <i nz-icon nzType="file-text"> </i
      ><app-format-text [text]="lang.translate().templates"></app-format-text>
    </span>
  </li>

  <li class="first-li" (click)="openExternalBlog()" nz-menu-item>
    <span title>
      <i nz-icon nzType="global"> </i
      ><app-format-text [text]="lang.translate().blogLink"></app-format-text>
    </span>
  </li>
</ul>
