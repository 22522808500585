import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CookieApplication } from '../models/cookieApplication';
import { CookieUser } from '../models/cookieUser';

@Injectable({
  providedIn: 'root'
})
export class Cookies {
  private cookieNameUser = 'eprivacyaudit.eu';
  private cookieNameApplication = 'eprivacyaudit.eu_application';

  constructor(private cookieService: CookieService) {}

  hasAnyCookie(): boolean {
    return (
      this.cookieService.check(this.cookieNameUser) ||
      this.cookieService.check(this.cookieNameApplication)
    );
  }

  hasUserCookie(): boolean {
    return this.cookieService.check(this.cookieNameUser);
  }

  setUserCookie(hash: string): void {
    const cookie = this.getUserCookie();
    cookie.hash = hash;
    this.cookieService.set(this.cookieNameUser, JSON.stringify(cookie), 0, '/');
  }

  setLangInApplicationCookie(lang: string): void {
    const cookie = this.getApplicationCookie();
    cookie.lang = lang;
    this.cookieService.set(
      this.cookieNameApplication,
      JSON.stringify(cookie),
      0,
      '/'
    );
  }

  getUserCookie(): CookieUser {
    const cookie = this.cookieService.get(this.cookieNameUser);
    const cookieUser = cookie ? JSON.parse(cookie) : new CookieUser();
    return cookieUser;
  }

  getApplicationCookie(): CookieApplication {
    const cookie = this.cookieService.get(this.cookieNameApplication);
    const cookieApplication = cookie
      ? JSON.parse(cookie)
      : new CookieApplication();
    return cookieApplication;
  }

  removeCookies(): void {
    this.cookieService.delete(this.cookieNameUser);
  }
}
