<div class="topWidth">
  <app-landinglogo></app-landinglogo>
  <app-landingtoprightrow></app-landingtoprightrow>
</div>

<nz-result
  *ngIf="success"
  nzStatus="success"
  [nzTitle]="lang.translate().processVerifySuccessed"
  [nzSubTitle]="lang.translate().establishVerifyConnectionSuccessed"
>
  <div nz-result-extra>
    <button nz-button (click)="router.navigateToLanding()">{{ lang.translate().home }}</button>
    <button nz-button nzType="primary">{{ lang.translate().next }}</button>
  </div>
</nz-result>

<nz-result
  *ngIf="failed"
  nzStatus="error"
  [nzTitle]="lang.translate().processVerifyFailed"
  [nzSubTitle]="lang.translate().establishVerifyConnectionFailed"
>
  <div nz-result-extra>
    <button nz-button (click)="router.navigateToLanding()">{{ lang.translate().home }}</button>
    <button (click)="onNavigate()" nz-button nzType="primary">{{ lang.translate().contact }}</button>
  </div>
</nz-result>

<nz-result
  *ngIf="!success && !failed"
  [nzIcon]="loadingIcon"
  [nzTitle]="lang.translate().processVerify"
  [nzSubTitle]="lang.translate().establishVerifyConnection"
>
  <ng-template #loadingIcon> <i nz-icon [nzType]="'loading'"></i> </ng-template>
</nz-result>

<div id="footernavi">
  <app-landingfooter></app-landingfooter>
</div>
