import { AccountComponent } from './views/application/account/account.component';
import { ContactComponent } from './views/application/contact/contact.component';
import { VerifyEmailComponent } from './views/application/verify-email/verify-email.component';
import { Routes } from '@angular/router';
import { Error401Component } from './components/error/error401.component';
import { LoginGuard } from './guards/auth/login.guard';
import { RoleGuard } from './guards/role/role.guard';
import { Roles } from './models/roles';
import { AgbComponentComponent } from './views/application/agb/agb.component';
import { DatenschutzerklaerungComponent } from './views/application/datenschutzerklaerung/datenschutzerklaerung.component';
import { EinfuehrungComponent } from './views/application/einfuehrung/einfuehrung.component';
import { FaqComponent } from './views/application/faq/faq.component';
import { GlossarComponent } from './views/application/glossar/glossar.component';
import { HistoryComponent } from './views/application/history/history.component';
import { ImpressumComponent } from './views/application/impressum/impressum.component';
import { ManualComponent } from './views/application/manual/manual.component';
import { SupportComponent } from './views/application/support/support.component';
import { LandingComponent } from './views/landing/landing.component';
import { LoginComponent } from './views/login/login.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { WaitForContactComponent } from './views/application/waitforcontact/waitforcontact.component';
import { EmailDesignerComponent } from './views/email-designer/email-designer.component';
import { IdLinkComponent } from './views/idlink/idlink.component';
const ɵ0 = { roles: [Roles.CONSULT] };
const routes = [
    { path: '', redirectTo: '/landing', pathMatch: 'full' },
    { path: 'landing', component: LandingComponent },
    { path: 'login', component: LoginComponent },
    { path: 'error401', component: Error401Component, canActivate: [LoginGuard] },
    {
        path: 'templates',
        loadChildren: './views/application/documents-template/documents-template.module#DocumentsTemplateModule',
        canActivate: [LoginGuard]
    },
    { path: 'faq', component: FaqComponent, canActivate: [LoginGuard] },
    { path: 'glossar', component: GlossarComponent, canActivate: [LoginGuard] },
    { path: 'history', component: HistoryComponent, canActivate: [LoginGuard] },
    { path: 'account', component: AccountComponent, canActivate: [LoginGuard] },
    { path: 'manual', component: ManualComponent, canActivate: [LoginGuard] },
    { path: 'support', component: SupportComponent, canActivate: [LoginGuard] },
    { path: 'impressum', component: ImpressumComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'terms', component: AgbComponentComponent },
    { path: 'privacy', component: DatenschutzerklaerungComponent },
    { path: 'verify', component: VerifyEmailComponent },
    { path: 'waitforcontact', component: WaitForContactComponent },
    { path: 'designer', component: EmailDesignerComponent },
    { path: 'idlink/:id', component: IdLinkComponent },
    {
        path: 'einfuehrung',
        component: EinfuehrungComponent,
        canActivate: [LoginGuard]
    },
    {
        path: 'home',
        loadChildren: './views/application/home/home.module#HomeModule',
        canActivate: [LoginGuard]
    },
    {
        path: 'audit',
        loadChildren: './views/application/audit/audit.module#AuditModule',
        canActivate: [LoginGuard]
    },
    {
        path: 'consulting',
        loadChildren: './views/application/consulting/consulting.module#ConsultingModule',
        canActivate: [LoginGuard, RoleGuard],
        data: ɵ0
    },
    {
        path: 'document-management',
        loadChildren: './views/application/documents/documents.module#DocumentsModule',
        canActivate: [LoginGuard]
    },
    { path: '**', component: PageNotFoundComponent }
];
export class AppRoutingModule {
}
export { ɵ0 };
