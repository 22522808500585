import { Component } from '@angular/core';
import { LanguageService } from '../../../utils/language.service';
import { DataModelService } from '../../../utils/datamodel.service';
import { RoutingService } from '../../../utils/routing.service';

@Component({
  selector: 'app-landingfooter',
  templateUrl: './landingfooter.component.html',
  styleUrls: ['./landingfooter.component.css']
})
export class LandingFooterComponent {
  constructor(public lang: LanguageService, public datamodel: DataModelService, private router: RoutingService) {}
  impressum(): void {
    this.router.navigateToImpressum();
  }
  privacy(): void {
    this.router.navigateToDatenschutzerklaerung();
  }
  terms(): void {
    this.router.navigateToTermsAndConditions();
  }
}
