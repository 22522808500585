/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./burgermenu.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/ng-zorro-antd/button/ng-zorro-antd-button.ngfactory";
import * as i3 from "ng-zorro-antd/core";
import * as i4 from "ng-zorro-antd/button";
import * as i5 from "@angular/cdk/observers";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "ng-zorro-antd/icon";
import * as i8 from "@angular/cdk/platform";
import * as i9 from "./burgermenu.component";
import * as i10 from "../../../utils/language.service";
import * as i11 from "../../../utils/datamodel.service";
var styles_BurgermenuComponent = [i0.styles];
var RenderType_BurgermenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BurgermenuComponent, data: { "animation": [{ type: 7, name: "rotatedState", definitions: [{ type: 0, name: "default", styles: { type: 6, styles: { transform: "rotate(-180deg) scale(1.4)" }, offset: null }, options: undefined }, { type: 0, name: "rotated", styles: { type: 6, styles: { transform: "rotate(0) scale(1.4)" }, offset: null }, options: undefined }, { type: 1, expr: "rotated => default", animation: { type: 4, styles: null, timings: "500ms ease-out" }, options: null }, { type: 1, expr: "default => rotated", animation: { type: 4, styles: null, timings: "500ms ease-out" }, options: null }], options: {} }] } });
export { RenderType_BurgermenuComponent as RenderType_BurgermenuComponent };
export function View_BurgermenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "buttondiv"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "button", [["class", "button"], ["nz-button", ""]], [[24, "@rotatedState", 0], [1, "nz-wave", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.triggerSider() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NzButtonComponent_0, i2.RenderType_NzButtonComponent)), i1.ɵprd(512, null, i3.NzUpdateHostClassService, i3.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(3, 1818624, null, 1, i4.NzButtonComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2, i5.ContentObserver, i3.NzUpdateHostClassService, i1.NgZone, i3.NzConfigService, [2, i3.NZ_WAVE_GLOBAL_CONFIG], [2, i6.ANIMATION_MODULE_TYPE]], { nzSize: [0, "nzSize"] }, null), i1.ɵqud(603979776, 1, { listOfIconElement: 1 }), (_l()(), i1.ɵeld(5, 0, [[1, 0]], 0, 1, "i", [["nz-icon", ""], ["nzType", "menu-unfold"]], null, null, null, null, null)), i1.ɵdid(6, 2834432, null, 0, i7.NzIconDirective, [i7.NzIconService, i1.ElementRef, i1.Renderer2, i8.Platform], { nzType: [0, "nzType"] }, null)], function (_ck, _v) { var currVal_2 = "large"; _ck(_v, 3, 0, currVal_2); var currVal_3 = "menu-unfold"; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state; var currVal_1 = i1.ɵnov(_v, 3).nzWave; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_BurgermenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-burgermenu", [], null, null, null, View_BurgermenuComponent_0, RenderType_BurgermenuComponent)), i1.ɵdid(1, 49152, null, 0, i9.BurgermenuComponent, [i10.LanguageService, i11.DataModelService], null, null)], null, null); }
var BurgermenuComponentNgFactory = i1.ɵccf("app-burgermenu", i9.BurgermenuComponent, View_BurgermenuComponent_Host_0, {}, {}, []);
export { BurgermenuComponentNgFactory as BurgermenuComponentNgFactory };
