/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./account.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../components/shared/stammdaten/stammdaten.component.ngfactory";
import * as i3 from "../../../components/shared/stammdaten/stammdaten.component";
import * as i4 from "../../../utils/datamodel.service";
import * as i5 from "../../../utils/language.service";
import * as i6 from "../../../utils/text-resolver.service";
import * as i7 from "../../../utils/question.service";
import * as i8 from "./account.component";
var styles_AccountComponent = [i0.styles];
var RenderType_AccountComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccountComponent, data: {} });
export { RenderType_AccountComponent as RenderType_AccountComponent };
export function View_AccountComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-stammdaten", [], null, null, null, i2.View_StammdatenComponent_0, i2.RenderType_StammdatenComponent)), i1.ɵdid(2, 114688, null, 0, i3.StammdatenComponent, [i4.DataModelService, i5.LanguageService, i6.TextResolverService, i7.QuestionService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_AccountComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-account", [], null, null, null, View_AccountComponent_0, RenderType_AccountComponent)), i1.ɵdid(1, 49152, null, 0, i8.AccountComponent, [i5.LanguageService], null, null)], null, null); }
var AccountComponentNgFactory = i1.ɵccf("app-account", i8.AccountComponent, View_AccountComponent_Host_0, {}, {}, []);
export { AccountComponentNgFactory as AccountComponentNgFactory };
