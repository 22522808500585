import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataModelService } from '../../../utils/datamodel.service';
import { LanguageService } from '../../../utils/language.service';
import { ManualTabObject } from '../../../models/manualTabObject';
import { TextResolverService } from '../../../utils/text-resolver.service';
import { Subscription } from 'rxjs';
import { v4 } from 'uuid';

@Component({
  selector: 'app-manual',
  templateUrl: './manual.component.html',
  styleUrls: ['./manual.component.css']
})
export class ManualComponent implements OnDestroy {
  intro1 = 'Titel';
  intro2 = 'Titel2';

  tabs: ManualTabObject[] = [];

  subscription: Subscription;

  constructor(
    public lang: LanguageService,
    private datamodel: DataModelService,
    private textResolverService: TextResolverService
  ) {
    this.subscription = this.lang.language.subscribe(_ => this.resolveText());
  }
  resolveText(): void {
    const content = JSON.parse(
      this.textResolverService.getLanguageText(this.datamodel.manualContentUUID)
    );

    if (content['tabs']) {
      this.intro1 = content['intro1'];
      this.intro2 = content['intro2'];

      this.tabs = content['tabs'];
    } else {
      this.intro1 = this.lang.translate().noManualAvailable;
      this.intro2 = '';
      this.tabs = [];
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
