import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Chapter } from '../models/chapter';
import { DataModelService } from './datamodel.service';
import { SegmentImplementationService } from './segment-implementation.service';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {
  constructor(
    private router: Router,
    private datamodel: DataModelService,
    private segmentImplService: SegmentImplementationService
  ) {}

  navigateToTermsAndConditions(): void {
    this.router.navigate(['terms']);
  }
  navigateToDatenschutzerklaerung(): void {
    this.router.navigate(['privacy']);
  }
  navigateToContact(): void {
    this.router.navigate(['contact']);
  }
  navigateToImpressum(): void {
    this.router.navigate(['impressum']);
  }
  navigateToAudit(): void {
    this.router.navigate(['audit']);
  }
  navigateToTemplates(): void {
    this.router.navigate(['templates']);
  }
  navigateToEinfuehrung(): void {
    this.router.navigate(['einfuehrung']);
  }
  navigateToFaq(): void {
    this.router.navigate(['faq']);
  }
  navigateToGlossar(): void {
    this.router.navigate(['glossar']);
  }
  navigateToGlossarAndScroll(elementId: string): void {
    this.router.navigate(['glossar']);
    this.datamodel.scrollElement.next(elementId);
  }
  navigateToHistory(): void {
    this.router.navigate(['history']);
  }
  navigateToHome(): void {
    this.router.navigate(['home']);
  }
  navigateToLanding(): void {
    this.router.navigate(['landing']);
  }
  navigateToLogin(): void {
    this.router.navigate(['login']);
  }
  navigateToWaitForContact(): void {
    this.router.navigate(['waitforcontact']);
  }
  navigateToManual(): void {
    this.router.navigate(['manual']);
  }
  navigateToSupport(): void {
    this.router.navigate(['support']);
  }
  navigateToDocumentManagement(): void {
    this.router.navigate(['document-management']);
  }
  navigateToChapter(chapter: Chapter): void {
    this.datamodel.selectedChapter = chapter;
    this.segmentImplService.reset();
    this.router.navigate(['audit/' + this.datamodel.chapters.indexOf(chapter)]);
  }
  navigateToAccount(): void {
    this.router.navigate(['account']);
  }
  navigateToConsulting(): void {
    this.router.navigate(['consulting']);
  }
  navigateToManageAudit(): void {
    this.router.navigate(['consulting/manage-audit']);
  }
  navigateToEditChapter(index: number): void {
    this.router.navigate(['consulting/manage-audit/' + index]);
  }

  navigateToManageTemplates(): void {
    this.router.navigate(['consulting/manage-templates']);
  }

  navigateToManageMasterData(): void {
    this.router.navigate(['consulting/manage-master-data']);
  }

  navigateToManageUsersAndCompanies(): void {
    this.router.navigate(['consulting/manage-users-companies']);
  }

  navigateToManageTags(): void {
    this.router.navigate(['consulting/manage-tags']);
  }

  navigateToManageManual(): void {
    this.router.navigate(['consulting/manage-manual']);
  }

  navigateToStatistic(): void {
    this.router.navigate(['consulting/statistic']);
  }

  navigateToReportDesigner(): void {
    this.router.navigate(['consulting/report-designer']);
  }

  getUrl(): string {
    return this.router.url;
  }

  isSelectedRoute(route: string): boolean {
    return route === this.router.url || '/' + route === this.router.url;
  }
}
