/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./slider.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./navigation/navigation.component.ngfactory";
import * as i3 from "./navigation/navigation.component";
import * as i4 from "ng-zorro-antd/modal";
import * as i5 from "../../../utils/language.service";
import * as i6 from "./slider.component";
var styles_SliderComponent = [i0.styles];
var RenderType_SliderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SliderComponent, data: { "animation": [{ type: 7, name: "nextSegment", definitions: [{ type: 0, name: "display", styles: { type: 6, styles: { opacity: "1.0", right: "0em", left: "0em", height: "*" }, offset: null }, options: undefined }, { type: 0, name: "positionLeft", styles: { type: 6, styles: { opacity: "0.0", right: "auto", left: "-2em", height: "*" }, offset: null }, options: undefined }, { type: 0, name: "positionRight", styles: { type: 6, styles: { opacity: "0.0", right: "-2em", left: "auto", height: "*" }, offset: null }, options: undefined }, { type: 1, expr: "display => positionRight", animation: { type: 4, styles: null, timings: "400ms ease-in-out" }, options: null }, { type: 1, expr: "display => positionLeft", animation: { type: 4, styles: null, timings: "400ms ease-in-out" }, options: null }, { type: 1, expr: "positionLeft => positionRight", animation: { type: 4, styles: null, timings: "0ms ease-in-out" }, options: null }, { type: 1, expr: "positionRight => positionLeft", animation: { type: 4, styles: null, timings: "0ms ease-in-out" }, options: null }, { type: 1, expr: "positionLeft => display", animation: { type: 4, styles: null, timings: "400ms ease-in-out" }, options: null }, { type: 1, expr: "positionRight => display", animation: { type: 4, styles: null, timings: "400ms ease-in-out" }, options: null }], options: {} }] } });
export { RenderType_SliderComponent as RenderType_SliderComponent };
export function View_SliderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "content"]], [[24, "@nextSegment", 0]], null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-navigation", [], null, [[null, "nextSegmentEmitter"], [null, "prevClicked"], [null, "nextClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("nextSegmentEmitter" === en)) {
        var pd_0 = (_co.evalSegment($event) !== false);
        ad = (pd_0 && ad);
    } if (("prevClicked" === en)) {
        var pd_1 = (_co.prevClicked.emit() !== false);
        ad = (pd_1 && ad);
    } if (("nextClicked" === en)) {
        var pd_2 = (_co.nextClicked.emit() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_NavigationComponent_0, i2.RenderType_NavigationComponent)), i1.ɵdid(3, 49152, null, 0, i3.NavigationComponent, [i4.NzModalService, i5.LanguageService], { amountSegments: [0, "amountSegments"], enableJumper: [1, "enableJumper"], showIndicators: [2, "showIndicators"], demo: [3, "demo"], nextReady: [4, "nextReady"], currentSegment: [5, "currentSegment"] }, { nextSegmentEmitter: "nextSegmentEmitter", nextClicked: "nextClicked", prevClicked: "prevClicked" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.amountSegments; var currVal_2 = _co.enableJumper; var currVal_3 = _co.showIndicators; var currVal_4 = _co.demo; var currVal_5 = _co.nextReady; var currVal_6 = _co._currentSegment; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nextSegmentStatus; _ck(_v, 0, 0, currVal_0); }); }
export function View_SliderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-slider", [], null, null, null, View_SliderComponent_0, RenderType_SliderComponent)), i1.ɵdid(1, 49152, null, 0, i6.SliderComponent, [i5.LanguageService], null, null)], null, null); }
var SliderComponentNgFactory = i1.ɵccf("app-slider", i6.SliderComponent, View_SliderComponent_Host_0, { amountSegments: "amountSegments", showIndicators: "showIndicators", enableJumper: "enableJumper", idToScroll: "idToScroll", yOffsetForScroll: "yOffsetForScroll", demo: "demo", nextReady: "nextReady", currentSegment: "currentSegment" }, { currentSegmentEmitter: "currentSegmentEmitter", nextClicked: "nextClicked", prevClicked: "prevClicked" }, ["*"]);
export { SliderComponentNgFactory as SliderComponentNgFactory };
