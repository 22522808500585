import { QuestionService } from './question.service';
import { SegmentEntryType } from '../models/segmentEntryType';
import * as i0 from "@angular/core";
import * as i1 from "./question.service";
export class SegmentImplementationService {
    constructor(questionService) {
        this.questionService = questionService;
        this.currentSegment = 0;
        this.mapQuestionsAnswered = new Map();
        this.previousSegments = [];
    }
    reset() {
        this.currentSegment = 0;
        this.mapQuestionsAnswered.clear();
        this.previousSegments = [];
    }
    checkSegmentState(segment, index) {
        if (this.segments && this.segments.length > 0) {
            let allAnswered = true;
            const currSegment = segment
                ? segment
                : this.segments[this.currentSegment];
            currSegment.segmentEntries.forEach(sE => {
                if (sE.type === SegmentEntryType.QUESTION &&
                    this.questionService.getQuestionAnswer(sE.entry._id).length === 0) {
                    allAnswered = false;
                }
            });
            this.mapQuestionsAnswered.set(index ? index : this.currentSegment, allAnswered);
        }
    }
    initMapQuestionAnswered() {
        this.segments.forEach((s, index) => this.checkSegmentState(s, index));
    }
    handlePrevState() {
        this.currentSegment = this.previousSegments.pop();
        this.checkSegmentState();
    }
    setNextSegmentState() {
        // next segment without conditions as fallback
        let nextSegment = this.segments.indexOf(this.segments.find((s, index) => index > this.currentSegment &&
            (!s.segmentConditions || s.segmentConditions.length === 0)));
        console.log(nextSegment);
        // next segment which conditions match users answers
        this.segments.forEach((segment, index) => {
            if (segment.segmentConditions) {
                segment.segmentConditions
                    .filter(sC => sC.referencedSegmentUUID ===
                    this.segments[this.currentSegment]._id)
                    .forEach(sC => {
                    let match = true;
                    sC.questionStates.forEach((answer, questionUUID) => {
                        if (this.questionService.getQuestionAnswer(questionUUID) !== answer) {
                            console.log('no match found');
                            match = false;
                        }
                    });
                    if (match) {
                        console.log(index);
                        nextSegment = index;
                    }
                });
            }
        });
        this.previousSegments.push(this.currentSegment);
        console.log(nextSegment);
        this.currentSegment = nextSegment < 0 ? this.segments.length - 1 : nextSegment;
    }
}
SegmentImplementationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SegmentImplementationService_Factory() { return new SegmentImplementationService(i0.ɵɵinject(i1.QuestionService)); }, token: SegmentImplementationService, providedIn: "root" });
