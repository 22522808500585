import { CaptchaComponent } from './views/application/contact/captcha/captcha.component';
import { AccountComponent } from './views/application/account/account.component';
import { ContactComponent } from './views/application/contact/contact.component';
import { VerifyEmailComponent } from './views/application/verify-email/verify-email.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // <-- NgModel lives here
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { de_DE, NgZorroAntdModule, NZ_I18N } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Error401Component } from './components/error/error401.component';
import { GlossarEntryComponent } from './components/glossarentry/glossarentry.component';
import { LandingCarouselComponent } from './components/landingpage/landingcarousel/landingcarousel.component';
import { LandingFooterComponent } from './components/landingpage/landingfooter/landingfooter.component';
import { LandingLoginComponent } from './components/landingpage/landinglogin/landinglogin.component';
import { LandingLogoComponent } from './components/landingpage/landinglogo/landinglogo.component';
import { LandingTopRightRowComponent } from './components/landingpage/landingtoprightrow/landingtoprightrow.component';
import { LoggedinFooterComponent } from './components/loggedinfooter/loggedinfooter.component';
import { SharedModule } from './components/shared/shared.module';
import { LinkcontainerComponent } from './components/sidebar/linkcontainer/linkcontainer.component';
import { NavmenuComponent } from './components/sidebar/navmenu/navmenu.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { BurgermenuComponent } from './components/topbar/burgermenu/burgermenu.component';
import { DropdownComponent } from './components/topbar/dropdown/dropdown.component';
import { FirmabuttonComponent } from './components/topbar/firmabutton/firmabutton.component';
import { HeaderbarComponent } from './components/topbar/headerbar.component';
import { LogoComponent } from './components/topbar/logo/logo.component';
import { NotifybuttonComponent } from './components/topbar/notifybutton/notifybutton.component';
import { SuchleisteComponent } from './components/topbar/suchleiste/suchleiste.component';
import { RoleDirective } from './directives/structural/role.directive';
import { MaterialModule } from './material.module';
import { Cookies } from './utils/cookies.service';
import { DataModelService } from './utils/datamodel.service';
import { DBService } from './utils/dbverbindung.service';
import { Encryption } from './utils/encryption.service';
import { RoleService } from './utils/role.service';
import { ScreenSizeService } from './utils/screenSize.service';
import { SocketService } from './utils/socket.service';
import { AgbComponentComponent } from './views/application/agb/agb.component';
import { ApplicationComponent } from './views/application/application.component';
import { DatenschutzerklaerungComponent } from './views/application/datenschutzerklaerung/datenschutzerklaerung.component';
import { DownloadsComponent } from './views/application/downloads/downloads.component';
import { EinfuehrungComponent } from './views/application/einfuehrung/einfuehrung.component';
import { FaqComponent } from './views/application/faq/faq.component';
import { GlossarComponent } from './views/application/glossar/glossar.component';
import { HistoryComponent } from './views/application/history/history.component';
import { ImpressumComponent } from './views/application/impressum/impressum.component';
import { ManualComponent } from './views/application/manual/manual.component';
import { SupportComponent } from './views/application/support/support.component';
import { TemplatesComponent } from './views/application/templates/templates.component';
import { UploadsComponent } from './views/application/uploads/uploads.component';
import { LandingComponent } from './views/landing/landing.component';
import { LoginComponent } from './views/login/login.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { NotifyEntryComponent } from './components/topbar/notifybutton/notifycontent/notifyentry.component';
import { FileService } from './utils/file.service';
import { SegmentImplementationService } from './utils/segment-implementation.service';
import { NotificationService } from './utils/notification.service';
import { SideComponent } from './components/chapter/side/side.component';
import { WaitForContactComponent } from './views/application/waitforcontact/waitforcontact.component';
import { EmailEditorModule } from 'angular-email-editor';
import { EmailDesignerComponent } from './views/email-designer/email-designer.component';
import { FocusDirective } from './directives/attribute/focus.directive';
import { IdLinkComponent } from './views/idlink/idlink.component';
import { SetPasswordComponent } from './components/setpassword/setpassword.component';
import { ForgotPasswordDialogComponent } from './components/shared/login-mask/forgotPasswordDialog/forgotPassordDialog.component';
import { EditorModule } from 'primeng/editor';
import { DialogCustomerReportConditionComponent } from './views/application/consulting/dashboard/report_designer/report_designer_inner/dialog-dialogcustomerreportentryeditcomponent/dialog-customerreportcondition/dialog-customerreportcondition.component';

registerLocaleData(localeDe);

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    DragDropModule,
    SharedModule,
    EmailEditorModule,
    EditorModule
  ],
  declarations: [
    AppComponent,
    Error401Component,
    LandingComponent,
    HeaderbarComponent,
    SuchleisteComponent,
    SuchleisteComponent,
    BurgermenuComponent,
    FirmabuttonComponent,
    DropdownComponent,
    LogoComponent,
    LandingLoginComponent,
    UploadsComponent,
    GlossarComponent,
    EinfuehrungComponent,
    NotifybuttonComponent,
    NotifyEntryComponent,
    NavmenuComponent,
    SidebarComponent,
    DownloadsComponent,
    HistoryComponent,
    AccountComponent,
    ManualComponent,
    FaqComponent,
    SupportComponent,
    ImpressumComponent,
    ContactComponent,
    WaitForContactComponent,
    VerifyEmailComponent,
    AgbComponentComponent,
    DatenschutzerklaerungComponent,
    GlossarEntryComponent,
    LinkcontainerComponent,
    LandingCarouselComponent,
    LandingLogoComponent,
    LandingTopRightRowComponent,
    LandingFooterComponent,
    LoggedinFooterComponent,
    LoginComponent,
    ApplicationComponent,
    PageNotFoundComponent,
    RoleDirective,
    TemplatesComponent,
    EmailDesignerComponent,
    CaptchaComponent,
    FocusDirective,
    IdLinkComponent,
    SetPasswordComponent,
    ForgotPasswordDialogComponent,
    DialogCustomerReportConditionComponent
  ],
  entryComponents: [
    ForgotPasswordDialogComponent,
    DialogCustomerReportConditionComponent
  ],
  providers: [
    FileService,
    DataModelService,
    SocketService,
    DBService,
    Encryption,
    CookieService,
    ScreenSizeService,
    Cookies,
    RoleService,
    SegmentImplementationService,
    NotificationService,
    { provide: NZ_I18N, useValue: de_DE }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
