import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DBCollections } from '../models/dbCollections';
import { LanguageInterface } from '../models/language.interface';
import { LanguageEnglisch } from './../models/language.englisch';
import { Cookies } from './cookies.service';
import { DataModelService } from './datamodel.service';
import { LanguageDeutsch } from '../models/language.deutsch';

@Injectable({
  // damit der service ein singleton ist
  providedIn: 'root'
})
export class LanguageService {
  public language: BehaviorSubject<LanguageInterface> = new BehaviorSubject<
    LanguageInterface
  >(null);

  private engLanguage: LanguageInterface = new LanguageEnglisch();
  private deuLanguage: LanguageInterface = new LanguageDeutsch();

  constructor(private cookieService: Cookies) {
    this.setInitialLanguage();
  }

  translate(): LanguageInterface {
    return this.language.value;
  }

  setInitialLanguage(lang?: string): void {
    let userLang = navigator.language;
    if (lang) {
      userLang = lang;
    } else if (this.cookieService.getApplicationCookie().lang) {
      userLang = this.cookieService.getApplicationCookie().lang;
    }
    if (userLang.includes('de')) {
      this.language.next(this.deuLanguage);
    } else if (userLang.includes('en')) {
      this.language.next(this.engLanguage);
    } else {
      this.language.next(this.engLanguage);
    }
  }

  getCurrentLanguageString(): string {
    switch (true) {
      case this.language.value instanceof LanguageDeutsch:
        return 'de';
      case this.language.value instanceof LanguageEnglisch:
        return 'en';
      default:
        return 'en';
    }
  }

  getAvailableLanguages(): string[] {
    return ['de', 'en'];
  }
}
