<nz-tabset class="dialogSize" [nzSelectedIndex]="tabIndex">
  <nz-tab>
    <p>{{ lang.translate().enterUserName }}</p>
    <input
      type="text"
      nz-input
      [placeholder]="lang.translate().username"
      class="margin"
      [(ngModel)]="account"
    />

    <button nz-button (click)="submitForm()">
      {{ lang.translate().next }}
    </button>
  </nz-tab>

  <nz-tab>
    <div class="centerItems">
      <h1>{{ lang.translate().requestNewPassword }}</h1>
      <p>{{ lang.translate().requestNewPasswordSubtext }}</p>
    </div>
  </nz-tab>
</nz-tabset>
