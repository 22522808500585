import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { NzModalService } from 'ng-zorro-antd';
import { LanguageService } from '../../../../utils/language.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent {
  @Input()
  amountSegments: number;
  @Input()
  enableJumper: boolean;
  @Input()
  showIndicators = true;
  @Input()
  demo: boolean;
  @Input()
  nextReady: boolean;

  @ViewChild('segmentInput', { static: false })
  inputRef: ElementRef;

  @Output()
  nextSegmentEmitter = new EventEmitter<number>();
  @Output()
  nextClicked = new EventEmitter<void>();
  @Output()
  prevClicked = new EventEmitter<void>();

  initialSegment: number;
  currentInputSegment: number;

  constructor(
    private modalService: NzModalService,
    public languageService: LanguageService
  ) {}

  @Input()
  set currentSegment(currentSegment: number) {
    this.initialSegment = this.currentInputSegment = currentSegment + 1;
  }

  isNextDisabled(): boolean {
    return this.initialSegment === this.amountSegments || !this.nextReady;
  }

  isCurrentSegment(index: number): boolean {
    return this.currentInputSegment === index + 1;
  }

  getMaxSegmentArray(): number[] {
    const numbers = [];
    for (let i = 0; i < this.amountSegments; i++) {
      numbers.push(i);
    }
    return numbers;
  }

  prevSegment() {
    this.emitSegment(this.currentInputSegment - 2);
    this.prevClicked.emit();
  }

  nextSegment() {
    if (this.demo) {
      this.modalService.info({
        nzTitle: 'Demo',
        nzContent: 'Weitere Inhalte sind in der Demoversion nicht verfügbar!'
      });
    } else {
      this.emitSegment(this.currentInputSegment);
      this.nextClicked.emit();
    }
  }

  emitSegment(nextSegment: number) {
    this.nextSegmentEmitter.emit(nextSegment);
  }

  resetCurrentInput() {
    this.currentInputSegment = this.initialSegment;
  }

  setToSegment(segmentIndex: number): void {
    const temp = segmentIndex;
    this.emitSegment(segmentIndex - 1);
    this.currentInputSegment = temp;
  }

  jumpToSegment($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      const allowedSegments: number[] = [];
      for (let i = 0; i < this.amountSegments; i++) {
        allowedSegments.push(i + 1);
      }
      if (
        !isNaN(this.currentInputSegment) &&
        allowedSegments.includes(+this.currentInputSegment)
      ) {
        this.setToSegment(this.currentInputSegment - 1);
        this.inputRef.nativeElement.blur();
      } else {
        this.inputRef.nativeElement.blur();
      }
    }
  }
}
