import { Component } from '@angular/core';
import { AuthService } from './guards/auth/auth.service';
import {
  Router,
  RouterEvent,
  NavigationEnd,
  NavigationStart
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { DataModelService } from './utils/datamodel.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ePrivacyAudit';

  constructor(
    public authService: AuthService,
    private router: Router,
    public dataModel: DataModelService
  ) {
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((e: NavigationStart) => {
        if (document.getElementById('appContent')) {
          // if smooth scrolling after navigation is needed:
          // document
          //   .getElementById('appContent')
          //   .scrollTo({ behavior: 'smooth', top: 0, left: 0 });
          // window.scrollTo({ behavior: 'smooth', top: 0, left: 0 });

          // instant "scrolling" to top after navigation
          document.getElementById('appContent').scrollTop = 0;
        }
        if (!e.url.includes('/audit')) {
          this.dataModel.selectedChapter = null;
        }
      });
  }
  isFooterVisible(): boolean {
    return !this.authService.isLoggedIn && !this.authService.loginInitiated;
  }
}
