import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { v4 } from 'uuid';
import { AuditFile } from '../models/auditFile';
import { Chapter } from '../models/chapter';
import { DBCollections } from '../models/dbCollections';
import { Download } from '../models/download';
import { FileType } from '../models/fileType';
import { InfoType } from '../models/infoType';
import { Segment } from '../models/segment';
import { SegmentEntry } from '../models/segmentEntry';
import { SegmentFile } from '../models/segmentFile';
import { TemplateFile } from '../models/templateFile';
import { ChapterCard } from './../models/chapterCard';
import { Info } from './../models/info';
import { Question } from './../models/question';
import { QuestionType } from './../models/questionType';
import { SegmentEntryType } from './../models/segmentEntryType';
import { DataModelService } from './datamodel.service';
import { LanguageService } from './language.service';
import { TextResolverService } from './text-resolver.service';
import { Tag } from '../models/tag';
import { Company } from '../models/company';
import { BookedPackage } from '../models/bookedPackage';
import { Country } from '../models/country';
import { QuestionService } from './question.service';

@Injectable({
  // damit der service ein singleton ist
  providedIn: 'root'
})
export class AuditService {
  constructor(
    private dataModel: DataModelService,
    public textResolverService: TextResolverService,
    private languageService: LanguageService,
    private questionService: QuestionService
  ) {}

  addChapter(title: string) {
    const chapter = Chapter.CLEAN_INSTANCE(
      this.textResolverService.setLanguageText(title),
      this.dataModel.chapters.length
    );
    this.dataModel.chapters = [...this.dataModel.chapters, chapter];
    this.dataModel.insertChapter(chapter);
  }

  addSegment(chapterCard: ChapterCard, segmentKey: string) {
    const segment = Segment.CLEAN_INSTANCE();
    chapterCard.segments.get(segmentKey).push(segment);
    this.dataModel.insertIntoCollection([segment], DBCollections.SEGMENTS);
    this.dataModel.updateCollection(
      chapterCard._id,
      {
        segments: this.dataModel.segmentMapToJSON(chapterCard)
      },
      DBCollections.CHAPTER_CARDS
    );
  }

  addSegmentEntry(
    chapterCard: ChapterCard,
    segmentKey: string,
    segmentIndex: number,
    segmentEntry: SegmentEntry
  ) {
    const segment = chapterCard.segments.get(segmentKey)[segmentIndex];
    const entries = segment.segmentEntries;

    segment.segmentEntries = entries
      ? [...entries, segmentEntry]
      : [segmentEntry];
    this.dataModel.insertIntoCollection(
      [segmentEntry],
      DBCollections.SEGMENT_ENTRIES
    );
    switch (segmentEntry.type) {
      case SegmentEntryType.QUESTION:
        this.dataModel.insertIntoCollection(
          [segmentEntry.entry],
          DBCollections.QUESTIONS
        );
        break;
      case SegmentEntryType.INFO:
        this.dataModel.insertIntoCollection(
          [segmentEntry.entry],
          DBCollections.INFOS
        );
        break;
      case SegmentEntryType.FILE:
        this.dataModel.insertIntoCollection(
          [segmentEntry.entry],
          DBCollections.SEGMENT_FILES
        );
        break;
    }
    this.dataModel.updateCollection(
      segment._id,
      {
        segmentEntries: segment.segmentEntries.map(sE => sE._id)
      },
      DBCollections.SEGMENTS
    );
  }

  addEntryTextPart(segmentEntry: SegmentEntry, text: string) {
    switch (segmentEntry.type) {
      case SegmentEntryType.QUESTION:
        segmentEntry.entry.textParts = [
          ...segmentEntry.entry.textParts,
          this.textResolverService.setLanguageText(text)
        ];
        this.dataModel.updateCollection(
          segmentEntry.entry._id,
          { textParts: segmentEntry.entry.textParts },
          DBCollections.QUESTIONS
        );
        break;
      case SegmentEntryType.INFO:
        segmentEntry.entry.textParts = [
          ...segmentEntry.entry.textParts,
          this.textResolverService.setLanguageText(text)
        ];
        this.dataModel.updateCollection(
          segmentEntry.entry._id,
          { textParts: segmentEntry.entry.textParts },
          DBCollections.INFOS
        );
        break;
      // TODO: implement other options
    }
  }

  removeChapter(chapter: Chapter) {
    this.dataModel.chapters.splice(this.dataModel.chapters.indexOf(chapter), 1);
    this.dataModel.deleteFromCollection([chapter._id], DBCollections.CHAPTERS);
  }

  removeSegment(
    chapterCard: ChapterCard,
    segmentKey: string,
    segmentIndex: number
  ) {
    const segment = chapterCard.segments.get(segmentKey)[segmentIndex];
    chapterCard.segments.get(segmentKey).length <= 1
      ? chapterCard.segments
          .get(segmentKey)
          .splice(segmentIndex, 1, Segment.CLEAN_INSTANCE())
      : chapterCard.segments.get(segmentKey).splice(segmentIndex, 1);
    this.dataModel.deleteFromCollection([segment._id], DBCollections.SEGMENTS);
    this.dataModel.updateCollection(
      chapterCard._id,
      { segments: this.dataModel.segmentMapToJSON(chapterCard) },
      DBCollections.CHAPTER_CARDS
    );
  }

  removeSegmentEntry(
    chapterCard: ChapterCard,
    segmentKey: string,
    segmentIndex: number,
    segmentEntry: SegmentEntry
  ): void {
    const segment = chapterCard.segments.get(segmentKey)[segmentIndex];
    this.dataModel.deleteFromCollection(
      [segmentEntry._id],
      DBCollections.SEGMENT_ENTRIES
    );
    this.removeInnerSegmentEntry(segmentEntry);
    this.dataModel.updateCollection(
      segment._id,
      { segmentEntries: segment.segmentEntries.map(sE => sE._id) },
      DBCollections.SEGMENTS
    );
    segment.segmentEntries.splice(
      segment.segmentEntries.indexOf(segmentEntry),
      1
    );
  }

  removeInnerSegmentEntry(segmentEntry: SegmentEntry): void {
    switch (segmentEntry.type) {
      case SegmentEntryType.QUESTION:
        this.dataModel.deleteFromCollection(
          [segmentEntry.entry._id],
          DBCollections.QUESTIONS
        );
        break;
      case SegmentEntryType.INFO:
        this.dataModel.deleteFromCollection(
          [segmentEntry.entry._id],
          DBCollections.INFOS
        );
        break;
      case SegmentEntryType.FILE:
        this.dataModel.deleteFromCollection(
          [segmentEntry.entry._id],
          DBCollections.SEGMENT_FILES
        );
        break;
    }
  }

  removeTextPart(segmentEntry: SegmentEntry, textPartIndex: number) {
    const textPartUUID = segmentEntry.entry.textParts[textPartIndex];
    this.dataModel.deleteFromCollection(
      [textPartUUID],
      DBCollections.LANGUAGE_TEXTS
    );
    switch (segmentEntry.type) {
      case SegmentEntryType.QUESTION:
        segmentEntry.entry.textParts.splice(textPartIndex, 1);
        this.dataModel.updateCollection(
          segmentEntry.entry._id,
          { textParts: segmentEntry.entry.textParts },
          DBCollections.QUESTIONS
        );
        break;
      case SegmentEntryType.INFO:
        segmentEntry.entry.textParts.splice(textPartIndex, 1);
        this.dataModel.updateCollection(
          segmentEntry.entry._id,
          { textParts: segmentEntry.entry.textParts },
          DBCollections.INFOS
        );
        break;
    }
  }

  reorderChapters(prevIndex: number, currIndex: number) {
    moveItemInArray(this.dataModel.chapters, prevIndex, currIndex);

    this.dataModel.chapters.forEach(
      (chapter, index) => (chapter.order = index)
    );
    this.dataModel.chapters.forEach(chapter =>
      this.dataModel.updateCollection(
        chapter._id,
        {
          order: chapter.order
        },
        DBCollections.CHAPTERS
      )
    );
  }

  reorderSegments(
    chapterCard: ChapterCard,
    segmentKey: string,
    prevIndex: number,
    currIndex: number
  ) {
    moveItemInArray(chapterCard.segments.get(segmentKey), prevIndex, currIndex);
    this.dataModel.updateCollection(
      chapterCard._id,
      {
        segments: this.dataModel.segmentMapToJSON(chapterCard)
      },
      DBCollections.CHAPTER_CARDS
    );
  }

  reorderTextParts(
    segmentEntry: SegmentEntry,
    prevIndex: number,
    currIndex: number
  ): void {
    switch (segmentEntry.type) {
      case SegmentEntryType.QUESTION:
        moveItemInArray(segmentEntry.entry.textParts, prevIndex, currIndex);
        this.dataModel.updateCollection(
          segmentEntry.entry._id,
          { textParts: segmentEntry.entry.textParts },
          DBCollections.QUESTIONS
        );
        break;
      case SegmentEntryType.INFO:
        moveItemInArray(segmentEntry.entry.textParts, prevIndex, currIndex);
        this.dataModel.updateCollection(
          segmentEntry.entry._id,
          { textParts: segmentEntry.entry.textParts },
          DBCollections.INFOS
        );
        break;
    }
  }

  reorderSegmentEntries(
    chapterCard: ChapterCard,
    segmentKey: string,
    segmentIndex: number,
    prevIndex: number,
    currIndex: number
  ): void {
    const segment = chapterCard.segments.get(segmentKey)[segmentIndex];
    moveItemInArray(segment.segmentEntries, prevIndex, currIndex);
    this.dataModel.updateCollection(
      segment._id,
      {
        segmentEntries: segment.segmentEntries.map(sE => sE._id)
      },
      DBCollections.SEGMENTS
    );
    // this.dataModel.updateChapters();
  }

  changeHelpMap(segmentEntry: SegmentEntry, helpMap: Map<string, string>) {
    segmentEntry.helpMap = helpMap;
    this.dataModel.updateCollection(
      segmentEntry._id,
      { helpMap: this.dataModel.helpMapToJSON(segmentEntry) },
      DBCollections.SEGMENT_ENTRIES
    );
  }

  changeChapterBlocked(chapter: Chapter, blocked: boolean): void {
    chapter.blocked = blocked;
    this.dataModel.updateCollection(
      chapter._id,
      { blocked: blocked },
      DBCollections.CHAPTERS
    );
  }

  changeChapterHidden(chapter: Chapter, hidden: boolean): void {
    chapter.hidden = hidden;
    this.dataModel.updateCollection(
      chapter._id,
      { hidden: hidden },
      DBCollections.CHAPTERS
    );
  }

  changeChapterName(chapter: Chapter, name: string): void {
    this.textResolverService.setLanguageText(name, chapter.title);
  }

  changeEntryType(segmentEntry: SegmentEntry, entryType: number) {
    segmentEntry.entry.type = entryType;
    switch (segmentEntry.type) {
      case SegmentEntryType.QUESTION:
        this.dataModel.updateCollection(
          segmentEntry.entry._id,
          { type: entryType },
          DBCollections.QUESTIONS
        );
        break;
      case SegmentEntryType.INFO:
        this.dataModel.updateCollection(
          segmentEntry.entry._id,
          { type: entryType },
          DBCollections.INFOS
        );
        break;
      case SegmentEntryType.FILE:
        segmentEntry.entry.templateFilesUUID =
          segmentEntry.entry.type !== FileType.TEMPLATE
            ? null
            : segmentEntry.entry.templateFilesUUID;
        this.dataModel.updateCollection(
          segmentEntry.entry._id,
          {
            type: entryType,
            auditFileTemplateUUID_DE:
              segmentEntry.entry.auditFileTemplateUUID_DE,
            auditFileTemplateUUID_EN:
              segmentEntry.entry.auditFileTemplateUUID_EN
          },
          DBCollections.SEGMENT_FILES
        );
        break;
    }
  }

  changeSegmentEntryResult(segmentEntry: SegmentEntry) {
    segmentEntry.result = !segmentEntry.result;
    this.dataModel.updateCollection(
      segmentEntry._id,
      { result: segmentEntry.result },
      DBCollections.SEGMENT_ENTRIES
    );
  }

  changeSegmentTitle(
    chapterCard: ChapterCard,
    segmentKey: string,
    segmentIndex: number,
    title: string
  ): void {
    const segment = chapterCard.segments.get(segmentKey)[segmentIndex];
    segment.title = this.textResolverService.setLanguageText(
      title,
      segment.title
    );
    this.dataModel.updateCollection(
      segment._id,
      { title: segment.title },
      DBCollections.SEGMENTS
    );
  }

  changeTextPart(
    segmentEntry: SegmentEntry,
    textPartIndex: number,
    text: string
  ) {
    let dbCollection;
    switch (segmentEntry.type) {
      case SegmentEntryType.QUESTION:
        segmentEntry.entry.textParts[
          textPartIndex
        ] = this.textResolverService.setLanguageText(
          text,
          segmentEntry.entry.textParts[textPartIndex]
        );
        dbCollection = DBCollections.QUESTIONS;
        break;
      case SegmentEntryType.INFO:
        segmentEntry.entry.textParts[
          textPartIndex
        ] = this.textResolverService.setLanguageText(
          text,
          segmentEntry.entry.textParts[textPartIndex]
        );
        dbCollection = DBCollections.INFOS;
        break;
    }
    this.dataModel.updateCollection(
      segmentEntry.entry._id,
      { textParts: segmentEntry.entry.textParts },
      dbCollection
    );
  }

  changeIntroductionText(chapterCard: ChapterCard, text: string) {
    chapterCard.introductionText = this.textResolverService.setLanguageText(
      text,
      chapterCard.introductionText
    );
    this.dataModel.updateCollection(
      chapterCard._id,
      { introductionText: chapterCard.introductionText },
      DBCollections.CHAPTER_CARDS
    );
  }

  checkChapterReportValidity(
    segments: Segment[]
  ): { valid: boolean; segmentChainUUIDs: string[] } {
    let valid = true;
    const segmentChainUUIDs: string[] = [];

    let runSegmentIndex = 0;

    while (runSegmentIndex < segments.length - 1 && valid) {
      let nextSegmentIndex: number;
      segments[runSegmentIndex].segmentEntries.forEach(sE => {
        if (
          sE.type === SegmentEntryType.QUESTION &&
          this.questionService.getQuestionAnswer(sE.entry._id).length === 0
        ) {
          valid = false;
        }
      });

      segmentChainUUIDs.push(segments[runSegmentIndex]._id);

      if (valid) {
        nextSegmentIndex = segments.indexOf(
          segments.find(
            (s, index) =>
              index > runSegmentIndex &&
              (!s.segmentConditions || s.segmentConditions.length === 0)
          )
        );

        let match = true;
        segments.forEach((segment, index) => {
          if (segment.segmentConditions) {
            segment.segmentConditions
              .filter(
                sC => sC.referencedSegmentUUID === segments[runSegmentIndex]._id
              )
              .some(sC => {
                match = true;
                sC.questionStates.forEach((answer, questionUUID) => {
                  if (
                    this.questionService.getQuestionAnswer(questionUUID) !==
                    answer
                  ) {
                    match = false;
                  }
                });
                if (match) {
                  nextSegmentIndex = index;
                  return match;
                }
              });
          }
        });
      }
      runSegmentIndex = nextSegmentIndex;
    }

    return { valid: valid, segmentChainUUIDs: segmentChainUUIDs };
  }

  hasQuestionTag(questionUUID: string): boolean {
    return (
      this.dataModel.tags.filter(t => t.usedQuestionUUID === questionUUID)
        .length > 0
    );
  }

  hasSegmentFileTemplates(file: SegmentFile | TemplateFile): boolean {
    return (
      (file.auditFileTemplateUUID_DE &&
        file.auditFileTemplateUUID_DE.length > 0) ||
      (file.auditFileTemplateUUID_EN &&
        file.auditFileTemplateUUID_EN.length > 0)
    );
  }

  hasTemplateFileTemplates(templateFile: TemplateFile): boolean {
    return (
      (templateFile.auditFileTemplateUUID_DE &&
        templateFile.auditFileTemplateUUID_DE.length > 0) ||
      (templateFile.auditFileTemplateUUID_EN &&
        templateFile.auditFileTemplateUUID_EN.length > 0)
    );
  }

  hasSegmentEntries(
    chapterCard: ChapterCard,
    segmentKey: string,
    segmentIndex: number
  ): boolean {
    const temp: Segment = chapterCard.segments.get(segmentKey)[segmentIndex];
    return temp.segmentEntries && temp.segmentEntries.length > 0;
  }

  isInfo(segmentEntry: SegmentEntry): boolean {
    return segmentEntry.type === SegmentEntryType.INFO;
  }

  isQuestion(segmentEntry: SegmentEntry): boolean {
    return segmentEntry.type === SegmentEntryType.QUESTION;
  }

  isFile(segmentEntry: SegmentEntry): boolean {
    return segmentEntry.type === SegmentEntryType.FILE;
  }

  isQuestionWithPredefinedAnswers(segmentEntry: SegmentEntry): boolean {
    return (
      segmentEntry.type === SegmentEntryType.QUESTION &&
      (segmentEntry.entry.type === QuestionType.MULTIPLE_CHOICE ||
        segmentEntry.entry.type === QuestionType.RADIO ||
        segmentEntry.entry.type === QuestionType.SELECT)
    );
  }

  getTagFromQuestionUUID(questionUUID: string): Tag {
    return this.dataModel.tags.find(t => t.usedQuestionUUID === questionUUID);
  }

  getSegmentUUIDsForChapter(
    chapterCard: ChapterCard,
    segmentKey: string
  ): string[] {
    return chapterCard.segments.get(segmentKey).map(segment => segment._id);
  }

  getQuestionsSuitableForConditions(
    chapterCard: ChapterCard,
    segmentKey: string,
    segmentUUID: string
  ): SegmentEntry[] {
    return chapterCard.segments
      .get(segmentKey)
      .find(segment => segment._id === segmentUUID)
      .segmentEntries.reduce((acc, sE) => {
        if (this.isQuestionWithPredefinedAnswers(sE)) {
          acc.push(sE);
        }
        return acc;
      }, []);
  }

  getTextParts(segmentEntry: SegmentEntry): string[] {
    switch (segmentEntry.type) {
      case SegmentEntryType.QUESTION:
        return segmentEntry.entry.textParts;
      case SegmentEntryType.INFO:
        return segmentEntry.entry.textParts;
      default:
        return ['SegmentEntryType not implemented yet'];
    }
  }

  getTemplateAuditFile(uuid: string): AuditFile {
    return this.dataModel.auditFiles.find(auditFile => auditFile._id === uuid);
  }

  getNewSegmentEntryForSegmentEntryType(
    segmentEntryType: SegmentEntryType,
    entryType: number
  ): SegmentEntry {
    const segmentEntry = SegmentEntry.CLEAN_INSTANCE(segmentEntryType);
    switch (segmentEntryType) {
      case SegmentEntryType.QUESTION:
        segmentEntry.entry = new Question(
          v4(),
          entryType,
          [],
          null,
          null,
          null,
          null,
          null,
          null,
          null
        );
        break;
      case SegmentEntryType.INFO:
        segmentEntry.entry = new Info(v4(), [], null, entryType);
        break;
      case SegmentEntryType.FILE:
        segmentEntry.entry = new SegmentFile(v4(), entryType, null, null);
    }
    return segmentEntry;
  }

  getAuditFileTemplateUUIDs(file: SegmentFile | TemplateFile): string[] {
    if (this.languageService.getCurrentLanguageString() === 'de') {
      return file.auditFileTemplateUUID_DE || [];
    } else if (this.languageService.getCurrentLanguageString() === 'en') {
      return file.auditFileTemplateUUID_EN || [];
    }
    return [];
  }

  getTemplateFileForEntity(entityUUID: string): TemplateFile {
    return this.dataModel.templateFiles.find(
      tF => tF.entityUUID === entityUUID
    );
  }

  getUploadedFileDownloads(chapterUUID: string): Download[] {
    // return this.dataModel.auditFiles
    //   ? this.dataModel.auditFiles.reduce<Download[]>((acc, auditFile) => {
    //       if (
    //         auditFile.providedByCompanyUUID &&
    //         auditFile.providedForCompanyUUID &&
    //         auditFile.entityUUID === chapterUUID
    //       ) {
    //         acc.push(
    //           new Download(
    //             auditFile._id,
    //             auditFile.fileName,
    //             auditFile.dateCreated,
    //             auditFile.fileSize + '',
    //             [
    //               this.dataModel.selectedCompany,
    //               this.dataModel.epcCompany
    //             ].find(c => c._id === auditFile.providedByCompanyUUID).name
    //           )
    //         );
    //       }
    //       return acc;
    //     }, [])
    //   : [];
    return this.dataModel.auditFiles
      ? this.dataModel.auditFiles
          .filter(
            auditFile =>
              auditFile.providedByCompanyUUID &&
              auditFile.providedForCompanyUUID &&
              auditFile.entityUUID === chapterUUID
          )
          .map(
            auditFile =>
              new Download(
                auditFile._id,
                auditFile.fileName,
                auditFile.dateCreated,
                auditFile.fileSize + '',
                [
                  this.dataModel.selectedCompany2.getValue()
                    ? this.dataModel.selectedCompany2.getValue()
                    : new Company(
                        '0000-0000',
                        'Bitte eine Firma auswählen',
                        '1',
                        new Country('', '', ''),
                        '2',
                        BookedPackage.test,
                        '0000',
                        '0001',
                        [],
                        [],
                        null
                      ),
                  this.dataModel.epcCompany
                ].find(c => c._id === auditFile.providedByCompanyUUID).name
              )
          )
      : [];
  }

  getTemplateLinksInChapters(auditFileUUID: string): Map<string, string[]> {
    const res = new Map<string, string[]>();
    this.dataModel.chapters.forEach(c =>
      c.chapterCards.forEach(cC =>
        Array.from(cC.segments.values()).forEach(sA =>
          sA.forEach((s, indexSegment) =>
            s.segmentEntries
              .filter(sE => sE.type === SegmentEntryType.FILE)
              .forEach(sEE => {
                if (
                  (sEE.entry as SegmentFile).type === FileType.TEMPLATE &&
                  (((sEE.entry as SegmentFile).auditFileTemplateUUID_DE &&
                    (sEE.entry as SegmentFile).auditFileTemplateUUID_DE.includes(
                      auditFileUUID
                    )) ||
                    ((sEE.entry as SegmentFile).auditFileTemplateUUID_EN &&
                      (sEE.entry as SegmentFile).auditFileTemplateUUID_EN.includes(
                        auditFileUUID
                      )))
                ) {
                  const cardTitle = this.languageService
                    .translate()
                    [cC.title].toLowerCase();
                  if (
                    !res.has(this.textResolverService.getLanguageText(c.title))
                  ) {
                    res.set(
                      this.textResolverService.getLanguageText(c.title),
                      []
                    );
                  }
                  res.set(this.textResolverService.getLanguageText(c.title), [
                    ...Array.from(
                      res.get(this.textResolverService.getLanguageText(c.title))
                    ),
                    cardTitle.charAt(0).toUpperCase() +
                      cardTitle.slice(1) +
                      ' - ' +
                      (indexSegment + 1) +
                      '. Segment'
                  ]);
                }
              })
          )
        )
      )
    );
    return res;
  }

  getTemplateFileDownloads(file: SegmentFile | TemplateFile): Download[] {
    return file
      ? this.getAuditFileTemplateUUIDs(file).reduce<Download[]>(
          (acc, auditFileTemplateUUID) => {
            const auditFile = this.dataModel.auditFiles.find(
              aF =>
                aF._id === auditFileTemplateUUID &&
                aF.lang === this.languageService.getCurrentLanguageString()
            );
            if (auditFile) {
              acc.push(
                new Download(
                  auditFile._id,
                  auditFile.fileName,
                  auditFile.dateCreated,
                  auditFile.fileSize + '',
                  'ePrivacy GmbH'
                )
              );
            }
            return acc;
          },
          []
        )
      : [];
  }

  getAmountSegments(chapterCard: ChapterCard, segmentKey: string): number {
    return chapterCard.segments.get(segmentKey).length;
  }

  getSegmentTitle(
    chapterCard: ChapterCard,
    segmentKey: string,
    segmentIndex: number
  ): string {
    const temp: Segment[] = chapterCard.segments.get(segmentKey);
    return temp.length > 0
      ? this.textResolverService.getLanguageText(temp[segmentIndex].title)
      : null;
  }

  getSegmentEntries(
    chapterCard: ChapterCard,
    segmentKey: string,
    segmentIndex: number
  ): SegmentEntry[] {
    return chapterCard.segments.get(segmentKey)[segmentIndex].segmentEntries;
  }

  getSegmentEntryTypeName(segmentEntryType: SegmentEntryType): string {
    switch (segmentEntryType) {
      case SegmentEntryType.QUESTION:
        return 'Frage';
      case SegmentEntryType.INFO:
        return 'Info';
      case SegmentEntryType.MASTER_DATA:
        return 'Stammdaten';
      case SegmentEntryType.FILE:
        return 'Dokument';
    }
  }

  getQuestionTypeName(questionType: QuestionType): string {
    switch (questionType) {
      case QuestionType.TEXTFIELD:
        return 'Textfeld';
      case QuestionType.RADIO:
        return 'Radio';
      case QuestionType.MULTIPLE_CHOICE:
        return 'Multiple Choice';
      case QuestionType.CHECKBOX:
        return 'Checkbox';
      case QuestionType.SELECT:
        return 'Select';
      default:
        return 'Neu';
    }
  }

  getInfoTypeName(infoType: InfoType): string {
    switch (infoType) {
      case InfoType.PLAINTEXT:
        return 'Text';
      case InfoType.LIST_NUMBERED:
        return 'Nummeriert';
      case InfoType.LIST_NOT_NUMBERED:
        return 'Bulletpoints';
      case InfoType.LIST_NUMBERED_GROUPED:
        return 'Nummeriert und gruppiert';
      case InfoType.LIST_NOT_NUMBERED_GROUPED:
        return 'Bulletpoints und gruppiert';
      default:
        return 'Neu';
    }
  }

  getFileTypeName(fileType: FileType): string {
    switch (fileType) {
      case FileType.TEMPLATE:
        return 'Vorlage';
      case FileType.UPLOAD:
        return 'Upload';
      default:
        return 'Neu';
    }
  }

  getAvailableSegmentEntryTypes(): number[] {
    return [
      SegmentEntryType.QUESTION,
      SegmentEntryType.INFO,
      SegmentEntryType.FILE
    ];
    /* Later on if all available segment entry types are supported for consulting
    return Object.values(SegmentEntryType).filter(type => !isNaN(type));
    */
  }

  getAvailableQuestionTypes(): number[] {
    return Object.values(QuestionType)
      .filter(type => !isNaN(type))
      .filter(n => n !== 0);
  }

  getAvailableInfoTypes(): number[] {
    return Object.values(InfoType).filter(type => !isNaN(type));
  }

  getAvailableFileTypes(): number[] {
    return Object.values(FileType).filter(type => !isNaN(type));
  }
}
