import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";
import * as forge from "node-forge";
import { Globals } from "./../models/globals";

@Injectable({
  // damit der service ein singleton ist
  providedIn: "root"
})
export class Encryption {
  private symKey: string;
  private currentUUID: string;
  private keyTransmitted = false;
  private randomWord =
    "a54ddf44670efb909ef7937dcc05a2f88aa1edfabd0bdb5d8bedbdbb2c2e55c7";
  private globals: Globals;
  headers = new HttpHeaders({ "Content-Type": "application/json" });

  constructor(public http: HttpClient) {
    this.globals = new Globals();
    // this.prepareEncryption();
  }

  xorCrypt(a: number[], b: number[]): number[] {
    if (a.length !== 64 || b.length !== 64) {
      return;
    }
    const output = [];

    for (let i = 0; i < 64; ++i) {
      output.push(a[i] ^ b[i]);
    }

    return output;
  }

  strToNrArray(str: string): number[] {
    const output = [];
    for (let i = 0; i < str.length; ++i) {
      output.push(str.charCodeAt(i));
    }
    return output;
  }

  nrArrayToString(nr: number[]): string {
    const output = [];
    for (let i = 0; i < nr.length; ++i) {
      output.push(String.fromCharCode(nr[i]));
    }
    return output.join("");
  }

  getCookieKeyContent(): string {
    const hash = this.getCanvasFingerprint();
    const sndHash = CryptoJS.PBKDF2(this.randomWord, hash, {
      keySize: 256 / 32,
      iterations: 10
    }).toString();
    const xor = this.xorCrypt(
      this.strToNrArray(sndHash),
      this.strToNrArray(this.symKey)
    );
    return JSON.stringify(xor);
  }

  prepareEncryption() {
    this.symKey = CryptoJS.PBKDF2(
      Math.ceil(Math.random() * 9999).toString(),
      CryptoJS.lib.WordArray.random(128 / 8),
      {
        keySize: 256 / 32,
        iterations: 1000
      }
    ).toString();

    this.http
      .post(`${this.globals.dbUrl}/8957f2c5-65a3-46a5-8201-7c0c0f5922a9`, null)
      .toPromise()
      .then(res => {
        this.currentUUID = res["uuid"].toString();

        const pk = forge.pki.publicKeyFromPem(
          res["publickey"].toString()
        ) as any;
        const encrypted = forge.util.encode64(
          pk.encrypt(this.symKey, "RSAES-OAEP")
        );

        this.http
          .post(`${this.globals.dbUrl}/51dd5c3e-b6a5-46d5-8ae0-0bd655616ee5`, {
            uuid: this.currentUUID,
            encryptedKey: encrypted
          })
          .toPromise()
          .then(res2 => {
            if (JSON.parse(this.decrypt(res2)).result === true) {
              this.keyTransmitted = true;
            }
          });
      });
  }

  getCurrentUUID(): string {
    return this.currentUUID;
  }
  setCurrentUUID(uuid: string): void {
    this.currentUUID = uuid;
  }

  public encryptString(plaintext: string) {
    const key = CryptoJS.enc.Utf8.parse(this.symKey);
    const iv = CryptoJS.enc.Utf8.parse(this.symKey);
    const encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(plaintext),
      key,
      {
        keySize: 256 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }
    );
    return encrypted.toString();
  }

  public decrypt(encryptedContent): string {
    if (encryptedContent) {
      const key = CryptoJS.enc.Utf8.parse(this.symKey);
      const iv = CryptoJS.enc.Utf8.parse(this.symKey);
      const decrypted = CryptoJS.AES.decrypt(encryptedContent, key, {
        keySize: 256 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      return decrypted.toString(CryptoJS.enc.Utf8);
    }
  }

  public hashPassword(password, salt): string {
    return CryptoJS.PBKDF2(password, salt, {
      keySize: 512 / 32,
      iterations: 100
    }).toString();
  }

  public getKeyTransmitted(): boolean {
    return this.keyTransmitted;
  }

  public setSymKey(key: string): void {
    this.symKey = key;
  }

  decryptCookieKey(cookiecontent: string) {
    const hash = this.getCanvasFingerprint();
    const sndHash = CryptoJS.PBKDF2(this.randomWord, hash, {
      keySize: 256 / 32,
      iterations: 10
    }).toString();
    const keyArray = this.xorCrypt(
      JSON.parse(cookiecontent),
      this.strToNrArray(sndHash)
    );
    return this.nrArrayToString(keyArray);
  }

  private getCanvasFingerprint(): string {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const txt = "i9asdm..$#po((^@KbXrww!~cz";
    ctx.textBaseline = "top";
    ctx.font = '16px "Arial"';
    ctx.textBaseline = "alphabetic";
    ctx.rotate(0.05);
    ctx.fillStyle = "#f60";
    ctx.fillRect(125, 1, 62, 20);
    ctx.fillStyle = "#069";
    ctx.fillText(txt, 2, 15);
    ctx.fillStyle = "rgba(102, 200, 0, 0.7)";
    ctx.fillText(txt, 4, 17);
    ctx.shadowBlur = 10;
    ctx.shadowColor = "blue";
    ctx.fillRect(-20, 10, 234, 5);
    const strng = canvas.toDataURL();

    // document.body.appendChild(canvas);

    let hash = 0;
    if (strng.length === 0) {
      return "23436325";
    }
    for (let i = 0; i < strng.length; i++) {
      hash = (hash << 5) - hash + strng.charCodeAt(i);
      hash = hash & hash;
    }
    return hash.toString();
  }
}
