/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popoverMarked.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./popoverMarkedManual/popoverMarkedManual.component.ngfactory";
import * as i3 from "./popoverMarkedManual/popoverMarkedManual.component";
import * as i4 from "../format-text/format-text.component.ngfactory";
import * as i5 from "../format-text/format-text.component";
import * as i6 from "@angular/common";
import * as i7 from "./popoverMarked.component";
import * as i8 from "../../../utils/text-resolver.service";
var styles_PopoverMarkedComponent = [i0.styles];
var RenderType_PopoverMarkedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopoverMarkedComponent, data: {} });
export { RenderType_PopoverMarkedComponent as RenderType_PopoverMarkedComponent };
function View_PopoverMarkedComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-popover-marked-manual", [], null, null, null, i2.View_PopoverMarkedManualComponent_0, i2.RenderType_PopoverMarkedManualComponent)), i1.ɵdid(2, 49152, null, 0, i3.PopoverMarkedManualComponent, [], { iconTitle: [0, "iconTitle"], iconPopover: [1, "iconPopover"], title: [2, "title"], text: [3, "text"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "info-circle"; var currVal_1 = "info-circle"; var currVal_2 = _co.textResolverService.getLanguageText(_co.getHelpKey(_v.parent.context.index)); var currVal_3 = _co.getPopoverText(_v.parent.context.index); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_PopoverMarkedComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-format-text", [], null, null, null, i4.View_FormatTextComponent_0, i4.RenderType_FormatTextComponent)), i1.ɵdid(2, 49152, null, 0, i5.FormatTextComponent, [], { text: [0, "text"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopoverMarkedComponent_3)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isLastPart(_v.context.index); _ck(_v, 4, 0, currVal_1); }, null); }
function View_PopoverMarkedComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopoverMarkedComponent_2)), i1.ɵdid(2, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.splittedText; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PopoverMarkedComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-format-text", [], null, null, null, i4.View_FormatTextComponent_0, i4.RenderType_FormatTextComponent)), i1.ɵdid(1, 49152, null, 0, i5.FormatTextComponent, [], { text: [0, "text"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PopoverMarkedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopoverMarkedComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noHelp", 2]], null, 0, null, View_PopoverMarkedComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.textContainsHelp(); var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_PopoverMarkedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popover-marked", [], null, null, null, View_PopoverMarkedComponent_0, RenderType_PopoverMarkedComponent)), i1.ɵdid(1, 49152, null, 0, i7.PopoverMarkedComponent, [i8.TextResolverService], null, null)], null, null); }
var PopoverMarkedComponentNgFactory = i1.ɵccf("app-popover-marked", i7.PopoverMarkedComponent, View_PopoverMarkedComponent_Host_0, { text: "text", helpMap: "helpMap" }, {}, []);
export { PopoverMarkedComponentNgFactory as PopoverMarkedComponentNgFactory };
