import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-explanation-hover',
  templateUrl: './explanation-hover.component.html',
  styleUrls: ['./explanation-hover.component.css']
})
export class ExplanationHoverComponent {
  @Input()
  explanation: string;
  @Input()
  placement = 'topRight';

  constructor() {}
}
