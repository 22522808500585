import { Component, Input } from "@angular/core";
import { Info } from "../../../models/info";
import { InfoType } from "../../../models/infoType";
import { SegmentEntry } from "../../../models/segmentEntry";
import { HelpMapService } from "../../../utils/helpMap.service";
import { LanguageService } from "../../../utils/language.service";

@Component({
  selector: "app-infos",
  templateUrl: "./infos.component.html",
  styleUrls: ["./infos.component.css"]
})
export class InfosComponent {
  @Input()
  segmentEntry: SegmentEntry;

  constructor(
    public languageService: LanguageService,
    public helpMapService: HelpMapService
  ) {}

  isPlaintext(info: Info): boolean {
    return info.type === InfoType.PLAINTEXT;
  }

  isList(info: Info): boolean {
    return (
      info.type === InfoType.LIST_NUMBERED ||
      info.type === InfoType.LIST_NOT_NUMBERED
    );
  }

  isListGrouped(info: Info): boolean {
    return (
      info.type === InfoType.LIST_NUMBERED_GROUPED ||
      info.type === InfoType.LIST_NOT_NUMBERED_GROUPED
    );
  }
}
