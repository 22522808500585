import { Injectable } from '@angular/core';
import { SegmentEntry } from './../models/segmentEntry';
import { TextResolverService } from './text-resolver.service';

@Injectable({
  // damit der service ein singleton ist
  providedIn: 'root'
})
export class HelpMapService {
  constructor(public textResolverService: TextResolverService) {}

  getHelpMapForTextParts(segmentEntry: SegmentEntry): Map<string, string>[] {
    const entryHelpMaps = [];

    if (segmentEntry && segmentEntry.helpMap) {
      const usedHelpMapKeys = [];
      const helpMapWords = Array.from(segmentEntry.helpMap.keys());

      for (
        let index = 0;
        index < segmentEntry.entry.textParts.length;
        index++
      ) {
        const textPart = this.textResolverService.getLanguageText(
          segmentEntry.entry.textParts[index]
        );
        entryHelpMaps.push(new Map());

        helpMapWords.forEach(helpMapWord => {
          if (
            textPart &&
            textPart.includes(
              this.textResolverService.getLanguageText(helpMapWord)
            ) &&
            !usedHelpMapKeys.includes(helpMapWord)
          ) {
            entryHelpMaps[index].set(
              helpMapWord,
              segmentEntry.helpMap.get(helpMapWord)
            );
            usedHelpMapKeys.push(helpMapWord);
          }
        });
      }
    }

    return entryHelpMaps;
  }
}
