import { DBService } from '../../../utils/dbverbindung.service';
import { FormOfAddress } from '../../../models/formOfAddress';
import { Location } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { AuthService } from '../../../guards/auth/auth.service';
import { LanguageService } from '../../../utils/language.service';
import { RoutingService } from '../../../utils/routing.service';
import { Subscription } from 'rxjs';
import { Email, EmailTypeEnum } from '../../../models/email';
import { EmailTag } from '../../../models/emailTag';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnDestroy {
  captchaUUID: string;
  captchaText: string;
  captchaFalse: boolean;
  subscriptions: Subscription[] = [];
  companyname = '';
  legalForm = '';

  formOfAddress: FormOfAddress;
  firstName = '';
  lastName = '';
  lang = '';
  email = '';
  message = '';
  phone = '';

  constructor(
    public authService: AuthService,
    private _location: Location,
    public langService: LanguageService,
    private router: RoutingService,
    private dbservice: DBService
  ) {}
  isEverythingEmpty(): boolean {
    return (
      this.companyname === '' ||
      this.legalForm === '' ||
      this.formOfAddress === null ||
      this.lastName === '' ||
      this.lang === '' ||
      this.email === ''
    );
  }
  setCaptchaUUID(uuid: string): void {
    this.captchaUUID = uuid;
  }
  setCaptchaText(text: string): void {
    this.captchaText = text;
  }
  testCaptchaAndSendRequest(): void {
    this.dbservice
      .isCaptchaValid(this.captchaText, this.captchaUUID)
      .subscribe(ret => {
        this.captchaFalse = ret['boolean'] === false;
        if (ret['boolean'] === true) {
          this.contactRequest();
        }
      });
  }
  contactRequest(): void {
    const companyname = this.companyname;
    const legalForm = this.legalForm;
    const formOfAdress = this.formOfAddress;
    const firstName = this.firstName;
    const lastName = this.lastName;
    const lang = this.lang;
    const email = this.email;
    const message = this.message;
    const phone = this.phone;

    this.companyname = '';
    this.legalForm = '';
    this.formOfAddress = null;
    this.firstName = '';
    this.lastName = '';
    this.lang = '';
    this.email = '';
    this.message = '';
    this.phone = '';

    const emailTags = [];
    emailTags.push(new EmailTag('companyname', companyname));
    emailTags.push(new EmailTag('legalForm', legalForm));
    emailTags.push(new EmailTag('formOfAdress', formOfAdress));
    emailTags.push(new EmailTag('firstName', firstName));
    emailTags.push(new EmailTag('lastName', lastName));
    emailTags.push(new EmailTag('lang', lang));
    emailTags.push(new EmailTag('email', email));
    emailTags.push(new EmailTag('phone', phone));
    emailTags.push(new EmailTag('message', message));

    this.dbservice
      .sendEmail(
        new Email(
          '',
          '',
          EmailTypeEnum.Kontaktanfrage_Landingpage_Kunde,
          emailTags,
          lang
        )
      )
      .then(x => {
        this.dbservice.sendEmail(
          new Email(
            '',
            '',
            EmailTypeEnum.Kontaktanfrage_Landingpage_Sales,
            emailTags,
            lang
          )
        );
        this.router.navigateToWaitForContact();
      });
  }

  changeFormOfAdress(add: FormOfAddress) {
    this.formOfAddress = add;
  }
  getFormOfAddressEnum(): any {
    return FormOfAddress;
  }
  zurueck(): void {
    this._location.back();
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(element => {
      element.unsubscribe();
    });
  }
}
