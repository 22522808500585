import { Subscription } from 'rxjs';
import { LanguageService } from './../../../../utils/language.service';
import { DBService } from './../../../../utils/dbverbindung.service';
import {
  Component,
  OnDestroy,
  OnInit,
  Input,
  Sanitizer,
  SecurityContext,
  Output,
  EventEmitter
} from '@angular/core';
import { v4 } from 'uuid';
import { Globals } from '../../../../models/globals';

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.css']
})
export class CaptchaComponent implements OnInit, OnDestroy {
  captchaURL: string;
  isInvalid = true;
  input: string;
  subscriptions: Subscription[] = [];

  @Input()
  text: string;
  @Output()
  emitInput: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  emitUUID: EventEmitter<string> = new EventEmitter<string>();

  dbUrl = new Globals().dbUrl;
  constructor(
    public langService: LanguageService,
    private dbservice: DBService
  ) {}

  ngOnInit(): void {
    this.refreshCaptcha();
  }

  refreshCaptcha(): void {
    const uuid = v4();
    this.emitUUID.emit(uuid);
    this.captchaURL = this.dbUrl + '/getCaptcha/' + uuid;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(element => {
      element.unsubscribe();
    });
  }
  onChange(inp: string): void {
    this.emitInput.emit(inp);
  }
}
