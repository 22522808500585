import { v4 } from 'uuid';
import { Answer } from './../models/answer';
import { AuditAnswer } from './../models/auditAnswer';
import { DataModelService } from './datamodel.service';
import * as moment from 'moment';
import { DBCollections } from '../models/dbCollections';
import { Level } from '../models/level';
import * as i0 from "@angular/core";
import * as i1 from "./datamodel.service";
export class QuestionService {
    constructor(dataModel) {
        this.dataModel = dataModel;
        this.cacheAnswers = [];
    }
    getQuestionAnswer(questionUUID) {
        const auditAnswer = this.dataModel.auditAnswers.find(a => a.questionUUID === questionUUID);
        if (auditAnswer) {
            const tag = this.dataModel.tags.find(t => t.usedQuestionUUID === auditAnswer.questionUUID);
            if (tag) {
                if (tag.level === Level.USER) {
                    const answers = auditAnswer.answers.filter(a => a.forUserUUID === this.dataModel.selectedUser._id);
                    return answers.length > 0
                        ? answers.reduce((curr, prev) => curr.date > prev.date ? curr : prev).answer
                        : '';
                }
            }
            return auditAnswer.answers.reduce((curr, prev) => curr.date > prev.date ? curr : prev).answer;
        }
        return '';
    }
    saveCacheAnswer() {
        this.cacheAnswers.forEach(cA => {
            const auditAnswer = this.dataModel.auditAnswers.find(a => a.questionUUID === cA.questionUUID);
            if (auditAnswer) {
                auditAnswer.answers.push(cA.answers[0]);
                this.dataModel.updateCollection(auditAnswer._id, { answers: auditAnswer.answers.map(a => a._id) }, DBCollections.AUDIT_ANSWERS);
            }
            else {
                this.dataModel.auditAnswers.push(cA);
                this.dataModel.insertIntoCollection([cA], DBCollections.AUDIT_ANSWERS);
            }
            this.dataModel.insertIntoCollection([cA.answers[0]], DBCollections.ANSWERS);
        });
        this.cacheAnswers = [];
    }
    insertOrUpdateCacheAnswer(question, answer) {
        const answerObj = new Answer(v4(), this.dataModel.user._id, this.dataModel.selectedUser._id, answer, moment.now());
        const cacheAnswer = this.cacheAnswers.find(cA => cA.questionUUID === question._id);
        if (cacheAnswer) {
            cacheAnswer.answers = [answerObj];
        }
        else {
            this.cacheAnswers.push(new AuditAnswer(v4(), this.dataModel.selectedCompany2.getValue()._id, question._id, [answerObj]));
        }
    }
}
QuestionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QuestionService_Factory() { return new QuestionService(i0.ɵɵinject(i1.DataModelService)); }, token: QuestionService, providedIn: "root" });
