import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SegmentEntry } from '../../../../models/segmentEntry';
import { LanguageService } from '../../../../utils/language.service';
import { QuestionService } from '../../../../utils/question.service';
import { TextResolverService } from '../../../../utils/text-resolver.service';

@Component({
  selector: 'app-multiplechoice',
  templateUrl: './multipleChoice.component.html',
  styleUrls: ['./multipleChoice.component.css']
})
export class MultipleChoiceComponent implements OnInit {
  @Input()
  segmentEntry: SegmentEntry;
  @Input()
  answer = '';
  @Input()
  disabled = false;

  @Output()
  answered = new EventEmitter<string>();

  checkboxAnswers = [];

  constructor(
    public languageService: LanguageService,
    public textResolverService: TextResolverService
  ) {}

  ngOnInit(): void {
    this.segmentEntry.entry.predefinedAnswers.forEach(answer => {
      this.checkboxAnswers.push({
        label: this.textResolverService.getLanguageText(answer),
        value: answer,
        checked: this.answer.split('!-!').includes(answer)
      });
    });
  }

  updateCheckboxes(checkboxGroup) {
    this.answered.emit(
      checkboxGroup
        .reduce((acc, checkbox) => {
          if (checkbox.checked) {
            acc.push(checkbox.value);
          }
          return acc;
        }, [])
        .join('!-!')
    );
  }
}
