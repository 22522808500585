import { Component, Input } from '@angular/core';
import { DataModelService } from '../../../utils/datamodel.service';
import { LanguageService } from './../../../utils/language.service';
import { RoutingService } from '../../../utils/routing.service';

@Component({
  selector: 'app-landingtoprightrow',
  templateUrl: './landingtoprightrow.component.html',
  styleUrls: ['./landingtoprightrow.component.css']
})
export class LandingTopRightRowComponent {
  @Input()
  login = true;

  constructor(
    public lang: LanguageService,
    public datamodel: DataModelService,
    private router: RoutingService
  ) {}
  contact(): void {
    this.router.navigateToContact();
  }

  switchLang(lang: string): void {
    this.lang.setInitialLanguage(lang);
    this.datamodel.setUserLang(lang);
  }
}
