import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { NzModalService } from 'ng-zorro-antd';
import { Subscription } from 'rxjs';
import { InputDialogComponent } from '../dialogs/input-dialog/input-dialog.component';

@Component({
  selector: 'app-rename',
  templateUrl: './rename.component.html',
  styleUrls: ['./rename.component.css']
})
export class RenameComponent implements OnDestroy {
  @Input()
  title: string;
  @Input()
  text: string;
  @Input()
  name: string;
  @Input()
  minRows = 1;
  @Input()
  maxRows = 1;

  @Output()
  renamed: EventEmitter<string> = new EventEmitter<string>();

  subscription: Subscription;

  constructor(private modalService: NzModalService) {}

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  renameDialog(): void {
    this.subscription = this.modalService
      .create({
        nzTitle: this.title,
        nzContent: InputDialogComponent,
        nzComponentParams: {
          inputContent: this.name,
          text: this.text,
          minRows: this.minRows,
          maxRows: this.maxRows
        }
      })
      .afterClose.subscribe(result => {
        if (result) {
          this.renamed.emit(result);
        }
      });
  }
}
