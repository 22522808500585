import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { Observable, of } from 'rxjs';
import { Roles } from '../../models/roles';
import { DataModelService } from '../../utils/datamodel.service';
import { RoutingService } from '../../utils/routing.service';
import { LanguageService } from './../../utils/language.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(
    private dataModel: DataModelService,
    private modalService: NzModalService,
    private router: Router,
    private routingService: RoutingService,
    private languageService: LanguageService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const roles = next.data.roles as Array<Roles>;

    if (next.data.chapter && this.dataModel.chapters[+next.url]) {
      if (this.dataModel.chapters[+next.url].blocked) {
        return of(this.checkRoleAndReturn(roles, true));
      } else {
        return of(true);
      }
    }
    return of(this.checkRoleAndReturn(roles, false));
  }

  checkRoleAndReturn(roles: Roles[], isChapter: boolean): boolean {
    const result = this.getRoleCheck(roles);
    if (!result) {
      this.navigateAndMessage(isChapter);
    }
    return result;
  }

  getRoleCheck(roles: Roles[]): boolean {
    if (
      this.dataModel.user.role === undefined ||
      this.dataModel.user.role === null
    ) {
      return false;
    } else if (this.dataModel.user.role === Roles.ADMIN) {
      return true;
    } else if (roles.includes(this.dataModel.user.role)) {
      return true;
    } else {
      return false;
    }
  }

  navigateAndMessage(chapter: boolean): void {
    if (this.router.url === '/login' || this.router.url === '/landing') {
      this.routingService.navigateToHome();
    }
    this.modalService.info({
      nzTitle: chapter
        ? this.languageService.translate().notAvailableDemoTitle
        : this.languageService.translate().accessDeniedTitle,
      nzContent: chapter
        ? this.languageService.translate().notAvailableDemoText
        : this.languageService.translate().accessDeniedText
    });
  }
}
