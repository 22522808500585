import { BookedPackage } from './bookedPackage';
import { CompanyStatus } from './companyStatus';
import { Country } from './country';
export class Company {
  constructor(
    public _id: string,
    public name: string,
    public legalForm: string,
    public country: Country,
    public additionalFolderName: string,
    public bookedPackage: BookedPackage,
    public contactPersonUUID: string,
    public contactPersonUUID2: string,
    public inReviewChapterUUIDs: string[],
    public doneChapterUUIDs: string[],
    public companyStatus: CompanyStatus
  ) {}
}
