import { Component, Input } from '@angular/core';
import { Notice } from '../../../models/notice';
import { LanguageService } from '../../../utils/language.service';
import { v4 } from 'uuid';
import * as moment from 'moment';
import { DataModelService } from '../../../utils/datamodel.service';
import { RoleService } from '../../../utils/role.service';
import { DBCollections } from '../../../models/dbCollections';

@Component({
  selector: 'app-notices',
  templateUrl: './notices.component.html',
  styleUrls: ['./notices.component.css']
})
export class NoticesComponent {
  @Input()
  entityUUID: string;
  @Input()
  notices: Notice[];

  newNotice: string;

  constructor(
    public languageService: LanguageService,
    private dataModel: DataModelService,
    private roleService: RoleService
  ) {}

  createNotice($entry: KeyboardEvent) {
    if (
      !this.newNotice ||
      this.newNotice.trim() === '' ||
      this.newNotice === undefined
    ) {
      // this.newNotice = '';
    } else {
      if ($entry.key === 'Enter') {
        const notice = new Notice(
          v4(),
          this.roleService.isConsultOrHigher()
            ? this.dataModel.epcCompany._id
            : this.dataModel.selectedCompany2.getValue()._id,
          this.roleService.isConsultOrHigher()
            ? this.dataModel.selectedCompany2.getValue()._id
            : this.dataModel.epcCompany._id,
          this.dataModel.user._id,
          this.entityUUID,
          this.newNotice,
          moment.now()
        );
        this.dataModel.notices = [notice, ...this.dataModel.notices];

        this.newNotice = '';

        this.dataModel.insertIntoCollection([notice], DBCollections.NOTICES);
      }
    }
  }
  createNoticeButton(): void {
    if (this.newNotice || this.newNotice.trim() !== '') {
      const notice = new Notice(
        v4(),
        this.roleService.isConsultOrHigher()
          ? this.dataModel.epcCompany._id
          : this.dataModel.selectedCompany2.getValue()._id,
        this.roleService.isConsultOrHigher()
          ? this.dataModel.selectedCompany2.getValue()._id
          : this.dataModel.epcCompany._id,
        this.dataModel.user._id,
        this.entityUUID,
        this.newNotice,
        moment.now()
      );
      this.dataModel.notices = [notice, ...this.dataModel.notices];

      this.newNotice = '';

      this.dataModel.insertIntoCollection([notice], DBCollections.NOTICES);
    }
  }

  deleteNotice(notice: Notice) {
    this.dataModel.deleteFromCollection(
      [
        ...this.dataModel.notices
          .splice(this.notices.indexOf(notice), 1)
          .map(n => n._id)
      ],
      DBCollections.NOTICES
    );
  }

  isNoticeDisabled(): boolean {
    if (
      !this.newNotice ||
      this.newNotice.trim() === '' ||
      this.newNotice === undefined
    ) {
      return true;
    } else {
      return false;
    }
  }
}
