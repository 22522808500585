import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd';

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.css']
})
export class InputDialogComponent implements OnInit {
  @Input()
  trimResultAllowEnter = true;
  @Input()
  optional = false;
  @Input()
  text: string;
  @Input()
  inputContent: string;
  @Input()
  minRows = 1;
  @Input()
  maxRows = 1;
  @Input()
  disableFormatting = false;

  validateForm: FormGroup;

  constructor(private fb: FormBuilder, private modalRef: NzModalRef) {
    this.modalRef.getInstance().onClickOkCancel = (type: string) => {
      if (type === 'ok') {
        this.enterSubmit();
      } else {
        this.modalRef.destroy();
      }
    };
  }

  ngOnInit() {
    this.validateForm = this.fb.group({
      textarea: [
        this.inputContent || '',
        this.optional ? [] : [Validators.required]
      ]
    });
  }

  listenToSubmit(event): void {
    if (event.delta.ops[1] && event.delta.ops[1].insert === '\n') {
      if (this.trimResultAllowEnter) {
        this.enterSubmit();
      }
    }
  }

  enterSubmit(event?: KeyboardEvent) {
    if (event) {
      event.preventDefault();
    }
    /* this.validateForm
      .get('textarea')
      .setValue(this.validateForm.get('textarea').value.trim());*/
    // tslint:disable-next-line: forin
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.valid) {
      let res: string = this.validateForm.get('textarea').value;

      if (this.trimResultAllowEnter) {
        res = res.replace(/<p>|<\/p>/g, '');
      } else {
        res = res.replace(/<p>/g, '');
        res = res.replace(/<br \/><\/p>/g, '<br />');
        res = res.replace(/<br><\/p>/g, '<br />');
        res = res.replace(/<\/p>/g, '<br />');
      }

      this.modalRef.destroy(res);
    }
  }

  getTextAreaSize() {
    return { minRows: this.minRows, maxRows: this.maxRows };
  }
}
