import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy
} from '@angular/core';
import { SegmentEntry } from '../../../../models/segmentEntry';
import { LanguageService } from '../../../../utils/language.service';
import { QuestionService } from '../../../../utils/question.service';
import { TextResolverService } from '../../../../utils/text-resolver.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-textfield',
  templateUrl: './textField.component.html',
  styleUrls: ['./textField.component.css']
})
export class TextFieldComponent implements OnInit, OnDestroy {
  @Input()
  segmentEntry: SegmentEntry;
  @Input()
  disabled = false;
  @Input()
  saveButton = true;

  initialAnswer: string;
  _answer: string;

  @Output()
  answered = new EventEmitter<string>();

  subscriptions: Subscription[] = [];
  answerChanged: Subject<string> = new Subject<string>();
  debounceTime = 1000;

  constructor(
    public languageService: LanguageService,
    public textResolverService: TextResolverService
  ) {}

  @Input()
  set answer(initialAnswer: string) {
    this.initialAnswer = initialAnswer;
    this._answer = initialAnswer;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.answerChanged
        .pipe(debounceTime(this.debounceTime))
        .subscribe((text: string) => {
          this.answered.emit(text);
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  isDisabled(): boolean {
    return this._answer === this.initialAnswer || this.disabled;
  }
}
