import { Injectable } from '@angular/core';
import { v4 } from 'uuid';
import { Question } from '../models/question';
import { QuestionType } from '../models/questionType';
import { Answer } from './../models/answer';
import { AuditAnswer } from './../models/auditAnswer';
import { DataModelService } from './datamodel.service';
import * as moment from 'moment';
import { DBCollections } from '../models/dbCollections';
import { SegmentImplementationService } from './segment-implementation.service';
import { Level } from '../models/level';

@Injectable({
  // damit der service ein singleton ist
  providedIn: 'root'
})
export class QuestionService {
  cacheAnswers: AuditAnswer[] = [];

  constructor(private dataModel: DataModelService) {}

  getQuestionAnswer(questionUUID: string): string {
    const auditAnswer = this.dataModel.auditAnswers.find(
      a => a.questionUUID === questionUUID
    );
    if (auditAnswer) {
      const tag = this.dataModel.tags.find(
        t => t.usedQuestionUUID === auditAnswer.questionUUID
      );
      if (tag) {
        if (tag.level === Level.USER) {
          const answers = auditAnswer.answers.filter(
            a => a.forUserUUID === this.dataModel.selectedUser._id
          );
          return answers.length > 0
            ? answers.reduce((curr, prev) =>
                curr.date > prev.date ? curr : prev
              ).answer
            : '';
        }
      }
      return auditAnswer.answers.reduce((curr, prev) =>
        curr.date > prev.date ? curr : prev
      ).answer;
    }
    return '';
  }

  saveCacheAnswer(): void {
    this.cacheAnswers.forEach(cA => {
      const auditAnswer = this.dataModel.auditAnswers.find(
        a => a.questionUUID === cA.questionUUID
      );
      if (auditAnswer) {
        auditAnswer.answers.push(cA.answers[0]);
        this.dataModel.updateCollection(
          auditAnswer._id,
          { answers: auditAnswer.answers.map(a => a._id) },
          DBCollections.AUDIT_ANSWERS
        );
      } else {
        this.dataModel.auditAnswers.push(cA);
        this.dataModel.insertIntoCollection([cA], DBCollections.AUDIT_ANSWERS);
      }
      this.dataModel.insertIntoCollection(
        [cA.answers[0]],
        DBCollections.ANSWERS
      );
    });
    this.cacheAnswers = [];
  }

  insertOrUpdateCacheAnswer(question: Question, answer: string): void {
    const answerObj = new Answer(
      v4(),
      this.dataModel.user._id,
      this.dataModel.selectedUser._id,
      answer,
      moment.now()
    );
    const cacheAnswer = this.cacheAnswers.find(
      cA => cA.questionUUID === question._id
    );
    if (cacheAnswer) {
      cacheAnswer.answers = [answerObj];
    } else {
      this.cacheAnswers.push(
        new AuditAnswer(
          v4(),
          this.dataModel.selectedCompany2.getValue()._id,
          question._id,
          [answerObj]
        )
      );
    }
  }
}
