<nz-input-group
  class="input"
  [ngStyle]="{ width: this.getSize() }"
  [nzAddOnAfter]="afterInputIcon"
  *ngIf="this.suffixIconName; else plain"
>
  <input
    #inputElement
    nz-input
    nz-tooltip
    nzTrigger="focus"
    nzPlacement="topLeft"
    [nzTitle]="this.tooltip"
    [placeholder]="this.tooltip"
    [disabled]="this.disabled"
    [(ngModel)]="this.text"
    (ngModelChange)="this.textChanged.emit(this.text)"
  />
</nz-input-group>

<ng-template #afterInputIcon>
  <i
    class="after-input-icon"
    nz-icon
    nzType="setting"
    nzTheme="outline"
    (click)="this.iconClicked.emit()"
  ></i>
</ng-template>

<ng-template #plain>
  <input
    #inputElement
    class="input"
    [ngStyle]="{ width: this.getSize() }"
    nz-input
    nz-tooltip
    nzTrigger="focus"
    nzPlacement="topLeft"
    [nzTitle]="this.tooltip"
    [placeholder]="this.tooltip"
    [disabled]="this.disabled"
    [(ngModel)]="this.text"
    (ngModelChange)="this.textChanged.emit(this.text)"
  />
</ng-template>
