import { Component } from "@angular/core";
import { RoutingService } from "../../utils/routing.service";

@Component({
  selector: "app-page-not-found",
  templateUrl: "./page-not-found.component.html",
  styleUrls: ["./page-not-found.component.css"]
})
export class PageNotFoundComponent {
  constructor(private router: RoutingService) {}

  navigateLanding() {
    this.router.navigateToLanding();
  }
}
