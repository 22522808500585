import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChapterCard } from '../../../models/chapterCard';
import { LanguageService } from '../../../utils/language.service';
import { TextResolverService } from './../../../utils/text-resolver.service';

@Component({
  selector: 'app-chapter-card',
  templateUrl: './chapter-card.component.html',
  styleUrls: ['./chapter-card.component.css'],
  animations: [
    trigger('contentExpand', [
      /*
      state(
        'off',
        style({
          height: 0,
          margin: 0,
          opacity: 0
        })
      ),
      transition('off => on', [
        group([
          animate('400ms ease-in-out', style({ height: '*' })),
          query(
            ':self',
            [
              stagger(200, [
                animate('100ms ease-in-out', style({ opacity: 1 }))
              ])
            ],
            { optional: true }
          )
        ])
      ]),
      transition('on => off', [
        group([
          animate('400ms ease-in-out', style({ height: 0 })),
          animate('200ms ease-in-out', style({ opacity: 0 }))
        ])
      ])
      */
      state(
        'off',
        style({
          opacity: 0,
          height: 0
        })
      ),
      state(
        'on',
        style({
          opacity: 1,
          height: '*'
        })
      ),
      transition('off => on', animate('400ms ease-in-out')),
      transition('on => off', animate('400ms ease-in-out'))
    ])
  ]
})
export class ChapterCardComponent {
  @Input()
  chapterCard: ChapterCard;
  @Input()
  idToScroll: string;
  @Input()
  yOffsetForScroll = 0;
  @Input()
  set isOpen(open: boolean) {
    this.open = open;
    if (open) {
      this.contentExpandStatus = 'on';
    }
  }

  @Output()
  opened = new EventEmitter<boolean>();

  contentExpandStatus = 'off';
  open = false;

  constructor(
    public languageService: LanguageService,
    public textResolverService: TextResolverService
  ) {}

  toggleCardSize() {
    this.contentExpandStatus = !this.open ? 'on' : 'off';
    if (this.open) {
      setTimeout(() => {
        this.updateOpen();
      }, 400);
    } else {
      this.updateOpen();
    }
  }

  updateOpen() {
    this.open = !this.open;
    this.opened.emit(this.open);
    this.scrollToBox();
  }

  getButtonText() {
    return this.open
      ? this.languageService.translate().less
      : this.languageService.translate().more;
  }

  scrollToBox() {
    if (this.idToScroll) {
      const y = document.getElementById(this.idToScroll).getBoundingClientRect()
        .top;
      const main = document.getElementById('appContent');
      main.scrollTo({
        top: y + main.scrollTop + this.yOffsetForScroll,
        behavior: 'smooth'
      });
      /*
      window.scrollTo({
        top: y + window.pageYOffset + this.yOffsetForScroll + 85.0,
        behavior: "smooth"
      });
      */
    }
  }
}
