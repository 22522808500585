export enum DBCollections {
  USERS = 'users',
  COMPANIES = 'companies',

  CHAPTERS = 'chapters',
  CHAPTER_CARDS = 'chapter-cards',
  SEGMENTS = 'segments',
  SEGMENT_CONDITIONS = 'segment-conditions',
  SEGMENT_ENTRIES = 'segment-entries',
  QUESTIONS = 'questions',
  INFOS = 'infos',
  MASTER_DATA = 'master-data',
  SEGMENT_FILES = 'segment-files',
  MASTER_DATA_SEGMENTS = 'master-data-segments',

  TAGS = 'tags',

  AUDIT_ANSWERS = 'audit-answers',
  ANSWERS = 'answers',

  NOTICES = 'notices',

  LANGUAGE_TEXTS = 'language-texts',

  AUDIT_FILES = 'audit-files',
  TEMPLATE_FILES = 'template-files'
}
