<ng-template #contentTemplate>
  <div class="login">
    <app-login-mask></app-login-mask>
  </div>
</ng-template>

<button
  class="button noshadow"
  nz-button
  nz-popover
  [nzPopoverContent]="contentTemplate"
  nzPopoverPlacement="bottom"
  nzPopoverTrigger="click"
  nzType="primary"
>
  {{ this.languageService.translate().login }}
</button>
