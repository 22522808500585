import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Company } from '../../../models/company';
import { RoleService } from '../../../utils/role.service';
import { DataModelService } from './../../../utils/datamodel.service';
import { LanguageService } from './../../../utils/language.service';
import { SegmentImplementationService } from '../../../utils/segment-implementation.service';
import { User } from '../../../models/user';
import { BookedPackage } from '../../../models/bookedPackage';
import { Country } from '../../../models/country';

@Component({
  selector: 'app-firmabutton',
  templateUrl: './firmabutton.component.html',
  styleUrls: ['./firmabutton.component.css']
})
export class FirmabuttonComponent implements OnDestroy {
  subscriptions: Subscription[] = [];

  selectedCompany: Company;
  dummyCompany = new Company(
    '0000-0000',
    'Bitte eine Firma auswählen',
    '1',
    new Country('', '', ''),
    '2',
    BookedPackage.test,
    '0000',
    '0001',
    [],
    [],
    null
  );

  constructor(
    public lang: LanguageService,
    public dataModel: DataModelService,
    public roleServcie: RoleService,
    private segmentImplService: SegmentImplementationService
  ) {}

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  updateSelectedCompany(cmp: Company): void {
    let company = cmp;
    if (!cmp) {
      company = this.dummyCompany;
      this.selectedCompany = null;
    }

    this.subscriptions.push(
      this.dataModel
        .getCompanyRelatedData(company._id, this.dataModel.user._id)
        .subscribe(_ => {
          this.segmentImplService.checkSegmentState();
          this.dataModel.selectedCompany2.next(company);
          this.dataModel.selectedUser = this.getAvailableUsersOfCompany()[0];
        })
    );
  }

  getCompanyName(): string {
    return this.dataModel.company
      ? this.getFullCompanyName(this.dataModel.company)
      : 'not logged in';
  }

  getFullCompanyName(company: Company): string {
    return (
      company.name +
      ' ' +
      company.legalForm +
      ' (' +
      company.country.alpha2Code +
      ')'
    );
  }

  getFullUserName(user: User): string {
    return user.firstName + ' ' + user.lastName;
  }

  getAvailableUsersOfCompany(): User[] {
    return this.dataModel.availableUsers.filter(u => {
      if (this.dataModel.selectedCompany2.getValue()) {
        return u.companyUUID === this.dataModel.selectedCompany2.getValue()._id;
      } else {
        return [];
      }
    });
  }
}
