import { v4 } from 'uuid';
import { DBCollections } from '../models/dbCollections';
import { LanguageEnglisch } from './../models/language.englisch';
import { LanguageText } from './../models/languageText';
import { DataModelService } from './datamodel.service';
import { LanguageService } from './language.service';
import { LanguageDeutsch } from '../models/language.deutsch';
import * as i0 from "@angular/core";
import * as i1 from "./language.service";
import * as i2 from "./datamodel.service";
export class TextResolverService {
    constructor(languageService, dataModelService) {
        this.languageService = languageService;
        this.dataModelService = dataModelService;
    }
    setLanguageText(text, uuid) {
        const currentLang = this.languageService.translate();
        let created = false;
        let langText;
        if (uuid && uuid !== undefined) {
            langText = this.dataModelService.languageTexts.find(item => item._id === uuid);
        }
        else {
            created = true;
            langText = new LanguageText(v4(), null, null);
            this.dataModelService.languageTexts.push(langText);
        }
        switch (true) {
            case currentLang instanceof LanguageDeutsch:
                langText.de = text;
                break;
            case currentLang instanceof LanguageEnglisch:
                langText.en = text;
                break;
        }
        if (created) {
            this.dataModelService.insertIntoCollection([langText], DBCollections.LANGUAGE_TEXTS);
        }
        else {
            this.dataModelService.updateCollection(langText._id, { de: langText.de, en: langText.en }, DBCollections.LANGUAGE_TEXTS);
        }
        return langText._id;
    }
    updateInsertLanguageTexts(langTexts) {
        langTexts.forEach(langText => {
            const langTextInCollection = this.dataModelService.languageTexts.find(text => text._id === langText._id);
            if (langTextInCollection) {
                this.dataModelService.languageTexts.splice(this.dataModelService.languageTexts.indexOf(this.dataModelService.languageTexts.find(text => text._id === langText._id)), 1, langText);
                this.dataModelService.updateCollection(langText._id, { de: langText.de, en: langText.en }, DBCollections.LANGUAGE_TEXTS);
            }
            else {
                this.dataModelService.languageTexts.push(langText);
                this.dataModelService.insertIntoCollection([langText], DBCollections.LANGUAGE_TEXTS);
            }
        });
    }
    deleteLanguageTexts(toDeleteLanguageTexts) {
        toDeleteLanguageTexts.forEach(langText => {
            const langTextInCollection = this.dataModelService.languageTexts.find(text => text._id === langText._id);
            if (langTextInCollection) {
                this.dataModelService.languageTexts.splice(this.dataModelService.languageTexts.indexOf(langTextInCollection), 1);
                this.dataModelService.deleteFromCollection(langText._id, DBCollections.LANGUAGE_TEXTS);
            }
        });
    }
    getLanguageText(uuid) {
        if (!uuid) {
            return null;
        }
        const currentLang = this.languageService.translate();
        const langText = this.dataModelService.languageTexts.find(text => text._id === uuid);
        if (langText) {
            switch (true) {
                case currentLang instanceof LanguageDeutsch:
                    return langText.de || this.getFallbackText(langText);
                case currentLang instanceof LanguageEnglisch:
                    return langText.en || this.getFallbackText(langText);
                default:
                    return langText.en || this.getFallbackText(langText);
            }
        }
        return 'Missing text';
    }
    getFallbackText(langText) {
        return ('<span style="background-color: orange">' +
            (langText.en || langText.de) +
            '</span>');
    }
}
TextResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TextResolverService_Factory() { return new TextResolverService(i0.ɵɵinject(i1.LanguageService), i0.ɵɵinject(i2.DataModelService)); }, token: TextResolverService, providedIn: "root" });
