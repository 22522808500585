/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./landing.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../components/landingpage/landinglogo/landinglogo.component.ngfactory";
import * as i4 from "../../components/landingpage/landinglogo/landinglogo.component";
import * as i5 from "../../utils/routing.service";
import * as i6 from "../../components/landingpage/landingtoprightrow/landingtoprightrow.component.ngfactory";
import * as i7 from "../../components/landingpage/landingtoprightrow/landingtoprightrow.component";
import * as i8 from "../../utils/language.service";
import * as i9 from "../../utils/datamodel.service";
import * as i10 from "../../components/landingpage/landingcarousel/landingcarousel.component.ngfactory";
import * as i11 from "../../components/landingpage/landingcarousel/landingcarousel.component";
import * as i12 from "../../components/shared/placeholder-card/placeholder-card.component.ngfactory";
import * as i13 from "../../components/shared/placeholder-card/placeholder-card.component";
import * as i14 from "./landing.component";
import * as i15 from "../../guards/auth/auth.service";
import * as i16 from "../../utils/dbverbindung.service";
import * as i17 from "../../utils/cookies.service";
import * as i18 from "@angular/router";
var styles_LandingComponent = [i0.styles];
var RenderType_LandingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LandingComponent, data: {} });
export { RenderType_LandingComponent as RenderType_LandingComponent };
function View_LandingComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "outer imagevisible"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { imagevisible: 0, imageinvisible: 1 }), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(6, { "background-image": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "outer imagevisible"; var currVal_1 = _ck(_v, 3, 0, (_co.currentImageIndex === _v.context.index), (_co.currentImageIndex !== _v.context.index)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 6, 0, _v.context.$implicit); _ck(_v, 5, 0, currVal_2); }, null); }
function View_LandingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "topWidth"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-landinglogo", [], null, null, null, i3.View_LandingLogoComponent_0, i3.RenderType_LandingLogoComponent)), i1.ɵdid(3, 49152, null, 0, i4.LandingLogoComponent, [i5.RoutingService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-landingtoprightrow", [], null, null, null, i6.View_LandingTopRightRowComponent_0, i6.RenderType_LandingTopRightRowComponent)), i1.ɵdid(5, 49152, null, 0, i7.LandingTopRightRowComponent, [i8.LanguageService, i9.DataModelService, i5.RoutingService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LandingComponent_2)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "carouselDiv"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-landingcarousel", [], null, [[null, "index"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("index" === en)) {
        var pd_0 = (_co.indexChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_LandingCarouselComponent_0, i10.RenderType_LandingCarouselComponent)), i1.ɵdid(10, 49152, null, 0, i11.LandingCarouselComponent, [i8.LanguageService, i9.DataModelService], null, { index: "index" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.images; _ck(_v, 7, 0, currVal_0); }, null); }
function View_LandingComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-placeholder-card", [], null, null, null, i12.View_PlaceholderCardComponent_0, i12.RenderType_PlaceholderCardComponent)), i1.ɵdid(2, 49152, null, 0, i13.PlaceholderCardComponent, [], null, null)], null, null); }
export function View_LandingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LandingComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LandingComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.authService.isLoggedIn; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.authService.isLoggedIn; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_LandingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-landing", [], null, null, null, View_LandingComponent_0, RenderType_LandingComponent)), i1.ɵdid(1, 49152, null, 0, i14.LandingComponent, [i8.LanguageService, i15.AuthService, i16.DBService, i17.Cookies, i8.LanguageService, i18.Router], null, null)], null, null); }
var LandingComponentNgFactory = i1.ɵccf("app-landing", i14.LandingComponent, View_LandingComponent_Host_0, {}, {}, []);
export { LandingComponentNgFactory as LandingComponentNgFactory };
