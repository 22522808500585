/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./components/topbar/headerbar.component.ngfactory";
import * as i4 from "./components/topbar/headerbar.component";
import * as i5 from "./utils/language.service";
import * as i6 from "./views/application/application.component.ngfactory";
import * as i7 from "./views/application/application.component";
import * as i8 from "./utils/screenSize.service";
import * as i9 from "./utils/datamodel.service";
import * as i10 from "@angular/common";
import * as i11 from "./components/landingpage/landingfooter/landingfooter.component.ngfactory";
import * as i12 from "./components/landingpage/landingfooter/landingfooter.component";
import * as i13 from "./utils/routing.service";
import * as i14 from "./app.component";
import * as i15 from "./guards/auth/auth.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "height"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-headerbar", [], null, null, null, i3.View_HeaderbarComponent_0, i3.RenderType_HeaderbarComponent)), i1.ɵdid(2, 49152, null, 0, i4.HeaderbarComponent, [i5.LanguageService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-application", [], null, null, null, i6.View_ApplicationComponent_0, i6.RenderType_ApplicationComponent)), i1.ɵdid(4, 49152, null, 0, i7.ApplicationComponent, [i8.ScreenSizeService, i9.DataModelService], null, null)], null, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.authService.isLoggedIn; var currVal_1 = i1.ɵnov(_v.parent, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AppComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["id", "footernavi"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-landingfooter", [], null, null, null, i11.View_LandingFooterComponent_0, i11.RenderType_LandingFooterComponent)), i1.ɵdid(2, 49152, null, 0, i12.LandingFooterComponent, [i5.LanguageService, i9.DataModelService, i13.RoutingService], null, null)], null, null); }
function View_AppComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_5)), i1.ɵdid(4, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = _co.isFooterVisible(); _ck(_v, 4, 0, currVal_0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["normal", 2]], null, 0, null, View_AppComponent_2)), (_l()(), i1.ɵand(0, [["landing", 2]], null, 0, null, View_AppComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataModel.bypassUsualRouterOutlet.getValue(); var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i14.AppComponent, [i15.AuthService, i2.Router, i9.DataModelService], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i14.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
