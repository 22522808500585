import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { RoleService } from '../../../utils/role.service';
import { DataModelService } from '../../../utils/datamodel.service';
import { NzModalService } from 'ng-zorro-antd';
import { BookedPackage } from '../../../models/bookedPackage';
import { ChapterStatusService } from '../../../utils/chapter-status.service';
import { TextResolverService } from '../../../utils/text-resolver.service';
import { LanguageService } from '../../../utils/language.service';
import { DBCollections } from '../../../models/dbCollections';
import { Subscription } from 'rxjs';
import { Chapter } from '../../../models/chapter';
import { DBService } from '../../../utils/dbverbindung.service';
import { Email, EmailTypeEnum } from '../../../models/email';
import { EmailTag } from '../../../models/emailTag';

@Component({
  selector: 'app-submission',
  templateUrl: './submission.component.html',
  styleUrls: ['./submission.component.css']
})
export class SubmissionComponent implements OnDestroy {
  @Input()
  chapter: Chapter;
  @Input()
  compact = false;
  @Input()
  explanationPlacement = 'topRight';

  subscriptions: Subscription[] = [];

  constructor(
    private roleService: RoleService,
    private dataModel: DataModelService,
    private modalService: NzModalService,
    private chapterStatusService: ChapterStatusService,
    private textResolverService: TextResolverService,
    public languageService: LanguageService,
    private dbService: DBService
  ) {}

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  isExtraChapter(): boolean {
    return !this.dataModel.chapters.includes(this.chapter);
  }

  isUser(): boolean {
    return !this.roleService.isConsultOrHigher();
  }

  hasFullWithConsulting(): boolean {
    return (
      this.dataModel.selectedCompany2.getValue().bookedPackage ===
        BookedPackage.fullWithConsulting ||
      this.dataModel.selectedCompany2.getValue().bookedPackage === BookedPackage.fullWithDSB
    );
  }

  isDone(): boolean {
    return this.chapterStatusService.isChapterDone(this.chapter._id);
  }

  isInReview(): boolean {
    return this.chapterStatusService.isChapterInReview(this.chapter._id);
  }

  userUnlock(withConsulting: boolean): void {
    this.modalService.confirm({
      nzTitle: this.languageService.translate().chapterBackToInProgressTitle,
      nzContent: withConsulting
        ? this.languageService.translate().chapterBackToInProgressWithConsulting
        : this.languageService.translate().chapterBackToInProgress,
      nzBodyStyle: { 'white-space': 'pre-wrap' },
      nzOnOk: _ => this.unlock()
    });
  }

  unlock(): void {
    if (
      this.dataModel.selectedCompany2.getValue().inReviewChapterUUIDs &&
      this.dataModel.selectedCompany2.getValue().inReviewChapterUUIDs.includes(
        this.chapter._id
      )
    ) {
      this.dataModel.selectedCompany2.getValue().inReviewChapterUUIDs.splice(
        this.dataModel.selectedCompany2.getValue().inReviewChapterUUIDs.indexOf(
          this.chapter._id
        ),
        1
      );
    }
    if (
      this.dataModel.selectedCompany2.getValue().doneChapterUUIDs &&
      this.dataModel.selectedCompany2.getValue().doneChapterUUIDs.includes(this.chapter._id)
    ) {
      this.dataModel.selectedCompany2.getValue().doneChapterUUIDs.splice(
        this.dataModel.selectedCompany2.getValue().doneChapterUUIDs.indexOf(
          this.chapter._id
        ),
        1
      );
    }

    this.dataModel.updateCollection(
      this.dataModel.selectedCompany2.getValue()._id,
      {
        doneChapterUUIDs: this.dataModel.selectedCompany2.getValue().doneChapterUUIDs,
        inReviewChapterUUIDs: this.dataModel.selectedCompany2.getValue()
          .inReviewChapterUUIDs
      },
      DBCollections.COMPANIES
    );
    if (!this.isUser()) {
    this.dbService.sendEmail(
      new Email(
        this.dataModel.selectedUser.companyUUID,
        this.dataModel.selectedUser._id,
        EmailTypeEnum.Statusaenderung_Kapitel_in_Bearbeitung_Kunde,
        [new EmailTag('KAPITEL', this.chapter._id)],
        this.dataModel.selectedUser.lang
      )
    );
  }
  }

  toDoneOrUnlock(): void {
    if (this.isDone()) {
      this.userUnlock(false);
    } else {
      this.modalService.confirm({
        nzTitle: this.languageService.translate().chapterToDoneTitle,
        nzContent: this.languageService.translate().chapterToDone,
        nzBodyStyle: { 'white-space': 'pre-wrap' },
        nzOnOk: _ => {
          this.dataModel.selectedCompany2.getValue().doneChapterUUIDs = this.dataModel
            .selectedCompany2.getValue().doneChapterUUIDs
            ? this.dataModel.selectedCompany2.getValue().doneChapterUUIDs
            : [];
          this.dataModel.selectedCompany2.getValue().doneChapterUUIDs = [
            ...this.dataModel.selectedCompany2.getValue().doneChapterUUIDs,
            this.chapter._id
          ];

          this.dataModel.updateCollection(
            this.dataModel.selectedCompany2.getValue()._id,
            {
              doneChapterUUIDs: this.dataModel.selectedCompany2.getValue().doneChapterUUIDs
            },
            DBCollections.COMPANIES
          );

        }
      });
    }
  }

  toReviewOrUnlock(): void {
    if (this.isDone()) {
      this.userUnlock(true);
    } else {
      this.modalService.confirm({
        nzTitle: this.languageService.translate().chapterToInReviewTitle,
        nzContent: this.languageService.translate().chapterToInReview,
        nzBodyStyle: { 'white-space': 'pre-wrap' },
        nzOnOk: () => {
          this.dataModel.selectedCompany2.getValue().inReviewChapterUUIDs = this.dataModel
            .selectedCompany2.getValue().inReviewChapterUUIDs
            ? this.dataModel.selectedCompany2.getValue().inReviewChapterUUIDs
            : [];
          this.dataModel.selectedCompany2.getValue().inReviewChapterUUIDs = [
            ...this.dataModel.selectedCompany2.getValue().inReviewChapterUUIDs,
            this.chapter._id
          ];

          this.dataModel.updateCollection(
            this.dataModel.selectedCompany2.getValue()._id,
            {
              inReviewChapterUUIDs: this.dataModel.selectedCompany2.getValue()
                .inReviewChapterUUIDs
            },
            DBCollections.COMPANIES
          );
          this.dbService.sendEmail(
            new Email(
              this.dataModel.user.companyUUID,
              this.dataModel.user._id,
              EmailTypeEnum.Statusaenderung_Kapitel_in_Pruefung_DSBTeam,
              [new EmailTag('KAPITEL', this.chapter._id)],
              this.dataModel.user.lang
            )
          );
        }
      });
    }
  }

  unlockWithNote(): void {
    // InputDialog does not work if the input is empty. It will destroy on cancel without param
    // and everywhere where this is used, i listen if there is a param. I can't add a special param
    // to know if cancel instead of okay has been clicked. I would need another comp for that
    // this.subscriptions.push(
    //   this.modalService
    //     .confirm({
    //       nzTitle: 'Kapitel freigeben',
    //       nzContent: InputDialogComponent,
    //       nzBodyStyle: { 'white-space': 'pre-wrap' },
    //       nzComponentParams: {
    //         optional: true,
    //         text:
    //           'Wollen Sie das Kapitel für diesen Kunden wieder zur Bearbeitung freigeben? Sie können optional auch eine Notiz hinterlassen, die der Kunde unten im Kapitel einsehen kann'
    //       }
    //     })
    //     .afterClose.subscribe((text: string) => {
    //       if (text && text.trim().length > 0) {
    //         const notice = new Notice(
    //           v4(),
    //           this.dataModel.epcCompany._id,
    //           this.dataModel.selectedCompany._id,
    //           this.dataModel.user._id,
    //           this.chapter._id,
    //           text.trim(),
    //           moment.now()
    //         );
    //         this.dataModel.notices = [notice, ...this.dataModel.notices];

    //         this.dataModel.insertIntoCollection(
    //           [notice],
    //           DBCollections.NOTICES
    //         );
    //       }

    //       this.unlock();
    //     })
    // );

    this.modalService.confirm({
      nzTitle: 'Kapitel freigeben',
      nzContent:
        'Wollen Sie das Kapitel für diesen Kunden wieder zur Bearbeitung freigeben?\n\nFalls der Kunde Beratung gebucht hat, so muss dieser das Kapitel erst wieder in Prüfung geben.',
      nzBodyStyle: { 'white-space': 'pre-wrap' },
      nzOnOk: _ => this.unlock()
    });
  }

  finishUpSuccessfully(): void {
    this.modalService.confirm({
      nzTitle: 'Kapitel abschließen',
      nzContent: 'Wollen Sie das Kapitel für diesen Kunden abschließen?',
      nzBodyStyle: { 'white-space': 'pre-wrap' },
      nzOnOk: _ => {
        if (
          this.dataModel.selectedCompany2.getValue().inReviewChapterUUIDs &&
          this.dataModel.selectedCompany2.getValue().inReviewChapterUUIDs.includes(
            this.chapter._id
          )
        ) {
          this.dataModel.selectedCompany2.getValue().inReviewChapterUUIDs.splice(
            this.dataModel.selectedCompany2.getValue().inReviewChapterUUIDs.indexOf(
              this.chapter._id
            ),
            1
          );
        }
        this.dataModel.selectedCompany2.getValue().doneChapterUUIDs = this.dataModel
          .selectedCompany2.getValue().doneChapterUUIDs
          ? [
              ...this.dataModel.selectedCompany2.getValue().doneChapterUUIDs,
              this.chapter._id
            ]
          : [this.chapter._id];
        this.dataModel.updateCollection(
          this.dataModel.selectedCompany2.getValue()._id,
          {
            doneChapterUUIDs: this.dataModel.selectedCompany2.getValue().doneChapterUUIDs,
            inReviewChapterUUIDs: this.dataModel.selectedCompany2.getValue()
              .inReviewChapterUUIDs
          },
          DBCollections.COMPANIES
        );
        this.dbService.sendEmail(
          new Email(
            this.dataModel.selectedUser.companyUUID,
            this.dataModel.selectedUser._id,
            EmailTypeEnum.Status_Kapitel_bearbeitet_Kunde,
            [new EmailTag('KAPITEL', this.chapter._id)],
            this.dataModel.selectedUser.lang
          )
        );
      }
    });
  }

  getChapterDescription(short: boolean): string {
    return this.textResolverService.getLanguageText(
      short ? this.chapter.shortDescription : this.chapter.longDescription
    );
  }

  getStatusText(): string {
    return this.chapterStatusService.getStatus(this.chapter._id).text;
  }

  getStatusExplanation(): string {
    return this.chapterStatusService.getStatus(this.chapter._id).explanation;
  }

  getStatusColor(): string {
    return this.chapterStatusService.getStatus(this.chapter._id).color;
  }

  getUserOptionsText(withConsulting: boolean): string {
    return this.isDone()
      ? this.languageService.translate().chapterStatusToInProgressButton
      : withConsulting
      ? this.languageService.translate().chapterStatusToInReviewButton
      : this.languageService.translate().chapterStatusToDoneButton;
  }
}
