export enum Fetch {
  CHAPTERS,
  LANGUAGE_TEXTS,
  TEMPLATE_FILES,
  AUDIT_FILES,
  TAGS,
  USER_COMPANY,
  COMPANIES,
  EPCCompany,
  USERS,
  REPORTS,
  EPCUsers,
  AUDIT_ANSWERS,
  NOTICES,

  TELEMETRIE_LAST_LOGIN
}
