import { NotifyEntry } from './../../../models/notifyEntry';
import { LanguageService } from './../../../utils/language.service';
import { Component, OnDestroy } from '@angular/core';
import { DataModelService } from './../../../utils/datamodel.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notifybutton',
  templateUrl: './notifybutton.component.html',
  styleUrls: ['./notifybutton.component.css']
})
export class NotifybuttonComponent implements OnDestroy {
  notifyEntries: NotifyEntry[];
  subscription: Subscription;

  constructor(public lang: LanguageService, public data: DataModelService) {
    this.subscription = this.data.getMyNotifyContent().subscribe(x => {
      this.notifyEntries = x;
    });
  }

  getMyNotifyContent(): NotifyEntry[] {
    return this.notifyEntries;
  }

  getMyNotifyVisibleCount(): number {
    return this.notifyEntries.filter(x => x.viewed === '0').length;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
