/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sidebar.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./navmenu/navmenu.component.ngfactory";
import * as i3 from "./navmenu/navmenu.component";
import * as i4 from "../../utils/routing.service";
import * as i5 from "../../utils/language.service";
import * as i6 from "../../utils/dbverbindung.service";
import * as i7 from "../../utils/datamodel.service";
import * as i8 from "ng-zorro-antd/modal";
import * as i9 from "../../utils/text-resolver.service";
import * as i10 from "../../utils/cookies.service";
import * as i11 from "./sidebar.component";
var styles_SidebarComponent = [i0.styles];
var RenderType_SidebarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SidebarComponent, data: {} });
export { RenderType_SidebarComponent as RenderType_SidebarComponent };
export function View_SidebarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navmenu", [], null, null, null, i2.View_NavmenuComponent_0, i2.RenderType_NavmenuComponent)), i1.ɵdid(1, 49152, null, 0, i3.NavmenuComponent, [i4.RoutingService, i5.LanguageService, i6.DBService, i7.DataModelService, i8.NzModalService, i9.TextResolverService, i10.Cookies], null, null)], null, null); }
export function View_SidebarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sidebar", [], null, null, null, View_SidebarComponent_0, RenderType_SidebarComponent)), i1.ɵdid(1, 49152, null, 0, i11.SidebarComponent, [i5.LanguageService, i7.DataModelService, i4.RoutingService], null, null)], null, null); }
var SidebarComponentNgFactory = i1.ɵccf("app-sidebar", i11.SidebarComponent, View_SidebarComponent_Host_0, {}, {}, []);
export { SidebarComponentNgFactory as SidebarComponentNgFactory };
