import { LanguageService } from './../../../utils/language.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-custom-empty',
  templateUrl: './custom-empty.component.html',
  styleUrls: ['./custom-empty.component.css']
})
export class CustomEmptyComponent implements OnInit {
  @Input() forTemplate = false;

  constructor(public lang: LanguageService) {}

  ngOnInit() {}
}
