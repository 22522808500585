import { LanguageService } from './../../../utils/language.service';
import { Component } from '@angular/core';
import { DataModelService } from './../../../utils/datamodel.service';
import { RoutingService } from '../../../utils/routing.service';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})
export class LogoComponent {
  constructor(public routingService: RoutingService) {}
}
