import { NzNotificationService } from 'ng-zorro-antd';
import { LanguageService } from './language.service';
import * as i0 from "@angular/core";
import * as i1 from "ng-zorro-antd/notification";
import * as i2 from "./language.service";
export class NotificationService {
    constructor(nzNotifyService, langService) {
        this.nzNotifyService = nzNotifyService;
        this.langService = langService;
        this.lastRequestResult = null;
        this.requestResultDuration = 3000;
    }
    requestResultNotification(res, shouldSuppressNotification) {
        if (!shouldSuppressNotification) {
            if (!res && (this.lastRequestResult === null || this.lastRequestResult)) {
                this.lastRequestResult = res;
                this.nzNotifyService.error(this.langService.translate().notificationErrorTitle, this.langService.translate().notificationErrorMessage, { nzDuration: this.requestResultDuration });
                setTimeout(() => (this.lastRequestResult = null), this.requestResultDuration);
            }
            else if (res && this.lastRequestResult === null) {
                this.lastRequestResult = res;
                this.nzNotifyService.success(this.langService.translate().notificationSuccessTitle, this.langService.translate().notificationSuccessMessage, { nzDuration: this.requestResultDuration });
                setTimeout(() => (this.lastRequestResult = null), this.requestResultDuration);
            }
        }
    }
}
NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.NzNotificationService), i0.ɵɵinject(i2.LanguageService)); }, token: NotificationService, providedIn: "root" });
