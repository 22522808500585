import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Question } from '../../../models/question';
import { QuestionType } from '../../../models/questionType';
import { SegmentEntry } from '../../../models/segmentEntry';
import { LanguageService } from '../../../utils/language.service';
import { QuestionService } from '../../../utils/question.service';
import { SegmentImplementationService } from '../../../utils/segment-implementation.service';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent {
  @Input()
  segmentEntry: SegmentEntry;
  @Input()
  answer: string;
  @Input()
  shouldSaveAnswer = false;
  @Input()
  answeringDisabled = false;

  @Output()
  answered = new EventEmitter<string>();

  constructor(
    public languageService: LanguageService,
    public questionService: QuestionService,
    private segmentImplService: SegmentImplementationService
  ) {}

  isCheckbox(question: Question): boolean {
    return question.type === QuestionType.CHECKBOX;
  }

  isRadio(question: Question): boolean {
    return question.type === QuestionType.RADIO;
  }

  isTextField(question: Question): boolean {
    return question.type === QuestionType.TEXTFIELD;
  }

  isMultipleChoice(question: Question): boolean {
    return question.type === QuestionType.MULTIPLE_CHOICE;
  }

  isDropdown(question: Question): boolean {
    return question.type === QuestionType.SELECT;
  }

  prepareAnswerForCheckbox(answer: string): boolean {
    return answer && answer.length > 0 ? JSON.parse(answer) : false;
  }

  getAnswer(): string {
    return this.answer === null || this.answer === undefined
      ? this.questionService.getQuestionAnswer(this.segmentEntry.entry._id)
      : this.answer;
  }

  handleAnswer(answer: string): void {
    this.answered.emit(answer);
    if (this.shouldSaveAnswer) {
      this.questionService.insertOrUpdateCacheAnswer(
        this.segmentEntry.entry,
        answer
      );
      this.questionService.saveCacheAnswer();
      this.segmentImplService.checkSegmentState();
    }
  }
}
