import { Answer } from './answer';

export class AuditAnswer {
  constructor(
    public _id: string,
    public companyUUID: string,
    public questionUUID: string,
    public answers: Answer[]
  ) {}

  toJSON() {
    return {
      _id: this._id,
      companyUUID: this.companyUUID,
      questionUUID: this.questionUUID,
      answers: this.answers.map(a => a._id)
    };
  }
}
