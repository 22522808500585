/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../popoverMarked/popoverMarked.component.ngfactory";
import * as i4 from "../../popoverMarked/popoverMarked.component";
import * as i5 from "../../../../utils/text-resolver.service";
import * as i6 from "./list.component";
import * as i7 from "../../../../utils/language.service";
import * as i8 from "../../../../utils/question.service";
var styles_ListComponent = [i0.styles];
var RenderType_ListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ListComponent, data: {} });
export { RenderType_ListComponent as RenderType_ListComponent };
function View_ListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "avatar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.segmentEntry.entry.iconPath; _ck(_v, 1, 0, currVal_0); }); }
function View_ListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "part"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "span", [["class", "base-point"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { point: 0 }), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-popover-marked", [], null, null, null, i3.View_PopoverMarkedComponent_0, i3.RenderType_PopoverMarkedComponent)), i1.ɵdid(8, 49152, null, 0, i4.PopoverMarkedComponent, [i5.TextResolverService], { text: [0, "text"], helpMap: [1, "helpMap"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "base-point"; var currVal_1 = _ck(_v, 4, 0, !_co.isNumbered()); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = _co.textResolverService.getLanguageText(_v.context.$implicit); var currVal_4 = _co.entryHelpMaps[_v.context.index]; _ck(_v, 8, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getPoint(_v.context.index); _ck(_v, 5, 0, currVal_2); }); }
export function View_ListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "info"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { result: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListComponent_2)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "info"; var currVal_1 = _ck(_v, 3, 0, _co.segmentEntry.result); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.hasIcon(); _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.segmentEntry.entry.textParts; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_ListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-list", [], null, null, null, View_ListComponent_0, RenderType_ListComponent)), i1.ɵdid(1, 114688, null, 0, i6.ListComponent, [i7.LanguageService, i8.QuestionService, i5.TextResolverService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ListComponentNgFactory = i1.ɵccf("app-list", i6.ListComponent, View_ListComponent_Host_0, { segmentEntry: "segmentEntry", entryHelpMaps: "entryHelpMaps" }, {}, []);
export { ListComponentNgFactory as ListComponentNgFactory };
