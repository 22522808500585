import { Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataModelService } from '../../../utils/datamodel.service';
import { LanguageService } from '../../../utils/language.service';
import { TextResolverService } from '../../../utils/text-resolver.service';

@Component({
  selector: 'app-shared-anchor',
  templateUrl: './shared-anchor.component.html',
  styleUrls: ['./shared-anchor.component.css']
})
export class SharedAnchorComponent implements OnDestroy {
  @Input() topOffset = '2em';
  @Input() scrollOffset = '100';
  @Input()
  links: { name: string; id: string }[] = [];

  subscription: Subscription;

  constructor(
    public dataModel: DataModelService,
    public languageService: LanguageService,
    public textresolveService: TextResolverService
  ) {}

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @Input()
  set langBasedLinks(langBasedLinks: string[]) {
    this.subscription = this.languageService.language.subscribe(_ => {
      this.links = [];
      langBasedLinks.forEach(langLink =>
        this.links.push({
          name: this.textresolveService.getLanguageText(langLink),
          id: this.getHref(langLink)
        })
      );
    });
  }

  getHref(langLink: string): string {
    return (
      '#' +
      this.textresolveService
        .getLanguageText(langLink)
        .split(' ')
        .join('-')
        .toLocaleLowerCase()
        .replace(/[^A-Za-z0-9\-]/g, '')
    );
  }
}
