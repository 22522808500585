<div class="main row">
  <span class="title-container">
    <span class="title">
      <ng-container *ngIf="!this.consultMode; else titleConsultMode">
        <app-format-text
          [text]="this.textResolverService.getLanguageText(this.segment.title)"
        ></app-format-text>
      </ng-container>
    </span>
    <app-rename
      [name]="this.textResolverService.getLanguageText(segment.title)"
      (renamed)="this.titleRenamed.emit($event)"
      *ngIf="this.consultMode"
    ></app-rename>
  </span>
  <span
    class="segment-entries-container"
    cdkDropList
    (cdkDropListDropped)="this.segmentEntryDropped.emit($event)"
  >
    <ng-container *ngIf="this.hasSegmentAnyEntries(); else noSegmentEntries">
      <div
        [ngClass]="{ 'edit-hover': this.consultMode }"
        cdkDrag
        [cdkDragDisabled]="!this.consultMode"
        (dblclick)="this.segmentEntryDoubleClicked.emit(singleEntry)"
        *ngFor="let singleEntry of this.segment.segmentEntries"
      >
        <ng-container *ngIf="this.isRadio(singleEntry)">
          <div class="entry">
            <ng-container
              *ngIf="
                this.consultMode && this.isQuestionNotComplete(singleEntry);
                else normalQuestion
              "
            >
              <div class="no-title" *ngIf="!this.hasTextParts(singleEntry)">
                Kein Text zur Frage vorhanden
              </div>
              <div
                class="no-title"
                *ngIf="!this.hasPredefinedAnswers(singleEntry)"
              >
                Keine Antwortmöglichkeiten zur Frage vorhanden
              </div>
            </ng-container>
            <ng-template #normalQuestion>
              <app-radio
                *ngIf="this.isRadio(singleEntry)"
                [segmentEntry]="singleEntry"
                [answer]="this.getAnswer(singleEntry.entry, false).answer"
                [disabled]="!this.editMode"
                (answered)="this.handleRadioAnswer(singleEntry.entry, $event)"
              ></app-radio>
            </ng-template>
          </div>
        </ng-container>
        <ng-container *ngIf="this.isTextField(singleEntry)">
          <div class="entry">
            <app-textfield
              [segmentEntry]="singleEntry"
              [answer]="this.getAnswer(singleEntry.entry, false).answer"
              [saveButton]="false"
              *ngIf="this.editMode; else normalInfo"
            ></app-textfield>
          </div>
        </ng-container>
        <ng-container *ngIf="this.isInfo(singleEntry) && this.editMode">
          <div class="entry">
            <div
              class="no-title"
              *ngIf="!this.hasTextParts(singleEntry); else normalInfo"
            >
              Kein Text für diese Info vorhanden
            </div>
            <ng-template #normalInfo>
              <app-infos [segmentEntry]="singleEntry"></app-infos>
            </ng-template>
          </div>
        </ng-container>
        <ng-container *ngIf="this.isMasterData(singleEntry)">
          <div class="entry">
            <div
              class="no-title"
              *ngIf="
                !this.hasQuestions(singleEntry.entry);
                else normalMasterData
              "
            >
              Noch keine Stammdaten Fragen vorhanden
            </div>
            <ng-template #normalMasterData>
              <div *ngFor="let questionArray of singleEntry.entry.questions">
                <ng-container *ngFor="let question of questionArray">
                  <ng-container *ngIf="this.editMode">
                    <app-select
                      *ngIf="this.isDropdown(question)"
                      [question]="question"
                      [answer]="this.getAnswer(question, true, false).answer"
                      (answered)="this.handleRadioAnswer(question, $event)"
                    ></app-select>
                    <app-text-input
                      *ngIf="!this.isDropdown(question)"
                      class="separator-input"
                      [tooltip]="this.getStammdatenQuestionTooltip(question)"
                      [text]="this.getAnswer(question, false).answer"
                      [question]="question"
                      [disabled]="this.isDisabled(question)"
                    ></app-text-input>
                  </ng-container>

                  <ng-container *ngIf="!this.editMode">
                    <span
                      class="separator-text no-space"
                      nz-tooltip
                      [nzTitle]="question.question"
                      ><app-format-text
                        [text]="this.getAnswer(question, true, true).answer"
                        [missing]="this.getAnswer(question, true).fallback"
                      ></app-format-text> </span
                  ></ng-container>
                </ng-container>
              </div>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container [ngTemplateOutlet]="this.addEntry" *ngIf="this.consultMode">
    </ng-container>
  </span>
</div>

<ng-template #titleConsultMode>
  <app-format-text
    [text]="this.textResolverService.getLanguageText(this.segment.title)"
    *ngIf="this.hasSegmentTitle(); else noTitle"
  ></app-format-text>
</ng-template>

<ng-template #noTitle>
  <span class="no-title">
    Kein Titel vergeben
  </span>
</ng-template>

<ng-template #noSegmentEntries>
  <span class="no-title">
    Kein Eintrag vorhanden
  </span>
</ng-template>
