import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot
} from '@angular/router';
import { RoutingService } from '../../utils/routing.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: RoutingService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.checkLoginState(state.url);
  }

  bypassLogin(): boolean {
    if (!this.authService.isLoggedIn) {
      this.authService.isLoggedIn = true;
    }
    return true;
  }

  checkLoginState(url: string): boolean {
    if (this.authService.isLoggedIn) {
      return true;
    }
    this.authService.desiredRoute = url;
    this.router.navigateToLogin();
    return false;
  }
}
