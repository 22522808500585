import { v4 } from 'uuid';
import { Segment } from './segment';

export class ChapterCard {
  constructor(
    public _id: string,
    public imagePath: string,
    public title: string,
    public introductionText: string,
    public segments: Map<string, Segment[]>
  ) {}

  static CLEAN_INSTANCE(title: string, iconPath: string): ChapterCard {
    return new ChapterCard(
      v4(),
      iconPath,
      title,
      null,
      new Map([['', [Segment.CLEAN_INSTANCE()]]])
    );
  }

  toJSON() {
    return {
      _id: this._id,
      imagePath: this.imagePath,
      title: this.title,
      introductionText: this.introductionText,
      segments: this.segmentMapToJSON()
    };
  }

  segmentMapToJSON() {
    return {
      dataType: 'Map',
      value: Array.from(this.segments.entries()).map(val => [
        val[0],
        val[1].map(segment => segment._id)
      ])
    };
  }
}
