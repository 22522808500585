import { TextResolverService } from './text-resolver.service';
import * as i0 from "@angular/core";
import * as i1 from "./text-resolver.service";
export class HelpMapService {
    constructor(textResolverService) {
        this.textResolverService = textResolverService;
    }
    getHelpMapForTextParts(segmentEntry) {
        const entryHelpMaps = [];
        if (segmentEntry && segmentEntry.helpMap) {
            const usedHelpMapKeys = [];
            const helpMapWords = Array.from(segmentEntry.helpMap.keys());
            for (let index = 0; index < segmentEntry.entry.textParts.length; index++) {
                const textPart = this.textResolverService.getLanguageText(segmentEntry.entry.textParts[index]);
                entryHelpMaps.push(new Map());
                helpMapWords.forEach(helpMapWord => {
                    if (textPart &&
                        textPart.includes(this.textResolverService.getLanguageText(helpMapWord)) &&
                        !usedHelpMapKeys.includes(helpMapWord)) {
                        entryHelpMaps[index].set(helpMapWord, segmentEntry.helpMap.get(helpMapWord));
                        usedHelpMapKeys.push(helpMapWord);
                    }
                });
            }
        }
        return entryHelpMaps;
    }
}
HelpMapService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HelpMapService_Factory() { return new HelpMapService(i0.ɵɵinject(i1.TextResolverService)); }, token: HelpMapService, providedIn: "root" });
