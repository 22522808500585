export class Notice {
  constructor(
    public _id: string,
    public fromCompanyUUID: string,
    public toCompanyUUID: string,
    public userUUID: string,
    public entityUUID: string,
    public text: string,
    public timestamp: number
  ) {}
}
