<app-chapter-card [chapterCard]="this.dataModel.stammdaten" [isOpen]="true">
  <div class="button">
    <button
      nz-button
      nzType="primary"
      class="default-button hollow-button"
      (click)="this.toggleEditModeWithoutSave()"
      *ngIf="!this.shouldBeSlides && this.editMode"
    >
      {{ this.getCancelButtonText() }}
    </button>
    <button
      nz-button
      nzType="primary"
      class="default-button hollow-button margin-button-save-edit"
      (click)="this.toggleEditModeWithSave()"
      *ngIf="!this.shouldBeSlides"
    >
      {{ getSaveEditButtonText() }}
    </button>
  </div>
  <ng-container *ngIf="this.shouldBeSlides">
    <app-slider
      #slider
      [amountSegments]="this.getAmountSegments()"
      [showIndicators]="true"
      [idToScroll]="this.getID()"
    >
      <app-group
        [editMode]="true"
        [segment]="this.getCurrentSegment(slider._currentSegment)"
      ></app-group>
    </app-slider>
  </ng-container>
  <ng-container *ngIf="!this.shouldBeSlides"
    ><ng-container *ngFor="let segment of this.getSegmentArray(); index as i">
      <app-group
        [shouldSave]="this.shouldSave"
        [editMode]="this.editMode"
        [segment]="segment"
      ></app-group>
      <nz-divider *ngIf="!isLastSegment(i)"></nz-divider> </ng-container
  ></ng-container>

  <div class="placeholder"></div>
</app-chapter-card>
