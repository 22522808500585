import { Location } from '@angular/common';
import { AfterViewInit, Component } from '@angular/core';
import { AuthService } from '../../../guards/auth/auth.service';
import { LanguageService } from '../../../utils/language.service';
import { RoutingService } from '../../../utils/routing.service';

@Component({
  selector: 'app-agb',
  templateUrl: './agb.component.html',
  styleUrls: ['./agb.component.css']
})
export class AgbComponentComponent implements AfterViewInit {
  constructor(public authService: AuthService, private _location: Location, public lang: LanguageService, private router: RoutingService) {}

  ngAfterViewInit(): void {
    const innerdiv = document.getElementById('innerdiv') as HTMLDivElement;
    if (innerdiv) {
      innerdiv.scrollIntoView();
    }
  }

  zurueck(): void {
    this._location.back();
  }
  getSectionCount(json: JSON): string[] {
    const retArr = [];
    for (let index = 0; index < Object.keys(json).length; index++) {
      retArr.push(index + 1 + '');
    }
    return retArr;
  }
  getKeysFromJSON(json: JSON): string[] {
    return Object.keys(json);
  }
}
