<nz-select
  class="width"
  [nzDropdownMatchSelectWidth]="false"
  [ngModel]="resultObject.tag"
  (ngModelChange)="changeSelectedTag($event)"
>
  <nz-option
    *ngFor="let tag of allConditionTags"
    [nzValue]="tag.tag"
    [nzLabel]="tag.tag"

  ></nz-option>
</nz-select>
<nz-radio-group class="margin-top width" [(ngModel)]="resultObject.condition">
  <label
    nz-radio
    *ngFor="let answer of selectedQuestionAnswers"
    [nzValue]="answer._id"
    class="wrap"
    >{{ answer.answer }}</label
  >
</nz-radio-group>
