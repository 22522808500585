export class AuditFile {
  constructor(
    public _id: string,
    public entityUUID: string,
    public providedForCompanyUUID: string,
    public providedByCompanyUUID: string,
    public userUUID: string,
    public fileName: string,
    public dateCreated: number,
    public fileSize: number,
    public lang: string
  ) {}
}
