<div class="carouselDiv" (wheel)="onMouseWheel($event)">
  <nz-carousel #carousel class="carousel" (nzAfterChange)="change($event)" [nzAutoPlay]="!isPaused" [nzAutoPlaySpeed]="8000" [nzEffect]="'scrollx'">
    <div class="heigth" nz-carousel-content *ngFor="let tile of lang.translate().landing_tiles">
      <mat-card (mouseenter)="isPaused = true" (mouseleave)="isPaused = false" class="cardStyle">
        <mat-card-header>
          <mat-card-title class="headertext wordwrap">
            {{ tile[0] }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="noselect contentHeight">
          <p *ngIf="tile !== lang.translate().landing_tiles[lang.translate().landing_tiles.length - 1]" class="textAlignLeft contendPadding inner">{{ tile[1] }}</p>
          <div *ngIf="tile === lang.translate().landing_tiles[lang.translate().landing_tiles.length - 1]" class="textAlignLeft wordwrap contendPadding inner">
            <div class="row">
              <div class="coloumnEins"><p></p></div>
              <div class="coloumnZwei">{{ tile[1] }}</div>
              <div class="coloumnDrei">{{ tile[2] }}</div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </nz-carousel>
  <div class="buttongroup">
    <i
      nz-icon
      class="clickable"
      [ngClass]="{ notClickable: isPaused, clickable: !isPaused }"
      (click)="isPaused = true"
      nzType="pause-circle"
      nzTheme="twotone"
      [nzTwotoneColor]="'#0f94c3'"
    >
    </i>
    <i
      nz-icon
      [ngClass]="{ notClickable: !isPaused, clickable: isPaused }"
      (click)="isPaused = false"
      class="buttonmargin"
      nzType="play-circle"
      nzTheme="twotone"
      [nzTwotoneColor]="'#0f94c3'"
    >
    </i>
  </div>
</div>
