import { Injectable } from '@angular/core';
import { v4 } from 'uuid';
import { DBCollections } from '../models/dbCollections';
import { LanguageEnglisch } from './../models/language.englisch';
import { LanguageText } from './../models/languageText';
import { DataModelService } from './datamodel.service';
import { LanguageService } from './language.service';
import { LanguageDeutsch } from '../models/language.deutsch';

@Injectable({
  // damit der service ein singleton ist
  providedIn: 'root'
})
export class TextResolverService {
  constructor(
    private languageService: LanguageService,
    private dataModelService: DataModelService
  ) {}

  setLanguageText(text: string, uuid?: string): string {
    const currentLang = this.languageService.translate();
    let created = false;
    let langText: LanguageText;
    if (uuid && uuid !== undefined) {
      langText = this.dataModelService.languageTexts.find(
        item => item._id === uuid
      );
    } else {
      created = true;
      langText = new LanguageText(v4(), null, null);
      this.dataModelService.languageTexts.push(langText);
    }
    switch (true) {
      case currentLang instanceof LanguageDeutsch:
        langText.de = text;
        break;
      case currentLang instanceof LanguageEnglisch:
        langText.en = text;
        break;
    }
    if (created) {
      this.dataModelService.insertIntoCollection(
        [langText],
        DBCollections.LANGUAGE_TEXTS
      );
    } else {
      this.dataModelService.updateCollection(
        langText._id,
        { de: langText.de, en: langText.en },
        DBCollections.LANGUAGE_TEXTS
      );
    }
    return langText._id;
  }

  updateInsertLanguageTexts(langTexts: LanguageText[]): void {
    langTexts.forEach(langText => {
      const langTextInCollection = this.dataModelService.languageTexts.find(
        text => text._id === langText._id
      );
      if (langTextInCollection) {
        this.dataModelService.languageTexts.splice(
          this.dataModelService.languageTexts.indexOf(
            this.dataModelService.languageTexts.find(
              text => text._id === langText._id
            )
          ),
          1,
          langText
        );
        this.dataModelService.updateCollection(
          langText._id,
          { de: langText.de, en: langText.en },
          DBCollections.LANGUAGE_TEXTS
        );
      } else {
        this.dataModelService.languageTexts.push(langText);
        this.dataModelService.insertIntoCollection(
          [langText],
          DBCollections.LANGUAGE_TEXTS
        );
      }
    });
  }

  deleteLanguageTexts(toDeleteLanguageTexts): void {
    toDeleteLanguageTexts.forEach(langText => {
      const langTextInCollection = this.dataModelService.languageTexts.find(
        text => text._id === langText._id
      );
      if (langTextInCollection) {
        this.dataModelService.languageTexts.splice(
          this.dataModelService.languageTexts.indexOf(langTextInCollection),
          1
        );
        this.dataModelService.deleteFromCollection(
          langText._id,
          DBCollections.LANGUAGE_TEXTS
        );
      }
    });
  }

  getLanguageText(uuid: string): string {
    if (!uuid) {
      return null;
    }
    const currentLang = this.languageService.translate();
    const langText = this.dataModelService.languageTexts.find(
      text => text._id === uuid
    );
    if (langText) {
      switch (true) {
        case currentLang instanceof LanguageDeutsch:
          return langText.de || this.getFallbackText(langText);
        case currentLang instanceof LanguageEnglisch:
          return langText.en || this.getFallbackText(langText);
        default:
          return langText.en || this.getFallbackText(langText);
      }
    }
    return 'Missing text';
  }

  getFallbackText(langText: LanguageText): string {
    return (
      '<span style="background-color: orange">' +
      (langText.en || langText.de) +
      '</span>'
    );
  }
}
