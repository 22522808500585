import { Component, Input } from '@angular/core';
import { InfoType } from '../../../../models/infoType';
import { SegmentEntry } from '../../../../models/segmentEntry';
import { LanguageService } from '../../../../utils/language.service';
import { QuestionService } from '../../../../utils/question.service';
import { TextResolverService } from '../../../../utils/text-resolver.service';

@Component({
  selector: 'app-list-grouped',
  templateUrl: './list-grouped.component.html',
  styleUrls: ['./list-grouped.component.css']
})
export class ListGroupedComponent {
  @Input()
  segmentEntry: SegmentEntry;
  @Input()
  entryHelpMaps: Map<string, string>[];

  constructor(
    public languageService: LanguageService,
    public questionService: QuestionService,
    public textResolverService: TextResolverService
  ) {}

  hasIcon() {
    return (
      this.segmentEntry.entry.iconPath &&
      this.segmentEntry.entry.iconPath.length > 0
    );
  }

  isNumbered(): boolean {
    return this.segmentEntry.entry.type === InfoType.LIST_NUMBERED_GROUPED;
  }

  getPoint(index: number): string {
    return this.isNumbered() ? index + 1 + '.' : '∙';
  }
}
