import { LanguageService } from './language.service';
import { FormOfAddress } from './../models/formOfAddress';
import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  forkJoin,
  Observable,
  Subscription,
  of,
  empty
} from 'rxjs';
import { map } from 'rxjs/operators';
import { v4 } from 'uuid';
import { AuditAnswer } from '../models/auditAnswer';
import { AuditFile } from '../models/auditFile';
import { Chapter } from '../models/chapter';
import { ChapterCard } from '../models/chapterCard';
import { DBCollections } from '../models/dbCollections';
import { GlossarEntry } from '../models/glossarentry';
import { LanguageText } from '../models/languageText';
import { Notice } from '../models/notice';
import { Segment } from '../models/segment';
import { SegmentCondition } from '../models/segmentCondition';
import { SegmentEntryType } from '../models/segmentEntryType';
import { TemplateFile } from '../models/templateFile';
import { Company } from './../models/company';
import { NotifyEntry } from './../models/notifyEntry';
import { SegmentEntry } from './../models/segmentEntry';
import { User } from './../models/user';
import { Cookies } from './cookies.service';
import { DBService } from './dbverbindung.service';
import { NotificationService } from './notification.service';
import { SocketService } from './socket.service';
import { QuestionType } from '../models/questionType';
import { Question } from '../models/question';
import { Info } from '../models/info';
import { InfoType } from '../models/infoType';
import { MasterData } from '../models/masterData';
import { Tag } from '../models/tag';
import { Telemetrie } from '../models/telemetrie';
import { Country } from '../models/country';
import { Email, EmailTypeEnum } from '../models/email';
import { BookedPackage } from '../models/bookedPackage';
import { EmailTag } from '../models/emailTag';
import { CustomerReport } from '../models/customerReport';
import { CustomerReportEntry } from '../models/customerReportEntry';

@Injectable({
  providedIn: 'root'
})
export class DataModelService implements OnDestroy {
  // logged in user and company information
  user: User;
  company: Company;

  // in case the company has various user - additionally to the logged in user we need references to the other users
  // to be able to get the meta information as name and other referencing stuff
  otherUsersOfCompany: User[] = [];

  // usually the logged in company itself - will be used as a consult for the company to be inspected
  selectedCompany2 = new BehaviorSubject<Company>(null);
  // usually the logged in user itself - will be used as a consult to inspect user related answers
  selectedUser: User;

  reports: CustomerReport[] = [];
  selectedReport: CustomerReport;
  // consult data
  availableCompanies: Company[] = [];
  availableUsers: User[] = [];
  availableCountries: Country[] = [];

  // reference to the ePrivacy company and users for referencing stuff like files
  epcCompany: Company;
  epcUsers: User[];

  openSider = true;

  lowerSidenavVisible = false;
  showFooter = true;
  bypassUsualRouterOutlet = new BehaviorSubject<boolean>(false);
  scrollElement = new BehaviorSubject<string>(null);
  glossarMap: Map<string, GlossarEntry[]> = new Map();
  tiles: string[][] = [];

  // NotifyBar
  notifyEntrySubject = new BehaviorSubject<NotifyEntry[]>([]);

  // audit related data which relates to every logged in user
  selectedChapter: Chapter;
  chapters: Chapter[] = [];
  stammdaten: ChapterCard = new ChapterCard(
    'd2f551d5-5ba2-47e0-b0ab-24b17daf3db3',
    'assets/icons8-bilden-80.png',
    'stammdaten',
    '3567a54c-4c87-4165-879f-af314983993f',
    null
  );
  // language text uuid
  manualContentUUID = '725b2270-d04e-405b-a33e-18dfa69c46b1';
  languageTexts: LanguageText[] = [];

  // audit data which changes based on logged in (as consult selected) company
  auditAnswers: AuditAnswer[] = [];
  auditFiles: AuditFile[];
  templateFiles: TemplateFile[] = [];
  notices: Notice[] = [];
  // contains the last 2 logins (if exist) since as a consult we want to see the latest login
  // and as a user we want to see the login before the current one
  lastLogin: Telemetrie[] = [];

  // tags are bound to questions (tag has a property used in which holds the uuid of the question its bound to)
  // will be used to 'tag' questions so it can explicity be found and its answers be handled like master data
  tags: Tag[] = [];

  // a reference of all questions which are being sent from the backend. will get mapped into segmententries
  // and ued where needed. since the tag management will bound tags to questions and questions may occur
  // in different entities, it will get tricky to 'find' all questions available. so thats why we hold
  // a reference to all, just to be able to search through all of them
  questions: Question[] = [];

  subscriptions: Subscription[] = [];

  // form of addresses
  formOfAddresses = [
    { uuid: 'b1b7e9c0-832a-41e5-b596-902ff383dd57' }, // Herr
    { uuid: 'faa0f899-300e-49ae-b708-9bd256e7267d' } // Frau
  ];

  // additional 'chapters' used for templates - not part of the chapter array
  templateChapters: Chapter[] = [
    new Chapter(
      '43561887-b99f-40bc-aeef-40338375e691',
      '8990f6c2-ddf5-4ea3-90f4-ffc566f2dc51',
      '',
      '',
      99,
      false,
      false,
      null,
      null
    ),
    new Chapter(
      'a7ba1488-8a9a-4e58-86a1-02a7eebe84c8',
      '68a0c24f-74b8-4d8c-b938-99c1a54cf291',
      '',
      '',
      99,
      false,
      false,
      null,
      null
    )
  ];

  templateChaptersLanguageTexts: LanguageText[] = [
    new LanguageText(
      '8990f6c2-ddf5-4ea3-90f4-ffc566f2dc51',
      'Sonstige',
      'Other'
    ),
    new LanguageText(
      '68a0c24f-74b8-4d8c-b938-99c1a54cf291',
      'Archiv',
      'Archive'
    )
  ];

  // additional 'chapters' used for documents - not part of the chapter array
  documentChapters: Chapter[] = [
    new Chapter(
      'c1c237f8-fc11-4c3b-841e-7cf31bbaa169',
      '60c4d2f1-d0a9-4c1c-a431-dadf4b8278f9',
      '',
      '',
      99,
      false,
      false,
      null,
      null
    )
  ];

  documentChaptersLanguageTexts: LanguageText[] = [
    new LanguageText(
      '60c4d2f1-d0a9-4c1c-a431-dadf4b8278f9',
      'Sonstige',
      'Other'
    )
  ];

  // additional language texts for local master data
  stammdatenLanguageTexts: LanguageText[] = [
    new LanguageText(
      '3567a54c-4c87-4165-879f-af314983993f',
      'Folgend Ihre Stammdaten',
      'Following your Master Data'
    )
  ];

  constructor(
    private dbService: DBService,
    private socket: SocketService,
    private notificationService: NotificationService,
    private cookieService: Cookies,
    private lang: LanguageService
  ) {
    this.tempkrams();
    this.subscriptions.push(
      this.socket.getMessages().subscribe((sock: any) => {
        if (this.user) {
          socket.emitUpdate(this.user);
        }
      })
    );
  }

  updateSession(user: User): void {
    this.socket.emitUpdate(user);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  createUser(user: User, bookedPackage: BookedPackage): void {
    this.subscriptions.push(
      this.dbService
        .createUser(user, this.user._id, user._id)
        .subscribe(res => {
          user.formOfAddress = this.lang.translate()[
            FormOfAddress[user.formOfAddress]
          ] as any;
          if (
            bookedPackage !== BookedPackage.test &&
            bookedPackage !== BookedPackage.withoutAudit
          ) {
            console.log('NewUserID');

            console.log(user._id);

            this.dbService.sendEmail(
              new Email(
                user.companyUUID,
                user._id,
                this.getEmailName(bookedPackage),
                [],
                user.lang
              )
            );
          }
        })
    );
  }

  getEmailName(bookedPackage: BookedPackage): EmailTypeEnum {
    switch (bookedPackage) {
      case BookedPackage.full:
        return EmailTypeEnum.Accounterstellung_Vollversion_Kunde;
      case BookedPackage.fullWithConsulting:
        return EmailTypeEnum.Accounterstellung_Vollversion_Beratung_Kunde;
      case BookedPackage.fullWithDSB:
        return EmailTypeEnum.Accounterstellung_Vollversion_DSB_Kunde;
    }
  }

  createCompanyWithUser(company: Company, user: User, lang: string): void {
    this.subscriptions.push(
      this.dbService
        .createCompany(company, this.user._id, user._id, lang)
        .subscribe(res => {
          this.notificationService.requestResultNotification(res);
          this.createUser(user, company.bookedPackage);
        })
    );
  }

  updateUser(user: User): void {
    this.subscriptions.push(
      this.dbService
        .updateUser(user, this.user._id, user._id)
        .subscribe(res =>
          this.notificationService.requestResultNotification(res)
        )
    );
  }

  updateCompany(company: Company): void {
    this.subscriptions.push(
      this.dbService
        .updateCompany(company, this.user._id, this.selectedUser._id)
        .subscribe(res =>
          this.notificationService.requestResultNotification(res)
        )
    );
  }

  insertChapter(chapter: Chapter): void {
    this.insertIntoCollection([chapter], DBCollections.CHAPTERS);
    chapter.chapterCards.forEach(chapterCard => {
      this.insertIntoCollection([chapterCard], DBCollections.CHAPTER_CARDS);
      Array.from(chapterCard.segments.values()).forEach(segmentArray =>
        segmentArray.forEach(segment => {
          this.insertIntoCollection([segment], DBCollections.SEGMENTS);
        })
      );
    });
  }

  insertIntoCollection(data: any[], collection: string) {
    this.subscriptions.push(
      this.dbService
        .insertIntoCollection(
          data.map(d => JSON.stringify(d)),
          collection
        )
        .subscribe(res =>
          this.notificationService.requestResultNotification(res)
        )
    );
  }

  deleteFromCollection(
    _ids: string[],
    collection: string,
    isTemplateFile = false
  ) {
    this.subscriptions.push(
      this.dbService
        .deleteFromCollection(
          _ids,
          collection,
          this.selectedCompany2.getValue()._id,
          isTemplateFile
        )
        .subscribe(res =>
          this.notificationService.requestResultNotification(res)
        )
    );
  }

  updateCollection(
    _id: string,
    dataToBeUpdated: any,
    collection: string,
    shouldSuppressNotification?: boolean
  ): void {
    this.subscriptions.push(
      this.dbService
        .updateCollection(_id, JSON.stringify(dataToBeUpdated), collection)
        .subscribe(res => {
          this.notificationService.requestResultNotification(
            res,
            shouldSuppressNotification
          );
        })
    );
  }

  segmentMapToJSON(chapterCard: ChapterCard) {
    return {
      dataType: 'Map',
      value: Array.from(chapterCard.segments.entries()).map(val => [
        val[0],
        val[1].map(segment => segment._id)
      ])
    };
  }

  helpMapToJSON(segmentEntry: SegmentEntry) {
    return {
      dataType: 'Map',
      value: Array.from(segmentEntry.helpMap.entries())
    };
  }

  questionStateToJSON(segmentCondition: SegmentCondition) {
    return {
      dataType: 'Map',
      value: Array.from(segmentCondition.questionStates.entries())
    };
  }

  setUserLang(language: string) {
    if (this.user) {
      this.user.lang = language;
      this.updateCollection(
        this.user._id,
        { lang: language },
        DBCollections.USERS,
        true
      );
    }
    this.cookieService.setLangInApplicationCookie(language);
  }

  getMyNotifyContent(): BehaviorSubject<NotifyEntry[]> {
    return this.notifyEntrySubject;
  }

  getAllInitialData(companyUUID: string, userUUID: string): Observable<any[]> {
    return forkJoin([
      this.getApplicationData(userUUID),
      companyUUID ? this.getCompanyRelatedData(companyUUID, userUUID) : of({})
    ]);
  }

  getApplicationData(userUUID: string): Observable<any[]> {
    const responses: Observable<any>[] = [];
    responses.push(
      this.dbService.getAuditData(userUUID).pipe(
        map(chapters => {
          try {
            const chaptersJSON = chapters[DBCollections.CHAPTERS];
            const chapterCardsJSON = chapters[DBCollections.CHAPTER_CARDS];
            const segmentsJSON = chapters[DBCollections.SEGMENTS];
            const segmentConditionsJSON =
              chapters[DBCollections.SEGMENT_CONDITIONS];
            const segmentEntriesJSON = chapters[DBCollections.SEGMENT_ENTRIES];
            const questionsJSON = chapters[DBCollections.QUESTIONS];
            const infosJSON = chapters[DBCollections.INFOS];
            const masterDataJSON = chapters[DBCollections.MASTER_DATA];
            const segmentFilesDataJSON = chapters[DBCollections.SEGMENT_FILES];
            const masterDataSegmentsJSON =
              chapters[DBCollections.MASTER_DATA_SEGMENTS];

            this.questions = questionsJSON;

            chaptersJSON.forEach(chapter => {
              chapter.chapterCards = chapter.chapterCards.reduce(
                (acc, chapterCardUUID) => {
                  const chapterCard = chapterCardsJSON.find(
                    card => card._id === chapterCardUUID
                  );
                  if (chapterCard !== undefined) {
                    acc.push(chapterCard);
                  }
                  return acc;
                },
                []
              );
              chapter.chapterCards.forEach(chapterCard => {
                Array.from(chapterCard.segments.entries()).forEach(
                  mapSegment => {
                    mapSegment[1] = mapSegment[1].reduce((acc, segmentUUID) => {
                      const segment = segmentsJSON.find(
                        seg => seg._id === segmentUUID
                      );
                      if (segment !== undefined) {
                        acc.push(segment);
                      }
                      return acc;
                    }, []);
                    chapterCard.segments.set(mapSegment[0], mapSegment[1]);
                    Array.from(chapterCard.segments.values()).forEach(
                      (segmentArray: Segment[]) =>
                        segmentArray.forEach((segment: Segment) => {
                          segment.segmentConditions = segment.segmentConditions
                            ? segment.segmentConditions.reduce(
                                (acc, segmentConditionUUID) => {
                                  const segmentCondition = segmentConditionsJSON.find(
                                    sC => sC._id === segmentConditionUUID
                                  );
                                  if (segmentCondition !== undefined) {
                                    acc.push(segmentCondition);
                                  }
                                  return acc;
                                },
                                []
                              )
                            : null;
                          segment.segmentEntries = segment.segmentEntries.reduce(
                            (acc, segmentEntryUUID) => {
                              const segmentEntry = segmentEntriesJSON.find(
                                sE => sE._id === segmentEntryUUID
                              );
                              if (segmentEntry !== undefined) {
                                acc.push(segmentEntry);
                              }
                              return acc;
                            },
                            []
                          );
                          this.mapAnyEntriesToTypedEntries(
                            segment.segmentEntries,
                            questionsJSON,
                            infosJSON,
                            masterDataJSON,
                            segmentFilesDataJSON
                          );
                        })
                    );
                  }
                );
              });
            });

            masterDataSegmentsJSON.forEach(mDS => {
              mDS.segmentEntries = mDS.segmentEntries.reduce((acc, sEUUID) => {
                const segmentEntry = segmentEntriesJSON.find(
                  sE => sE._id === sEUUID
                );
                if (segmentEntry) {
                  acc.push(segmentEntry);
                }
                return acc;
              }, []);
              this.mapAnyEntriesToTypedEntries(
                mDS.segmentEntries,
                questionsJSON,
                infosJSON,
                masterDataJSON,
                segmentFilesDataJSON
              );
            });

            this.stammdaten.segments = new Map([
              ['', masterDataSegmentsJSON.sort((s1, s2) => s1.order - s2.order)]
            ]);

            this.chapters = chaptersJSON;
            this.chapters = this.chapters.sort(
              (ch1, ch2) => ch1.order - ch2.order
            );
            this.dbService.chaptersFetching = false;
          } catch (e) {
            this.dbService.chaptersFetching = false;
            console.log(e);
          }
        })
      )
    );

    responses.push(
      this.dbService.getLanguageTexts().pipe(
        map(langTexts => {
          this.languageTexts = [
            ...this.templateChaptersLanguageTexts,
            ...this.documentChaptersLanguageTexts,
            ...this.stammdatenLanguageTexts,
            ...langTexts
          ];
          this.dbService.languageTextsFetching = false;
        })
      )
    );

    responses.push(
      this.dbService.getTemplateFiles().pipe(
        map(templateFiles => {
          this.templateFiles = templateFiles;
          this.dbService.templateFilesFetching = false;
        })
      )
    );

    responses.push(
      this.dbService.getEPCCompany().pipe(
        map(epcCompany => {
          this.epcCompany = epcCompany[0];
          this.dbService.epcCompanyFetching = false;
        })
      )
    );

    responses.push(
      this.dbService.getEPCUsers().pipe(
        map(epcUsers => {
          this.epcUsers = epcUsers;
          this.dbService.epcUsersFetching = false;
        })
      )
    );

    responses.push(
      this.dbService.getTags().pipe(
        map(tags => {
          this.tags = tags.sort((t1, t2) => t1.name.localeCompare(t2.name));
          this.dbService.tagsFetching = false;
        })
      )
    );

    return forkJoin(responses);
  }

  getCompanyRelatedData(
    companyUUID: string,
    userUUID: string
  ): Observable<any[]> {
    const responses: Observable<any>[] = [];

    responses.push(
      this.dbService.getAuditFiles(companyUUID).pipe(
        map(auditFiles => {
          this.auditFiles = auditFiles;
          this.dbService.auditFilesFetching = false;
        })
      )
    );

    responses.push(
      this.dbService.getNotifyEntries(userUUID).pipe(
        map(notifyEntries => {
          this.notifyEntrySubject.next(notifyEntries);
          this.dbService.noticesFetching = false;
        })
      )
    );

    responses.push(
      this.dbService.getAuditAnswers(companyUUID).pipe(
        map(auditAnswers => {
          try {
            const auditAnswersJSON = auditAnswers[DBCollections.AUDIT_ANSWERS];
            const answersJSON = auditAnswers[DBCollections.ANSWERS];

            auditAnswersJSON.forEach(
              aA =>
                (aA.answers = aA.answers.reduce((acc, answersUUID) => {
                  const answer = answersJSON.find(a => a._id === answersUUID);
                  if (answer) {
                    acc.push(answer);
                  }
                  return acc;
                }, []))
            );
            this.auditAnswers = auditAnswersJSON;
            this.dbService.auditFilesFetching = false;
          } catch (e) {
            console.log(e);
            this.dbService.auditFilesFetching = false;
          }
        })
      )
    );

    responses.push(
      this.dbService.getNotices(companyUUID).pipe(
        map(notices => {
          this.notices = notices.sort((n1, n2) => n2.timestamp - n1.timestamp);
          this.dbService.noticesFetching = false;
        })
      )
    );

    responses.push(
      this.dbService.getLastLogin(companyUUID).pipe(
        map(lastLogin => {
          this.lastLogin = lastLogin;
          this.dbService.telemetrieLastLoginFetching = false;
        })
      )
    );

    return forkJoin(responses);
  }

  getConsultingData(): Observable<any[]> {
    const responses: Observable<any>[] = [];

    responses.push(
      this.dbService.getCompanies().pipe(
        map(companies => {
          this.availableCompanies = companies.sort((a, b) =>
            a.name.localeCompare(b.name)
          );

          this.selectedCompany2.next(
            new Company(
              '0000-0000',
              'Bitte eine Firma auswählen',
              '1',
              new Country('', '', ''),
              '2',
              BookedPackage.test,
              '0000',
              '0001',
              [],
              [],
              null
            )
          );
          this.dbService.companiesFetching = false;
        })
      )
    );

    responses.push(
      this.dbService.getUsers().pipe(
        map(users => {
          this.availableUsers = users;
          this.dbService.usersFetching = false;
        })
      )
    );

    responses.push(
      this.dbService.getAllReports().pipe(
        map(reports => {
          this.reports = reports;
          this.dbService.reportsFetching = false;
        })
      )
    );

    responses.push(
      this.dbService.getCountries().pipe(
        map(countries => {
          this.availableCountries = countries.sort((c1, c2) =>
            c1.de.localeCompare(c2.de)
          );
        })
      )
    );

    return forkJoin(responses);
  }

  mapAnyEntriesToTypedEntries(
    segmentEntries: SegmentEntry[],
    questionsJSON,
    infosJSON,
    masterDataJSON,
    segmentFilesDataJSON
  ): void {
    segmentEntries.forEach(segmentEntry => {
      switch (segmentEntry.type) {
        case SegmentEntryType.QUESTION:
          segmentEntry.entry = questionsJSON.find(
            question => question._id === segmentEntry.entry
          );
          break;
        case SegmentEntryType.INFO:
          segmentEntry.entry = infosJSON.find(
            info => info._id === segmentEntry.entry
          );
          break;
        case SegmentEntryType.MASTER_DATA:
          segmentEntry.entry = masterDataJSON.find(
            masterData => masterData._id === segmentEntry.entry
          );
          segmentEntry.entry.questions = segmentEntry.entry.questions.map(qA =>
            qA.reduce((acc, questionUUID) => {
              const question = questionsJSON.find(q => q._id === questionUUID);
              if (question) {
                acc.push(question);
              }
              return acc;
            }, [])
          );
          break;
        case SegmentEntryType.FILE:
          segmentEntry.entry = segmentFilesDataJSON.find(
            segmentfilesData => segmentfilesData._id === segmentEntry.entry
          );
          break;
      }
    });
  }
  chapterHasNotices(chapterID): boolean {
    return this.notices.some(notice => notice.entityUUID === chapterID);
  }

  tempkrams(): void {
    // this.notifyEntrySubject.next([
    //   new NotifyEntry(
    //     'Willkommen im neuen ePrivacyAudit. Falls Sie Fragen haben, wenden Sie sich gerne an unseren Support'
    //   ),
    //   new NotifyEntry(
    //     'Willkommen im neuen ePrivacyAudit. Falls Sie Fragen haben, wenden Sie sich gerne an unseren Support'
    //   )
    // ]);
    const tempPanel: GlossarEntry[] = [];
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.push(new GlossarEntry(v4(), v4()));
    tempPanel.sort((a, b) => (a < b ? -1 : 1));
    tempPanel.forEach(element => {
      const letter = element.name.charAt(0);
      if (!this.glossarMap.has(letter)) {
        this.glossarMap.set(letter, [element]);
      } else {
        this.glossarMap.get(letter).push(element);
      }
    });

    // this.stammdatenLanguageTexts.push(
    //   new LanguageText(
    //     '3567a54c-4c87-4165-879f-af314983993f',
    //     'Folgend Ihre Stammdaten',
    //     ''
    //   ),
    //   new LanguageText(
    //     '19613f0e-f51e-4fd9-9fbb-e45ca78084cc',
    //     'Angaben zu Ihrem Unternehmen',
    //     'Company Details'
    //   ),
    //   new LanguageText(
    //     'eb831a30-5bb5-438a-a85d-53bdcbe66e8f',
    //     'Angaben zum gesetzlichen Vertreter / Geschäftsführung',
    //     ''
    //   ),
    //   new LanguageText(
    //     'b8fcf8e3-c9a2-4177-8c6b-39666c93677c',
    //     'Straße',
    //     'Street'
    //   ),
    //   new LanguageText(
    //     'c861e78c-1593-44ae-9143-a16836c843a0',
    //     'Postleitzahl',
    //     ''
    //   ),
    //   new LanguageText('cd84c9bf-6bfb-4a00-829a-3524b58be73f', 'Ort', ''),
    //   new LanguageText(
    //     '787b132d-7b61-462c-9a0b-b768b369d569',
    //     'Telefon',
    //     'Phone'
    //   ),
    //   new LanguageText(
    //     'd3afbd98-e642-4969-bb78-f6534011848d',
    //     'Ansprechpartner Datenschutz',
    //     ''
    //   ),
    //   new LanguageText(
    //     '7ecfcb9f-49b8-4f91-9c63-8263d56300d9',
    //     'Ansprechpartner',
    //     ''
    //   ),
    //   new LanguageText(
    //     'ec96994a-8e93-4f05-86f0-0da8ae2b1e53',
    //     'Vorname',
    //     'First Name'
    //   ),
    //   new LanguageText(
    //     '7b241e46-d39f-405c-b9cd-723c74e26133',
    //     'Nachname',
    //     'Last Name'
    //   ),
    //   new LanguageText(
    //     '671e4106-ce7c-4894-ad3f-382931c0b1d9',
    //     'E-Mail',
    //     'E-Mail'
    //   ),
    //   new LanguageText(
    //     'a1e62120-7cbd-4126-8801-463d8e3e9df8',
    //     'Position Ansprechpartner',
    //     'Job Position'
    //   )
    // );

    // this.stammdaten = new ChapterCard(
    //   'd2f551d5-5ba2-47e0-b0ab-24b17daf3db3',
    //   'assets/icons8-bilden-80.png',
    //   'stammdaten',
    //   '3567a54c-4c87-4165-879f-af314983993f',
    //   new Map([
    //     [
    //       '',
    //       [
    //         new Segment(
    //           '59325519-5cd8-4ec8-9acc-b0b23be58382',
    //           '19613f0e-f51e-4fd9-9fbb-e45ca78084cc',
    //           null,
    //           [
    //             new SegmentEntry(
    //               'bb9dbdb4-af1d-4660-b4f7-a8cee9f538c5',
    //               SegmentEntryType.INFO,
    //               new Info(
    //                 '7e4ec2c0-645b-485e-a62a-c38170aaa074',
    //                 ['eb831a30-5bb5-438a-a85d-53bdcbe66e8f'],
    //                 null,
    //                 InfoType.PLAINTEXT
    //               ),
    //               false,
    //               null
    //             ),
    //             new SegmentEntry(
    //               'fb7bad4c-8326-44ad-a2df-8bdbb4c981f8',
    //               SegmentEntryType.MASTER_DATA,
    //               new MasterData('c11b49ac-2429-4b6d-a124-0ced99b798d6', [
    //                 [
    //                   new Question(
    //                     'a9518930-cabe-4421-a02f-4302cd342827',
    //                     QuestionType.TEXTFIELD,
    //                     ['b8fcf8e3-c9a2-4177-8c6b-39666c93677c'],
    //                     null,
    //                     18,
    //                     null,
    //                     null,
    //                     null,
    //                     null,
    //                     null
    //                   )
    //                 ],
    //                 [
    //                   new Question(
    //                     'ce65122b-83f1-4dd9-bf34-cc5e6d13983b',
    //                     QuestionType.TEXTFIELD,
    //                     ['c861e78c-1593-44ae-9143-a16836c843a0'],
    //                     null,
    //                     8,
    //                     null,
    //                     null,
    //                     null,
    //                     null,
    //                     null
    //                   ),
    //                   new Question(
    //                     'b821e10a-54a1-42ef-85bf-c4189d786c41',
    //                     QuestionType.TEXTFIELD,
    //                     ['cd84c9bf-6bfb-4a00-829a-3524b58be73f'],
    //                     null,
    //                     10,
    //                     null,
    //                     null,
    //                     null,
    //                     null,
    //                     null
    //                   )
    //                 ],
    //                 [
    //                   new Question(
    //                     '974d0cb9-069d-464d-8919-e56752621fc3',
    //                     QuestionType.TEXTFIELD,
    //                     ['787b132d-7b61-462c-9a0b-b768b369d569'],
    //                     null,
    //                     12,
    //                     null,
    //                     null,
    //                     null,
    //                     null,
    //                     null
    //                   )
    //                 ]
    //               ]),
    //               false,
    //               null
    //             )
    //           ]
    //         ),
    //         new Segment(
    //           '234210f4-4a58-4e1e-b573-21459a85d0ba',
    //           'd3afbd98-e642-4969-bb78-f6534011848d',
    //           null,
    //           [
    //             new SegmentEntry(
    //               '0d23b154-6a96-4258-b6c1-fbc98a1be889',
    //               SegmentEntryType.INFO,
    //               new Info(
    //                 'f4dfadb0-f167-43c1-b573-51a92897a99e',
    //                 ['7ecfcb9f-49b8-4f91-9c63-8263d56300d9'],
    //                 null,
    //                 InfoType.PLAINTEXT
    //               ),
    //               false,
    //               null
    //             ),
    //             new SegmentEntry(
    //               '05795cc4-8287-4daa-8630-6f1f58f8772d',
    //               SegmentEntryType.MASTER_DATA,
    //               new MasterData('867837e1-1216-4976-8b32-28283c2ad8a7', [
    //                 [
    //                   new Question(
    //                     'ab2a85a4-ea20-40b1-aa11-4a5f63a8ee8a',
    //                     QuestionType.TEXTFIELD,
    //                     ['ec96994a-8e93-4f05-86f0-0da8ae2b1e53'],
    //                     null,
    //                     10,
    //                     null,
    //                     null,
    //                     null,
    //                     null,
    //                     null
    //                   ),
    //                   new Question(
    //                     '2b0bf53f-527b-44c8-8878-6d6853ee2960',
    //                     QuestionType.TEXTFIELD,
    //                     ['7b241e46-d39f-405c-b9cd-723c74e26133'],
    //                     null,
    //                     12,
    //                     null,
    //                     null,
    //                     null,
    //                     null,
    //                     null
    //                   )
    //                 ],
    //                 [
    //                   new Question(
    //                     'df19cf9c-27a2-40f3-bd28-53e734aa666b',
    //                     QuestionType.TEXTFIELD,
    //                     ['671e4106-ce7c-4894-ad3f-382931c0b1d9'],
    //                     null,
    //                     24,
    //                     null,
    //                     null,
    //                     null,
    //                     null,
    //                     null
    //                   )
    //                 ],
    //                 [
    //                   new Question(
    //                     'f47f9097-1d76-400d-8727-70849e72258d',
    //                     QuestionType.TEXTFIELD,
    //                     ['a1e62120-7cbd-4126-8801-463d8e3e9df8'],
    //                     null,
    //                     12,
    //                     null,
    //                     null,
    //                     null,
    //                     null,
    //                     null
    //                   )
    //                 ]
    //               ]),
    //               false,
    //               null
    //             )
    //           ]
    //         )
    //       ]
    //     ]
    //   ])
    // );

    /*
                new SegmentEntry(
                  v4(),
                  SegmentEntryType.QUESTION,
                  new Question(
                    questionUUID2,
                    QuestionType.RADIO,
                    ['Ist ePrivacy Ihr Datenschutzbeauftragter?'],
                    ['Ja', 'Nein']
                  ),
                  null,
                  [questionUUID1],
                  ['Nein'],
                  [['Nein']]
                ),
                new SegmentEntry(
                  v4(),
                  SegmentEntryType.INFO,
                  new Info(
                    v4(),
                    ['Bitte geben Sie den DSB an'],
                    null,
                    false,
                    InfoType.PLAINTEXT
                  ),
                  null,
                  null,
                  null,
                  null
                ),
                new SegmentEntry(
                  v4(),
                  SegmentEntryType.MASTER_DATA,
                  new MasterData(
                    v4(),
                    [
                      new Question(
                        v4(),
                        QuestionType.TEXTFIELD,
                        ['Name'],
                        null
                      ),
                      new Question(
                        v4(),
                        QuestionType.TEXTFIELD,
                        ['Vorname'],
                        null
                      )
                    ],
                    [InputSize.LARGE, InputSize.LARGE]
                  ),
                  null,
                  [questionUUID1, questionUUID2],
                  ['Nein', 'Ja'],
                  [['', ''], ['Bauer', 'Christoph']]
                ),
                new SegmentEntry(
                  v4(),
                  SegmentEntryType.MASTER_DATA,
                  new MasterData(
                    v4(),
                    [
                      new Question(
                        v4(),
                        QuestionType.TEXTFIELD,
                        ['Adresse'],
                        null
                      ),
                      new Question(v4(), QuestionType.TEXTFIELD, ['Nr.'], null)
                    ],
                    [InputSize.EXTRA_LARGE, InputSize.SMALL]
                  ),
                  null,
                  [questionUUID1, questionUUID2],
                  ['Nein', 'Ja'],
                  [['', ''], ['Große Bleichen', '21']]
                ),
                new SegmentEntry(
                  v4(),
                  SegmentEntryType.MASTER_DATA,
                  new MasterData(
                    v4(),
                    [
                      new Question(v4(), QuestionType.TEXTFIELD, ['PLZ'], null),
                      new Question(v4(), QuestionType.TEXTFIELD, ['Ort'], null)
                    ],
                    [InputSize.MEDIUM, InputSize.LARGE]
                  ),
                  null,
                  [questionUUID1, questionUUID2],
                  ['Nein', 'Ja'],
                  [['', ''], ['20357', 'Hamburg']]
                )
              ]
            ),
            new Segment(v4(), 'Kontakt', null, [
              new SegmentEntry(
                v4(),
                SegmentEntryType.MASTER_DATA,
                new MasterData(
                  v4(),
                  [
                    new Question(v4(), QuestionType.TEXTFIELD, ['E-Mail'], null)
                  ],
                  [InputSize.EXTRA_LARGE]
                ),
                null,
                null,
                null,
                null
              ),
              new SegmentEntry(
                v4(),
                SegmentEntryType.MASTER_DATA,
                new MasterData(
                  v4(),
                  [
                    new Question(
                      v4(),
                      QuestionType.TEXTFIELD,
                      ['Vorwahl'],
                      null
                    ),
                    new Question(v4(), QuestionType.TEXTFIELD, ['Nummer'], null)
                  ],
                  [InputSize.MEDIUM, InputSize.LARGE]
                ),
                null,
                null,
                null,
                null
              )

          ]
        ]
      ])
    );
    */
  }
}
