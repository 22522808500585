import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Roles } from '../../models/roles';
import { DataModelService } from './../../utils/datamodel.service';

/**
 * Add the template content to the DOM unless the condition is true.
 */
@Directive({ selector: '[appRole]' })
export class RoleDirective {
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private dataModel: DataModelService
  ) {}

  @Input() set appRole(role: Roles) {
    if (
      (this.dataModel.user.role === role ||
        this.dataModel.user.role === Roles.ADMIN) &&
      !this.hasView
    ) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
