<div *ngIf="dataModel.bypassUsualRouterOutlet.getValue(); else normal">
  <router-outlet></router-outlet>
</div>
<ng-template #normal>
  <div *ngIf="authService.isLoggedIn; else landing" class="height">
    <app-headerbar></app-headerbar>
    <app-application></app-application>
  </div>
</ng-template>

<ng-template #landing>
  <div>
    <router-outlet></router-outlet>
  </div>
  <div id="footernavi" *ngIf="isFooterVisible()">
    <app-landingfooter></app-landingfooter>
  </div>
</ng-template>
