import { QuestionType } from './questionType';
import { InputValidator } from './inputValidator';
import { InputType } from './inputType';
export class Question {
  constructor(
    public _id: string,
    public type: QuestionType,
    public textParts: string[],
    public predefinedAnswers: string[],
    public inputSize: number,
    public validators: InputValidator[],
    public inputType: InputType,
    public parentQuestionUUID: string,
    public parentConditions: string[],
    public parentDefinedAnswers: string[]
  ) {}
}
