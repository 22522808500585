/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chapter-card.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/ng-zorro-antd/card/ng-zorro-antd-card.ngfactory";
import * as i3 from "ng-zorro-antd/card";
import * as i4 from "ng-zorro-antd/core";
import * as i5 from "../format-text/format-text.component.ngfactory";
import * as i6 from "../format-text/format-text.component";
import * as i7 from "@angular/common";
import * as i8 from "../../../../../node_modules/ng-zorro-antd/button/ng-zorro-antd-button.ngfactory";
import * as i9 from "ng-zorro-antd/button";
import * as i10 from "@angular/cdk/observers";
import * as i11 from "@angular/platform-browser/animations";
import * as i12 from "./chapter-card.component";
import * as i13 from "../../../utils/language.service";
import * as i14 from "../../../utils/text-resolver.service";
var styles_ChapterCardComponent = [i0.styles];
var RenderType_ChapterCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChapterCardComponent, data: { "animation": [{ type: 7, name: "contentExpand", definitions: [{ type: 0, name: "off", styles: { type: 6, styles: { opacity: 0, height: 0 }, offset: null }, options: undefined }, { type: 0, name: "on", styles: { type: 6, styles: { opacity: 1, height: "*" }, offset: null }, options: undefined }, { type: 1, expr: "off => on", animation: { type: 4, styles: null, timings: "400ms ease-in-out" }, options: null }, { type: 1, expr: "on => off", animation: { type: 4, styles: null, timings: "400ms ease-in-out" }, options: null }], options: {} }] } });
export { RenderType_ChapterCardComponent as RenderType_ChapterCardComponent };
function View_ChapterCardComponent_1(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_ChapterCardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "image"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "title-font"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chapterCard.imagePath; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.languageService.translate()[_co.chapterCard.title].toUpperCase(); _ck(_v, 3, 0, currVal_1); }); }
export function View_ChapterCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "margin"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "nz-card", [], [[2, "ant-card-loading", null], [2, "ant-card-bordered", null], [2, "ant-card-hoverable", null], [2, "ant-card-small", null], [2, "ant-card-contain-grid", null], [2, "ant-card-type-inner", null], [2, "ant-card-contain-tabs", null]], null, null, i2.View_NzCardComponent_0, i2.RenderType_NzCardComponent)), i1.ɵdid(2, 49152, null, 2, i3.NzCardComponent, [i4.NzConfigService, i1.Renderer2, i1.ElementRef], { nzTitle: [0, "nzTitle"] }, null), i1.ɵqud(603979776, 1, { tab: 0 }), i1.ɵqud(603979776, 2, { grids: 1 }), (_l()(), i1.ɵeld(5, 0, null, 0, 13, "div", [["class", "content-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "p", [["class", "intro-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-format-text", [], null, null, null, i5.View_FormatTextComponent_0, i5.RenderType_FormatTextComponent)), i1.ɵdid(9, 49152, null, 0, i6.FormatTextComponent, [], { text: [0, "text"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [], [[24, "@contentExpand", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChapterCardComponent_1)), i1.ɵdid(12, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 5, "div", [["class", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 4, "button", [["class", "default-button"], ["nz-button", ""], ["nzType", "primary"]], [[1, "nz-wave", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleCardSize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_NzButtonComponent_0, i8.RenderType_NzButtonComponent)), i1.ɵprd(512, null, i4.NzUpdateHostClassService, i4.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(16, 1818624, null, 1, i9.NzButtonComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2, i10.ContentObserver, i4.NzUpdateHostClassService, i1.NgZone, i4.NzConfigService, [2, i4.NZ_WAVE_GLOBAL_CONFIG], [2, i11.ANIMATION_MODULE_TYPE]], { nzType: [0, "nzType"] }, null), i1.ɵqud(603979776, 3, { listOfIconElement: 1 }), (_l()(), i1.ɵted(18, 0, [" ", " "])), (_l()(), i1.ɵand(0, [["extraTemplate", 2]], null, 0, null, View_ChapterCardComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_8 = i1.ɵnov(_v, 19); _ck(_v, 2, 0, currVal_8); var currVal_9 = _co.textResolverService.getLanguageText(_co.chapterCard.introductionText); _ck(_v, 9, 0, currVal_9); var currVal_11 = _co.open; _ck(_v, 12, 0, currVal_11); var currVal_13 = "primary"; _ck(_v, 16, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chapterCard.title; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 2).nzLoading; var currVal_2 = i1.ɵnov(_v, 2).nzBordered; var currVal_3 = i1.ɵnov(_v, 2).nzHoverable; var currVal_4 = (i1.ɵnov(_v, 2).nzSize === "small"); var currVal_5 = (i1.ɵnov(_v, 2).grids && i1.ɵnov(_v, 2).grids.length); var currVal_6 = (i1.ɵnov(_v, 2).nzType === "inner"); var currVal_7 = !!i1.ɵnov(_v, 2).tab; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_10 = _co.contentExpandStatus; _ck(_v, 10, 0, currVal_10); var currVal_12 = i1.ɵnov(_v, 16).nzWave; _ck(_v, 14, 0, currVal_12); var currVal_14 = _co.getButtonText(); _ck(_v, 18, 0, currVal_14); }); }
export function View_ChapterCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chapter-card", [], null, null, null, View_ChapterCardComponent_0, RenderType_ChapterCardComponent)), i1.ɵdid(1, 49152, null, 0, i12.ChapterCardComponent, [i13.LanguageService, i14.TextResolverService], null, null)], null, null); }
var ChapterCardComponentNgFactory = i1.ɵccf("app-chapter-card", i12.ChapterCardComponent, View_ChapterCardComponent_Host_0, { chapterCard: "chapterCard", idToScroll: "idToScroll", yOffsetForScroll: "yOffsetForScroll", isOpen: "isOpen" }, { opened: "opened" }, ["*"]);
export { ChapterCardComponentNgFactory as ChapterCardComponentNgFactory };
