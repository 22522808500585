import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-popover-marked-manual',
  templateUrl: './popoverMarkedManual.component.html',
  styleUrls: ['./popoverMarkedManual.component.css']
})
export class PopoverMarkedManualComponent {
  @Input()
  iconTitle: string;
  @Input()
  iconPopover: string;
  @Input()
  title: string;
  @Input()
  text: string;

  constructor() {}
}
