/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./infos.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./plaintext/plaintext.component.ngfactory";
import * as i3 from "./plaintext/plaintext.component";
import * as i4 from "../../../utils/language.service";
import * as i5 from "../../../utils/question.service";
import * as i6 from "../../../utils/text-resolver.service";
import * as i7 from "./list/list.component.ngfactory";
import * as i8 from "./list/list.component";
import * as i9 from "./list-grouped/list-grouped.component.ngfactory";
import * as i10 from "./list-grouped/list-grouped.component";
import * as i11 from "@angular/common";
import * as i12 from "./infos.component";
import * as i13 from "../../../utils/helpMap.service";
var styles_InfosComponent = [i0.styles];
var RenderType_InfosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InfosComponent, data: {} });
export { RenderType_InfosComponent as RenderType_InfosComponent };
function View_InfosComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-plaintext", [], null, null, null, i2.View_PlaintextComponent_0, i2.RenderType_PlaintextComponent)), i1.ɵdid(1, 49152, null, 0, i3.PlaintextComponent, [i4.LanguageService, i5.QuestionService, i6.TextResolverService], { segmentEntry: [0, "segmentEntry"], entryHelpMaps: [1, "entryHelpMaps"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.segmentEntry; var currVal_1 = _co.helpMapService.getHelpMapForTextParts(_co.segmentEntry); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_InfosComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-list", [], null, null, null, i7.View_ListComponent_0, i7.RenderType_ListComponent)), i1.ɵdid(1, 114688, null, 0, i8.ListComponent, [i4.LanguageService, i5.QuestionService, i6.TextResolverService], { segmentEntry: [0, "segmentEntry"], entryHelpMaps: [1, "entryHelpMaps"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.segmentEntry; var currVal_1 = _co.helpMapService.getHelpMapForTextParts(_co.segmentEntry); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_InfosComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-list-grouped", [], null, null, null, i9.View_ListGroupedComponent_0, i9.RenderType_ListGroupedComponent)), i1.ɵdid(1, 49152, null, 0, i10.ListGroupedComponent, [i4.LanguageService, i5.QuestionService, i6.TextResolverService], { segmentEntry: [0, "segmentEntry"], entryHelpMaps: [1, "entryHelpMaps"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.segmentEntry; var currVal_1 = _co.helpMapService.getHelpMapForTextParts(_co.segmentEntry); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_InfosComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfosComponent_1)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfosComponent_2)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfosComponent_3)), i1.ɵdid(5, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isPlaintext(_co.segmentEntry.entry); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isList(_co.segmentEntry.entry); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.isListGrouped(_co.segmentEntry.entry); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_InfosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-infos", [], null, null, null, View_InfosComponent_0, RenderType_InfosComponent)), i1.ɵdid(1, 49152, null, 0, i12.InfosComponent, [i4.LanguageService, i13.HelpMapService], null, null)], null, null); }
var InfosComponentNgFactory = i1.ɵccf("app-infos", i12.InfosComponent, View_InfosComponent_Host_0, { segmentEntry: "segmentEntry" }, {}, []);
export { InfosComponentNgFactory as InfosComponentNgFactory };
