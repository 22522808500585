import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LanguageService } from '../../../utils/language.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css'],
  animations: [
    trigger('nextSegment', [
      state(
        'display',
        style({
          opacity: '1.0',
          right: '0em',
          left: '0em',
          height: '*'
        })
      ),
      state(
        'positionLeft',
        style({
          opacity: '0.0',
          right: 'auto',
          left: '-2em',
          height: '*'
        })
      ),
      state(
        'positionRight',
        style({
          opacity: '0.0',
          right: '-2em',
          left: 'auto',
          height: '*'
        })
      ),
      transition('display => positionRight', animate('400ms ease-in-out')),
      transition('display => positionLeft', animate('400ms ease-in-out')),
      transition('positionLeft => positionRight', animate('0ms ease-in-out')),
      transition('positionRight => positionLeft', animate('0ms ease-in-out')),
      transition('positionLeft => display', animate('400ms ease-in-out')),
      transition('positionRight => display', animate('400ms ease-in-out'))
    ])
  ]
})
export class SliderComponent {
  @Input()
  amountSegments: number;
  @Input()
  showIndicators: boolean;
  @Input()
  enableJumper = false;
  @Input()
  idToScroll: string;
  @Input()
  yOffsetForScroll = 0;
  @Input()
  demo = false;
  @Input()
  nextReady = true;

  @Output()
  currentSegmentEmitter = new EventEmitter<number>();
  @Output()
  nextClicked = new EventEmitter<void>();
  @Output()
  prevClicked = new EventEmitter<void>();

  _currentSegment = 0;

  nextSegmentStatus = 'display';

  constructor(public languageService: LanguageService) {}

  @Input()
  set currentSegment(currentSegment: number) {
    setTimeout(() => (this._currentSegment = currentSegment<0?this.amountSegments-1:currentSegment), 500);
  }

  evalSegment(nextSegment: number) {
    this.nextSegmentStatus =
      nextSegment > this._currentSegment ? 'positionLeft' : 'positionRight';
    setTimeout(() => {
      this.nextSegmentStatus =
        nextSegment > this._currentSegment ? 'positionRight' : 'positionLeft';
    }, 500);
    setTimeout(() => {
      this.setSegment(nextSegment);
    }, 500);
  }

  setSegment(nextSegment: number) {
    this._currentSegment = nextSegment;
    this.currentSegmentEmitter.emit(this._currentSegment);
    if (this.idToScroll) {
      this.scrollToID();
    }
    this.nextSegmentStatus = 'display';
  }

  scrollToID() {
    const y = document.getElementById(this.idToScroll).getBoundingClientRect()
      .top;
    const main = document.getElementById('appContent');
    main.scrollTo({
      top: y + main.scrollTop + this.yOffsetForScroll,
      behavior: 'smooth'
    });
    /*
    window.scrollTo({
      top: y + window.pageYOffset + this.yOffsetForScroll + 85.0,
      behavior: 'smooth'
    });
    */
  }
}
