import { LanguageInterface } from './language.interface';

export class LanguageDeutsch implements LanguageInterface {
  login = 'Login';
  informations = 'Informationen';
  contact = 'Kontakt';
  imprint_header = 'Impressum';
  imprint_content = [
    'ePrivacy GmbH',
    'vertreten durch den Geschäftsführer',
    'Prof. Dr. Christoph Bauer',
    'Große Bleichen 21, 20354 Hamburg',
    'Telefon: +49 40 609451 810',
    'Fax: +49 40 609451 820 ',
    'AG Hamburg, HRB 118163',
    'UStID DE277922094',
    '',
    'Nachfolgend eine Auflistung der verwendeten Bilder auf dieser Website von Adobe Stock:',
    '© StockPhotoPro',
    '© Tiko',
    '© ipopba',
    '© rogerphoto',
    '© joyfotoliakid'
  ];
  privacypolice = 'Datenschutzerklärung';
  agb = 'Lizenzbedingungen';
  contactform = 'Kontaktformular';
  name = 'Deutsch';
  account = 'Account';
  logout = 'Ausloggen';
  settings = 'Einstellungen';
  language = 'Sprache';
  username = 'Benutzername';
  password = 'Passwort';
  home = 'HOME';
  introduction = 'EINFÜHRUNG';
  audit = 'AUDIT';
  uploads = 'UPLOADS';
  files = 'Dateien';
  messages = 'Nachrichten';
  glossar = 'GLOSSAR';
  downloads = 'DOWNLOADS';
  history = 'HISTORY';
  manageManual = 'Einführung bearbeiten';
  reportDesigner = 'Report Designer';
  manual = 'ANLEITUNG';
  manualIntroTitle = 'Willkommen im ePrivacyaudit';
  blogLink = 'NEWS';
  blogLang = 'de';
  noManualAvailable =
    'Entschuldigung! Leider ist derzeit kein Handbuch verfügbar.';
  support = 'SUPPORT';
  faqs = 'FAQs';
  implementation = 'UMSETZUNG';
  praxistips = 'PRAXISTIPS';
  more = 'MEHR';
  less = 'WENIGER';
  notes = 'Notizen';
  ok = 'Ok';
  cancel = 'Abbrechen';
  documents = 'UNTERLAGEN';
  stammdaten = 'STAMMDATEN';
  edit = 'BEARBEITEN';
  save = 'SPEICHERN';
  consulting = 'Consulting';
  manageAudit = 'Audit bearbeiten';
  manageMasterData = 'Stammdaten bearbeiten';
  accessDeniedTitle = 'Fehlende Berechtigung';
  accessDeniedText = 'Ihnen fehlt die Berechtigung diese Seite aufzurufen!';
  templates = 'VORLAGEN';
  documentManagement = 'DOKUMENTE';
  next = 'WEITER';
  prev = 'ZURÜCK';
  rememberMe = 'Angemeldet bleiben';
  accountNotEmpty = 'Bitte gib deinen Benutzernamen an!';
  pwNotEmpty = 'Bitte gib dein Passwort ein!';
  loginFailed = 'Benutzername und / oder Passwort falsch!';
  notAvailableDemoTitle = 'In Bearbeitung';
  notAvailableDemoText =
    'Dieses Kapitel befindet sich aktuell noch in der Erstellung und steht Ihnen bald zur Verfügung. Im Bereich Vorlagen erhalten Sie jetzt bereits Muster zur Bearbeitung des Themas. Sollten Sie aktuelle Dokumente zu dem Thema vorliegen haben, können Sie diese hochladen und in Prüfung geben.';
  landing_tiles = [
    [
      'Willkommen beim ePrivacyaudit - dem smarten Datenschutzmanagement',
      'Die innovative Software für die einfache Verwaltung der Datenschutz-Dokumentation. Das praktische Tool umfasst zahlreiche Mustervorlagen, die Ihnen die Erstellung der erforderlichen Dokumente erleichtern. Als eine zentrale Plattform zum Verwalten aller relevanten Dokumente kann ePrivacyaudit von mehreren Nutzern im Unternehmen verwendet werden. Die einfach zu bedienende user-freundliche Benutzeroberfläche ermöglicht Ihnen einen guten Überblick.'
    ],
    [
      'Unsere langjährige Expertise im Software-Format',
      'ePrivacyaudit ist von Juristen, Datenschutzbeauftragten sowie IT-Spezialisten entwickelt worden und bietet ein effizientes Werkzeug für den Verantwortlichen im Datenschutz. Unsere langjährige Expertise und Kenntnisse aus der Beratung von Unternehmen mit digitalen Produkten und Dienstleistungen sind in die Software eingeflossen. ePrivacyaudit kann von Unternehmen unterschiedlicher Größe sowie von externen und internen Datenschutzbeauftragten eingesetzt werden.'
    ],
    [
      'Die Datenschutz Dokumentation immer aktuell und jederzeit zugreifbar',
      'ePrivacyaudit ermöglicht sehr einfache Updates der bereits erstellten Dokumente, so dass Ihre Datenschutz-Dokumentation immer up-to-date bleibt. Die Software führt Sie durch alle Bereiche, in denen die DSGVO Anforderungen an Unternehmen stellt. Der aktuelle Stand Ihrer GDPR-Compliance wird erfasst und Sie haben immer einen Überblick sowie die notwendige Hilfestellung bei der Umsetzung offener Punkte. Der Zugriff auf ePrivacyaudit ist jederzeit von überall möglich und erlaubt Ihnen flexibles Arbeiten.'
    ],
    [
      'Die Mustervorlagen erleichtern die Dokumentationserstellung und sparen Zeit',
      'Die Nutzung unsere Mustervorlagen für die Dokumentationserstellung und Aktualisierung erleichtert Ihnen die Arbeit und spart außerdem viel Zeit. Die Richtigkeit der Angaben ist durch erfahrene Datenschutz-Juristen geprüft. Die Software und alle Vorlagen sind auf Deutsch und Englisch verfügbar.'
    ],
    [
      'Folgende Module sind im ePrivacyaudit enthalten:',
      '• Datenschutzkonforme Datenverarbeitung\n' +
        '• Informationspflichten\n' +
        '• Verarbeitungsübersicht\n' +
        '• TOMs' +
        '• Prozess bei Datenpannen\n' +
        '• Auftragsverarbeitung (ADV-Verträge)\n' +
        '• Datenschutzmanagementsystem\n' +
        '• Und weitere…'
    ]
  ];
  privacypolice_header = 'DATENSCHUTZERKLÄRUNG EPRIVACY GMBH';
  privacypolice_content: JSON = <JSON>(<unknown>{
    '1': {
      '': {
        '1':
          'Mit dieser Datenschutzerklärung möchten wir Sie darüber informieren, wie wir personenbezogene Daten verarbeiten. Der Schutz Ihrer Privatsphäre ist für uns von höchster Bedeutung, deshalb ist das Einhalten der gesetzlichen Bestimmungen zum Datenschutz für uns selbstverständlich. '
      }
    },
    '2': {
      'Name und Kontaktdaten des Verantwortlichen': {
        '1': 'ePrivacy GmbH',
        '2': 'vertreten durch Prof. Dr. Christoph Bauer',
        '3': 'Große Bleichen 21, 20354 Hamburg',
        '4': '',
        '5': 'info@eprivacy.eu'
      }
    },
    '3': {
      'Personenbezogene Daten': {
        '1':
          'Personenbezogene Daten sind alle Informationen über eine bestimmte oder bestimmbare Person. Darunter fallen die folgenden Kategorien personenbezogener Daten an, die wir verarbeiten: ',
        '2': [
          'Kontaktinformationen (z.B. E-mailadresse, Name und Jobposition)',
          'Unternehmensdaten',
          'Online-Identifikatoren (z. B. Ihre IP-Adresse, Browsertyp und Browserversion, das verwandte Betriebssystem, die Referrer-URL, den Dateinamen, den Zugriffsstatus, die übertragene Datenmenge, das Datum und die Uhrzeit der Serveranfrage)'
        ]
      }
    },
    '4': {
      Verarbeitungszwecke: {
        '1': 'Wir verarbeiten Ihre Daten zu den folgenden Zwecken:',
        '2': [
          'Für die von Ihnen gewünschte Kontaktaufnahme,',
          'zur Abwicklung von Verträgen mit Ihnen (Projektmanagement, externer Datenschutzbeauftragter),',
          'zur Bereitstellung unseres Dienstes',
          'zur Qualitätssicherung und',
          'für unsere Statistik'
        ]
      }
    },

    '5': {
      'Rechtsgrundlage der Datenverarbeitung': {
        '1':
          'Die Verarbeitung Ihrer Daten geschieht auf den folgenden Rechtsgrundlagen:',
        '2': [
          'zur Durchführung eines Vertrags mit Ihnen nach Art. 6 Abs. 1 lit. b) DSGVO',
          'zur Erfüllung gesetzlicher Pflichten nach Art. 6 Abs. 1 lit. c) DSGVO oder',
          'aus einem berechtigten Interesse nach Art. 6 Abs. 1 lit. f) DSGVO.'
        ]
      }
    },
    '6': {
      'Berechtigte Interessen': {
        '1':
          'Sofern wir die Verarbeitung Ihrer personenbezogenen Daten auf berechtigte Interessen iSv. Art. 6 Abs. 1 lit. f) DS-GVO stützen, sind solche',
        '2': [
          'die Verbesserung unseres Angebots,',
          'der Schutz unserer Systeme vor Missbrauch und',
          'die Erstellung von Statistiken.'
        ]
      }
    },
    '7': {
      'Empfänger oder Kategorien von Empfängern der personenbezogenen Daten': {
        '1':
          'Bei der Verarbeitung Ihrer Daten arbeiten wir mit den folgenden Dienstleistern zusammen, die Zugriff auf Ihre Daten haben:',
        '2': ['Web-Hosting', 'Partnerkanzlei']
      }
    },
    '8': {
      Speicherdauer: {
        '1':
          'Wir speichern Ihre personenbezogenen Daten nur so lange, wie es für die Erreichung des Verarbeitungszweckes nötig ist oder die Speicherung einer gesetzlichen Aufbewahrungsfrist unterliegt.',
        '2': 'Wir speichern Ihre Daten,',
        '3': [
          'wenn wir die Daten zur Durchführung eines Vertrags benötigen, höchstens so lang, wie das Vertragsverhältnis mit Ihnen besteht oder gesetzliche Aufbewahrungsfristen laufen,',
          'wenn wir die Daten auf der Grundlage eines berechtigten Interesses verwenden, höchstens so lang, wie Ihr Interesse an einer Löschung oder Anonymisierung nicht überwiegt.'
        ]
      }
    },

    '9': {
      'Ihre Rechte': {
        '1':
          'Sie haben – teilweise unter bestimmten Voraussetzungen – das Recht,',
        '2': [
          'unentgeltlich Auskunft über die Verarbeitung Ihrer Daten zu verlangen, sowie den Erhalt einer Kopie Ihrer personenbezogenen Daten. Auskunft können Sie u. a. verlangen über die Zwecke der Verarbeitung, die Kategorien der personenbezogenen Daten, die verarbeitet werden, die Empfänger der Daten (sofern eine Weitergabe erfolgt), die Dauer der Speicherung oder die Kriterien für die Festlegung der Dauer;',
          'Ihre Daten zu berichtigen. Sollten Ihre personenbezogenen Daten unvollständig sein, so haben Sie, unter Berücksichtigung der Verarbeitungszwecke, das Recht die Daten zu vervollständigen;',
          'Ihre Daten löschen oder sperren zu lassen. Gründe für das Bestehen eines Löschungs-/Sperrungsanspruchs können u.a. sein der Widerruf der Einwilligung auf dem die Verarbeitung fußt, die betroffene Person legt Widerspruch gegen die Verarbeitung ein, die personenbezogenen Daten wurden unrechtmäßig verarbeitet;',
          'die Verarbeitung einschränken zu lassen;',
          'der Verarbeitung Ihrer Daten zu widersprechen;',
          'Ihre Einwilligung zur Verarbeitung Ihrer Daten für die Zukunft zu widerrufen und',
          'sich bei der zuständigen Aufsichtsbehörde über eine unzulässige Datenverarbeitung zu beschweren.'
        ]
      }
    },
    '10': {
      'Erfordernis oder Verpflichtung zur Bereitstellung von Daten': {
        '1':
          'Soweit dies bei der Erhebung nicht ausdrücklich angegeben ist, ist die Bereitstellung von Daten nicht erforderlich oder verpflichtend. Eine solche Verpflichtung kann sich aus gesetzlichen Vorgaben oder vertraglichen Regelungen ergeben.'
      }
    },

    '11': { 'Weitere Informationen zum Datenschutz': { '1': '' } },

    '12': {
      Logfiles: {
        '1':
          'Bei jedem Zugriff auf unsere Internet-Seiten werden Nutzungsdaten durch den jeweiligen Internetbrowser übermittelt und in Protokolldateien, den sogenannten Server-Logfiles, gespeichert. Die dabei gespeicherten Datensätze enthalten die folgenden Daten: Datum und Uhrzeit des Abrufs IP-Adresse des zugreifenden Rechners. Diese Logfile-Datensätze werden in anonymisierter Form ausgewertet, um Fehler zu finden und zu beheben oder zur Betrugsprävention.'
      }
    },

    '13': {
      Cookies: {
        '1':
          'Ein Cookie ist eine Textdatei mit einer Identifikationsnummer, die bei der Nutzung der Website zusammen mit den anderen, eigentlich angeforderten Daten an den Computer des Nutzers übermittelt und dort hinterlegt wird. Die Datei wird dort für einen späteren Zugriff bereitgehalten und dient zur Authentifizierung des Nutzers. Da Cookies nur einfache Dateien und keine ausführbaren Programme sind, geht von ihnen keine Gefahr für den Computer aus.',
        '2':
          'Je nach gewählter Einstellung des Internetbrowsers durch den Nutzer akzeptiert dieser Cookies automatisch. Diese Einstellung kann aber geändert und die Speicherung von Cookies deaktiviert oder dergestalt eingestellt werden, dass der Nutzer benachrichtigt wird, sobald ein Cookie gesetzt wird. Im Falle einer Deaktivierung der Cookienutzung stehen ggf. einige Funktionen der Webseite nicht oder nur eingeschränkt zur Verfügung. Sie können die Setzung von Cookies durch unsere Internetseite jederzeit mittels einer entsprechenden Einstellung des genutzten Internetbrowsers verhindern und damit der Setzung von Cookies dauerhaft widersprechen.',
        '3':
          'Bereits aktive Cookies können jederzeit über einen Internetbrowser oder andere Softwareprogramme gelöscht werden. '
      }
    },

    '14': {
      'Persistent Cookies': {
        '1':
          'Sie haben die Möglichkeit einen „Remember-me“ Cookie zu setzen, um den Anmeldeprozess zu ihrem Account zu vereinfachen. Dieser persistente Cookies bleibt nach dem Schließen des Browsers auf Ihrem Endgerät erhalten. Die Funktionsdauer entspricht einem Jahr. Danach werden diese automatisch gelöscht.'
      }
    },

    '15': {
      'Stand dieser Datenschutzerklärung': {
        '1':
          'Bei Änderungen unserer Prozesse passen wir die Informationen an. ',
        '2': 'Stand der Datenschutzerklärung: Juni 2022'
      }
    }
  });
  terms_header =
    'ALLGEMEINE LIZENZBEDINGUNGEN FÜR DIE NUTZUNG DES EPRIVACYAUDITS';
  terms_content: JSON = <JSON>(<unknown>{
    '1': {
      '1. Allgemeines, Geltungsbereich der Lizenz': [
        'Die ePrivacy GmbH (nachfolgend „ePrivacy“) bietet zur Vorbereitung der Umsetzung auf die Anforderungen der Datenschutzgrundverordnung (DSGVO) eine Auditierungssoftware unter der Bezeichnung „ePrivacyaudit“ an (nachfolgend einheitlich auch „IT-Produkt“ genannt).',
        'Die nachfolgenden Bestimmungen regeln die Nutzung des ePrivacyaudits sowie die Übertragung von Nutzungsrechten (Lizenz) an der entsprechenden Software.'
      ]
    },
    '2': {
      '2. Lizenz': [
        '2.1. Umfang der Nutzungsrechte - Nach Maßgabe der nachfolgenden Bestimmungen überträgt ePrivacy dem Lizenznehmer das nicht-ausschließliche und nicht-übertragbare Recht, die dem ePrivacyaudit zugrundeliegende Software zu nutzen.',
        '2.2. Der Lizenznehmer verpflichtet sich, diese Software nicht zu verändern, zu verarbeiten oder zu dekompilieren. Er verpflichtet sich auch, die Sicherheitsvorkehrungen nicht zu umgehen sowie  keine rassistischen, verfassungsfeindlichen, pornografischen oder sittenwidrige Inhalte hochzuladen.',
        '2.3. Die Nutzungsrechte werden dem Lizenznehmer zeitlich befristet eingeräumt, so wie es im Vertrag genau geregelst ist.'
      ]
    },

    '3': {
      '3. Lizenzgebühr, Vergütung': [
        '3.1. Es gibt zwei Möglichkeiten, den Audit zu nutzen. Der sogenannte Quickcheck und der Datenschutzerklärungsgenerator sind unentgeltlich. Das Lizenzmodell ist entgeltlich. Im letzteren Falle verpflichtet sich der Lizenznehmer, an ePrivacy für die Nutzung des ePrivacyaudits die individuell vereinbarte Nutzungsgebühr zu bezahlen.',
        '3.2. Darüber hinausgehende Leistungen werden, soweit die Parteien nichts Abweichendes vereinbart haben, zusätzlich vergütet.'
      ]
    },

    '4': {
      '4. Eigenwerbung / Veröffentlichung der Siegel': [
        'ePrivacy ist berechtigt, im Rahmen der Eigenwerbung auf die Zusammenarbeit mit dem Lizenznehmer hinzuweisen, sofern die Parteien nichts Abweichendes vereinbart haben.'
      ]
    },

    '5': {
      '5. Haftung': [
        '5.1. ePrivacy schließt eine Haftung für leicht fahrlässige Pflichtverletzung aus, sofern diese keine vertragswesentlichen Pflichten betreffen, Leben, Gesundheit oder Körper betroffen sind oder Ansprüche nach dem Produkthaftungsgesetz oder aus Garantie bestehen. Gleiches gilt für Pflichtverletzungen von Vertretern und Erfüllungsgehilfen.',
        '5.2. Die Haftung für sonstige Schäden, die durch Verzug, durch eine zu vertretende Unmöglichkeit oder die Verletzung einer wesentlichen Vertragspflicht entstehen, ist auf vertragstypische und vorhersehbare Schäden begrenzt. Eine Haftung für mittelbare Schäden, insbesondere Vermögensschäden, wird ausgeschlossen.',
        '5.3. Die Haftung ist der Höhe nach beschränkt auf maximal 10.000,00 Euro pro Jahr. ePrivacy haftet nicht für Änderungen in der Rechtsprechung und in den rechtlichen Rahmenbedingungen.',
        'ePrivacy weist darauf hin, dass der ePrivacyaudit an den datenschutzrechtlichen Bestimmungen der DSGVO ausgerichtet ist. Angesichts der unscharfen Konturen der DSGVO weist ePrivacy jedoch ausdrücklich darauf hin, dass auch eine vollständige Erfüllung der Auditierungskriterien des ePrivacyaudits nicht bedeutet, dass damit ein Verstoß gegen die datenschutzrechtlichen Bestimmungen der DSGVO vollständig ausgeschlossen ist. Der ePrivacyaudit bietet eine Unterstützung bei der Umsetzung der durch die DSGVO zu erfüllenden Kriterien. ePrivacy kann jedoch nicht ausschließen, dass ein mit dieser Frage befasstes Gericht oder eine Datenschutzbehörde oder eine vergleichbare Institution dennoch andere Rechtsauffassungen vertritt und weitergehende Forderungen stellt, als dies nach dem ePrivacyaudit der Fall ist. ePrivacy schließt daher jede Haftung in diesem Fall aus.',
        '5.4 Die Haftung der ePrivacy GmbH für die inhaltliche Richtigkeit und Vollständigkeit der mit dem Datenschutzerklärungs-Generator erstellten Texte ist ausgeschlossen. Bitte überprüfen Sie vor der Verwendung der von Ihnen erstellten Texte, ob die vorgeschlagenen Textbausteine den gesetzlichen Anforderungen an die Datenverarbeitungsvorgänge in Ihrem Unternehmen entsprechen und ob gegebenenfalls weitere oder andere Angaben erforderlich sind.'
      ]
    },

    '6': {
      '6. Keine Rechtsberatung': [
        'ePrivacy leistet keine Rechtsberatung im Sinne des Rechtsdienstleistungsgesetzes. Eine individuelle rechtliche Prüfung kann und darf ePrivacy nicht leisten. Ein positives Prüfungsergebnis beinhaltet daher insbesondere nicht die Aussage, dass das IT-Produkt des Lizenznehmers allen einschlägigen gesetzlichen Bestimmungen, insbesondere den Vorschriften des Bundesdatenschutzgesetzes bzw. Telemediengesetzes bzw. den europäischen Datenschutzvorschriften (DSGVO etc.)  entspricht.'
      ]
    },
    '7': {
      '7. Geheimhaltung': [
        '7.1. Jede Partei ist verpflichtet, alle Informationen kaufmännischer oder technischer Natur aus dem Bereich der anderen Partei in mündlicher, schriftlicher oder sonstiger Form, zu denen sie im Zusammenhang mit dieser Vereinbarung Zugang erhält, insbesondere Informationen über Betriebs- und Geschäftsgeheimnisse (z. B. technische Ablaufprozesse, Formeln, Quellcodes, Produktdesigns, Kosten und andere unveröffentlichte Finanzinformationen und sonstige Marketingdaten) sowie den Abschluss dieser Vereinbarung und deren Inhalt vertraulich zu behandeln und weder ganz noch teilweise an Dritte weiterzugeben. Jede Partei wird die vorgenannten Informationen ausschließlich zum Zwecke der Erbringung der Vertragsleistung und weder für andere eigene, noch für Zwecke Dritter nutzen.',
        '7.2. Von den in Ziffer 7.1 genannten Verpflichtungen sind Informationen ausgenommen, die nachweislich',
        'a) im Zeitpunkt der Kenntniserlangung oder später allgemein zugänglich bzw. Stand der Technik sind oder',
        'b) der empfangenen Partei im Zeitpunkt der Kenntniserlangung bereits bekannt waren oder',
        'c) der empfangenen Partei durch Dritte zugänglich gemacht werden oder wurden, die die Informationen rechtmäßig erlangt haben und zur Weitergabe befugt sind oder',
        'd) zuvor schriftlich durch die überlassene Partei von diesen Verpflichtungen ausgenommen wurden.',
        '7.3. Die in dieser Ziffer 7 geregelten Verpflichtungen gelten auch nach Beendigung dieser Vereinbarung für die Dauer von 2 Jahren fort.'
      ]
    },

    '8': {
      '8. Schlussbestimmungen': [
        '8.1. Sollte eine Regelung dieser Vereinbarung unwirksam sein, bleiben die übrigen Regelungen hiervor unberührt. Die unwirksame Regelung ist durch eine Regelung zu ersetzen, die dem wirtschaftlichen Sinn der unwirksamen Regelung in wirksamer Weise am nächsten kommt. Dies gilt auch, sollte diese Vereinbarung Regelungslücken enthalten.',
        '8.2. Alle Änderungen und Ergänzungen dieses Vertrages bedürfen zu ihrer Wirksamkeit der Schriftform. Dies gilt auch für eine Änderung oder Aufhebung dieser Schriftformklausel.',
        '8.3. Diese Vereinbarung unterliegt deutschem Recht. Ausschließlicher Gerichtsstand, soweit gesetzlich zulässig, für alle Streitigkeiten aus diesem Vertragsverhältnis ist Hamburg.'
      ]
    }
  });
  manageTemplates = 'Vorlagen bearbeiten';
  deleteDocumentTitle = 'Dokument löschen';
  deleteDocumentText1 =
    'Sind Sie sich sicher, dass sie folgendes Dokument löschen wollen?';
  deleteDocumentText2 = 'Diese Aktion kann nicht rückgängig gemacht werden!';
  delete = 'LÖSCHEN';
  download = 'DOWNLOAD';
  upload = 'Hochladen';
  uploading = 'Wird hochgeladen...';
  uploadedBy = 'Hochgeladen von';
  uploadComponentHint1 = 'Klicken oder Dateien in diesen Bereich ziehen';
  uploadComponentHint2 = 'Unterstützt einzel sowie multi Upload.';
  manageUsersCompanies = 'Benutzer und Unternehmen';
  MR = 'Herr';
  MRS = 'Frau';
  test = 'Testversion';
  full = 'Vollversion';
  fullWithConsulting = 'Vollversion mit Beratung';
  fullWithDSB = 'Vollversion mit DSB';
  withoutAudit = 'Kunde ohne Audit';
  active = 'Aktiv';
  terminated = 'Gekündigt';
  onHold = 'On-Hold';
  de = 'Deutsch';
  en = 'Englisch';
  back = 'Zurück';
  companyname = 'Firmenname';
  legalForm = 'Rechtsform';
  street = 'Straße';
  plz = 'Postleitzahl';
  city = 'Stadt';
  country = 'Land';
  userInformations = 'Benutzerinformationen';
  firstname = 'Vorname';
  lastname = 'Nachname';
  prefLanguage = 'Sprache';
  emailAddress = 'E-Mailadresse';
  phone = 'Telefonnummer';
  message = 'Nachricht';
  captchaError = 'Das Captcha konnte nicht aufgelöst werden';
  companyinformations = 'Firmeninformationen';
  formOfAddress = 'Anrede';
  register = 'Registrieren';
  send = 'Absenden';
  documentManagement_intro1 =
    'Hier können Sie Ihre Dokumente und Unterlagen verwalten und so behalten Sie die Übersicht über Ihren Umsetzungsstand. Die Dateien können gelöscht oder heruntergeladen werden. Muster und Vorlagen zum Download finden Sie im Abschnitt Vorlagen';
  documentManagement_intro2 =
    'Ein neues Dokument können Sie hier hochladen, oder im jeweiligen Kapitel';
  documentManagement_heading = 'DOKUMENTE';
  downloadComp_recent = 'Aktuelle Uploads';
  downloadComp_history = 'Weitere Uploads';
  downloadComp_noactivity = 'Keine Aktivitäten';
  documentManagement_button = 'Hochladen';
  noTemplate = 'Keine Vorlagen';
  noDocument = 'Kein Dokumente';
  uploadedDocumentExistsInQueue =
    'Das ausgewählte Dokument befindet sich bereits in der Queue!';
  uploadedDocumentExistsInAudit =
    'Das ausgewählte Dokument wurde bereits im folgenden Kapitel hochgeladen:';
  notificationSuccessTitle = 'Gespeichert';
  notificationSuccessMessage = 'Aktion wurde erfolgreich gespeichert';
  notificationErrorTitle = 'Nicht gespeichert!';
  notificationErrorMessage =
    'Aktion konnte nicht erfolgreich gespeichert werden! Bitte aktualisieren Sie diese Seite und führen Sie die Aktion erneut durch!';
  establishVerifyConnection =
    'Wir stellen aktuell eine Verbindung zum Server her und schalten ihren Account frei.';
  processVerify = 'Ihr Account wird freigeschaltet';
  processVerifySuccessed = 'Ihr Account wurde freigeschaltet';
  enterUserName = 'Bitte geben Sie ihren Benutzernamen ein';
  requestNewPassword = 'Wiederherstellungsanfrage erhalten';
  requestNewPasswordSubtext =
    'Wenn Ihr Benutzer vorhanden ist, erhalten Sie gleich einen Wiederherstellungslink';
  establishVerifyConnectionSuccessed =
    'Ihr Account wurde aktiviert. Sie können sich nun einloggen.';
  processVerifyFailed = 'Ihr Account wurde nicht freigeschaltet';
  establishVerifyConnectionFailed =
    'Ihr Account konnte nicht freigeschaltet werden. Bitte kontaktieren Sie uns.';
  processRequestAccount = 'Ihr Account wurde angefordert';
  processRequestAccountMessage = 'Wir werden uns per E-Mail bei Ihnen melden.';
  processContactRequest = 'Ihre Kontaktanfrage wurde versendet';
  processContactRequestMessage =
    'Wir werden uns in Kürze per E-Mail bei Ihnen melden.';
  disclaimer =
    'Ich erkläre mich damit einverstanden, dass die ePrivacy GmbH mich unter den von mir angegebenen Daten kontaktiert, um meine Anfrage zu bearbeiten. Ich kann diese Einwilligung jederzeit unter info@eprivacy.eu widerrufen. Weitere Informationen finde ich in der Datenschutzerklärung.';
  welcomeContactTitle = 'Herzlich Willkommen im ePrivacyaudit';
  welcomeContact =
    'Wir freuen uns, dass Sie unsere einfache und benutzerfreundliche Lösung für den Datenschutz Ihres Unternehmens ausprobieren wollen. Um mit Ihnen bezüglich eines Accounts in Kontakt zu treten, benötigen wir einige Angaben Ihrerseits:';
  deleteNoticeTitle = 'Notiz löschen';
  deleteNoticeMessage =
    'Sind Sie sich sicher, dass Sie die Notiz löschen wollen? Dieser Vorgang kann nicht rückgängig gemacht werden!';
  chapterStatusNew = 'Neu';
  chapterStatusInProgress = 'In Bearbeitung beim Unternehmen';
  chapterStatusInReview = 'In Prüfung bei ePrivacy';
  chapterStatusDone = 'Bearbeitet';
  chapterStatusNewExplanation =
    'Es wurden bisher keine Angaben in der Umsetzung gemacht und keine Dokumente in den Unterlagen hochgeladen';
  chapterStatusInProgressExplanation =
    'Sie haben bereits Angaben in der Umsetzung gemacht oder Dokumente hochgeladen';
  chapterStatusInReviewExplanation =
    'Sie haben dieses Kapitel in Prüfung gegeben. Während der Prüfung ist es nicht möglich neue Dateien in dem Kapitel hochzuladen oder die Umsetzungsfunktion zu nutzen. Bitte wenden Sie sich an Ihren Ansprechpartner oder an service@eprivacy.eu, wenn Sie das Kapitel wieder freischalten oder Unterlagen ergänzen wollen.';
  chapterStatusDoneExplanation = '';
  shortVersion = 'Kurzform';
  longVersion = 'Langform';
  manageTags = 'Tags verwalten';
  chapterToInReviewTitle = 'Kapitel in Prüfung geben';
  chapterToInReview =
    'Beachten Sie bitte, dass es während der Prüfung nicht möglich ist, neue Dateien in dem Kapitel hochzuladen oder die Umsetzungsfunktion zu nutzen.\n\nSie erhalten eine Mailbenachrichtigung sobald Ihnen die geprüften Dokumente im ePrivacyaudit zur Verfügung stehen.\n\nBitte bestätigen Sie, dass Sie das Kapitel in Prüfung geben möchten.';
  chapterToDoneTitle = 'Kapitel abschließen';
  chapterToDone =
    'Beachten Sie bitte, dass es nach Abschluss des Kapitels nicht möglich ist, neue Dateien in dem Kapitel hochzuladen oder die Umsetzungsfunktion zu nutzen. Das Kapitel wird auf der Seite „Home“ als abgeschlossen angezeigt.\n\nSie können das Kapitel jedoch jederzeit wieder zur Bearbeitung freigeben.\n\nBitte bestätigen Sie, dass Sie das Kapitel abschließen möchten.';
  chapterBackToInProgressTitle = 'Freigeben';
  chapterBackToInProgress =
    'Sie haben dieses Kapitel abgeschlossen. Wenn Sie das Kapitel wieder freigeben, ändert sich der Status zu „In Bearbeitung“.';
  chapterBackToInProgressWithConsulting =
    'Sie haben dieses Kapitel abgeschlossen. Wenn Sie das Kapitel wieder freigeben, ändert sich der Status zu „In Bearbeitung“.\n\nSie werden das Kapitel nach der Bearbeitung wieder in Prüfung geben müssen.\n\nBitte bestätigen Sie, dass Sie das Kapitel wieder freigeben möchten.';
  chapterStatusToInReviewButton = 'IN PRÜFUNG';
  chapterStatusToDoneButton = 'ABSCHLIEßEN';
  chapterStatusToInProgressButton = 'FREIGEBEN';
  progress = 'Fortschritt';
  statistic = 'Statistik';
  uploadedDocuments = 'Hochgeladene Unterlagen';
  lastLoggedIn = 'Letzter Login';
  urlNotValid =
    'Entschuldigung - die angefragte URL ist nicht mehr gültig!\nBitte kontaktieren Sie uns, falls Sie dzau Hilfe benötigen.';
  pwChangedSuccesfully =
    'Das Passwort wurde erfolgreich geändert!\nSie können sich nun mit dem eingegebenen Passwort anmelden.';
  waitForVerification = 'Auf Verifikation warten...';
  landingPage = 'Startseite';
  forgotPW = 'Passwort vergessen?';
  forgotPWTitle = 'Passwort vergessen';
  forgotPWMessage =
    'Falls Sie Ihr Passwort oder Benutzernamen vergessen haben, so kontaktieren Sie\n\n<b>service@eprivacy.eu</b>\n\nGeben Sie Ihren Benutzernamen an und wir kommen auf Sie zurück!';
  filesSelectedSingular = 'DATEI AUSGEWÄHLT';
  filesSelectedPlural = 'DATEIEN AUSGEWÄHLT';
  chooseChapter = 'Kapitel auswählen...';
  filesAlreadyInQueue = 'Folgende Dateien sind bereits in der Queue:';
  filesAlreadyUploaded = 'Folgende Dateien wurden bereits hochgeladen:';
  filesAlreadyInChapters = 'Die Dateien befinden sich in folgenden Kapiteln:';
  filesCouldNotBeAdded = 'Einige Dateien konnten nicht hinzugefügt werden!';
  close = 'Schließen';
  templateIntroductionText =
    'Unten stehend finden Sie unsere Vorlagen zum Thema Datenschutz. Achten Sie bei der Nutzung der Muster darauf, diese an Ihre Datenprozesse anzupassen.';

  gbWarning =
    'Bitte wechseln Sie in der oberen, rechten Ecke die Sprache auf Englisch, um auf die Vorlagen zur Umsetzung der UK GDPR zugreifen zu können.';
  generateReportButton = 'REPORT GENERIEREN';
  dropdownSelect = 'Auswählen...';
  userLocked = 'Gültigkeit';
}
